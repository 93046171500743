import { CSSProperties, FC, ReactNode, useEffect, useState } from "react"

export const Paper: FC<{ children?: ReactNode; style?: CSSProperties }> = ({ children, style }) => {
    return (
        <div className="paper" style={style}>
            {children}
        </div>
    )
}

export const FlexRow: FC<{ children?: ReactNode; style?: CSSProperties }> = ({ children, style }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                ...style
            }}
        >
            {children}
        </div>
    )
}

export const FlexCol: FC<{ children?: ReactNode; style?: CSSProperties }> = ({ children, style }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                ...style
            }}
        >
            {children}
        </div>
    )
}

export const StretchedDashString: FC = () => {
    return (
        <div
            style={{
                flexGrow: 4,
                borderBottom: "1px dashed black"
            }}
        />
    )
}

export const BlinkOnUpdate: FC<{
    value: string
}> = ({ value }) => {
    const [backgroundColor, setBackgroundColor] = useState<string>("transparent")
    useEffect(() => {
        setBackgroundColor("rgba(255, 255, 0, 0.5)")
        setTimeout(() => {
            setBackgroundColor("transparent")
        }, 500)
    }, [value])
    return (
        <span
            style={{
                backgroundColor
            }}
        >
            {value}
        </span>
    )
}
