import { createContext, useContext, useReducer } from "react"
import { Action } from "./common"
import { Amount, OrderBook, Price } from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/types"

const getLocalStorageNullableString = (key: string): string | null => {
    const value = localStorage.getItem(key)
    if (value === null) {
        return null
    }
    return value
}

const setLocalStorageNullableString = (key: string, value: string | null) => {
    if (value === null) {
        localStorage.removeItem(key)
    } else {
        localStorage.setItem(key, value)
    }
}

interface State {
    executorLeftmostExchangeName: string | null
    executorRightmostExchangeName: string | null
    executorLeftmostActiveTab: string | null
    executorRightmostActiveTab: string | null
    executorCommonCoin: string | null
    executorCommonNetwork: string | null
    executorCommonAddress: string | null
    executorCommonMemo: string | null
    executorCommonMinDepositAmount: string | null

    executorLeftmostOrderbook: OrderBook | null
    executorRightmostOrderbook: OrderBook | null

    executorLeftmostEffectiveVolume: Amount | null
    executorRightmostEffectiveVolume: Amount | null

    executorLeftmostEffectiveLimitPrice: Price | null
    executorRightmostEffectiveLimitPrice: Price | null

    executorLinkEnabled: boolean
}

const initialState: State = {
    executorLeftmostExchangeName: getLocalStorageNullableString("executorLeftmostExchangeName"),
    executorRightmostExchangeName: getLocalStorageNullableString("executorRightmostExchangeName"),
    executorLeftmostActiveTab: getLocalStorageNullableString("executorLefmostActiveTab"),
    executorRightmostActiveTab: getLocalStorageNullableString("executorRightmostActiveTab"),
    executorCommonCoin: getLocalStorageNullableString("executorCommonCoin"),
    executorCommonNetwork: getLocalStorageNullableString("executorCommonNetwork"),
    executorCommonAddress: null,
    executorCommonMemo: null,
    executorCommonMinDepositAmount: null,

    executorLeftmostOrderbook: null,
    executorRightmostOrderbook: null,

    executorLeftmostEffectiveVolume: null,
    executorRightmostEffectiveVolume: null,

    executorLeftmostEffectiveLimitPrice: null,
    executorRightmostEffectiveLimitPrice: null,

    executorLinkEnabled: true
}

interface ExecutorContextType extends State {
    setExecutorLeftmostExchangeName: (executorLeftmostExchangeName: string | null) => void
    setExecutorRightmostExchangeName: (executorRightmostExchangeName: string | null) => void
    setExecutorLefmostActiveTab: (executorLefmostActiveTab: string | null) => void
    setExecutorRightmostActiveTab: (executorRightmostActiveTab: string | null) => void
    setExecutorCommonCoin: (executorCommonCoin: string | null) => void
    setExecutorCommonNetwork: (executorCommonNetwork: string | null) => void
    setExecutorCommonAddress: (executorCommonAddress: string | null) => void
    setExecutorCommonMemo: (executorCommonMemo: string | null) => void
    setExecutorCommonMinDepositAmount: (executorCommonMinDepositAmount: string | null) => void

    setExecutorLeftmostOrderbook: (executorLeftmostOrderbook: OrderBook | null) => void
    setExecutorRightmostOrderbook: (executorRightmostOrderbook: OrderBook | null) => void

    setExecutorLeftmostEffectiveVolume: (executorLeftmostEffectiveVolume: Amount | null) => void
    setExecutorRightmostEffectiveVolume: (executorRightmostEffectiveVolume: Amount | null) => void

    setExecutorLeftmostEffectiveLimitPrice: (executorLeftmostEffectiveLimitPrice: Price | null) => void
    setExecutorRightmostEffectiveLimitPrice: (executorRightmostEffectiveLimitPrice: Price | null) => void

    setExecutorLinkEnabled: (executorLinkEnabled: boolean) => void
}

function executorReducer(state: State, action: Action) {
    switch (action.type) {
        case "SET_EXECUTOR_LEFTMOST_EXCHANGE_NAME":
            setLocalStorageNullableString("executorLeftmostExchangeName", action.payload)
            return {
                ...state,
                executorLeftmostExchangeName: action.payload
            }
        case "SET_EXECUTOR_RIGHTMOST_EXCHANGE_NAME":
            setLocalStorageNullableString("executorRightmostExchangeName", action.payload)
            return {
                ...state,
                executorRightmostExchangeName: action.payload
            }
        case "SET_EXECUTOR_LEFMOST_ACTIVE_TAB":
            setLocalStorageNullableString("executorLefmostActiveTab", action.payload)
            return {
                ...state,
                executorLefmostActiveTab: action.payload
            }
        case "SET_EXECUTOR_RIGHTMOST_ACTIVE_TAB":
            setLocalStorageNullableString("executorRightmostActiveTab", action.payload)
            return {
                ...state,
                executorRightmostActiveTab: action.payload
            }
        case "SET_EXECUTOR_COMMON_COIN":
            setLocalStorageNullableString("executorCommonCoin", action.payload)
            return {
                ...state,
                executorCommonCoin: action.payload
            }
        case "SET_EXECUTOR_COMMON_NETWORK":
            setLocalStorageNullableString("executorCommonNetwork", action.payload)
            return {
                ...state,
                executorCommonNetwork: action.payload
            }
        case "SET_EXECUTOR_COMMON_ADDRESS":
            return {
                ...state,
                executorCommonAddress: action.payload
            }
        case "SET_EXECUTOR_LINK_ENABLED":
            return {
                ...state,
                executorLinkEnabled: action.payload
            }
        case "SET_EXECUTOR_COMMON_MEMO":
            return {
                ...state,
                executorCommonMemo: action.payload
            }
        case "SET_EXECUTOR_COMMON_MIN_DEPOSIT_AMOUNT":
            return {
                ...state,
                executorCommonMinDepositAmount: action.payload
            }
        case "SET_EXECUTOR_LEFTMOST_ORDERBOOK":
            return {
                ...state,
                executorLeftmostOrderbook: action.payload
            }
        case "SET_EXECUTOR_RIGHTMOST_ORDERBOOK":
            return {
                ...state,
                executorRightmostOrderbook: action.payload
            }
        case "SET_EXECUTOR_LEFTMOST_EFFECTIVE_VOLUME":
            return {
                ...state,
                executorLeftmostEffectiveVolume: action.payload
            }
        case "SET_EXECUTOR_RIGHTMOST_EFFECTIVE_VOLUME":
            return {
                ...state,
                executorRightmostEffectiveVolume: action.payload
            }
        case "SET_EXECUTOR_LEFTMOST_EFFECTIVE_LIMIT_PRICE":
            return {
                ...state,
                executorLeftmostEffectiveLimitPrice: action.payload
            }
        case "SET_EXECUTOR_RIGHTMOST_EFFECTIVE_LIMIT_PRICE":
            return {
                ...state,
                executorRightmostEffectiveLimitPrice: action.payload
            }
        default:
            return state
    }
}

export const ExecutorContext = createContext<ExecutorContextType | undefined>(undefined)

export const useExecutorContext = () => {
    const context = useContext(ExecutorContext)
    if (context === undefined) {
        throw new Error("useExecutorContext must be used within a ExecutorContextProvider")
    }
    return context
}

export const ExecutorContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(executorReducer, initialState)

    const setExecutorLeftmostExchangeName = (executorLeftmostExchangeName: string | null) => {
        dispatch({
            type: "SET_EXECUTOR_LEFTMOST_EXCHANGE_NAME",
            payload: executorLeftmostExchangeName
        })
    }

    const setExecutorRightmostExchangeName = (executorRightmostExchangeName: string | null) => {
        dispatch({
            type: "SET_EXECUTOR_RIGHTMOST_EXCHANGE_NAME",
            payload: executorRightmostExchangeName
        })
    }

    const setExecutorLefmostActiveTab = (executorLefmostActiveTab: string | null) => {
        dispatch({
            type: "SET_EXECUTOR_LEFMOST_ACTIVE_TAB",
            payload: executorLefmostActiveTab
        })
    }

    const setExecutorRightmostActiveTab = (executorRightmostActiveTab: string | null) => {
        dispatch({
            type: "SET_EXECUTOR_RIGHTMOST_ACTIVE_TAB",
            payload: executorRightmostActiveTab
        })
    }

    const setExecutorCommonCoin = (executorCommonCoin: string | null) => {
        dispatch({
            type: "SET_EXECUTOR_COMMON_COIN",
            payload: executorCommonCoin
        })
    }

    const setExecutorCommonNetwork = (executorCommonNetwork: string | null) => {
        dispatch({
            type: "SET_EXECUTOR_COMMON_NETWORK",
            payload: executorCommonNetwork
        })
    }

    const setExecutorCommonAddress = (executorCommonAddress: string | null) => {
        dispatch({
            type: "SET_EXECUTOR_COMMON_ADDRESS",
            payload: executorCommonAddress
        })
    }

    const setExecutorCommonMemo = (executorCommonMemo: string | null) => {
        dispatch({
            type: "SET_EXECUTOR_COMMON_MEMO",
            payload: executorCommonMemo
        })
    }

    const setExecutorLinkEnabled = (executorLinkEnabled: boolean) => {
        dispatch({
            type: "SET_EXECUTOR_LINK_ENABLED",
            payload: executorLinkEnabled
        })
    }
    const setExecutorCommonMinDepositAmount = (executorCommonMinDepositAmount: string | null) => {
        dispatch({
            type: "SET_EXECUTOR_COMMON_MIN_DEPOSIT_AMOUNT",
            payload: executorCommonMinDepositAmount
        })
    }
    const setExecutorLeftmostOrderbook = (executorLeftmostOrderbook: OrderBook | null) => {
        dispatch({
            type: "SET_EXECUTOR_LEFTMOST_ORDERBOOK",
            payload: executorLeftmostOrderbook
        })
    }
    const setExecutorRightmostOrderbook = (executorRightmostOrderbook: OrderBook | null) => {
        dispatch({
            type: "SET_EXECUTOR_RIGHTMOST_ORDERBOOK",
            payload: executorRightmostOrderbook
        })
    }
    const setExecutorLeftmostEffectiveVolume = (executorLeftmostEffectiveVolume: Amount | null) => {
        dispatch({
            type: "SET_EXECUTOR_LEFTMOST_EFFECTIVE_VOLUME",
            payload: executorLeftmostEffectiveVolume
        })
    }
    const setExecutorRightmostEffectiveVolume = (executorRightmostEffectiveVolume: Amount | null) => {
        dispatch({
            type: "SET_EXECUTOR_RIGHTMOST_EFFECTIVE_VOLUME",
            payload: executorRightmostEffectiveVolume
        })
    }
    const setExecutorLeftmostEffectiveLimitPrice = (executorLeftmostEffectiveLimitPrice: Price | null) => {
        dispatch({
            type: "SET_EXECUTOR_LEFTMOST_EFFECTIVE_LIMIT_PRICE",
            payload: executorLeftmostEffectiveLimitPrice
        })
    }
    const setExecutorRightmostEffectiveLimitPrice = (executorRightmostEffectiveLimitPrice: Price | null) => {
        dispatch({
            type: "SET_EXECUTOR_RIGHTMOST_EFFECTIVE_LIMIT_PRICE",
            payload: executorRightmostEffectiveLimitPrice
        })
    }

    return (
        <ExecutorContext.Provider
            value={{
                ...state,
                setExecutorLeftmostExchangeName,
                setExecutorRightmostExchangeName,
                setExecutorLefmostActiveTab,
                setExecutorRightmostActiveTab,
                setExecutorCommonCoin,
                setExecutorCommonNetwork,
                setExecutorCommonAddress,
                setExecutorCommonMemo,
                setExecutorCommonMinDepositAmount,
                setExecutorLeftmostOrderbook,
                setExecutorRightmostOrderbook,
                setExecutorLeftmostEffectiveVolume,
                setExecutorRightmostEffectiveVolume,
                setExecutorLeftmostEffectiveLimitPrice,
                setExecutorRightmostEffectiveLimitPrice,
                setExecutorLinkEnabled
            }}
        >
            {children}
        </ExecutorContext.Provider>
    )
}
