import { message as antdMessage } from "antd"
import { type Exchange, type ErrorType, type Coin, Network } from "./types"
import { Dayjs } from "dayjs"

export const getAPIEndpoint = (path: string) => {
    if (window.location.hostname === "localhost") {
        return "http://localhost:8022" + path
    } else if (window.location.hostname === "dev.arbitrage.ngineera.com") {
        return `https://${window.location.hostname}/api${path}`
        return "http://localhost:8022" + path
    } else {
        return `https://${window.location.hostname}/api${path}`
    }
}

export const callAPI = async <T>(
    path: string,
    authToken: string,
    setAuthToken: (token: string | null) => void,
    requestInit?: RequestInit
): Promise<{
    responseHeaders: Headers
    responseObject: T | null
}> => {
    if (!requestInit) {
        requestInit = {}
    }
    requestInit.headers = {
        Authorization: "Bearer " + authToken
    }
    const resp = await fetch(getAPIEndpoint(path), requestInit)
    if (resp.ok) {
        let responseObject: T | null = null
        if (resp.headers.get("Content-Type") === "application/octet-stream") {
            responseObject = (await resp.arrayBuffer()) as unknown as T
        } else if (resp.headers.get("Content-Type") === "application/json") {
            responseObject = (await resp.json()) as T
        } else {
            responseObject = null
        }
        return {
            responseHeaders: resp.headers,
            responseObject: responseObject
        }
    } else {
        console.log("API call error: ", resp.status, resp.statusText)
        switch (resp.status) {
            case 500:
                const myErr = (await resp.json()) as ErrorType
                console.log("API call error: ", myErr.Reason)
                void antdMessage.error(`Une erreur est survenu: ${myErr.Reason}`)
                break
            // throw new Error(myErr.Reason)
            case 401:
                void antdMessage.error("Unauthorized")
                setAuthToken(null)
                window.location.pathname = "/app/home"
                break
            // throw new Error('Unauthorized')
            default:
                void antdMessage.error("Une erreur est survenu: " + resp.statusText)
        }
        return {
            responseHeaders: resp.headers,
            responseObject: null
        }
    }
}

export const getExchangePairCoinTimeseriesParams = (
    exchangeFromID: Exchange["ID"],
    exchangeToID: Exchange["ID"],
    coinID: Coin["ID"],
    from: Dayjs | null,
    to: Dayjs | null
): string => {
    let query = new URLSearchParams()
    query.set("exchange_from_id", exchangeFromID.toString())
    query.set("exchange_to_id", exchangeToID.toString())
    query.set("coin_id", coinID.toString())
    if (from != null) {
        query.set("from", from.toISOString())
    }
    if (to != null) {
        query.set("to", to.toISOString())
    }
    return "?" + query.toString()
}

export const getExchangePairCoinNetworkTimeseriesParams = (
    exchangeFromID: Exchange["ID"],
    exchangeToID: Exchange["ID"],
    coinID: Coin["ID"],
    networkID: Network["ID"] | null,
    from: Dayjs | null,
    to: Dayjs | null
): string => {
    let query = getExchangePairCoinTimeseriesParams(exchangeFromID, exchangeToID, coinID, from, to)
    query += `&network_id=${networkID}`
    return query
}
