import { FullscreenExitOutlined, LogoutOutlined, ReloadOutlined } from "@ant-design/icons"
import {
    message,
    Spin,
    Row,
    Col,
    Space,
    Button,
    Select,
    Tooltip,
    Switch,
    Popconfirm,
    message as antdMessage,
    Tag,
    Alert
} from "antd"
import { BaseOptionType } from "antd/es/select"
import {
    IExchangeDriver,
    SymbolInfo,
    CoinInfo,
    CoinSpotBalance,
    OrderBook,
    Price,
    Amount,
    OrderSide,
    OrderType,
    OrderTimeInForce,
    CoinMarginBalance,
    MarginAccountMode,
    MarginTradeType
} from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/types"
import { gt, lt, truncateFloatUsingDecimalStepHE } from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/utils"
import bigDecimal from "js-big-decimal"
import { FC, useState, useRef, useCallback, useEffect, useMemo, ReactElement } from "react"
import { FlexRow, FlexCol } from "../../common"
import { colorHexToRGBA, ARBITRAGE_COLOR_LONG, ARBITRAGE_COLOR_SHORT } from "../../constants"
import { useExecutorContext } from "../../reducers/executorReducer"
import { formattedNumberToFixedWithoutTrailingZeros } from "../../utils"
import { _OrdersHistoryList, OrderbookWidget, OrderHistoryMode, OrderQtyInput } from "./ordersCommon"
import { USDT_DECIMAL_STEP, isLeveraged, symbolIsUSDTQuoted } from "./utils"
import { ExecutorControllerSide } from "../../types"

const MarginOrdersHistoryList: FC<{
    exchangeDriver: IExchangeDriver | null
    symbol: SymbolInfo | null
    loadBalances: () => Promise<void>
}> = ({ exchangeDriver, symbol, loadBalances }) => {
    return (
        <_OrdersHistoryList
            exchangeDriver={exchangeDriver}
            symbol={symbol}
            mode={OrderHistoryMode.Margin}
            loadBalances={loadBalances}
        />
    )
}

export const MarginOrderController: FC<{
    exchangeDriver: IExchangeDriver | null
    executorControllerSide: ExecutorControllerSide
}> = ({ exchangeDriver, executorControllerSide }) => {
    const [coins, setCoins] = useState<CoinInfo[] | null>(null)
    const [symbols, setSymbols] = useState<SymbolInfo[] | null>(null) // margin symbols

    const [spotBalances, setSpotBalances] = useState<CoinSpotBalance[] | null>(null)
    const [marginBalances, setMarginBalances] = useState<CoinMarginBalance[] | null>(null)

    const [selectedCoinName, setSelectedCoinName] = useState<string | null>(null)
    const [currentSymbol, setCurrentSymbol] = useState<SymbolInfo | null>(null)

    const [currentQuoteSpotBalance, setCurrentQuoteSpotBalance] = useState<CoinSpotBalance | null>(null)
    const [currentQuoteMarginBalance, setCurrentQuoteMarginBalance] = useState<CoinMarginBalance | null>(null)
    const [currentQuoteMarginMaxTransferableBalance, setCurrentQuoteMarginMaxTransferableBalance] =
        useState<Amount | null>(null)

    const [currentBaseSpotBalance, setCurrentBaseSpotBalance] = useState<CoinSpotBalance | null>(null)
    const [currentBaseMarginBalance, setCurrentBaseMarginBalance] = useState<CoinMarginBalance | null>(null)
    const [currentBaseMarginMaxBorrowableBalance, setCurrentBaseMarginMaxBorrowableBalance] = useState<Amount | null>(
        null
    )

    const [orderbook, setOrderbook] = useState<OrderBook | null>(null)
    const [limitPrice, setLimitPrice] = useState<Price | null>(null)

    const [baseQty, setBaseQty] = useState<Amount | null>(null)
    const [quoteQty, setQuoteQty] = useState<Amount | null>(null)

    const [orderSide, setOrderSide] = useState<OrderSide>(OrderSide.Sell)
    const [orderType, setOrderType] = useState<OrderType>(OrderType.Limit)
    const [orderTimeInForce, setOrderTimeInForce] = useState<OrderTimeInForce>(OrderTimeInForce.GoodTilCancelled)

    const [clockIsEnabled, setClockIsEnabled] = useState<boolean>(true)
    const [clockValue, setClockValue] = useState<number>(0)
    const orderbookMutexRef = useRef<boolean>(false)

    const {
        executorLinkEnabled,
        executorCommonCoin,
        executorLeftmostEffectiveVolume,
        executorLeftmostEffectiveLimitPrice,
        executorRightmostEffectiveVolume,
        executorRightmostEffectiveLimitPrice,
        setExecutorCommonCoin,
        setExecutorLeftmostOrderbook,
        setExecutorRightmostOrderbook
    } = useExecutorContext()

    const loadCoins = useCallback(async () => {
        if (exchangeDriver === null) {
            setCoins(null)
            return
        }
        try {
            let { coins } = await exchangeDriver.getCoins()
            let _coins: CoinInfo[] = []
            for (let coin of coins) {
                if (isLeveraged(coin)) {
                    continue
                }
                _coins.push(coin)
            }
            setCoins(_coins)
        } catch (e: any) {
            console.log(`MarginOrderController: ${exchangeDriver.name()}: getCoins error`, e)
        }
    }, [exchangeDriver])

    const loadBalances = useCallback(async () => {
        if (exchangeDriver === null) {
            setSpotBalances(null)
            return
        }
        try {
            let { balances } = await exchangeDriver.getSpotBalances()
            setSpotBalances(balances)
        } catch (e: any) {
            console.log(`MarginOrderController: ${exchangeDriver.name()}: getSpotBalances error`, e)
        }
        let isolatedSymbolName = null
        if (currentSymbol !== null) {
            isolatedSymbolName = currentSymbol.ownName
        }
        console.log(`MarginOrderController: ${exchangeDriver.name()}: isolatedSymbolName: ${isolatedSymbolName}`)
        try {
            let { balances } = await exchangeDriver.getMarginBalances(isolatedSymbolName)
            setMarginBalances(balances)
        } catch (e: any) {
            console.log(`MarginOrderController: ${exchangeDriver.name()}: getSpotBalances error`, e)
        }
    }, [exchangeDriver, currentSymbol])

    const loadSymbols = useCallback(async () => {
        if (exchangeDriver === null) {
            setSymbols(null)
            return
        }
        try {
            let { symbols } = await exchangeDriver.getMarginSymbols()
            let unleveragedSymbols = symbols.filter(s => !isLeveraged(s))
            let unleveragedUsdtSymbols = unleveragedSymbols.filter(s => symbolIsUSDTQuoted(s))
            setSymbols(unleveragedUsdtSymbols)
        } catch (e: any) {
            console.log(`MarginOrderController: ${exchangeDriver.name()}: getSymbols error`, e)
        }
    }, [exchangeDriver])

    const loadQuoteCoinBalance = useCallback(async () => {
        if (exchangeDriver === null) {
            setCurrentQuoteSpotBalance(null)
            setCurrentQuoteMarginBalance(null)
            setCurrentQuoteMarginMaxTransferableBalance(null)
            return
        }
        // Spot balance
        try {
            let { raw, balance } = await exchangeDriver.getSpotCoinBalance("USDT")
            // console.log(`MarginOrderController: ${exchangeDriver.name()}: quote getSpotCoinBalance: `, balance, raw)
            setCurrentQuoteSpotBalance(balance)
        } catch (e: any) {
            console.warn(`MarginOrderController: ${exchangeDriver.name()}: loadQuoteCoinBalance error`, e)
        }
        // Margin balance
        let isolatedSymbolName = null
        if (currentSymbol !== null) {
            isolatedSymbolName = currentSymbol.ownName
        }
        // console.log(`MarginOrderController: ${exchangeDriver.name()}: isolatedSymbolName: ${isolatedSymbolName}`)
        try {
            let { raw, balance } = await exchangeDriver.getMarginCoinBalance({
                coinName: "USDT",
                isolatedSymbolName
            })
            // console.log(`MarginOrderController: ${exchangeDriver.name()}: quote getMarginCoinBalance: `, balance, raw)
            setCurrentQuoteMarginBalance(balance)
        } catch (e: any) {
            console.warn(`MarginOrderController: ${exchangeDriver.name()}: loadQuoteCoinBalance error`, e)
        }

        // Max transferable
        try {
            let { amount } = await exchangeDriver.getMarginTransferableBalance({
                coinName: "USDT",
                isolatedSymbolName
            })
            setCurrentQuoteMarginMaxTransferableBalance(amount)
        } catch (e: any) {
            console.warn(`MarginOrderController: ${exchangeDriver.name()}: loadQuoteCoinBalance error`, e)
            setCurrentQuoteMarginMaxTransferableBalance(null)
        }
    }, [exchangeDriver, currentSymbol])

    const loadBaseCoinBalance = useCallback(async () => {
        if (exchangeDriver === null || selectedCoinName === null || currentSymbol === null) {
            setCurrentBaseSpotBalance(null)
            setCurrentBaseMarginBalance(null)
            setCurrentBaseMarginMaxBorrowableBalance(null)
            return
        }
        // Spot balance
        try {
            let { balance } = await exchangeDriver.getSpotCoinBalance(selectedCoinName)
            setCurrentBaseSpotBalance(balance)
        } catch (e: any) {
            console.warn(`MarginOrderController: ${exchangeDriver.name()}: loadBaseCoinBalance error`, e)
        }
        // Margin balance
        let isolatedSymbolName = null
        if (currentSymbol !== null) {
            isolatedSymbolName = currentSymbol.ownName
        }
        // console.log(`MarginOrderController: ${exchangeDriver.name()}: isolatedSymbolName: ${isolatedSymbolName}`)
        try {
            let { raw, balance } = await exchangeDriver.getMarginCoinBalance({
                coinName: selectedCoinName,
                isolatedSymbolName
            })
            // console.log(`MarginOrderController: ${exchangeDriver.name()}: base getMarginCoinBalance: `, balance, raw)
            setCurrentBaseMarginBalance(balance)
        } catch (e: any) {
            console.warn(`MarginOrderController: ${exchangeDriver.name()}: loadBaseCoinBalance error`, e)
        }

        // Max borrowable
        try {
            let { amount } = await exchangeDriver.getMarginMaxBorrowAmount({
                coinName: selectedCoinName,
                isolatedSymbolName: currentSymbol.ownName
            })
            setCurrentBaseMarginMaxBorrowableBalance(amount)
        } catch (e: any) {
            console.warn(`MarginOrderController: ${exchangeDriver.name()}: loadBaseCoinBalance error`, e)
            setCurrentBaseMarginMaxBorrowableBalance(null)
        }
    }, [exchangeDriver, selectedCoinName, currentSymbol])

    const transferQuoteCoinSpotToMargin = useCallback(async () => {
        if (exchangeDriver === null || currentSymbol === null || currentQuoteSpotBalance === null) {
            return
        }
        if (!gt(currentQuoteSpotBalance.freeQty, 0)) {
            return
        }
        try {
            await exchangeDriver.transferCoinSpotToMargin({
                coinName: "USDT",
                isolatedSymbolName: currentSymbol.ownName,
                amount: currentQuoteSpotBalance.freeQty
            })
            message.success("USDT is consolidated on MARGIN!")
        } catch (e: any) {
            message.info(`Could not consolidate USDT on MARGIN: ${e.toString()}`)
        }
    }, [exchangeDriver, currentSymbol, currentQuoteSpotBalance])

    const transferBaseCoinSpotToMargin = useCallback(async () => {
        if (exchangeDriver === null || currentSymbol === null || currentBaseSpotBalance === null) {
            return
        }
        if (!gt(currentBaseSpotBalance.freeQty, 0)) {
            return
        }
        try {
            await exchangeDriver.transferCoinSpotToMargin({
                coinName: currentSymbol.baseAsset,
                isolatedSymbolName: currentSymbol.ownName,
                amount: currentBaseSpotBalance.freeQty
            })
            message.success(`${currentSymbol.baseAsset} is consolidated on MARGIN!`)
        } catch (e: any) {
            message.info(`Could not consolidate ${currentSymbol.baseAsset} on MARGIN: ${e.toString()}`)
        }
    }, [exchangeDriver, currentSymbol, currentBaseSpotBalance])

    const repayAvailableBaseCoin = useCallback(async () => {
        if (exchangeDriver === null || currentSymbol === null || currentBaseMarginBalance === null) {
            return
        }
        if (!gt(currentBaseMarginBalance.freeQty, 0)) {
            return
        }
        let amountToRepay = currentBaseMarginBalance.borrowedQty
        if (lt(currentBaseMarginBalance.freeQty, amountToRepay)) {
            amountToRepay = currentBaseMarginBalance.freeQty
        }
        console.log(`MarginOrdercontroller: repaying ${amountToRepay} ${currentSymbol.baseAsset}`)
        try {
            await exchangeDriver.marginLoanRepay({
                coinName: currentSymbol.baseAsset,
                amount: amountToRepay,
                isolatedSymbolName: currentSymbol.ownName
            })
            loadBaseCoinBalance()
            message.success(`${currentSymbol.baseAsset} is repaid on MARGIN!`)
        } catch (e: any) {
            message.info(`Could not repay ${currentSymbol.baseAsset} on MARGIN: ${e.toString()}`)
        }
    }, [exchangeDriver, currentSymbol, currentBaseMarginBalance, loadBalances, loadSymbols])

    // Load coins
    useEffect(() => {
        loadCoins()
    }, [exchangeDriver])

    // Load balances
    useEffect(() => {
        loadBalances()
    }, [exchangeDriver])

    // Load symbols
    useEffect(() => {
        loadSymbols()
    }, [exchangeDriver])

    // Set the current coin symbol
    useEffect(() => {
        if (exchangeDriver === null || symbols === null || selectedCoinName === null) {
            setCurrentSymbol(null)
            return
        }
        for (let symbol of symbols) {
            if (isLeveraged(symbol) || !symbolIsUSDTQuoted(symbol)) {
                continue
            }
            if (symbol.baseAsset.toUpperCase() === selectedCoinName.toUpperCase()) {
                setCurrentSymbol(symbol)
                return
            }
        }
        setCurrentSymbol(null)
    }, [exchangeDriver, symbols, selectedCoinName])

    // Current quote (USDT) balance
    useEffect(() => {
        loadQuoteCoinBalance()
    }, [exchangeDriver, spotBalances, selectedCoinName])

    // Current base  (COIN) balance
    useEffect(() => {
        loadBaseCoinBalance()
    }, [exchangeDriver, spotBalances, selectedCoinName])

    // Current borrowable capability
    useEffect(() => {}, [exchangeDriver, selectedCoinName])

    // Current Symbol
    useEffect(() => {
        if (symbols === null || selectedCoinName === null || exchangeDriver === null) {
            setCurrentSymbol(null)
            return
        }
        for (let symbol of symbols) {
            if (symbol.baseAsset === selectedCoinName) {
                setCurrentSymbol(symbol)
                return
            }
        }
        setCurrentSymbol(null)
    }, [exchangeDriver, selectedCoinName, symbols])

    // Linking
    useEffect(() => {
        if (!executorLinkEnabled) {
            return
        }
        if (executorCommonCoin !== null) {
            setSelectedCoinName(executorCommonCoin)
        }
        if (executorControllerSide === ExecutorControllerSide.Leftmost) {
            if (executorLeftmostEffectiveVolume) {
                setBaseQty(executorLeftmostEffectiveVolume)
            }
            if (executorLeftmostEffectiveLimitPrice) {
                setLimitPrice(executorLeftmostEffectiveLimitPrice)
            }
        } else if (executorControllerSide === ExecutorControllerSide.Rightmost) {
            if (executorRightmostEffectiveVolume) {
                setBaseQty(executorRightmostEffectiveVolume)
            }
            if (executorRightmostEffectiveLimitPrice) {
                setLimitPrice(executorRightmostEffectiveLimitPrice)
            }
        }
    }, [
        executorLinkEnabled,
        executorCommonCoin,
        executorControllerSide,
        executorLeftmostEffectiveLimitPrice,
        executorLeftmostEffectiveVolume,
        executorRightmostEffectiveLimitPrice,
        executorRightmostEffectiveVolume
    ])

    // Orderbook
    useEffect(() => {
        if (exchangeDriver === null || currentSymbol === null) {
            return
        }
        // console.debug(`MarginOrderController: clock tick received: ${clockValue}: ${orderbookMutexRef.current}`)
        if (orderbookMutexRef.current) {
            return
        }
        // console.debug(`MarginOrderController: ${exchangeDriver.name()}: getSymbolOrderbook: ${currentSymbol.ownName}`)
        orderbookMutexRef.current = true
        let t0 = Date.now()
        exchangeDriver
            .getSymbolOrderbook(currentSymbol.ownName)
            .then(({ orderbook }) => {
                setOrderbook(orderbook)
                if (executorControllerSide === ExecutorControllerSide.Leftmost) {
                    setExecutorLeftmostOrderbook(orderbook)
                } else if (executorControllerSide === ExecutorControllerSide.Rightmost) {
                    setExecutorRightmostOrderbook(orderbook)
                }
            })
            .finally(() => {
                let t1 = Date.now()
                let dT = t1 - t0
                let remainingTime = 1000 - dT
                // console.debug(
                //     `MarginOrderController: ${exchangeDriver.name()}: ${
                //         currentSymbol.ownName
                //     }'s orderbook: waiting for ${remainingTime} ms`
                // )
                setTimeout(() => {
                    orderbookMutexRef.current = false
                }, remainingTime)
            })
    }, [exchangeDriver, currentSymbol, clockValue])

    // Clock
    useEffect(() => {
        if (exchangeDriver === null || currentSymbol === null) {
            return
        }
        if (!clockIsEnabled) {
            return
        }
        let interval = setInterval(() => {
            let t = Date.now()
            setClockValue(t)
        }, 500)
        return () => {
            clearInterval(interval)
        }
    }, [exchangeDriver, currentSymbol, clockIsEnabled])

    const memoCanOrder = useMemo(() => {
        if (currentSymbol === null) {
            return false
        }
        if (orderSide === OrderSide.Buy) {
            // BUY
            return true
        } else if (orderSide === OrderSide.Sell) {
            // SELL
            return true
        }
    }, [currentSymbol, orderSide])

    const memoCoinsOptions = useMemo((): BaseOptionType[] => {
        if (coins === null || spotBalances === null || marginBalances === null || symbols === null) {
            return []
        }
        // console.debug(`MarginOrderController: getMemoCoinsOptions: coinNetworks: `, coins)
        let preOptions: {
            coinName: string
            coinBalance: Amount
            symbolExists: boolean
            symbolIsAvailable: boolean
        }[] = []
        for (let coin of coins) {
            let symbol: SymbolInfo | undefined = symbols.find(symbol => {
                return symbol.baseAsset === coin.coinName
            })
            let spotBalance: CoinSpotBalance | undefined = spotBalances.find(balance => {
                return balance.coinName === coin.coinName
            })
            let marginBalance: CoinMarginBalance | undefined = marginBalances.find(balance => {
                return balance.coinName === coin.coinName
            })
            let coinBalance = "0"
            // if (spotBalance !== undefined) {
            //     coinBalance = bigDecimal.add(spotBalance.freeQty, spotBalance.lockedQty)
            // }
            if (marginBalance !== undefined) {
                coinBalance = bigDecimal.add(coinBalance, marginBalance.freeQty)
                coinBalance = bigDecimal.add(coinBalance, marginBalance.lockedQty)
            }
            preOptions.push({
                coinName: coin.coinName,
                coinBalance,
                symbolExists: symbol !== undefined,
                symbolIsAvailable: symbol?.isAvailable ?? false
            })
        }
        preOptions.sort((a, b) => {
            if (a.symbolExists && !b.symbolExists) {
                return -1
            } else if (!a.symbolExists && b.symbolExists) {
                return 1
            } else {
                if (a.symbolIsAvailable && !b.symbolIsAvailable) {
                    return -1
                } else if (!a.symbolIsAvailable && b.symbolIsAvailable) {
                    return 1
                } else {
                    let i = bigDecimal.compareTo(a.coinBalance, b.coinBalance)
                    if (i !== 0) {
                        return -i // descending
                    } else {
                        return a.coinName.localeCompare(b.coinName)
                    }
                }
            }
        })
        let options: BaseOptionType[] = []
        for (let preOption of preOptions) {
            if (!preOption.symbolExists) {
                continue
            }
            let children: ReactElement[] = [<>{preOption.coinName}</>]
            if (gt(preOption.coinBalance, 0)) {
                children.push(<span>{formattedNumberToFixedWithoutTrailingZeros(preOption.coinBalance)}</span>)
            }
            options.push({
                label: <FlexRow style={{ justifyContent: "space-between", marginRight: "1.5rem" }}>{children}</FlexRow>,
                value: preOption.coinName,
                disabled: !preOption.symbolExists || !preOption.symbolIsAvailable
            })
        }
        return options
    }, [coins, symbols, spotBalances, marginBalances])

    const memoQuoteBalanceElement = useMemo(() => {
        if (currentQuoteMarginBalance === null) {
            return <>N/A</>
        }
        let freeChildren: ReactElement[] = [
            <Tooltip overlay={currentQuoteMarginBalance.freeQty}>
                {formattedNumberToFixedWithoutTrailingZeros(bigDecimal.round(currentQuoteMarginBalance.freeQty, 2))}
            </Tooltip>
        ]
        let lockedChildren: ReactElement[] = []
        if (currentQuoteMarginBalance.lockedQty !== null && gt(currentQuoteMarginBalance.lockedQty, 0)) {
            lockedChildren.push(
                <Tooltip overlay={currentQuoteMarginBalance.lockedQty}>
                    {formattedNumberToFixedWithoutTrailingZeros(
                        bigDecimal.round(currentQuoteMarginBalance.lockedQty, 2)
                    )}
                </Tooltip>
            )
        }
        let maxTransferableChildren: ReactElement[] = []
        if (currentQuoteMarginMaxTransferableBalance !== null && gt(currentQuoteMarginMaxTransferableBalance, 0)) {
            maxTransferableChildren.push(
                <Tooltip overlay={currentQuoteMarginMaxTransferableBalance}>
                    {formattedNumberToFixedWithoutTrailingZeros(
                        bigDecimal.round(currentQuoteMarginMaxTransferableBalance, 2)
                    )}
                </Tooltip>
            )
        }
        return (
            <ul style={{ margin: 0 }}>
                <li>Free: {freeChildren}</li>
                {lockedChildren.length > 0 && <li>Lock: {lockedChildren}</li>}
                {maxTransferableChildren.length > 0 && <li>Tx-able: {maxTransferableChildren}</li>}
            </ul>
        )
    }, [currentQuoteMarginBalance, currentQuoteMarginMaxTransferableBalance])

    const memoBaseBalanceElement = useMemo(() => {
        if (currentBaseMarginBalance === null || currentSymbol === null) {
            return <>N/A</>
        }
        // calculate avg price for approximate USDT value of coins' balances
        let avgPrice: Price | null = null
        if (orderbook !== null) {
            let hb = orderbook.bids[0]
            let la = orderbook.asks[0]
            if (hb !== undefined && la !== undefined) {
                avgPrice = bigDecimal.divide(bigDecimal.add(hb.price, la.price), 2)
            }
        }

        let freeChildren: ReactElement[] = []
        let lockedChildren: ReactElement[] = []
        let borrowedChildren: ReactElement[] = []
        let maxBorrowableChildren: ReactElement[] = []

        // Free quantity in coins
        let formattedfreeQty = currentBaseMarginBalance.freeQty
        if (currentSymbol.lotStep !== null) {
            formattedfreeQty = truncateFloatUsingDecimalStepHE(formattedfreeQty, currentSymbol.lotStep)
        }
        freeChildren.push(
            <Tooltip overlay={currentBaseMarginBalance.freeQty}>
                {bigDecimal.getPrettyValue(formattedNumberToFixedWithoutTrailingZeros(formattedfreeQty))}
            </Tooltip>
        )

        // Free quantity in USDT (approximation)
        let freeQtyUSDT: Amount | null = null
        if (avgPrice !== null) {
            freeQtyUSDT = bigDecimal.multiply(currentBaseMarginBalance.freeQty, avgPrice)
        }
        if (freeQtyUSDT !== null && gt(freeQtyUSDT, 0)) {
            let formattedFreeQtyUSDT = formattedNumberToFixedWithoutTrailingZeros(bigDecimal.round(freeQtyUSDT, 2))
            freeChildren.push(<span> (~ {bigDecimal.getPrettyValue(formattedFreeQtyUSDT)} USDT)</span>)
        }

        // Locked
        if (currentBaseMarginBalance.lockedQty !== null && gt(currentBaseMarginBalance.lockedQty, 0)) {
            // Locked quantity in coins
            let formattedLockedQty = currentBaseMarginBalance.lockedQty
            if (currentSymbol.lotStep !== null) {
                formattedLockedQty = truncateFloatUsingDecimalStepHE(formattedLockedQty, currentSymbol.lotStep)
            }
            lockedChildren.push(
                <Tooltip overlay={currentBaseMarginBalance.lockedQty}>
                    {bigDecimal.getPrettyValue(formattedNumberToFixedWithoutTrailingZeros(formattedLockedQty))}{" "}
                </Tooltip>
            )
            // Locked quantity in USDT (approximation)
            let lockedQtyUSDT: Amount | null = null
            if (avgPrice !== null) {
                lockedQtyUSDT = bigDecimal.multiply(currentBaseMarginBalance.lockedQty, avgPrice)
            }
            if (lockedQtyUSDT !== null && gt(lockedQtyUSDT, 0)) {
                let formattedLockedQtyUSDT = formattedNumberToFixedWithoutTrailingZeros(
                    bigDecimal.round(lockedQtyUSDT, 2)
                )
                lockedChildren.push(<span> (~ {bigDecimal.getPrettyValue(formattedLockedQtyUSDT)} USDT)</span>)
            }
        }

        // Borrowed
        if (currentBaseMarginBalance.borrowedQty !== null && gt(currentBaseMarginBalance.borrowedQty, 0)) {
            // Borrowed quantity in coins
            let formattedBorrowedQty = currentBaseMarginBalance.borrowedQty
            if (currentSymbol.lotStep !== null) {
                formattedBorrowedQty = truncateFloatUsingDecimalStepHE(formattedBorrowedQty, currentSymbol.lotStep)
            }
            borrowedChildren.push(
                <Tooltip overlay={currentBaseMarginBalance.borrowedQty}>
                    {bigDecimal.getPrettyValue(formattedNumberToFixedWithoutTrailingZeros(formattedBorrowedQty))}{" "}
                </Tooltip>
            )
            // Borrowed quantity in USDT (approximation)
            let borrowedQtyUSDT: Amount | null = null
            if (avgPrice !== null) {
                borrowedQtyUSDT = bigDecimal.multiply(currentBaseMarginBalance.borrowedQty, avgPrice)
            }
            if (borrowedQtyUSDT !== null && gt(borrowedQtyUSDT, 0)) {
                let formattedBorrowedQtyUSDT = formattedNumberToFixedWithoutTrailingZeros(
                    bigDecimal.round(borrowedQtyUSDT, 2)
                )
                borrowedChildren.push(<span> (~ {bigDecimal.getPrettyValue(formattedBorrowedQtyUSDT)} USDT)</span>)
            }
            borrowedChildren.push(
                <Tooltip overlay={<>Repay {currentSymbol.baseAsset}</>}>
                    <Button
                        style={{ marginLeft: 3 }}
                        size="small"
                        icon={<LogoutOutlined />}
                        onClick={repayAvailableBaseCoin}
                    />
                </Tooltip>
            )
        }

        if (currentBaseMarginMaxBorrowableBalance !== null && gt(currentBaseMarginMaxBorrowableBalance, 0)) {
            // Max borrowable quantity in coins
            let formattedMaxBorrowableQty = currentBaseMarginMaxBorrowableBalance
            if (currentSymbol.lotStep !== null) {
                formattedMaxBorrowableQty = truncateFloatUsingDecimalStepHE(
                    formattedMaxBorrowableQty,
                    currentSymbol.lotStep
                )
            }
            maxBorrowableChildren.push(
                <>{bigDecimal.getPrettyValue(formattedNumberToFixedWithoutTrailingZeros(formattedMaxBorrowableQty))}</>
            )
            // Max borrowable quantity in USDT (approximation)
            let maxBorrowableQtyUSDT: Amount | null = null
            if (avgPrice !== null) {
                maxBorrowableQtyUSDT = bigDecimal.multiply(currentBaseMarginMaxBorrowableBalance, avgPrice)
            }
            if (maxBorrowableQtyUSDT !== null && gt(maxBorrowableQtyUSDT, 0)) {
                let formattedMaxBorrowableQtyUSDT = formattedNumberToFixedWithoutTrailingZeros(
                    bigDecimal.round(maxBorrowableQtyUSDT, 2)
                )
                maxBorrowableChildren.push(
                    <span> (~ {bigDecimal.getPrettyValue(formattedMaxBorrowableQtyUSDT)} USDT)</span>
                )
            }
        }

        return (
            <ul style={{ margin: 0 }}>
                <li>Free: {freeChildren}</li>
                {lockedChildren.length > 0 && <li>Lock: {lockedChildren}</li>}
                {borrowedChildren.length > 0 && <li>Borrowed: {borrowedChildren}</li>}
                {maxBorrowableChildren.length > 0 && <li>Bw-able: {maxBorrowableChildren}</li>}
            </ul>
        )
    }, [currentBaseMarginBalance, orderbook, currentSymbol])

    const placeOrder = useCallback(async () => {
        if (exchangeDriver === null || currentSymbol === null) {
            return
        }
        let _baseQty = baseQty
        let _quoteQty = quoteQty
        let _price = limitPrice
        let _orderTimeInForce: OrderTimeInForce | null = orderTimeInForce
        if (orderType === OrderType.Market) {
            _price = null
            _orderTimeInForce = null
            if (orderSide === OrderSide.Buy) {
                _baseQty = null
            } else {
                _quoteQty = null
            }
        } else {
            if (_price === null) {
                message.warning("MarginOrdercontroller: could not place a LIMIT order with null price")
                return
            }
            _quoteQty = null
        }
        if (_price !== null) {
            if (currentSymbol.priceMin !== null && _price < currentSymbol.priceMin) {
                message.warning(`MarginOrdercontroller: could not place an order with price less than priceMin`)
                return
            }
            if (currentSymbol.priceMax !== null && _price > currentSymbol.priceMax) {
                message.warning(`MarginOrdercontroller: could not place an order with price more than priceMax`)
                return
            }
            _price = truncateFloatUsingDecimalStepHE(_price, currentSymbol.priceTick)
        }

        // Borrow if needed
        if (orderSide === OrderSide.Sell && _baseQty !== null) {
            let amountToBorrow = _baseQty
            if (currentBaseMarginBalance !== null && gt(currentBaseMarginBalance.freeQty, 0)) {
                amountToBorrow = bigDecimal.subtract(_baseQty, currentBaseMarginBalance.freeQty)
            }
            if (gt(amountToBorrow, 0)) {
                console.log(`MarginOrdercontroller: borrowing ${amountToBorrow} ${selectedCoinName}`)
                try {
                    let { loanId } = await exchangeDriver.marginLoanBorrow({
                        coinName: currentSymbol.baseAsset,
                        amount: amountToBorrow,
                        isolatedSymbolName: currentSymbol.ownName
                    })
                    console.log(`${exchangeDriver.name()}: borrowed ${amountToBorrow} ${selectedCoinName}: #${loanId}`)
                } catch (e: any) {
                    let msg = `${exchangeDriver.name()}: could not borrow ${amountToBorrow} ${
                        currentSymbol.baseAsset
                    }: ${e.message}`
                    console.warn(msg)
                    antdMessage.error(msg)
                    return // Do not place order
                }
            }
        }
        // Place order
        console.log(
            `${exchangeDriver.name()}: placing margin order`,
            currentSymbol.ownName,
            orderSide,
            orderType,
            _orderTimeInForce,
            _baseQty,
            _quoteQty,
            _price
        )
        try {
            let { orderId } = await exchangeDriver.placeMarginOrder({
                symbolName: currentSymbol.ownName,
                orderSide: orderSide,
                orderType: orderType,
                timeInForce: _orderTimeInForce,
                price: _price,
                baseQty: _baseQty,
                quoteQty: _quoteQty
            })
            let msg = `${exchangeDriver.name()}: successfully placed order #${orderId}`
            console.log(msg)
            antdMessage.success(msg)
            await loadSymbols()
            await loadBalances()
        } catch (e: any) {
            let msg = `${exchangeDriver.name()}: could not place order: ${e.message}`
            console.error(msg)
            antdMessage.error(msg)
            await loadSymbols()
            await loadBalances()
            return
        }

        // Try to repay if needed
        if (orderSide === OrderSide.Buy) {
            let amountToRepay = "0"
            if (currentBaseMarginBalance !== null && gt(currentBaseMarginBalance.borrowedQty, 0)) {
                amountToRepay = currentBaseMarginBalance.borrowedQty
            }
            let { balance } = await exchangeDriver.getMarginCoinBalance({
                coinName: currentSymbol.baseAsset,
                isolatedSymbolName: currentSymbol.ownName
            })
            if (balance !== null && lt(balance.freeQty, amountToRepay)) {
                amountToRepay = balance.freeQty
            }
            if (gt(amountToRepay, 0)) {
                console.log(`MarginOrdercontroller: repaying ${amountToRepay} ${currentSymbol.baseAsset}`)
                try {
                    let { loanId } = await exchangeDriver.marginLoanRepay({
                        coinName: currentSymbol.baseAsset,
                        amount: amountToRepay,
                        isolatedSymbolName: currentSymbol.ownName
                    })
                    console.log(
                        `${exchangeDriver.name()}: repaid ${amountToRepay} ${currentSymbol.baseAsset}: #${loanId}`
                    )
                } catch (e: any) {
                    let msg = `${exchangeDriver.name()}: could not repay ${amountToRepay} ${currentSymbol.baseAsset}: ${
                        e.message
                    }`
                    console.warn(msg)
                    // antdMessage.error(msg)
                }
            }
        }
        await loadQuoteCoinBalance()
        await loadBaseCoinBalance()
    }, [
        exchangeDriver,
        selectedCoinName,
        currentSymbol,
        orderSide,
        orderType,
        orderTimeInForce,
        limitPrice,
        baseQty,
        quoteQty,
        currentBaseMarginBalance
    ])

    const memoOrderHistory = useMemo(() => {
        if (exchangeDriver === null || currentSymbol === null) {
            return null
        }
        return (
            <MarginOrdersHistoryList
                exchangeDriver={exchangeDriver}
                symbol={currentSymbol}
                key={currentSymbol.ownName}
                loadBalances={loadBalances}
            />
        )
    }, [exchangeDriver, currentSymbol])

    const memoOrderbookWidget = useMemo(() => {
        if (currentSymbol === null || orderbook === null) {
            return null
        }
        return (
            <OrderbookWidget
                symbol={currentSymbol}
                orderbook={orderbook}
                orderSide={orderSide}
                quoteQty={quoteQty}
                baseQty={baseQty}
                setQuoteQty={setQuoteQty}
                setBaseQty={setBaseQty}
                setLimitPrice={setLimitPrice}
                colorAsks={ARBITRAGE_COLOR_LONG}
                colorBids={ARBITRAGE_COLOR_SHORT}
            />
        )
    }, [currentSymbol, orderbook, orderSide, quoteQty, baseQty])

    const memoOrderPopconfirm = useMemo((): ReactElement => {
        if (exchangeDriver === null || currentSymbol === null) {
            return <></>
        }
        let borrowChildren: ReactElement[] = []
        let repayChildren: ReactElement[] = []
        let orderChildren: ReactElement[] = []

        // Borrow
        if (orderSide === OrderSide.Sell && baseQty !== null) {
            let amountToBorrow = baseQty
            let amountFromOwned = "0"
            if (currentBaseMarginBalance !== null && gt(currentBaseMarginBalance.freeQty, 0)) {
                amountFromOwned = currentBaseMarginBalance.freeQty
                amountToBorrow = bigDecimal.subtract(baseQty, amountFromOwned)
            }
            if (!lt(amountFromOwned, baseQty)) {
                amountFromOwned = baseQty
                amountToBorrow = "0"
            }
            if (gt(amountToBorrow, 0)) {
                borrowChildren.push(
                    <li>
                        <b>{amountToBorrow}</b> {currentSymbol.baseAsset} will be <b>borrowed</b> to support the order
                    </li>
                )
            }
            if (gt(amountFromOwned, 0)) {
                borrowChildren.push(
                    <li>
                        <b>{amountFromOwned}</b> {currentSymbol.baseAsset} will be used from the <b>owned balance</b>
                    </li>
                )
            }
        }

        // Repay
        if (
            orderSide === OrderSide.Buy &&
            baseQty !== null &&
            currentBaseMarginBalance !== null &&
            gt(currentBaseMarginBalance.borrowedQty, 0)
        ) {
            // let amountToOwned = "0"
            let amountToRepay = baseQty
            if (lt(currentBaseMarginBalance.borrowedQty, baseQty)) {
                amountToRepay = currentBaseMarginBalance.borrowedQty
            }
            let amountToOwned = bigDecimal.subtract(baseQty, amountToRepay)
            // if (currentBaseMarginBalance !== null && gt(currentBaseMarginBalance.borrowedQty, 0)) {
            // }
            if (gt(amountToRepay, 0)) {
                repayChildren.push(
                    <li>
                        <b>{amountToRepay}</b> {currentSymbol.baseAsset} will be <b>repaid</b> if order is fulfilled
                        immediately
                    </li>
                )
            }
            if (gt(amountToOwned, 0)) {
                repayChildren.push(
                    <li>
                        <b>{amountToOwned}</b> {currentSymbol.baseAsset} will be left as <b>owned balance</b>
                    </li>
                )
            }
        }

        // Order
        if (orderType === OrderType.Limit) {
            orderChildren.push(
                <>
                    <b>
                        {orderType} {orderSide}
                    </b>{" "}
                    ({orderTimeInForce}) of <b>{baseQty}</b> {currentSymbol.baseAsset} at limit price of{" "}
                    <b>{limitPrice}</b> USDT
                </>
            )
        } else if (orderType === OrderType.Market) {
            if (orderSide === OrderSide.Buy) {
                orderChildren.push(
                    <>
                        <b>
                            {orderType} {orderSide}
                        </b>{" "}
                        of <b>{quoteQty}</b> USDT worth of {currentSymbol.baseAsset}
                    </>
                )
            } else if (orderSide === OrderSide.Sell) {
                orderChildren.push(
                    <>
                        <b>
                            {orderType} {orderSide}
                        </b>{" "}
                        of <b>{baseQty}</b> {currentSymbol.baseAsset}
                    </>
                )
            }
        }
        return (
            <div>
                {orderChildren}
                <br />
                <ul>{borrowChildren}</ul>
                <ul>{repayChildren}</ul>
            </div>
        )
    }, [
        exchangeDriver,
        currentSymbol,
        orderSide,
        orderType,
        orderTimeInForce,
        limitPrice,
        baseQty,
        quoteQty,
        currentBaseMarginBalance
    ])

    const memoMaxSellableBaseQty = useMemo(() => {
        if (exchangeDriver === null || currentSymbol === null || currentBaseMarginBalance === null) {
            return null
        }
        let maxSellBaseQty = currentBaseMarginBalance.freeQty
        if (currentBaseMarginMaxBorrowableBalance !== null) {
            maxSellBaseQty = bigDecimal.add(maxSellBaseQty, currentBaseMarginMaxBorrowableBalance)
        }
        return maxSellBaseQty
    }, [exchangeDriver, currentSymbol, currentBaseMarginBalance, currentBaseMarginMaxBorrowableBalance])

    if (
        exchangeDriver === null ||
        symbols === null ||
        coins === null ||
        spotBalances === null ||
        marginBalances === null
    ) {
        return (
            <FlexCol
                style={{
                    minHeight: 200,
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Spin size="large" />
            </FlexCol>
        )
    }

    return (
        <div
            style={{
                backgroundColor:
                    orderSide === OrderSide.Buy ?
                        colorHexToRGBA(ARBITRAGE_COLOR_LONG, 0.1)
                    :   colorHexToRGBA(ARBITRAGE_COLOR_SHORT, 0.1),
                padding: 10
            }}
        >
            <Row
                gutter={[10, 5]}
                style={{
                    height: "100%"
                }}
            >
                {/* Side Selector */}
                <Col xs={24}>
                    <Space.Compact
                        style={{
                            width: "100%",
                            justifyContent: "center",
                            marginTop: 5,
                            marginBottom: 5
                        }}
                    >
                        <Button
                            block
                            type={orderSide === OrderSide.Buy ? "primary" : "default"}
                            style={{
                                backgroundColor: orderSide === OrderSide.Buy ? ARBITRAGE_COLOR_LONG : undefined
                            }}
                            onClick={() => {
                                setOrderSide(OrderSide.Buy)
                            }}
                        >
                            LONG
                        </Button>
                        <Button
                            block
                            type={orderSide === OrderSide.Sell ? "primary" : "default"}
                            style={{
                                backgroundColor: orderSide === OrderSide.Sell ? ARBITRAGE_COLOR_SHORT : undefined
                            }}
                            onClick={() => {
                                setOrderSide(OrderSide.Sell)
                            }}
                        >
                            SHORT
                        </Button>
                    </Space.Compact>
                </Col>
                {/* Coin Selector */}
                <Col xs={24}>
                    <FlexRow style={{ gap: 5, justifyContent: "space-between" }}>
                        <Select
                            style={{
                                width: "100%"
                            }}
                            loading={coins === null}
                            options={memoCoinsOptions}
                            value={selectedCoinName}
                            onChange={value => {
                                if (value === undefined) {
                                    return
                                }
                                setSelectedCoinName(value)
                                if (executorLinkEnabled) {
                                    setExecutorCommonCoin(value)
                                }
                            }}
                            placeholder="Select symbol"
                            showSearch
                            // suffixIcon={<> / USDT</>}
                        />
                        <FlexCol style={{ gap: 0, alignItems: "end", justifyContent: "center" }}>
                            <FlexRow style={{ gap: 3 }}>
                                <Tag
                                    style={{ marginRight: 0 }}
                                    color={
                                        exchangeDriver.config().marginAccountMode === MarginAccountMode.Unified ?
                                            "green"
                                        :   "orange"
                                    }
                                >
                                    {MarginAccountMode[exchangeDriver.config().marginAccountMode]}
                                </Tag>
                                |
                                {exchangeDriver.config().marginTradeTypes.map(t => (
                                    <Tag
                                        style={{ marginRight: 0 }}
                                        key={t}
                                        color={t === MarginTradeType.Cross ? "green" : "warning"}
                                    >
                                        {MarginTradeType[t]}
                                    </Tag>
                                ))}
                            </FlexRow>
                        </FlexCol>
                    </FlexRow>
                </Col>
                {currentSymbol !== null && (
                    <Col xs={12}>
                        {currentSymbol.isAvailable ?
                            <FlexCol
                                style={{
                                    height: "100%"
                                }}
                            >
                                <FlexRow
                                    style={{
                                        gap: 3,
                                        alignItems: "center",
                                        width: "100%",
                                        justifyContent: "end",
                                        marginBottom: -44,
                                        zIndex: 10
                                    }}
                                >
                                    <Tooltip overlay={<>Consolidate balances on MARGIN account</>}>
                                        <Button
                                            icon={<FullscreenExitOutlined />}
                                            onClick={async () => {
                                                try {
                                                    await transferQuoteCoinSpotToMargin()
                                                } catch (e: any) {
                                                    message.error(
                                                        `Could not consolidate USDT on MARGIN: ${e.toString()}`
                                                    )
                                                }
                                                try {
                                                    await transferBaseCoinSpotToMargin()
                                                } catch (e: any) {
                                                    message.error(
                                                        `Could not consolidate ${
                                                            currentSymbol.baseAsset
                                                        } on MARGIN: ${e.toString()}`
                                                    )
                                                }
                                                setSpotBalances(null)
                                                setSymbols(null)
                                                await loadBalances()
                                                await loadSymbols()
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip overlay="Refresh balances">
                                        <Button
                                            icon={<ReloadOutlined />}
                                            onClick={async () => {
                                                setSpotBalances(null)
                                                setMarginBalances(null)
                                                setSymbols(null)
                                                await loadBalances()
                                                await loadSymbols()
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip overlay="Orderbook auto-refresh">
                                        <Switch
                                            checked={clockIsEnabled}
                                            onChange={checked => {
                                                setClockIsEnabled(checked)
                                            }}
                                        />
                                    </Tooltip>
                                </FlexRow>
                                {/* Quote and Base balances */}
                                <Row justify="space-between" align="top">
                                    <Col>
                                        <FlexCol style={{ gap: 0, marginLeft: 5 }}>
                                            <span>
                                                <b>USDT:</b> {memoQuoteBalanceElement}
                                            </span>
                                            <span>
                                                <b>{currentSymbol.baseAsset}:</b>
                                                {memoBaseBalanceElement}
                                            </span>
                                        </FlexCol>
                                    </Col>
                                </Row>
                                <Row gutter={[5, 5]}>
                                    {/* OrderType & Price (if LIMIT) */}
                                    <Col xs={12} xl={"auto"}>
                                        <Select
                                            style={{
                                                width: "100%"
                                            }}
                                            options={[{ value: OrderType.Limit }, { value: OrderType.Market }]}
                                            value={orderType}
                                            onChange={value => {
                                                setOrderType(value)
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} xl={"auto"}>
                                        <Select
                                            style={{
                                                width: "100%"
                                            }}
                                            options={[
                                                { value: OrderTimeInForce.GoodTilCancelled },
                                                { value: OrderTimeInForce.ImmediateOrCancel },
                                                { value: OrderTimeInForce.FillOrKill }
                                            ]}
                                            value={orderTimeInForce}
                                            onChange={value => {
                                                setOrderTimeInForce(value)
                                            }}
                                            disabled={
                                                !exchangeDriver.config().timeInForceIsSupported ||
                                                orderType === OrderType.Market
                                            }
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <OrderQtyInput
                                            propValue={limitPrice}
                                            suffixValue={null}
                                            stepValue={currentSymbol.priceTick}
                                            prefix="Price"
                                            onChange={(value: string | null) => {
                                                if (value === null) {
                                                    setLimitPrice(null)
                                                    return
                                                }
                                                setLimitPrice(
                                                    formattedNumberToFixedWithoutTrailingZeros(
                                                        truncateFloatUsingDecimalStepHE(value, currentSymbol.priceTick)
                                                    )
                                                )
                                            }}
                                            subscript={
                                                <i style={{ fontSize: "0.8rem" }}>
                                                    Price step:{" "}
                                                    {currentSymbol.priceTick ?
                                                        formattedNumberToFixedWithoutTrailingZeros(
                                                            currentSymbol.priceTick
                                                        )
                                                    :   "N/A"}
                                                </i>
                                            }
                                            disabled={orderType === OrderType.Market}
                                        />
                                    </Col>
                                    {/* Order quantities (quote and base) */}
                                    <Col xs={24}>
                                        <OrderQtyInput
                                            propValue={quoteQty}
                                            suffixValue={
                                                orderSide === OrderSide.Buy && currentQuoteMarginBalance !== null ?
                                                    currentQuoteMarginBalance.freeQty
                                                :   null
                                            }
                                            stepValue={USDT_DECIMAL_STEP}
                                            prefix="USDT"
                                            onChange={(value: string | null) => {
                                                if (value === null) {
                                                    setQuoteQty(null)
                                                    return
                                                }
                                                setQuoteQty(
                                                    formattedNumberToFixedWithoutTrailingZeros(
                                                        truncateFloatUsingDecimalStepHE(value, USDT_DECIMAL_STEP)
                                                    )
                                                )
                                                if (limitPrice !== null && currentSymbol !== null) {
                                                    let _baseQty = bigDecimal.divide(value, limitPrice)
                                                    _baseQty = formattedNumberToFixedWithoutTrailingZeros(
                                                        truncateFloatUsingDecimalStepHE(_baseQty, currentSymbol.lotStep)
                                                    )
                                                    setBaseQty(_baseQty)
                                                } else {
                                                    setBaseQty(null)
                                                }
                                            }}
                                            subscript={
                                                <i style={{ fontSize: "0.8rem" }}>
                                                    Min ({orderType}):{" "}
                                                    {orderType === OrderType.Limit ?
                                                        currentSymbol.limitNotionalMin ?
                                                            formattedNumberToFixedWithoutTrailingZeros(
                                                                currentSymbol.limitNotionalMin
                                                            )
                                                        :   "N/A"
                                                    : currentSymbol.marketNotionalMin ?
                                                        formattedNumberToFixedWithoutTrailingZeros(
                                                            currentSymbol.marketNotionalMin
                                                        )
                                                    :   "N/A"}
                                                </i>
                                            }
                                            disabled={orderType === OrderType.Market && orderSide === OrderSide.Sell}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <OrderQtyInput
                                            propValue={baseQty}
                                            suffixLabel={
                                                (
                                                    orderSide === OrderSide.Buy &&
                                                    currentBaseMarginBalance !== null &&
                                                    gt(currentBaseMarginBalance.borrowedQty, 0)
                                                ) ?
                                                    "Liq"
                                                :   undefined
                                            }
                                            suffixValue={(function () {
                                                if (orderSide === OrderSide.Sell && memoMaxSellableBaseQty !== null) {
                                                    return memoMaxSellableBaseQty
                                                } else if (
                                                    orderSide === OrderSide.Buy &&
                                                    currentBaseMarginBalance !== null &&
                                                    gt(currentBaseMarginBalance.borrowedQty, 0)
                                                ) {
                                                    let _borrowedQty: Amount | null =
                                                        currentBaseMarginBalance.borrowedQty
                                                    if (
                                                        currentSymbol.lotMin !== null &&
                                                        lt(_borrowedQty, currentSymbol.lotMin)
                                                    ) {
                                                        _borrowedQty = null
                                                    }
                                                    if (_borrowedQty !== null && currentSymbol.lotStep !== null) {
                                                        _borrowedQty = truncateFloatUsingDecimalStepHE(
                                                            _borrowedQty,
                                                            currentSymbol.lotStep
                                                        )
                                                        if (lt(_borrowedQty, currentBaseMarginBalance.borrowedQty)) {
                                                            _borrowedQty = bigDecimal.add(
                                                                _borrowedQty,
                                                                currentSymbol.lotStep
                                                            )
                                                        }
                                                    }
                                                    return _borrowedQty
                                                } else {
                                                    return null
                                                }
                                            })()}
                                            stepValue={currentSymbol.lotStep}
                                            prefix={currentSymbol.baseAsset}
                                            onChange={(value: string | null) => {
                                                if (value === null) {
                                                    setBaseQty(null)
                                                    return
                                                }
                                                value = formattedNumberToFixedWithoutTrailingZeros(
                                                    truncateFloatUsingDecimalStepHE(value, currentSymbol.lotStep)
                                                )
                                                setBaseQty(value)
                                                if (limitPrice !== null && currentSymbol !== null) {
                                                    let _quoteQty = bigDecimal.multiply(value, limitPrice)
                                                    setQuoteQty(
                                                        formattedNumberToFixedWithoutTrailingZeros(
                                                            truncateFloatUsingDecimalStepHE(
                                                                _quoteQty,
                                                                USDT_DECIMAL_STEP
                                                            )
                                                        )
                                                    )
                                                } else {
                                                    setQuoteQty(null)
                                                }
                                            }}
                                            subscript={
                                                <FlexRow>
                                                    <i style={{ fontSize: "0.8rem" }}>
                                                        Min:{" "}
                                                        {currentSymbol.lotMin ?
                                                            formattedNumberToFixedWithoutTrailingZeros(
                                                                currentSymbol.lotMin
                                                            )
                                                        :   "N/A"}
                                                    </i>
                                                    <i style={{ fontSize: "0.8rem" }}>
                                                        Lot step:{" "}
                                                        {currentSymbol.lotStep ?
                                                            formattedNumberToFixedWithoutTrailingZeros(
                                                                currentSymbol.lotStep
                                                            )
                                                        :   "N/A"}
                                                    </i>
                                                </FlexRow>
                                            }
                                            disabled={orderType === OrderType.Market && orderSide === OrderSide.Buy}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[5, 5]} style={{ flexGrow: 3 }} align={"bottom"}>
                                    <Col xs={24} order={orderSide === OrderSide.Sell ? 2 : 1}>
                                        <Popconfirm
                                            disabled={memoCanOrder === false}
                                            title={
                                                <>
                                                    Are you sure to place this order?
                                                    <br />
                                                    <br />
                                                    {memoOrderPopconfirm}
                                                </>
                                            }
                                            onConfirm={placeOrder}
                                        >
                                            <Button
                                                block
                                                disabled={memoCanOrder === false}
                                                type="primary"
                                                style={{
                                                    backgroundColor:
                                                        orderSide === OrderSide.Buy ?
                                                            ARBITRAGE_COLOR_LONG
                                                        :   ARBITRAGE_COLOR_SHORT
                                                }}
                                            >
                                                {orderSide === OrderSide.Buy ? "LONG" : "SHORT"}
                                            </Button>
                                        </Popconfirm>
                                    </Col>
                                </Row>
                            </FlexCol>
                        :   <FlexCol>
                                <Alert message="Symbol is not available for MARGIN trading" type="warning" />
                            </FlexCol>
                        }
                    </Col>
                )}
                {currentSymbol !== null && orderbook !== null && <Col xs={12}>{memoOrderbookWidget}</Col>}
                {currentSymbol !== null && <Col xs={24}>{memoOrderHistory}</Col>}
            </Row>
        </div>
    )
}
