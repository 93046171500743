import { FC, useMemo } from "react"
import {
    Chart as ChartJS,
    Tooltip,
    Legend,
    CategoryScale,
    BarElement,
    LineElement,
    PointElement,
    LinearScale,
    TimeScale,
    Title,
    ChartOptions,
    ChartData,
    Colors
} from "chart.js"
import { Bar, Line } from "react-chartjs-2"
import { Alert } from "antd"
import { colorHexToRGBA, ExchangeNameColorsMap } from "../constants"
import autocolors from "chartjs-plugin-autocolors"
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm"
import { Dayjs } from "dayjs"

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    Colors,
    autocolors
)

export const StatsBarChart: FC<{
    categories: string[]
    categoryTitle: string
    quantities: number[]
    quantityTitle: string
    maxY?: number
}> = ({ categories, categoryTitle, quantities, quantityTitle, maxY }) => {
    const options: ChartOptions<"bar"> = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
                display: false
            },
            title: {
                display: false
            },
            colors: {
                enabled: true
            },
            autocolors: {
                enabled: false
            }
        },
        skipNull: true,
        scales: {
            x: {
                title: {
                    display: false,
                    text: categoryTitle
                }
            },
            y: {
                title: {
                    display: true,
                    text: quantityTitle
                },
                max: maxY
            }
        },
        datasets: {
            bar: {
                categoryPercentage: 1,
                barPercentage: 0.8,
                borderWidth: 1
            }
        }
    }

    const data: ChartData<"bar", (number | null)[], string> = {
        labels: categories,
        datasets: [
            {
                data: quantities
            }
        ]
    }

    return <Bar data={data} options={options} />
}

export const StatsStackedBarChart: FC<{
    categories: string[]
    categoryTitle: string
    datasets: Record<string, number[]>
    quantityTitle: string
    maxY?: number
}> = ({ categories, categoryTitle, datasets, quantityTitle, maxY }) => {
    // console.log(`StatsStackedBarChart: datasets`, datasets)
    for (const [label, data] of Object.entries(datasets)) {
        if (data.length !== categories.length) {
            return <Alert type="error" message={<>Data Length ({label}) !== Categories Length</>} />
        }
    }

    const options: ChartOptions<"bar"> = {
        responsive: true,
        animation: false,
        normalized: true,
        plugins: {
            legend: {
                position: "top" as const,
                display: false
            },
            title: {
                display: false
            },
            colors: {
                forceOverride: categoryTitle === "Exchange",
                enabled: true
            },
            autocolors: {
                mode: "label"
            }
        },
        skipNull: true,
        scales: {
            x: {
                title: {
                    display: false,
                    text: categoryTitle
                },
                stacked: true
            },
            y: {
                title: {
                    display: true,
                    text: quantityTitle
                },
                stacked: true,
                max: maxY
            }
        },
        datasets: {
            bar: {
                categoryPercentage: 1,
                barPercentage: 0.8,
                borderWidth: 1
            }
        }
    }

    const memoData: ChartData<"bar", (number | null)[], string> = useMemo(() => {
        let sortedLabelDataPairs = Object.entries(datasets)
        let sum = (a: number[]) => a.reduce((acc, cur) => acc + cur, 0)
        let mean = (a: number[]) => (a.length === 0 ? 0 : sum(a) / a.length)
        sortedLabelDataPairs.sort((a, b) => {
            return mean(b[1]) - mean(a[1])
        })
        return {
            labels: categories,
            datasets: sortedLabelDataPairs.map(([label, data]) => {
                return {
                    label,
                    data,
                    backgroundColor:
                        ExchangeNameColorsMap[label] !== undefined ?
                            colorHexToRGBA(ExchangeNameColorsMap[label], 0.4)
                        :   undefined,
                    borderColor: ExchangeNameColorsMap[label]
                }
            })
        }
    }, [categories, datasets])

    return <Bar data={memoData} options={options} />
}

export const StatsLineChart: FC<{
    categories: number[]
    categoryTitle: string
    datasets: Record<string, number[]>
    quantityTitle: string
    maxY?: number
}> = ({ categories, categoryTitle, datasets, quantityTitle, maxY }) => {
    const options: ChartOptions<"line"> = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
                display: true
            },
            title: {
                display: false
            },
            colors: {
                // forceOverride: true,
                enabled: true
            },
            autocolors: {
                mode: "label"
            }
        },
        scales: {
            x: {
                title: {
                    display: false,
                    text: categoryTitle
                }
            },
            y: {
                title: {
                    display: true,
                    text: quantityTitle
                },
                max: maxY
            }
        }
    }

    const memoData: ChartData<"line", (number | null)[], string> = useMemo(() => {
        let sortedLabelDataPairs = Object.entries(datasets)
        let sum = (a: number[]) => a.reduce((acc, cur) => acc + cur, 0)
        let mean = (a: number[]) => (a.length === 0 ? 0 : sum(a) / a.length)
        sortedLabelDataPairs.sort((a, b) => {
            return mean(b[1]) - mean(a[1])
        })
        return {
            labels: categories.map(x => x.toString()),
            datasets: sortedLabelDataPairs.map(([label, data]) => {
                let [exchangeFrom, exchangeTo] = label.split("_")
                let exchangeFromColor = ExchangeNameColorsMap[exchangeFrom]
                let exchangeToColor = ExchangeNameColorsMap[exchangeTo]
                return {
                    label,
                    data,
                    fill: false,
                    backgroundColor:
                        exchangeFromColor !== undefined ? colorHexToRGBA(exchangeToColor, 0.25) : undefined,
                    pointBorderColor: exchangeToColor,
                    pointBorderWidth: 1,
                    pointRadius: 6,

                    borderColor: exchangeFromColor,
                    borderWidth: 2
                }
            })
        }
    }, [categories, datasets])

    // console.log(`StatsLineChart: memoData`, memoData)

    return <Line data={memoData} options={options} height={200} />
}

export const StatsTimeseriesChart: FC<{
    categories: Dayjs[]
    categoryTitle: string
    datasets: Record<string, number[]>
    quantityTitle: string
    maxY?: number
}> = ({ categories, categoryTitle, datasets, quantityTitle, maxY }) => {
    const options: ChartOptions<"line"> = {
        responsive: true,
        plugins: {
            legend: {
                position: "top" as const,
                display: true
            },
            title: {
                display: false
            },
            colors: {
                // forceOverride: true,
                enabled: true
            },
            autocolors: {
                mode: "label"
            }
        },
        scales: {
            x: {
                type: "time",
                // time: {
                //     unit: "hour"
                // },
                title: {
                    display: false,
                    text: categoryTitle
                }
            },
            y: {
                title: {
                    display: true,
                    text: quantityTitle
                },
                max: maxY
            }
        }
    }

    const memoData: ChartData<"line", (number | null)[], string> = useMemo(() => {
        let sortedLabelDataPairs = Object.entries(datasets)
        let sum = (a: number[]) => a.reduce((acc, cur) => acc + cur, 0)
        let mean = (a: number[]) => (a.length === 0 ? 0 : sum(a) / a.length)
        sortedLabelDataPairs.sort((a, b) => {
            return mean(b[1]) - mean(a[1])
        })
        return {
            labels: categories.map(x => x.toString()),
            datasets: sortedLabelDataPairs.map(([label, data]) => {
                let [exchangeFrom, exchangeTo] = label.split("_")
                let exchangeFromColor = ExchangeNameColorsMap[exchangeFrom]
                let exchangeToColor = ExchangeNameColorsMap[exchangeTo]
                return {
                    label,
                    data,
                    fill: false,
                    backgroundColor:
                        exchangeFromColor !== undefined ? colorHexToRGBA(exchangeToColor, 0.25) : undefined,
                    pointBorderColor: exchangeToColor,
                    pointBorderWidth: 1,
                    pointRadius: 6,

                    borderColor: exchangeFromColor,
                    borderWidth: 2
                }
            })
        }
    }, [categories, datasets])

    // console.log(`StatsLineChart: memoData`, memoData)

    return <Line data={memoData} options={options} height={200} />
}
