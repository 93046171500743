import {
    NewBinanceExchangeDriver,
    NewMexcExchangeDriver,
    NewOkxExchangeDriver,
    NewKucoinExchangeDriver,
    NewBitgetExchangeDriver,
    NewBybitExchangeDriver,
    NewPoloniexExchangeDriver,
    NewHtxExchangeDriver,
    NewGateExchangeDriver,
    NewXtExchangeDriver
} from "arbitrage-ts-exchange-apis"
import { IExchangeDriver, SymbolInfo, CoinInfo } from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/types"
import { Exchange, APIKeySet, CommonNetworkNameMap } from "../../types"

export const USDT_DECIMAL_STEP = "0.0001"
export const extendedCommonNetworkNames: string[] = ["KAVA", "ALGO"]

export const getExchangeDriverByName = (
    exchangeName: Exchange["Name"],
    apiKeySet: APIKeySet
): IExchangeDriver | null => {
    let exchangeDriver: IExchangeDriver | null = null
    switch (exchangeName) {
        case "BINANCE":
            exchangeDriver = NewBinanceExchangeDriver(apiKeySet)
            break
        case "MEXC":
            exchangeDriver = NewMexcExchangeDriver(apiKeySet)
            break
        case "OKX":
            exchangeDriver = NewOkxExchangeDriver(apiKeySet)
            break
        case "KUCOIN":
            exchangeDriver = NewKucoinExchangeDriver(apiKeySet)
            break
        case "BITGET":
            exchangeDriver = NewBitgetExchangeDriver(apiKeySet)
            break
        case "BYBIT":
            exchangeDriver = NewBybitExchangeDriver(apiKeySet)
            break
        case "POLONIEX":
            exchangeDriver = NewPoloniexExchangeDriver(apiKeySet)
            break
        case "HTX":
            exchangeDriver = NewHtxExchangeDriver(apiKeySet)
            break
        case "GATE":
            exchangeDriver = NewGateExchangeDriver(apiKeySet)
            break
        case "XT":
            exchangeDriver = NewXtExchangeDriver(apiKeySet)
            break
        default:
            console.warn(exchangeName + " exchange not supported")
            break
    }
    return exchangeDriver
}

export const isLeveraged = (symbol: SymbolInfo | CoinInfo): boolean => {
    return symbol.ownName.match(/[3,5][l,s].{0,1}$/i) !== null
}

export const symbolIsUSDTQuoted = (symbol: SymbolInfo): boolean => {
    return symbol.quoteAsset.toUpperCase() === "USDT"
}

export const getDirectNetworkCommonName = (
    networkName: string,
    commonNetworkNameMap: CommonNetworkNameMap
): string | null => {
    networkName = networkName.toUpperCase()
    let commonName: string | undefined = commonNetworkNameMap[networkName]
    if (commonName === undefined) {
        for (let [k, v] of Object.entries(commonNetworkNameMap)) {
            if (networkName.toUpperCase() === k.toUpperCase()) {
                commonName = v
                break
            }
        }
    }
    if (commonName === undefined) {
        for (let substr of extendedCommonNetworkNames) {
            if (networkName.toUpperCase().includes(substr)) {
                commonName = substr
                break
            }
        }
    }
    if (commonName === undefined) {
        return null
    }
    console.log(`getDirectNetworkCommonName: ${networkName} -> ${commonName}`)
    return commonName
}

export const getReverseNetworkCommonName = (
    commonName: string,
    candidates: string[],
    commonNetworkNameMap: CommonNetworkNameMap
): string | null => {
    let ownName: string | null = null
    for (let [k, v] of Object.entries(commonNetworkNameMap)) {
        if (commonName === v) {
            for (let c of candidates) {
                if (c.toUpperCase() === k.toUpperCase()) {
                    ownName = c
                    break
                }
            }
            if (ownName !== null) {
                break
            }
        }
    }
    const _lookForSubstrMatch = (substr: string) => {
        if (commonName.toUpperCase().includes(substr)) {
            for (let candidate of candidates) {
                if (candidate.toUpperCase().includes(substr)) {
                    return candidate
                }
            }
        }
        return null
    }
    // extended search
    if (ownName === null) {
        for (let substr of extendedCommonNetworkNames) {
            ownName = _lookForSubstrMatch(substr)
            if (ownName !== null) {
                break
            }
        }
    }
    console.log(`getReverseNetworkCommonName: ${commonName} -> ${ownName} (candidates: ${candidates})`)
    return ownName
}

export const getExchangeDriverIconSrcUrl = (exchangeName: Exchange["Name"]): string => {
    return `https://arbitrage.ngineera.com/_next/image?url=%2Fexchange_logos%2Flogo-${exchangeName.toLowerCase()}.png&w=96&q=95`
}
