import { APIKeySet } from "./types"
import { get, set, del, keys, createStore } from "idb-keyval"

const EXCHANGE_API_KEY_SETS_STORE = createStore("arbitrage-db", "exchange-api-key-store")

export const loadExchangeAPIKeySet = async (exchangeName: string): Promise<APIKeySet | null> => {
    let key = exchangeName.toLowerCase()
    let value = await get(key, EXCHANGE_API_KEY_SETS_STORE)
    if (!value) {
        return null
    }
    if (value[0] === "~") {
        value = atob(value.slice(1))
    }
    let parts = value.split(";")
    if (parts.length !== 3) {
        return null
    }
    let apiKeys: APIKeySet = {
        apiKey: parts[0],
        secretKey: parts[1],
        passphrase: parts[2]
    }
    return apiKeys
}

export const storeExchangeAPIKeySet = async (exchangeName: string, apiKeySet: APIKeySet) => {
    let key = exchangeName.toLowerCase()
    let apiKey = ""
    if (apiKeySet.apiKey) {
        apiKey = apiKeySet.apiKey
    }
    let secretKey = ""
    if (apiKeySet.secretKey) {
        secretKey = apiKeySet.secretKey
    }
    let passphrase = ""
    if (apiKeySet.passphrase) {
        passphrase = apiKeySet.passphrase
    }
    let value = `${apiKey};${secretKey};${passphrase}`
    // console.log("store exchange api key set", key, value)
    let valueB64 = "~" + btoa(value)
    await set(key, valueB64, EXCHANGE_API_KEY_SETS_STORE)
}

export const deleteExchangeAPIKeySet = async (exchangeName: string) => {
    let key = exchangeName.toLowerCase()
    return await del(key, EXCHANGE_API_KEY_SETS_STORE)
}

export const fetchStoredExchangeNames = async (): Promise<string[]> => {
    let idbKeys = await keys(EXCHANGE_API_KEY_SETS_STORE)
    return idbKeys.map(k => k.toString().toUpperCase())
}
