import { ExportOutlined } from "@ant-design/icons"
import { Tag, Typography, Table, Input, Spin, Button, Modal, Tabs, message as antdMessage} from "antd"
import { ColumnsType } from "antd/es/table"
import { IExchangeDriver, CoinNetworkInfo } from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/types"
import { FC, useState, useCallback, useEffect, useMemo } from "react"
import { FlexCol, FlexRow } from "../../common"
import { fetchStoredExchangeNames, loadExchangeAPIKeySet } from "../../store"
import { getExchangeDriverByName } from "./utils"

interface WhitelistAddressAggregated {
    coin: string
    networks: string[]
    isEVM: boolean
    address: string
    memo: string | null
}

const WhitelistManagerModalExchangeTab: FC<{
    exchangeDriver: IExchangeDriver | null
}> = ({ exchangeDriver }) => {
    const [coinNetworks, setCoinNetworks] = useState<CoinNetworkInfo[]>([])
    const [coinName, setCoinName] = useState<string>("USDT")
    const [aggregatedAddressesList, setAggregatedAddressesList] = useState<WhitelistAddressAggregated[]>([])

    const [isLoading, setIsLoading] = useState<boolean>(true)

    const loadCoinNetworks = useCallback(async () => {
        if (exchangeDriver === null) {
            return
        }
        try {
            let { networks } = await exchangeDriver.getCoinNetworks(coinName)
            setCoinNetworks(networks)
        } catch (e: any) {
            let msg = `WhitelistManagerModalExchangeTab: ${exchangeDriver.name()}: getCoinNetworks error: ${e.message}`
            console.error(msg)
            antdMessage.error(msg)
        }
    }, [exchangeDriver, coinName])

    const loadAddressList = useCallback(async () => {
        if (exchangeDriver === null || coinNetworks.length === 0) {
            return
        }
        let _addressesList: { coin: string; network: string; address: string; memo: string | null }[] = []
        for (let network of coinNetworks) {
            try {
                let { address } = await exchangeDriver.getCoinNetworkDepositAddress({
                    coinName,
                    networkName: network.ownName
                })
                let addr = address.address
                if (addr === null || addr === "" || addr === undefined) {
                    addr = "N/A"
                }
                _addressesList.push({
                    coin: coinName,
                    network: network.networkName,
                    address: address.address,
                    memo: address.memo
                })

                let uniqueAddressesMap: Record<string, { networks: Set<string>; memo: string | null }> = {}
                for (let addr of _addressesList) {
                    if (uniqueAddressesMap[addr.address] === undefined) {
                        uniqueAddressesMap[addr.address] = {
                            networks: new Set(),
                            memo: addr.memo
                        }
                    }
                    uniqueAddressesMap[addr.address].networks.add(addr.network)
                    uniqueAddressesMap[addr.address].memo = addr.memo
                }
                let _aggregatedAddressesList: WhitelistAddressAggregated[] = []
                for (let addr in uniqueAddressesMap) {
                    let { networks, memo } = uniqueAddressesMap[addr]
                    let isEVM = addr.match(/^0x[a-fA-F0-9]{40}$/) !== null
                    _aggregatedAddressesList.push({
                        coin: coinName,
                        networks: Array.from(networks),
                        isEVM,
                        address: addr,
                        memo
                    })
                }
                _aggregatedAddressesList.sort((a, b) => {
                    if (a.isEVM && !b.isEVM) {
                        return -1
                    } else if (!a.isEVM && b.isEVM) {
                        return 1
                    } else {
                        if (a.networks.length > b.networks.length) {
                            return -1
                        } else if (a.networks.length < b.networks.length) {
                            return 1
                        } else {
                            return a.address.localeCompare(b.address)
                        }
                    }
                })
                setAggregatedAddressesList(_aggregatedAddressesList)
            } catch (e: any) {
                let msg = `WhitelistManagerModalExchangeTab: ${exchangeDriver.name()}: getCoinNetworkDepositAddress error: ${
                    e.message
                }`
                console.warn(msg)
                antdMessage.warning(msg)
            }
        }
    }, [exchangeDriver, coinName, coinNetworks])

    useEffect(() => {
        setIsLoading(true)
        loadCoinNetworks().finally(() => {
            setIsLoading(false)
        })
    }, [exchangeDriver, coinName])

    const callbackAggAddrToTag = useCallback(
        (addr: WhitelistAddressAggregated): string => {
            if (exchangeDriver === null) {
                return ""
            }
            let tag = ""
            if (addr.isEVM) {
                tag = "EVM"
                if (addr.networks.length === 1) {
                    tag += "-" + addr.networks[0].replaceAll(" ", "-").slice(0, 3)
                }
            } else {
                for (let network of addr.networks) {
                    tag += network.replaceAll(" ", "-").slice(0, 3)
                }
            }
            return `${exchangeDriver.name().toUpperCase()}-${tag}`
        },
        [exchangeDriver]
    )

    const memoTextInput = useMemo((): string => {
        if (exchangeDriver === null) {
            return ""
        }
        let text = ""
        let i = 1
        for (let aggAddr of aggregatedAddressesList) {
            if (aggAddr.memo) {
                continue
            }
            let tag = callbackAggAddrToTag(aggAddr)
            text += `${aggAddr.address},${tag}\n`
            i++
        }
        return text
    }, [exchangeDriver, aggregatedAddressesList])

    const tableColumns = useMemo(
        (): ColumnsType<WhitelistAddressAggregated> => [
            {
                title: "Networks",
                dataIndex: "networks",
                render: (text: string[]) => {
                    return (
                        <div
                            style={{
                                maxWidth: "min-content",
                                overflowWrap: "normal"
                            }}
                        >
                            {text.map(network => (
                                <Tag color="default" key={network}>
                                    {network}
                                </Tag>
                            ))}
                        </div>
                    )
                }
            },
            {
                title: "Address",
                dataIndex: "address",
                render: (text: string) => {
                    return <Typography.Text copyable>{text}</Typography.Text>
                },
                sorter: (a: WhitelistAddressAggregated, b: WhitelistAddressAggregated) => {
                    return a.address?.localeCompare(b.address) ?? 0
                },
                // defaultSortOrder: "ascend",
                sortDirections: ["ascend", "descend"]
            },
            {
                title: "Memo",
                dataIndex: "memo",
                // defaultSortOrder: "ascend",
                sorter: (a: WhitelistAddressAggregated, b: WhitelistAddressAggregated) => {
                    return a.memo?.localeCompare(b.memo ?? "") ?? 0
                },
                sortDirections: ["ascend", "descend"]
            },
            {
                title: "Tag",
                render: (_, record) => {
                    return <Typography.Text copyable>{callbackAggAddrToTag(record)}</Typography.Text>
                }
            }
        ],
        [exchangeDriver]
    )

    return (
        <FlexCol>
            <Table
                columns={tableColumns}
                dataSource={aggregatedAddressesList}
                pagination={false}
                scroll={{
                    x: "max-content"
                }}
                footer={() => {
                    return <div>Total addresses: {aggregatedAddressesList.length}</div>
                }}
                loading={isLoading}
            />
            <Input.TextArea
                style={{
                    width: "100%",
                    minHeight: "10rem"
                }}
                value={memoTextInput}
                disabled={isLoading}
            />
            {isLoading ?
                <FlexCol
                    style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <div style={{ minHeight: "1.2rem" }}></div>
                    <Spin size="large" />
                </FlexCol>
            :   <FlexRow style={{ justifyContent: "space-between", alignItems: "end" }}>
                    <FlexCol>
                        Networks ({coinNetworks.length}):{" "}
                        <div>
                            {coinNetworks.map(network => (
                                <Tag color={network.depositOK ? "green" : "red"} key={network.networkName}>
                                    {network.networkName} ({network.ownName})
                                </Tag>
                            ))}
                        </div>
                    </FlexCol>
                    {exchangeDriver?.config().withdrawalWhitelist && (
                        <Button
                            target="_blank"
                            href={(function () {
                                switch (exchangeDriver?.name()) {
                                    case "BITGET":
                                        return "https://www.bitget.com/asset/address"
                                    case "OKX":
                                        return "https://www.okx.com/balance/withdrawal-address/usdt/7"
                                    case "HTX":
                                        return "https://www.htx.com/en-us/finance/address/"
                                    case "GATE":
                                        return "https://www.gate.io/myaccount/withdraw_address"
                                    case "BYBIT":
                                        return "https://www.bybit.com/user/assets/money-address/batch"
                                    default:
                                        return ""
                                }
                            })()}
                        >
                            Goto whitelist <ExportOutlined />
                        </Button>
                    )}
                    <Button
                        type="primary"
                        onClick={() => {
                            setIsLoading(true)
                            loadAddressList().finally(() => {
                                setIsLoading(false)
                            })
                        }}
                    >
                        Load addresses
                    </Button>
                </FlexRow>
            }
        </FlexCol>
    )
}

export const WhitelistManagerModal: FC<{
    isOpen: boolean
    setIsOpen: (v: boolean) => void
}> = ({ isOpen, setIsOpen }) => {
    const [exchangeDrivers, setExchangeDrivers] = useState<IExchangeDriver[]>([])

    const instantiateExchangeDrivers = useCallback(async () => {
        let storedExchangeNames = await fetchStoredExchangeNames()
        if (storedExchangeNames.length === 0) {
            return
        }
        let _exchangeDrivers: IExchangeDriver[] = []
        for (let exchangeName of storedExchangeNames) {
            let apiKeySet = await loadExchangeAPIKeySet(exchangeName)
            if (apiKeySet === null) {
                continue
            }
            let _exchangeDriver = getExchangeDriverByName(exchangeName, apiKeySet)
            if (_exchangeDriver === null) {
                continue
            }
            _exchangeDrivers.push(_exchangeDriver)
        }
        setExchangeDrivers(_exchangeDrivers)
    }, [])

    // exchangeDrivers
    useEffect(() => {
        instantiateExchangeDrivers()
    }, [])

    const memoTabsItems = useMemo(() => {
        return exchangeDrivers.map(ed => {
            return {
                key: ed.name(),
                label: ed.name(),
                children: <WhitelistManagerModalExchangeTab exchangeDriver={ed} />
            }
        })
    }, [exchangeDrivers])

    return (
        <Modal
            title="Manage whitelist"
            width={"80vw"}
            open={isOpen}
            onCancel={() => {
                setIsOpen(false)
            }}
            onOk={() => {
                setIsOpen(false)
            }}
        >
            <Tabs items={memoTabsItems} />
        </Modal>
    )
}