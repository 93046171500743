import { Link } from "react-router-dom"
import { Table, Row, Col, Collapse, Alert, Tabs, Spin, Typography, Tooltip, Popover, Radio } from "antd"
import { ColumnsType } from "antd/es/table"
import { FC, useState, useEffect, ReactNode, useMemo } from "react"
import ReactMarkdown from "react-markdown"
import { FlexCol, FlexRow } from "../common"
import { useAuthContext } from "../reducers/authReducer"
import {
    type CMCFullCoinInfo,
    type CMCMarketPairExchange,
    type WellKnownFeeRatesMap,
    type CoinExchange,
    type CoinExchangeNetwork,
    type Coin,
    type PeriodicOpportunity,
    type Exchange,
    type Network,
    CGTicker,
    CGCoinInfo
} from "../types"
import {
    CheckCircleFilled,
    CloseCircleFilled,
    FileProtectOutlined,
    HomeOutlined,
    InfoCircleTwoTone,
    QuestionCircleFilled,
    SafetyCertificateOutlined
} from "@ant-design/icons"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTelegram, faSquareXTwitter, faFacebookSquare } from "@fortawesome/free-brands-svg-icons"

import {
    ARBITRAGE_COLOR_GREEN,
    ARBITRAGE_COLOR_NEUTRAL,
    ARBITRAGE_COLOR_RED,
    ARBITRAGE_COLOR_WARN,
    colorHexToRGBA
} from "../constants"
import { contractAddressesMatch, formatNumberLog1e3, numberToFixedWithoutTrailingZeros, useMediaQuery } from "../utils"
import { faExternalLink } from "@fortawesome/free-solid-svg-icons"

enum SecurityLevel {
    FORCED = 1,
    LOW,
    MEDIUM,
    HIGH,
    VERY_HIGH
}

enum DYORProvider {
    CoinMarketCap = "CoinMarketCap",
    CoinGecko = "CoinGecko"
}

const getHostname = (url: string) => {
    let _url = new URL(url)
    return _url.hostname
}

const getDyorString = (coinID: number) => {
    return (
        <span>
            Please,{" "}
            <Link to={`/dyor?coin_id=${coinID}`} target="_blank">
                <b>do your own research</b>
            </Link>{" "}
            before proceeding!
        </span>
    )
}

interface IRiskAssessmentPeriodicOpportunity {
    ExchangeFromID: number
    ExchangeToID: number
    CoinID: number
    NetworkID: number
    ContractAddressFrom: string
    ContractAddressTo: string
}

export const RiskAssessmentWidget: FC<{
    periodicOpportunity: IRiskAssessmentPeriodicOpportunity | null
    exchangesMap: Record<Exchange["ID"], Exchange>
    coinsMap: Record<Coin["ID"], Coin>
    networksMap: Record<Network["ID"], Network>
    fontSize: string
    forceCoinNetworkPairMatch?: string[]
}> = ({ periodicOpportunity, exchangesMap, coinsMap, networksMap, fontSize, forceCoinNetworkPairMatch }) => {
    const [securityLevel, setSecurityLevel] = useState<SecurityLevel | null>(null)
    const [securityReason, setSecurityReason] = useState<ReactNode | null>(null)

    useEffect(() => {
        if (periodicOpportunity === null) {
            return
        }
        let exchangeFrom = exchangesMap[periodicOpportunity.ExchangeFromID]
        let exchangeTo = exchangesMap[periodicOpportunity.ExchangeToID]
        let coinName = coinsMap[periodicOpportunity.CoinID].Name
        let networkName = networksMap[periodicOpportunity.NetworkID].Name
        let coinNetworkPair = `${coinName}@${networkName}`
        if (forceCoinNetworkPairMatch !== undefined && forceCoinNetworkPairMatch.includes(coinNetworkPair)) {
            setSecurityLevel(SecurityLevel.FORCED)
            setSecurityReason(
                <>
                    <b>{coinName}</b> on <b>{networkName}</b> is <b>forced</b>!
                </>
            )
            return
        }

        if (periodicOpportunity.ContractAddressFrom === "" || periodicOpportunity.ContractAddressTo === "") {
            setSecurityLevel(SecurityLevel.MEDIUM)
            let coin = coinsMap[periodicOpportunity.CoinID]
            let network = networksMap[periodicOpportunity.NetworkID]
            if (coin === undefined || network === undefined) {
                console.warn("RiskAssessmentWidget: coin or network undefined")
                return
            }
            if (network.Name === coin.Name) {
                setSecurityLevel(SecurityLevel.VERY_HIGH)
                setSecurityReason(
                    <>
                        <b>{coin.Name}</b> is a coin on its <b>native</b> network!
                    </>
                )
                return
            }
            if (periodicOpportunity.ContractAddressFrom !== "" || periodicOpportunity.ContractAddressTo !== "") {
                setSecurityReason(
                    <>
                        One of the contract addresses is <b>unknown</b>!
                        <br />
                        {getDyorString(periodicOpportunity.CoinID)}
                        <br />
                        <ContractAddressesDiv
                            exchangeFrom={exchangeFrom}
                            exchangeTo={exchangeTo}
                            contractAddressFrom={periodicOpportunity.ContractAddressFrom}
                            contractAddressTo={periodicOpportunity.ContractAddressTo}
                        />
                    </>
                )
                return
            } else {
                setSecurityReason(
                    <>
                        Contract addresses are both <b>unknown</b>!
                        <br />
                        {getDyorString(periodicOpportunity.CoinID)}
                    </>
                )
                return
            }
        }
        if (
            contractAddressesMatch(
                coinsMap[periodicOpportunity.CoinID].Name,
                periodicOpportunity.ContractAddressFrom,
                periodicOpportunity.ContractAddressTo
            )
        ) {
            setSecurityLevel(SecurityLevel.VERY_HIGH)
            setSecurityReason(
                <FlexCol>
                    <span>
                        Contract addresses <b>match</b>!
                    </span>
                    <ContractAddressesDiv
                        exchangeFrom={exchangeFrom}
                        exchangeTo={exchangeTo}
                        contractAddressFrom={periodicOpportunity.ContractAddressFrom}
                        contractAddressTo={periodicOpportunity.ContractAddressTo}
                    />
                </FlexCol>
            )
            return
        } else {
            setSecurityLevel(SecurityLevel.LOW)
            setSecurityReason(
                <>
                    Contract addresses don't match!
                    <br />
                    {getDyorString(periodicOpportunity.CoinID)}
                    <br />
                    <ContractAddressesDiv
                        exchangeFrom={exchangeFrom}
                        exchangeTo={exchangeTo}
                        contractAddressFrom={periodicOpportunity.ContractAddressFrom}
                        contractAddressTo={periodicOpportunity.ContractAddressTo}
                    />
                </>
            )
            return
        }
    }, [periodicOpportunity])

    return (
        <Popover
            content={securityReason}
            overlayStyle={{
                maxWidth: "80vw"
            }}
            placement="bottomRight"
        >
            <SafetyCertificateOutlined
                style={{
                    fontSize,
                    color: (function () {
                        switch (securityLevel) {
                            case SecurityLevel.FORCED:
                                return "violet"
                            case SecurityLevel.LOW:
                                return ARBITRAGE_COLOR_RED
                            case SecurityLevel.MEDIUM:
                                return ARBITRAGE_COLOR_WARN
                            case SecurityLevel.VERY_HIGH:
                                return colorHexToRGBA(ARBITRAGE_COLOR_GREEN, 0.8)
                            default:
                                return "#ccc"
                        }
                    })()
                }}
            />
        </Popover>
    )
}

export const WellKnownFeeRatesWidget: FC<{
    periodicOpportunity: PeriodicOpportunity | null
    coinsMap: Record<Coin["ID"], Coin>
    networksMap: Record<Network["ID"], Network>
    wellKnownFeeRatesMap: WellKnownFeeRatesMap | null
    fontSize: string
}> = ({ periodicOpportunity, coinsMap, networksMap, wellKnownFeeRatesMap, fontSize }) => {
    const [securityLevel, setSecurityLevel] = useState<SecurityLevel | null>(null)
    const [securityReason, setSecurityReason] = useState<ReactNode | null>(null)

    useEffect(() => {
        if (periodicOpportunity === null || wellKnownFeeRatesMap === null) {
            return
        }
        let coin = coinsMap[periodicOpportunity.CoinID]
        if (coin === undefined) {
            return
        }
        let network = networksMap[periodicOpportunity.NetworkID]
        if (network === undefined) {
            return
        }

        if (coin.Name === network.Name) {
            setSecurityLevel(SecurityLevel.VERY_HIGH)
            setSecurityReason(
                <>
                    <b>{coin.Name}</b> is a coin on its <b>native</b> network!
                </>
            )
            return
        }

        let wellKnownFee = wellKnownFeeRatesMap[coin.Name]
        if (wellKnownFee === undefined) {
            let smAddress = ""
            if (periodicOpportunity.ContractAddressFrom !== "") {
                smAddress = periodicOpportunity.ContractAddressFrom
            }
            if (smAddress === "" && periodicOpportunity.ContractAddressTo !== "") {
                smAddress = periodicOpportunity.ContractAddressTo
            }
            let smSourceCodeHref = ""
            if (smAddress !== "") {
                switch (network.Name) {
                    case "ETH":
                        smSourceCodeHref = `https://etherscan.io/token/${smAddress}#readContract`
                        break
                    case "BSC":
                        smSourceCodeHref = `https://bscscan.com/token/${smAddress}#readContract`
                        break
                    case "ARB-ONE":
                        smSourceCodeHref = `https://arbiscan.io/token/${smAddress}#readContract`
                        break
                }
            }
            // console.log(network.Name, smAddress, smSourceCodeHref)
            setSecurityLevel(SecurityLevel.MEDIUM)
            setSecurityReason(
                <>
                    <b>{coin.Name}</b> was not audited for hidden fees yet!
                    <br />
                    {getDyorString(periodicOpportunity.CoinID)}
                    <br />
                    {smSourceCodeHref !== "" && (
                        <span>
                            Note: you can check the contract's{" "}
                            <a href={smSourceCodeHref} target="_blank">
                                source code
                            </a>{" "}
                            for fees to make sure!
                        </span>
                    )}
                </>
            )
        } else {
            let wellKnownFeeRate = Math.max(wellKnownFee.DepositFeeRate, wellKnownFee.WithdrawFeeRate)
            if (wellKnownFeeRate === 0) {
                setSecurityLevel(SecurityLevel.VERY_HIGH)
                setSecurityReason(
                    <>
                        <b>{coin.Name}</b> does not implement <b>any</b> hidden fees.
                    </>
                )
            } else {
                setSecurityLevel(SecurityLevel.LOW)
                setSecurityReason(
                    <>
                        <b>{coin.Name}</b> implements up to <b>{100 * wellKnownFeeRate}%</b> of "hidden" fees.
                        <br />
                        <span>
                            <b>Proceed with caution!</b>
                        </span>
                    </>
                )
            }
        }
    }, [periodicOpportunity, wellKnownFeeRatesMap])

    return (
        <Popover
            content={securityReason}
            overlayStyle={{
                maxWidth: "80vw"
            }}
            placement="bottomRight"
        >
            <FileProtectOutlined
                style={{
                    fontSize,
                    color: (function () {
                        switch (securityLevel) {
                            case SecurityLevel.LOW:
                                return ARBITRAGE_COLOR_NEUTRAL
                            case SecurityLevel.MEDIUM:
                                return ARBITRAGE_COLOR_WARN
                            case SecurityLevel.VERY_HIGH:
                                return colorHexToRGBA(ARBITRAGE_COLOR_GREEN, 0.8)
                            default:
                                return "#ccc"
                        }
                    })()
                }}
            />
        </Popover>
    )
}

const getCMCCoinLogoHref = (cmcCoinID: number) => {
    return `https://s2.coinmarketcap.com/static/img/coins/64x64/${cmcCoinID}.png`
}

const getCMCExchangeLogoHref = (cmcExchangeID: number) => {
    return `https://s2.coinmarketcap.com/static/img/exchanges/64x64/${cmcExchangeID}.png`
}

const getCGExchangeLogoHref = (cgExchangeSlug: string) => {
    let _id = {
        binance: 52,
        bitget: 540,
        bybit_spot: 698,
        bitmart: 239,
        mxc: 409,
        okex: 96,
        coinex: 135,
        poloniex: 37,
        digifinex: 225,
        kucoin: 61,
        xt: 404,
        huobi: 25,
        gate: 60,
        probit: 370
    }[cgExchangeSlug]
    let _name = {
        huobi: "logo_V_colour_black.png",
        bitget: "2023-07-25_21.47.43.jpg",
        gate: "gate_io_logo1.jpg",
        mxc: "MEXC_logo_square.jpeg",
        okex: "WeChat_Image_20220117220452.png",
        binance: "binance.jpg",
        coinex: "coinex.jpg",
        xt: "logo400x400.png",
        digifinex: "DF_logo.png"
    }[cgExchangeSlug]
    if (_name === undefined) {
        _name = cgExchangeSlug + ".png"
    }
    return `https://assets.coingecko.com/markets/images/${_id}/small/${_name}`
}

const ContractAddressesDiv: FC<{
    exchangeFrom?: Exchange
    exchangeTo?: Exchange
    contractAddressFrom: string
    contractAddressTo: string
}> = ({ exchangeFrom, exchangeTo, contractAddressFrom, contractAddressTo }) => {
    return (
        <div>
            <span>{exchangeFrom?.Name}: </span>
            <Typography.Text code copyable={contractAddressFrom !== ""}>
                {contractAddressFrom !== "" ? contractAddressFrom : "N/A"}
            </Typography.Text>
            <br />
            <span>{exchangeTo?.Name}: </span>
            <Typography.Text code copyable={contractAddressFrom !== ""}>
                {contractAddressTo !== "" ? contractAddressTo : "N/A"}
            </Typography.Text>
        </div>
    )
}

const BitgetContractAddressLink: FC<{
    coinCommonCode: string
    networkCommonCode: string
    networkCommonContractAddress: string
}> = ({ coinCommonCode, networkCommonCode, networkCommonContractAddress }) => {
    const [coinID, setCoinID] = useState<number | null>(null)
    const [coinChainID, setCoinChainID] = useState<number | null>(null)

    const { callSecureAPI } = useAuthContext()
    useEffect(() => {
        callSecureAPI<{
            CoinCommonCode: string
            NetworkCommonCode: string
            CoinID: number
            CoinChainID: number
        }>(`/bitget/coin_chain_id?coin_common_code=${coinCommonCode}&network_common_code=${networkCommonCode}`)
            .then(({ responseObject: resp }) => {
                if (resp === null) {
                    console.log("BitgetContractAddressLink: fetching coin chain id returned null")
                    return
                }
                setCoinChainID(resp.CoinChainID)
                setCoinID(resp.CoinID)
            })
            .catch((e: any) => {
                console.warn("BitgetContractAddressLink: error fetching coin chain id:", e)
            })
    }, [coinCommonCode, networkCommonCode])

    if (coinChainID === null || coinChainID === 0) {
        return null
    }

    return (
        <Tooltip
            overlay={
                <>
                    Click to check on Bitget: should be <b>****{networkCommonContractAddress.slice(-5)}</b>
                </>
            }
        >
            <a
                target="_blank"
                href={`https://www.bitget.com/asset/recharge?coinId=${coinID}&chainCoinId=${coinChainID}`}
                style={{
                    fontSize: "1.1rem",
                    marginTop: "-4px"
                }}
            >
                👀
            </a>
        </Tooltip>
    )
}

// CoinMarketCap
const CMCCoinNetworkExchangesTable: FC<{
    cmcExchanges: CMCMarketPairExchange[] | null
    coinExchanges: CoinExchange[] | null
    coinCommonCode: string
    networkCommonCode: string
    networkCommonContractAddress: string
}> = ({ cmcExchanges, coinExchanges, coinCommonCode, networkCommonCode, networkCommonContractAddress }) => {
    type RowType = {
        ExchangeName: string
        CoinExchange: CoinExchange | undefined
        OwnNetworks: CoinExchangeNetwork[]
        Symbol: string
        Price: number
        Volume: number
        PriceAsterisk: boolean
        VolumeAsterisk: boolean
        ExchangeID: number
        ExchangeSlug: string
        MarketURL: string
    }

    const [rows, setRows] = useState<RowType[]>([])

    useEffect(() => {
        if (cmcExchanges === null || coinExchanges === null) {
            return
        }
        let _rows: RowType[] = []
        for (let cmcExchange of cmcExchanges) {
            let ownCoinExchange = coinExchanges.find(ce => ce.ExchangeName === cmcExchange.ExchangeCommonName)
            var networks: CoinExchangeNetwork[] = []

            if (ownCoinExchange && ownCoinExchange.Networks !== null) {
                for (let network of ownCoinExchange.Networks) {
                    if (networkCommonCode === "") {
                        networks.push(network)
                    } else if (network.Name === networkCommonCode) {
                        networks.push(network)
                    }
                }
            }

            _rows.push({
                ExchangeName: cmcExchange.ExchangeCommonName,
                CoinExchange: ownCoinExchange,
                OwnNetworks: networks,

                Price: cmcExchange.Price,
                Volume: cmcExchange.VolumeUsd,
                PriceAsterisk: cmcExchange.PriceExcluded === 1,
                VolumeAsterisk: cmcExchange.VolumeExcluded === 1,
                Symbol: cmcExchange.MarketPair,
                ExchangeID: cmcExchange.exchangeId,
                ExchangeSlug: cmcExchange.ExchangeSlug,
                MarketURL: cmcExchange.marketUrl
            })
        }
        _rows.sort((a, b) => {
            if (a.OwnNetworks.length === 0 && b.OwnNetworks.length === 0) {
                return a.ExchangeName.localeCompare(b.ExchangeName)
            } else if (a.OwnNetworks.length === 0) {
                return 1
            } else if (b.OwnNetworks.length === 0) {
                return -1
            } else {
                let aCa = a.OwnNetworks[0].ContractAddress
                let bCa = b.OwnNetworks[0].ContractAddress
                if (aCa === "" && bCa === "") {
                    return a.ExchangeName.localeCompare(b.ExchangeName)
                } else if (aCa === "") {
                    return 1
                } else if (bCa === "") {
                    return -1
                } else {
                    if (
                        isSameAddress(aCa, networkCommonContractAddress) &&
                        !isSameAddress(bCa, networkCommonContractAddress)
                    ) {
                        return -1
                    } else if (
                        !isSameAddress(aCa, networkCommonContractAddress) &&
                        isSameAddress(bCa, networkCommonContractAddress)
                    ) {
                        return 1
                    } else {
                        return a.ExchangeName.localeCompare(b.ExchangeName)
                    }
                }
            }
        })
        setRows(_rows)
    }, [cmcExchanges, coinExchanges, networkCommonCode])

    const isSameAddress = (a: string, b: string): boolean => {
        let aLower = a.toLowerCase()
        let bLower = b.toLowerCase()
        if (aLower.length < bLower.length) {
            return bLower.includes(aLower)
        } else if (aLower.length > bLower.length) {
            return aLower.includes(bLower)
        } else {
            return aLower === bLower
        }
    }

    const columns: ColumnsType<RowType> = [
        {
            title: "Exchange",
            dataIndex: "ExchangeName",
            ellipsis: true,
            render: (_, r: RowType) => {
                return (
                    <FlexRow>
                        <a href={`https://coinmarketcap.com/exchanges/${r.ExchangeSlug}/`} target="_blank">
                            <FlexRow
                                style={{
                                    alignItems: "center",
                                    gap: 2
                                }}
                            >
                                <img src={getCMCExchangeLogoHref(r.ExchangeID)} width={24} height={24} />
                                <span>{r.ExchangeName}</span>
                            </FlexRow>
                        </a>
                        {r.CoinExchange === undefined && <i> — indexed on CoinMarketCap but delisted from exchange</i>}
                    </FlexRow>
                )
            },
            onCell: (r: RowType) => {
                return {
                    colSpan: r.CoinExchange === undefined ? 7 : 1
                }
            },
            fixed: "left"
        },
        {
            title: (
                <Tooltip overlay="Coin anem as it appears on the exchange">
                    <span style={{ whiteSpace: "nowrap" }}>
                        <InfoCircleTwoTone /> Coin
                    </span>
                </Tooltip>
            ),
            render: (_, r: RowType) => {
                return <span style={{ whiteSpace: "nowrap" }}>{r.CoinExchange?.OwnCoinName}</span>
            },
            onCell: (r: RowType) => {
                return {
                    colSpan: r.CoinExchange === undefined ? 0 : 1
                }
            }
        },
        {
            title: (
                <Tooltip overlay="Network name as it appears on the exchange">
                    <span style={{ whiteSpace: "nowrap" }}>
                        <InfoCircleTwoTone /> Net
                    </span>
                </Tooltip>
            ),
            render: (r: RowType) => {
                if (r.OwnNetworks.length === 0) {
                    return <i>network not supported on exchange</i>
                }
                return (
                    <FlexCol style={{ gap: 0 }}>
                        {r.OwnNetworks.map(net => {
                            return <span style={{ whiteSpace: "nowrap" }}>{net.OwnNetworkName || "—"}</span>
                        })}
                    </FlexCol>
                )
            },
            onCell: (r: RowType) => {
                let colSpan = 1
                if (r.CoinExchange === undefined) {
                    colSpan = 0
                } else if (r.OwnNetworks.length === 0) {
                    colSpan = 2
                }
                return {
                    colSpan: colSpan
                }
            }
        },
        {
            title: "Contract Address",
            ellipsis: true,
            render: (_, r: RowType) => {
                return (
                    <FlexCol
                        style={{
                            gap: 0
                        }}
                    >
                        {r.OwnNetworks.map(net => {
                            let contractAddressHealthNode: ReactNode | null = null
                            if (networkCommonContractAddress !== "") {
                                if (net.ContractAddress === "") {
                                    contractAddressHealthNode = (
                                        <FlexRow
                                            style={{
                                                gap: 2,
                                                alignItems: "center"
                                            }}
                                        >
                                            <QuestionCircleFilled style={{ color: ARBITRAGE_COLOR_WARN }} />
                                            {/* {r.CoinExchange?.ExchangeName === "BITGET" && (
                                                <BitgetContractAddressLink
                                                    coinCommonCode={coinCommonCode}
                                                    networkCommonCode={networkCommonCode}
                                                    networkCommonContractAddress={networkCommonContractAddress}
                                                />
                                            )} */}
                                        </FlexRow>
                                    )
                                } else if (isSameAddress(net.ContractAddress, networkCommonContractAddress)) {
                                    contractAddressHealthNode = (
                                        <CheckCircleFilled style={{ color: ARBITRAGE_COLOR_GREEN }} />
                                    )
                                } else {
                                    contractAddressHealthNode = <CloseCircleFilled />
                                }
                            }
                            return (
                                <FlexRow>
                                    {contractAddressHealthNode}
                                    <Typography.Text
                                        style={{
                                            maxWidth: "12rem"
                                        }}
                                        ellipsis={{ tooltip: net.ContractAddress }}
                                    >
                                        {net.ContractAddress || "—"}
                                    </Typography.Text>
                                </FlexRow>
                            )
                        })}
                    </FlexCol>
                )
            },
            onCell: (r: RowType) => {
                let colSpan = 1
                if (r.CoinExchange === undefined) {
                    colSpan = 0
                } else if (r.OwnNetworks.length === 0) {
                    colSpan = 0
                }
                return {
                    colSpan: colSpan
                }
            }
        },
        {
            title: "Price",
            dataIndex: "Price",
            render: (value: number, r: RowType) => {
                return (
                    <>
                        {r.PriceAsterisk && "*"} ${numberToFixedWithoutTrailingZeros(value, 8)}
                    </>
                )
            },
            onCell: (r: RowType) => {
                return {
                    colSpan: r.CoinExchange === undefined ? 0 : 1
                }
            }
        },
        {
            title: "Volume",
            dataIndex: "Volume",
            render: (value: number, r: RowType) => {
                return (
                    <>
                        {r.VolumeAsterisk && "*"} ${formatNumberLog1e3(value.toString(), 3)}
                    </>
                )
            },
            onCell: (r: RowType) => {
                return {
                    colSpan: r.CoinExchange === undefined ? 0 : 1
                }
            }
        },
        {
            title: "Symbol",
            dataIndex: "Symbol",
            render: (value: string, r: RowType) => {
                return (
                    <a target="_blank" href={r.MarketURL}>
                        {value}
                    </a>
                )
            },
            onCell: (r: RowType) => {
                return {
                    colSpan: r.CoinExchange === undefined ? 0 : 1
                }
            }
        }
    ]

    if (rows.length === 0) {
        return null
    }

    return (
        <Table
            columns={columns}
            dataSource={rows}
            size="small"
            pagination={false}
            scroll={{
                x: true
            }}
            style={{
                width: "100%"
            }}
        />
    )
}

const CMCInfoPanel: FC<{
    cmcFullCoinInfo: CMCFullCoinInfo
    coinExchanges: CoinExchange[]
    coinCommonCode: string
}> = ({ cmcFullCoinInfo, coinExchanges, coinCommonCode }) => {
    const isMobile = useMediaQuery()

    if (cmcFullCoinInfo.CoinInfo.Platforms === null) {
        cmcFullCoinInfo.CoinInfo.Platforms = []
    }

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <Row justify="space-between" align={"middle"}>
                    <Col>
                        <a
                            target="_blank"
                            href={`https://coinmarketcap.com/currencies/${cmcFullCoinInfo.CoinInfo.Slug}/`}
                        >
                            <FlexRow
                                style={{
                                    alignItems: "center"
                                }}
                            >
                                <img src={getCMCCoinLogoHref(cmcFullCoinInfo.CoinInfo.ID)} width={24} height={24} />
                                <span
                                    style={{
                                        fontSize: "1.2rem"
                                    }}
                                >
                                    <b>{cmcFullCoinInfo.CoinInfo.Name}</b>
                                </span>
                                <span
                                    style={{
                                        fontSize: "1.2rem",
                                        color: "#666"
                                    }}
                                >
                                    {cmcFullCoinInfo.CoinInfo.Symbol}
                                </span>
                                <span
                                    style={{
                                        fontSize: "1rem",
                                        color: "#666"
                                    }}
                                >
                                    #{cmcFullCoinInfo.CoinInfo.Statistics.Rank}
                                </span>
                            </FlexRow>
                        </a>
                    </Col>
                    <Col>
                        <FlexRow>
                            <span>
                                <b>Status: </b>
                                {cmcFullCoinInfo.CoinInfo.Status === "active" ?
                                    <CheckCircleFilled style={{ color: ARBITRAGE_COLOR_GREEN }} />
                                :   <CloseCircleFilled />}
                            </span>
                            <span>
                                <b>Category: </b>
                                {cmcFullCoinInfo.CoinInfo.Category.toUpperCase()}
                            </span>
                        </FlexRow>
                    </Col>
                </Row>
            </Col>
            <Col xs={24}>
                <Collapse>
                    <Collapse.Panel header="Description" key="1">
                        <ReactMarkdown>{cmcFullCoinInfo.CoinInfo.Description}</ReactMarkdown>
                    </Collapse.Panel>
                </Collapse>
            </Col>
            <Col xs={24}>
                {cmcFullCoinInfo.CoinInfo.Notice !== "" && (
                    <Alert
                        message={<ReactMarkdown>{cmcFullCoinInfo.CoinInfo.Notice}</ReactMarkdown>}
                        type="warning"
                        showIcon
                    />
                )}
            </Col>
            {cmcFullCoinInfo.MarketPairs !== null && cmcFullCoinInfo.MarketPairs.length > 0 ?
                <Col
                    xs={24}
                    style={{
                        minHeight: "30vh"
                    }}
                >
                    <Tabs
                        tabPosition={isMobile ? "top" : "left"}
                        moreIcon={null}
                        items={[
                            ...cmcFullCoinInfo.CoinInfo.Platforms,
                            {
                                ContractPlatform: "All",
                                ContractAddress: "",
                                PlatformCommonCode: ""
                            }
                        ].map(platform => {
                            let primaryNetworkName = platform.PlatformCommonCode
                            if (primaryNetworkName === "") {
                                primaryNetworkName = "ALL"
                            }
                            return {
                                label: (
                                    <FlexCol
                                        style={{
                                            gap: 0,
                                            alignItems: "start"
                                        }}
                                    >
                                        <span>{primaryNetworkName}</span>
                                        <span
                                            style={{
                                                fontSize: "0.7rem",
                                                color: "#666"
                                            }}
                                        >
                                            {platform.ContractPlatform}
                                        </span>
                                    </FlexCol>
                                ),
                                key: platform.ContractPlatform,
                                children: (
                                    <FlexCol
                                        style={{
                                            gap: 5,
                                            width: "100%",
                                            height: "100%"
                                        }}
                                    >
                                        {platform.ContractAddress !== "" ?
                                            <span>
                                                <b>Contract Address:</b>{" "}
                                                <Typography.Text code copyable>
                                                    {platform.ContractAddress}
                                                </Typography.Text>
                                            </span>
                                        :   <span>
                                                Table of <b>"all"</b> networks contains all the networks present for an
                                                asset for a given exchange. There might be networks not listed on{" "}
                                                <b>CoinMarketCap</b>
                                            </span>
                                        }
                                        {platform.PlatformCommonCode === "XRP" && (
                                            <span>
                                                <InfoCircleTwoTone /> <b>Note:</b> XRP contract addresses are composed
                                                of 2 parts: <b>TAG</b>_<b>ADDRESS</b>. Different exchanges might use one
                                                or another, or both. Please double check!
                                            </span>
                                        )}
                                        {platform.PlatformCommonCode === "ADA" && (
                                            <span>
                                                <InfoCircleTwoTone /> <b>Note:</b> Cardano contract addresses are
                                                composed of 2 parts: <b>ASSET_ID</b>_<b>POLICY_ID</b>. Different
                                                exchanges might use one or another, or both. Please double check!
                                            </span>
                                        )}
                                        <CMCCoinNetworkExchangesTable
                                            cmcExchanges={cmcFullCoinInfo.MarketPairs}
                                            coinExchanges={coinExchanges}
                                            coinCommonCode={coinCommonCode}
                                            networkCommonCode={platform.PlatformCommonCode}
                                            networkCommonContractAddress={platform.ContractAddress}
                                        />
                                    </FlexCol>
                                )
                            }
                        })}
                    />
                </Col>
            :   <Col xs={24}>
                    <Alert
                        message={
                            <>
                                This asset is not trading on any of the relevant exchanges
                                <br />
                                You think this might be mistaken? Please, don't hesitate to{" "}
                                <a href="mailto:support.arbitrage@ngineera.com">contact us!</a>
                            </>
                        }
                        type="info"
                        showIcon
                    />
                </Col>
            }
        </Row>
    )
}

const CGInfoPanel: FC<{
    cgCoinInfo: CGCoinInfo
    coinExchanges: CoinExchange[]
    coinCommonCode: string
}> = ({ cgCoinInfo, coinExchanges, coinCommonCode }) => {
    const isMobile = useMediaQuery()

    if (cgCoinInfo.platforms === null) {
        cgCoinInfo.platforms = {}
    }

    const memoNetworkTabsItems = useMemo(() => {
        if (cgCoinInfo.detail_platforms === null) {
            return []
        }
        let _platforms: {
            ownName: string
            commonName: string
            contractAddress: string
        }[] = []
        for (let [platformKey, platformValue] of Object.entries(cgCoinInfo.detail_platforms)) {
            _platforms.push({
                ownName: platformKey,
                commonName: platformValue.PlatformCommonCode,
                contractAddress: platformValue.contract_address
            })
        }
        return _platforms.map(platform => {
            let primaryNetworkName = platform.commonName
            if (primaryNetworkName === "") {
                primaryNetworkName = "ALL"
            }
            return {
                label: (
                    <FlexCol
                        style={{
                            gap: 0,
                            alignItems: "start"
                        }}
                    >
                        <span>{primaryNetworkName}</span>
                        <span
                            style={{
                                fontSize: "0.7rem",
                                color: "#666"
                            }}
                        >
                            {platform.ownName.toUpperCase()}
                        </span>
                    </FlexCol>
                ),
                key: platform.contractAddress,
                children: (
                    <FlexCol
                        style={{
                            gap: 5,
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        {platform.contractAddress !== "" ?
                            <span>
                                <b>Contract Address:</b>{" "}
                                <Typography.Text code copyable>
                                    {platform.contractAddress}
                                </Typography.Text>
                            </span>
                        :   <span>
                                Table of <b>"all"</b> networks contains all the networks present for an asset for a
                                given exchange. There might be networks not listed on <b>CoinMarketCap</b>
                            </span>
                        }
                        {platform.commonName === "XRP" && (
                            <span>
                                <InfoCircleTwoTone /> <b>Note:</b> XRP contract addresses are composed of 2 parts:{" "}
                                <b>TAG</b>_<b>ADDRESS</b>. Different exchanges might use one or another, or both. Please
                                double check!
                            </span>
                        )}
                        {platform.commonName === "ADA" && (
                            <span>
                                <InfoCircleTwoTone /> <b>Note:</b> Cardano contract addresses are composed of 2 parts:{" "}
                                <b>ASSET_ID</b>_<b>POLICY_ID</b>. Different exchanges might use one or another, or both.
                                Please double check!
                            </span>
                        )}
                        <CGCoinNetworkExchangesTable
                            cgExchanges={cgCoinInfo.Tickers}
                            coinExchanges={coinExchanges}
                            coinCommonCode={coinCommonCode}
                            networkCommonCode={platform.commonName}
                            networkCommonContractAddress={platform.contractAddress}
                        />
                    </FlexCol>
                )
            }
        })
    }, [cgCoinInfo])

    return (
        <Row gutter={[10, 10]} style={{ width: "100%" }}>
            <Col xs={24}>
                <Row justify="space-between" align={"top"}>
                    <Col>
                        <a target="_blank" href={`https://coingecko.com/coins/${cgCoinInfo.ID}/`}>
                            <FlexRow
                                style={{
                                    alignItems: "center"
                                }}
                            >
                                <img src={cgCoinInfo.Image.Small} width={24} height={24} />
                                <span
                                    style={{
                                        fontSize: "1.2rem"
                                    }}
                                >
                                    <b>{cgCoinInfo.Name}</b>
                                </span>
                                <span
                                    style={{
                                        fontSize: "1.2rem",
                                        color: "#666"
                                    }}
                                >
                                    {cgCoinInfo.Symbol.toUpperCase()}
                                </span>
                                <span
                                    style={{
                                        fontSize: "1rem",
                                        color: "#666"
                                    }}
                                >
                                    #{cgCoinInfo.market_cap_rank}
                                </span>
                            </FlexRow>
                        </a>
                    </Col>
                    {cgCoinInfo.Links && (
                        <Col>
                            <FlexRow>
                                <FlexRow style={{ gap: 3 }}>
                                    {/* Home */}
                                    {cgCoinInfo.Links.homepage !== null && (
                                        <>
                                            {cgCoinInfo.Links.homepage.map(uri => {
                                                if (!uri) {
                                                    return null
                                                }
                                                return (
                                                    <a target="_blank" href={uri}>
                                                        <Tooltip overlay={uri}>
                                                            <HomeOutlined />
                                                        </Tooltip>
                                                    </a>
                                                )
                                            })}
                                        </>
                                    )}
                                    {/* Telegram */}
                                    {cgCoinInfo.Links.telegram_channel_identifier !== null &&
                                        cgCoinInfo.Links.telegram_channel_identifier !== "" && (
                                            <Tooltip
                                                overlay={`https://t.me/${cgCoinInfo.Links.telegram_channel_identifier}`}
                                            >
                                                <a
                                                    target="_blank"
                                                    href={`https://t.me/${cgCoinInfo.Links.telegram_channel_identifier}`}
                                                >
                                                    <FontAwesomeIcon icon={faTelegram} />
                                                </a>
                                            </Tooltip>
                                        )}
                                    {/* Twitter (X) */}
                                    {cgCoinInfo.Links.twitter_screen_name !== null &&
                                        cgCoinInfo.Links.twitter_screen_name !== "" && (
                                            <Tooltip overlay={`https://x.com/${cgCoinInfo.Links.twitter_screen_name}`}>
                                                <a
                                                    target="_blank"
                                                    href={`https://x.com/${cgCoinInfo.Links.twitter_screen_name}`}
                                                >
                                                    <FontAwesomeIcon icon={faSquareXTwitter} />
                                                </a>
                                            </Tooltip>
                                        )}
                                    {/* Facebook */}
                                    {cgCoinInfo.Links.facebook_username !== null &&
                                        cgCoinInfo.Links.facebook_username !== "" && (
                                            <Tooltip
                                                overlay={`https://facebook.com/${cgCoinInfo.Links.facebook_username}`}
                                            >
                                                <a
                                                    target="_blank"
                                                    href={`https://facebook.com/${cgCoinInfo.Links.facebook_username}`}
                                                >
                                                    <FontAwesomeIcon icon={faFacebookSquare} />
                                                </a>
                                            </Tooltip>
                                        )}
                                </FlexRow>
                                {/* Blockchain scanners */}
                                {cgCoinInfo.Links.blockchain_site !== null && (
                                    <FlexCol style={{ gap: 0 }}>
                                        {cgCoinInfo.Links.blockchain_site.map(uri => {
                                            if (!uri) {
                                                return null
                                            }
                                            return (
                                                <a target="_blank" href={uri}>
                                                    {getHostname(uri)}{" "}
                                                    <FontAwesomeIcon
                                                        style={{ fontSize: "0.8rem" }}
                                                        icon={faExternalLink}
                                                    />
                                                </a>
                                            )
                                        })}
                                    </FlexCol>
                                )}
                            </FlexRow>
                        </Col>
                    )}
                </Row>
            </Col>
            <Col xs={24}>
                {cgCoinInfo.Description && cgCoinInfo.Description.en !== "" && (
                    <Collapse>
                        <Collapse.Panel header="Description" key="1">
                            <div dangerouslySetInnerHTML={{ __html: cgCoinInfo.Description.en }} />
                        </Collapse.Panel>
                    </Collapse>
                )}
            </Col>
            <Col xs={24}>
                {cgCoinInfo.public_notice !== "" && (
                    <Alert
                        message={<div dangerouslySetInnerHTML={{ __html: cgCoinInfo.public_notice }}></div>}
                        type="warning"
                        showIcon
                    />
                )}
            </Col>
            {cgCoinInfo.Tickers !== null && cgCoinInfo.Tickers.length > 0 && cgCoinInfo.detail_platforms !== null ?
                <Col
                    xs={24}
                    style={{
                        minHeight: "30vh"
                    }}
                >
                    <Tabs tabPosition={isMobile ? "top" : "left"} moreIcon={null} items={memoNetworkTabsItems} />
                </Col>
            :   <Col xs={24}>
                    <Alert
                        message={
                            <>
                                This asset is not trading on any of the relevant exchanges
                                <br />
                                You think this might be mistaken? Please, don't hesitate to{" "}
                                <a href="mailto:support.arbitrage@ngineera.com">contact us!</a>
                            </>
                        }
                        type="info"
                        showIcon
                    />
                </Col>
            }
        </Row>
    )
}

// CoinGecko
const CGCoinNetworkExchangesTable: FC<{
    cgExchanges: CGTicker[] | null
    coinExchanges: CoinExchange[] | null
    coinCommonCode: string
    networkCommonCode: string
    networkCommonContractAddress: string
}> = ({ cgExchanges, coinExchanges, coinCommonCode, networkCommonCode, networkCommonContractAddress }) => {
    type RowType = {
        ExchangeName: string
        CoinExchange: CoinExchange | undefined
        OwnNetworks: CoinExchangeNetwork[]
        Symbol: string
        Price: number
        Volume: number
        PriceAsterisk: boolean
        VolumeAsterisk: boolean
        ExchangeID: string
        ExchangeSlug: string
        MarketURL: string
    }

    const [rows, setRows] = useState<RowType[]>([])

    useEffect(() => {
        if (cgExchanges === null || coinExchanges === null) {
            return
        }
        let _rows: RowType[] = []
        for (let cgExchange of cgExchanges) {
            let ownCoinExchange = coinExchanges.find(ce => ce.ExchangeName === cgExchange.Market.ExchangeCommonName)
            var networks: CoinExchangeNetwork[] = []

            if (ownCoinExchange && ownCoinExchange.Networks !== null) {
                for (let network of ownCoinExchange.Networks) {
                    if (networkCommonCode === "") {
                        networks.push(network)
                    } else if (network.Name === networkCommonCode) {
                        networks.push(network)
                    }
                }
            }

            _rows.push({
                ExchangeName: cgExchange.Market.ExchangeCommonName,
                CoinExchange: ownCoinExchange,
                OwnNetworks: networks,

                Price: cgExchange.Last,
                Volume: cgExchange.Volume,
                PriceAsterisk: cgExchange.is_anomaly,
                VolumeAsterisk: cgExchange.is_anomaly,
                Symbol: cgExchange.Base + "/" + cgExchange.Target,
                ExchangeID: cgExchange.Market.Identifier,
                ExchangeSlug: cgExchange.Market.Identifier,
                MarketURL: cgExchange.trade_url
            })
        }
        _rows.sort((a, b) => {
            if (a.OwnNetworks.length === 0 && b.OwnNetworks.length === 0) {
                return a.ExchangeName.localeCompare(b.ExchangeName)
            } else if (a.OwnNetworks.length === 0) {
                return 1
            } else if (b.OwnNetworks.length === 0) {
                return -1
            } else {
                let aCa = a.OwnNetworks[0].ContractAddress
                let bCa = b.OwnNetworks[0].ContractAddress
                if (aCa === "" && bCa === "") {
                    return a.ExchangeName.localeCompare(b.ExchangeName)
                } else if (aCa === "") {
                    return 1
                } else if (bCa === "") {
                    return -1
                } else {
                    if (
                        isSameAddress(aCa, networkCommonContractAddress) &&
                        !isSameAddress(bCa, networkCommonContractAddress)
                    ) {
                        return -1
                    } else if (
                        !isSameAddress(aCa, networkCommonContractAddress) &&
                        isSameAddress(bCa, networkCommonContractAddress)
                    ) {
                        return 1
                    } else {
                        return a.ExchangeName.localeCompare(b.ExchangeName)
                    }
                }
            }
        })
        setRows(_rows)
    }, [cgExchanges, coinExchanges, networkCommonCode])

    const isSameAddress = (a: string, b: string): boolean => {
        let aLower = a.toLowerCase()
        let bLower = b.toLowerCase()
        if (aLower.length < bLower.length) {
            return bLower.includes(aLower)
        } else if (aLower.length > bLower.length) {
            return aLower.includes(bLower)
        } else {
            return aLower === bLower
        }
    }

    const columns: ColumnsType<RowType> = [
        {
            title: "Exchange",
            dataIndex: "ExchangeName",
            ellipsis: true,
            render: (_, r: RowType) => {
                return (
                    <FlexRow>
                        <a href={`https://www.coingecko.com/en/exchanges/${r.ExchangeSlug}`} target="_blank">
                            <FlexRow
                                style={{
                                    alignItems: "center",
                                    gap: 2
                                }}
                            >
                                <img src={getCGExchangeLogoHref(r.ExchangeID)} width={24} height={24} />
                                <span>{r.ExchangeName}</span>
                            </FlexRow>
                        </a>
                        {r.CoinExchange === undefined && <i> — indexed on CoinMarketCap but delisted from exchange</i>}
                    </FlexRow>
                )
            },
            onCell: (r: RowType) => {
                return {
                    colSpan: r.CoinExchange === undefined ? 7 : 1
                }
            }
        },
        {
            title: (
                <Tooltip overlay="Coin anem as it appears on the exchange">
                    <span style={{ whiteSpace: "nowrap" }}>
                        <InfoCircleTwoTone /> Coin
                    </span>
                </Tooltip>
            ),
            render: (_, r: RowType) => {
                return <span style={{ whiteSpace: "nowrap" }}>{r.CoinExchange?.OwnCoinName}</span>
            },
            onCell: (r: RowType) => {
                return {
                    colSpan: r.CoinExchange === undefined ? 0 : 1
                }
            }
        },
        {
            title: (
                <Tooltip overlay="Network name as it appears on the exchange">
                    <span style={{ whiteSpace: "nowrap" }}>
                        <InfoCircleTwoTone /> Net
                    </span>
                </Tooltip>
            ),
            render: (r: RowType) => {
                if (r.OwnNetworks.length === 0) {
                    return <i>network not supported on exchange</i>
                }
                return (
                    <FlexCol style={{ gap: 0 }}>
                        {r.OwnNetworks.map(net => {
                            return <span style={{ whiteSpace: "nowrap" }}>{net.OwnNetworkName || "—"}</span>
                        })}
                    </FlexCol>
                )
            },
            onCell: (r: RowType) => {
                let colSpan = 1
                if (r.CoinExchange === undefined) {
                    colSpan = 0
                } else if (r.OwnNetworks.length === 0) {
                    colSpan = 2
                }
                return {
                    colSpan: colSpan
                }
            }
        },
        {
            title: "Contract Address",
            ellipsis: true,
            render: (_, r: RowType) => {
                return (
                    <FlexCol
                        style={{
                            gap: 0
                        }}
                    >
                        {r.OwnNetworks.map(net => {
                            let contractAddressHealthNode: ReactNode | null = null
                            if (networkCommonContractAddress !== "") {
                                if (net.ContractAddress === "") {
                                    contractAddressHealthNode = (
                                        <FlexRow
                                            style={{
                                                gap: 2,
                                                alignItems: "center"
                                            }}
                                        >
                                            <QuestionCircleFilled style={{ color: ARBITRAGE_COLOR_WARN }} />
                                            {/* {r.CoinExchange?.ExchangeName === "BITGET" && (
                                                <BitgetContractAddressLink
                                                    coinCommonCode={coinCommonCode}
                                                    networkCommonCode={networkCommonCode}
                                                    networkCommonContractAddress={networkCommonContractAddress}
                                                />
                                            )} */}
                                        </FlexRow>
                                    )
                                } else if (isSameAddress(net.ContractAddress, networkCommonContractAddress)) {
                                    contractAddressHealthNode = (
                                        <CheckCircleFilled style={{ color: ARBITRAGE_COLOR_GREEN }} />
                                    )
                                } else {
                                    contractAddressHealthNode = <CloseCircleFilled />
                                }
                            }
                            return (
                                <FlexRow>
                                    {contractAddressHealthNode}
                                    <Typography.Text
                                        style={{
                                            maxWidth: "12rem"
                                        }}
                                        ellipsis={{ tooltip: net.ContractAddress }}
                                    >
                                        {net.ContractAddress || "—"}
                                    </Typography.Text>
                                </FlexRow>
                            )
                        })}
                    </FlexCol>
                )
            },
            onCell: (r: RowType) => {
                let colSpan = 1
                if (r.CoinExchange === undefined) {
                    colSpan = 0
                } else if (r.OwnNetworks.length === 0) {
                    colSpan = 0
                }
                return {
                    colSpan: colSpan
                }
            }
        },
        {
            title: "Price",
            dataIndex: "Price",
            render: (value: number, r: RowType) => {
                return (
                    <>
                        {r.PriceAsterisk && "*"} ${numberToFixedWithoutTrailingZeros(value, 8)}
                    </>
                )
            },
            onCell: (r: RowType) => {
                return {
                    colSpan: r.CoinExchange === undefined ? 0 : 1
                }
            }
        },
        {
            title: "Volume",
            dataIndex: "Volume",
            render: (value: number, r: RowType) => {
                return (
                    <>
                        {r.VolumeAsterisk && "*"} ${formatNumberLog1e3(value.toString(), 3)}
                    </>
                )
            },
            onCell: (r: RowType) => {
                return {
                    colSpan: r.CoinExchange === undefined ? 0 : 1
                }
            }
        },
        {
            title: "Symbol",
            dataIndex: "Symbol",
            render: (value: string, r: RowType) => {
                return (
                    <a target="_blank" href={r.MarketURL}>
                        {value}
                    </a>
                )
            },
            onCell: (r: RowType) => {
                return {
                    colSpan: r.CoinExchange === undefined ? 0 : 1
                }
            }
        }
    ]

    if (rows.length === 0) {
        return null
    }

    return (
        <Table
            columns={columns}
            dataSource={rows}
            size="small"
            pagination={false}
            scroll={{
                x: true
            }}
        />
    )
}

export const DYOR_CMC_Widget: FC<{
    coinID: Coin["ID"]
    coinCommonCode: string | null
    coinExchanges: CoinExchange[] | null
}> = ({ coinID, coinCommonCode, coinExchanges }) => {
    const [CMCFullCoinInfos, setCMCFullCoinInfos] = useState<CMCFullCoinInfo[] | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { callSecureAPI } = useAuthContext()

    useEffect(() => {
        setIsLoading(true)
        callSecureAPI<CMCFullCoinInfo[] | null>(`/coin/cmc?coin_id=${coinID}`)
            .then(({ responseObject: resp }) => {
                setCMCFullCoinInfos(resp)
            })
            .catch((e: any) => {
                console.warn("CoinInformationWidget: error fetching CMCFullCoinInfo:", e)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [coinID])

    if (isLoading) {
        return (
            <FlexCol
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%"
                }}
            >
                <Spin size="large" />
            </FlexCol>
        )
    }

    if (
        CMCFullCoinInfos === null ||
        CMCFullCoinInfos.length === 0 ||
        coinExchanges === null ||
        coinExchanges.length === 0 ||
        coinCommonCode === null
    ) {
        return null
    }

    return (
        <FlexCol
            style={{
                alignItems: "start",
                width: "100%"
            }}
        >
            <FlexRow
                style={{
                    fontSize: "1rem",
                    color: "#333",
                    gap: 5,
                    alignItems: "center"
                }}
            >
                <img src="/app/coinmarketcap-logo-square.png" alt="CoinMarketCap" width="24px" />
                <span>
                    According to <b>CoinMarketCap</b>, symbol <b style={{ fontSize: "1.5rem" }}>{coinCommonCode}</b>{" "}
                    might be one of the following <b style={{ fontSize: "1.5rem" }}>{CMCFullCoinInfos.length}</b> assets
                </span>
            </FlexRow>
            <Tabs
                style={{
                    width: "100%"
                }}
                size="large"
                items={CMCFullCoinInfos.map(cmcCoinInfo => {
                    return {
                        label: cmcCoinInfo.CoinInfo.Name,
                        key: cmcCoinInfo.CoinInfo.ID.toString(),
                        children: (
                            <CMCInfoPanel
                                cmcFullCoinInfo={cmcCoinInfo}
                                coinExchanges={coinExchanges}
                                coinCommonCode={coinCommonCode}
                            />
                        )
                    }
                })}
                defaultActiveKey={CMCFullCoinInfos[0].CoinInfo.Symbol}
            />
        </FlexCol>
    )
}

export const DYOR_CG_Widget: FC<{
    coinID: Coin["ID"]
    coinCommonCode: string | null
    coinExchanges: CoinExchange[] | null
}> = ({ coinID, coinCommonCode, coinExchanges }) => {
    const [cgCoinInfos, setCGCoinInfos] = useState<CGCoinInfo[] | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { callSecureAPI } = useAuthContext()

    useEffect(() => {
        setIsLoading(true)
        callSecureAPI<CGCoinInfo[] | null>(`/coin/cg?coin_id=${coinID}`)
            .then(({ responseObject: resp }) => {
                setCGCoinInfos(resp)
            })
            .catch((e: any) => {
                console.warn("CoinInformationWidget (CG): error fetching coinInfo:", e)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [coinID])

    if (isLoading) {
        return (
            <FlexCol
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%"
                }}
            >
                <Spin size="large" />
            </FlexCol>
        )
    }

    if (
        cgCoinInfos === null ||
        cgCoinInfos.length === 0 ||
        coinExchanges === null ||
        coinExchanges.length === 0 ||
        coinCommonCode === null
    ) {
        return null
    }

    return (
        <FlexCol
            style={{
                alignItems: "start",
                width: "100%"
            }}
        >
            <FlexRow
                style={{
                    fontSize: "1rem",
                    color: "#333",
                    gap: 5,
                    alignItems: "center"
                }}
            >
                <img src="/app/coingecko-logo-square.png" alt="CoinGecko" width="24px" />
                <span>
                    According to <b>CoinGecko</b>, symbol <b style={{ fontSize: "1.5rem" }}>{coinCommonCode}</b> might
                    be one of the following <b style={{ fontSize: "1.5rem" }}>{cgCoinInfos.length}</b> assets
                </span>
            </FlexRow>
            <Tabs
                style={{
                    width: "100%"
                }}
                size="large"
                items={cgCoinInfos.map(cgCoinInfo => {
                    return {
                        label: cgCoinInfo.Name,
                        key: cgCoinInfo.ID,
                        children: (
                            <CGInfoPanel
                                cgCoinInfo={cgCoinInfo}
                                coinExchanges={coinExchanges}
                                coinCommonCode={coinCommonCode}
                            />
                        )
                    }
                })}
                defaultActiveKey={cgCoinInfos[0].ID}
            />
        </FlexCol>
    )
}

export const DYORWidget: FC<{
    coinID: Coin["ID"]
}> = ({ coinID }) => {
    const [dyorProvider, setDyorProvider] = useState<DYORProvider>(DYORProvider.CoinGecko)
    const [coinExchanges, setCoinExchanges] = useState<CoinExchange[] | null>(null)
    const [coinCommonCode, setCoinCommonCode] = useState<string | null>(null)

    const { callSecureAPI } = useAuthContext()

    useEffect(() => {
        callSecureAPI<CoinExchange[] | null>(`/coin/exchanges?coin_id=${coinID}`)
            .then(({ responseObject: resp }) => {
                setCoinExchanges(resp)
                if (resp !== null && resp.length > 0) {
                    setCoinCommonCode(resp[0].CoinName)
                }
            })
            .catch((e: any) => {
                console.warn("CoinInformationWidget: error fetching CoinExchanges:", e)
            })
    }, [coinID])

    if (coinExchanges === null || coinCommonCode === null) {
        return null
    }

    return (
        <FlexCol
            style={{
                alignItems: "start",
                width: "100%"
            }}
        >
            <Radio.Group
                size="large"
                onChange={e => {
                    setDyorProvider(e.target.value)
                }}
                style={{
                    width: "100%"
                }}
                value={dyorProvider}
                buttonStyle="outline"
            >
                <Radio.Button value={DYORProvider.CoinGecko} style={{ width: "50%" }}>
                    <FlexRow
                        style={{
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 5
                        }}
                    >
                        <img src="/app/coingecko-logo-square.png" alt="CoinGecko" height="30px" />
                        CoinGecko
                    </FlexRow>
                </Radio.Button>
                <Radio.Button value={DYORProvider.CoinMarketCap} style={{ width: "50%" }}>
                    <FlexRow
                        style={{
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 5
                        }}
                    >
                        <img src="/app/coinmarketcap-logo-square.png" alt="CoinMarketCap" height="30px" />
                        CoinMarketCap
                    </FlexRow>
                </Radio.Button>
            </Radio.Group>
            {(function () {
                switch (dyorProvider) {
                    case DYORProvider.CoinMarketCap:
                        return (
                            <DYOR_CMC_Widget
                                coinID={coinID}
                                coinCommonCode={coinCommonCode}
                                coinExchanges={coinExchanges}
                            />
                        )
                    case DYORProvider.CoinGecko:
                        return (
                            <DYOR_CG_Widget
                                coinID={coinID}
                                coinCommonCode={coinCommonCode}
                                coinExchanges={coinExchanges}
                            />
                        )
                    default:
                        return null
                }
            })()}
            <FlexRow
                style={{
                    marginTop: 10,
                    fontSize: "1rem",
                    color: "#333",
                    gap: 0,
                    alignItems: "start",
                    justifyContent: "end",
                    textAlign: "right",
                    width: "100%"
                }}
            >
                <span>
                    <InfoCircleTwoTone style={{ marginRight: 5 }} />
                    <b>Discalimer:</b> this page is intended to facilitate your research and by no means replace it.
                    Please <Tooltip overlay="Do Your Own Research">DYOR</Tooltip>.
                    <br />
                    By continuing to use the service you agree with the{" "}
                    <a href="https://arbitrage.ngineera.com/terms" target="_blank">
                        General Terms of Service
                    </a>
                </span>
            </FlexRow>
        </FlexCol>
    )
}
