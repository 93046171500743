import {
    ExchangePairCoinDiscrepancy,
    ExchangePairCoinDiscrepancyTimeseries,
    InstantOpportunity,
    PeriodicOpportunitiesResult,
    PeriodicOpportunity,
    PeriodicOpportunityResult,
    PersistentPeriodicOpportunitiesResult,
    PersistentPeriodicOpportunity
} from "./types"
import { stringify as uuidStringify } from "uuid"

// InstantOpportunity encoding
// isPersistentVariant - controls parsing of fields after ContractAddrtesse
//   (if true, then it omits reading those bytes)
const _decodeInstantOpportunity = (
    view: DataView,
    i: number,
    isPersistentVariant: boolean
): [InstantOpportunity, number] => {
    const exchangeFromID = view.getUint8(i)
    i += 1
    const exchangeToID = view.getUint8(i)
    i += 1
    const coinID = view.getUint16(i)
    i += 2
    const networkID = view.getUint16(i)
    i += 2
    const orderbookTimestampMs = view.getBigUint64(i)
    i += 8
    const orderbookLastUpdatedTimestampFromMs = view.getBigUint64(i)
    i += 8
    const orderbookLastUpdatedTimestampToMs = view.getBigUint64(i)
    i += 8
    const metadataTimestampMs = view.getBigUint64(i)
    i += 8
    const transactionOK = view.getUint8(i) === 1
    i += 1
    const depositNbConfirmations = view.getUint32(i)
    i += 4
    const depositFeeFixUSDTTo = view.getFloat32(i)
    i += 4
    const depositFeeRateTo = view.getFloat32(i)
    i += 4
    const withdrawFeeFixUSDTFrom = view.getFloat32(i)
    i += 4
    const withdrawFeeRateFrom = view.getFloat32(i)
    i += 4
    const marginTradeIsAvailableTo = view.getUint8(i) === 1
    i += 1
    const marginLoanCeilingQuoteAmountToConcr = view.getFloat32(i)
    i += 4

    const contractAddressFromLen = view.getUint8(i)
    i += 1
    const contractAddressFrom = []
    for (let j = 0; j < contractAddressFromLen; j++) {
        contractAddressFrom.push(view.getUint8(i))
        i += 1
    }

    const contractAddressToLen = view.getUint8(i)
    i += 1
    const contractAddressTo = []
    for (let j = 0; j < contractAddressToLen; j++) {
        contractAddressTo.push(view.getUint8(i))
        i += 1
    }

    var absoluteDiscrepancy = 0
    var relativeDiscrepancy = 0
    var lowestAskFrom = 0
    var highestBidTo = 0
    var cumulativeProfit = 0
    var cumulativeVolume = 0
    var cumulativeCost = 0
    var virtualAntiProfit = 0
    var profitToVirtualAntiProfitRatio = 0
    var retrospectiveAverageVirtualAntiProfit = 0
    var profitToRetrospectiveAverageVirtualAntiProfitRatio = 0
    var askPricePoints = []
    var bidPricePoints = []
    var profitPoints = []
    var costPoints = []

    if (!isPersistentVariant) {
        absoluteDiscrepancy = view.getFloat64(i)
        i += 8
        relativeDiscrepancy = view.getFloat32(i)
        i += 4
        lowestAskFrom = view.getFloat64(i)
        i += 8
        highestBidTo = view.getFloat64(i)
        i += 8

        cumulativeProfit = view.getFloat32(i)
        i += 4
        cumulativeVolume = view.getFloat64(i)
        i += 8
        cumulativeCost = view.getFloat32(i)
        i += 4
        virtualAntiProfit = view.getFloat32(i)
        i += 4
        profitToVirtualAntiProfitRatio = view.getFloat32(i)
        i += 4
        retrospectiveAverageVirtualAntiProfit = view.getFloat32(i)
        i += 4
        profitToRetrospectiveAverageVirtualAntiProfitRatio = view.getFloat32(i)
        i += 4

        const askPricePointsLen = view.getUint16(i)
        i += 2
        askPricePoints = []
        for (let j = 0; j < askPricePointsLen; j++) {
            askPricePoints.push(view.getFloat64(i))
            i += 8
        }

        const bidPricePointsLen = view.getUint16(i)
        i += 2
        bidPricePoints = []
        for (let j = 0; j < bidPricePointsLen; j++) {
            bidPricePoints.push(view.getFloat64(i))
            i += 8
        }

        const profitPointsLen = view.getUint16(i)
        i += 2
        profitPoints = []
        for (let j = 0; j < profitPointsLen; j++) {
            profitPoints.push(view.getFloat32(i))
            i += 4
        }

        const costPointsLen = view.getUint16(i)
        i += 2
        costPoints = []
        for (let j = 0; j < costPointsLen; j++) {
            costPoints.push(view.getFloat32(i))
            i += 4
        }
    }

    let orderbookLastUpdatedTimestampFromStr = ""
    try {
        orderbookLastUpdatedTimestampFromStr = new Date(Number(orderbookLastUpdatedTimestampFromMs)).toISOString()
    } catch (e) {}
    let orderbookLastUpdatedTimestampToStr = ""
    try {
        orderbookLastUpdatedTimestampToStr = new Date(Number(orderbookLastUpdatedTimestampToMs)).toISOString()
    } catch (e) {}

    const marginLoanCeilingQuoteAmountTo =
        marginLoanCeilingQuoteAmountToConcr === -1 ? null : marginLoanCeilingQuoteAmountToConcr

    let instantOpportunity: InstantOpportunity = {
        ExchangeFromID: exchangeFromID,
        ExchangeToID: exchangeToID,
        CoinID: coinID,
        NetworkID: networkID,
        OrderbookTimestamp: new Date(Number(orderbookTimestampMs)).toISOString(),
        OrderbookLastUpdatedTimestampFrom: orderbookLastUpdatedTimestampFromStr,
        OrderbookLastUpdatedTimestampTo: orderbookLastUpdatedTimestampToStr,
        MetadataTimestamp: new Date(Number(metadataTimestampMs)).toISOString(),
        TransactionOK: transactionOK,
        DepositNbConfirmations: depositNbConfirmations,
        DepositFeeFixUSDTTo: depositFeeFixUSDTTo,
        DepositFeeRateTo: depositFeeRateTo,
        WithdrawFeeFixUSDTFrom: withdrawFeeFixUSDTFrom,
        WithdrawFeeRateFrom: withdrawFeeRateFrom,
        MarginTradeIsAvailableTo: marginTradeIsAvailableTo,
        MarginLoanCeilingQuoteAmountTo: marginLoanCeilingQuoteAmountTo,
        AbsoluteDiscrepancy: absoluteDiscrepancy,
        RelativeDiscrepancy: relativeDiscrepancy,
        LowestAskFrom: lowestAskFrom,
        HighestBidTo: highestBidTo,
        ContractAddressFrom: contractAddressFrom.map(charCode => String.fromCharCode(charCode >> 1)).join(""),
        ContractAddressTo: contractAddressTo.map(charCode => String.fromCharCode(charCode >> 1)).join(""),
        CumulativeProfit: cumulativeProfit,
        CumulativeVolume: cumulativeVolume,
        CumulativeCost: cumulativeCost,
        VirtualAntiProfit: virtualAntiProfit,
        ProfitToVirtualAntiProfitRatio: profitToVirtualAntiProfitRatio,
        RetrospectiveAverageVirtualAntiProfit: retrospectiveAverageVirtualAntiProfit,
        ProfitToRetrospectiveAverageVirtualAntiProfitRatio: profitToRetrospectiveAverageVirtualAntiProfitRatio,
        AskPricePoints: askPricePoints,
        BidPricePoints: bidPricePoints,
        ProfitPoints: profitPoints,
        CostPoints: costPoints
    }

    return [instantOpportunity, i]
}

const _decodePeriodicOpportunity = (
    view: DataView,
    i: number,
    isPersistentVariant: boolean
): [PeriodicOpportunity, number] => {
    let [instantOpportunity, nextI] = _decodeInstantOpportunity(view, i, isPersistentVariant)
    // console.log("decoded instant opportunity", instantOpportunity, [i, nextI], view.buffer.slice(i, nextI))
    i = nextI

    const timestampStartMs = view.getBigUint64(i)
    i += 8
    const timestampEndMs = view.getBigUint64(i)
    i += 8
    const durationMs = view.getUint32(i)
    i += 4
    const endedSinceMs = view.getUint32(i)
    i += 4
    const avgCumulativeProfit = view.getFloat32(i)
    i += 4
    const avgCumulativeVolume = view.getFloat32(i)
    i += 4
    const avgCumulativeCost = view.getFloat32(i)
    i += 4
    const avgVirtualAntiProfit = view.getFloat32(i)
    i += 4
    const avgProfitToVirtualAntiProfitRatio = view.getFloat32(i)
    i += 4
    const avgRetrospectiveAverageVirtualAntiProfit = view.getFloat32(i)
    i += 4
    const avgProfitToRetrospectiveAverageVirtualAntiProfitRatio = view.getFloat32(i)
    i += 4

    const avgFeeAdjustedROIConcr = view.getFloat32(i)
    i += 4
    const nbPoints = view.getUint16(i)
    i += 2

    const avgFeeAdjustedROI = avgFeeAdjustedROIConcr === -1 ? null : avgFeeAdjustedROIConcr

    const periodicOpportunity: PeriodicOpportunity = {
        ...instantOpportunity,
        TimestampStart: new Date(Number(timestampStartMs)).toISOString(),
        TimestampEnd: new Date(Number(timestampEndMs)).toISOString(),
        DurationMs: durationMs,
        EndedSinceMs: endedSinceMs,
        AvgCumulativeProfit: avgCumulativeProfit,
        AvgCumulativeVolume: avgCumulativeVolume,
        AvgCumulativeCost: avgCumulativeCost,
        AvgFeeAdjustedROI: avgFeeAdjustedROI,
        AvgVirtualAntiProfit: avgVirtualAntiProfit,
        AvgProfitToVirtualAntiProfitRatio: avgProfitToVirtualAntiProfitRatio,
        AvgRetrospectiveAverageVirtualAntiProfit: avgRetrospectiveAverageVirtualAntiProfit,
        AvgProfitToRetrospectiveAverageVirtualAntiProfitRatio: avgProfitToRetrospectiveAverageVirtualAntiProfitRatio,
        NbPoints: nbPoints
    }

    // console.log("BINARY: decode: periodicOpportunity", periodicOpportunity)
    return [periodicOpportunity, i]
}

const _decodePersistentPeriodicOpportunity = (view: DataView, i: number): [PersistentPeriodicOpportunity, number] => {
    let [periodicOpportunity, nextI] = _decodePeriodicOpportunity(view, i, true)
    // console.log("decoded periodic opportunity", periodicOpportunity, [i, nextI], view.buffer.slice(i, nextI))
    i = nextI

    var _uuid = uuidStringify(new Uint8Array(16))
    const _uuidBytes = new Uint8Array(view.buffer.slice(i, i + 16))
    i += 16
    try {
        _uuid = uuidStringify(_uuidBytes)
    } catch (e: any) {
        console.error("Error decoding UUID", e)
    }

    const persistentPeriodicOpportunity: PersistentPeriodicOpportunity = {
        ...periodicOpportunity,
        UUID: _uuid
    }
    return [persistentPeriodicOpportunity, i]
}

export const decodePeriodicOpportunity = (buf: ArrayBuffer): PeriodicOpportunityResult => {
    const view = new DataView(buf)
    let timestamp = new Date(Number(view.getBigUint64(0))).toISOString()
    let i = 8
    let [periodicOpportunity, _] = _decodePeriodicOpportunity(view, i, false)
    return {
        Timestamp: timestamp,
        Opportunity: periodicOpportunity
    }
}

export const decodePeriodicOpportunities = (buf: ArrayBuffer): PeriodicOpportunitiesResult => {
    const view = new DataView(buf)
    const periodicOpportunitiesResult: PeriodicOpportunitiesResult = {
        Timestamp: new Date(Number(view.getBigUint64(0))).toISOString(),
        Opportunities: []
    }
    let i = 8
    while (i < buf.byteLength) {
        // console.log('decoding periodic opportunities', i, periodicOpportunitiesResult.Opportunities.length)
        let [periodicOpportunity, nextI] = _decodePeriodicOpportunity(view, i, false)
        i = nextI
        periodicOpportunitiesResult.Opportunities.push(periodicOpportunity)
    }
    return periodicOpportunitiesResult
}

export const decodeInstantOpportunities = (buf: ArrayBuffer): InstantOpportunity[] => {
    const view = new DataView(buf)
    let i = 0
    let instantOpportunities: InstantOpportunity[] = []
    while (i < buf.byteLength) {
        let [instantOpportunity, nextI] = _decodeInstantOpportunity(view, i, false)
        i = nextI
        instantOpportunities.push(instantOpportunity)
    }
    return instantOpportunities
}

export const decodePersistentPeriodicOpportunities = (buf: ArrayBuffer): PersistentPeriodicOpportunitiesResult => {
    const view = new DataView(buf)
    const persistentPeriodicOpportunitiesResult: PersistentPeriodicOpportunitiesResult = {
        Timestamp: new Date(Number(view.getBigUint64(0))).toISOString(),
        Opportunities: []
    }
    let i = 8
    while (i < buf.byteLength) {
        let [persistentPeriodicOpportunity, nextI] = _decodePersistentPeriodicOpportunity(view, i)
        // console.log(
        //     "decoded persistent periodic opportunity",
        //     persistentPeriodicOpportunity,
        //     [i, nextI],
        //     view.buffer.slice(i, nextI)
        // )
        i = nextI
        persistentPeriodicOpportunitiesResult.Opportunities.push(persistentPeriodicOpportunity)
    }
    return persistentPeriodicOpportunitiesResult
}

// SimpleDiscrepancy encoding
//
// ExchangeFromID uint8
// ExchangeToID uint8
// CoinID uint16
// OrderbookTimestamp uint64
// DiscrepancyExchangeOneLAPrice float64
// DiscrepancyExchangeTwoLAPrice float64
// DiscrepancyExchangeOneHBPrice float64
// DiscrepancyExchangeTwoHBPrice float64

export const _decodeSimpleDiscrepancy = (view: DataView, i: number): [ExchangePairCoinDiscrepancy, number] => {
    const exchangeFromID = view.getUint8(i)
    i += 1
    const exchangeToID = view.getUint8(i)
    i += 1
    const coinID = view.getUint16(i)
    i += 2
    const orderbookTimestampMs = view.getBigUint64(i)
    i += 8
    const discrepancyExchangeOneLAPrice = view.getFloat64(i)
    i += 8
    const discrepancyExchangeTwoLAPrice = view.getFloat64(i)
    i += 8
    const discrepancyExchangeOneHBPrice = view.getFloat64(i)
    i += 8
    const discrepancyExchangeTwoHBPrice = view.getFloat64(i)
    i += 8

    const discrepancy: ExchangePairCoinDiscrepancy = {
        ExchangeOneID: exchangeFromID,
        ExchangeTwoID: exchangeToID,
        CoinID: coinID,
        OrderbookTimestamp: new Date(Number(orderbookTimestampMs)).toISOString(),
        Discrepancy: {
            ExchangeOneLAPrice: discrepancyExchangeOneLAPrice,
            ExchangeTwoLAPrice: discrepancyExchangeTwoLAPrice,
            ExchangeOneHBPrice: discrepancyExchangeOneHBPrice,
            ExchangeTwoHBPrice: discrepancyExchangeTwoHBPrice
        },
        Metadata: null
    }
    return [discrepancy, i]
}

export const decodeSimpleDiscrepancies = (buf: ArrayBuffer): ExchangePairCoinDiscrepancyTimeseries => {
    const view = new DataView(buf)
    let i = 0
    let discrepancies: ExchangePairCoinDiscrepancyTimeseries = []
    while (i < buf.byteLength) {
        let [discrepancy, nextI] = _decodeSimpleDiscrepancy(view, i)
        i = nextI
        discrepancies.push(discrepancy)
    }
    return discrepancies
}

// CompositeDiscrepancy encoding
//
// SimpleDiscrepancy
// MetadataTimestamp uint64
// ExchangeFromSymbolOK, ExchangeToSymbolOK, ExchangeFromWithdrawOK, ExchangeToDepositOK uint8
// ExchangeFromWithdrawFeeFix float64
// ExchangeFromWithdrawFeeRate int16 (fixed exponent: 1e4)
// ExchangeToDepositFeeRate int16 (fixed exponent: 1e4)

const _decodeCompositeDiscrepancy = (view: DataView, i: number): [ExchangePairCoinDiscrepancy, number] => {
    let [discrepancy, nextI] = _decodeSimpleDiscrepancy(view, i)
    i = nextI

    const metadataTimestampMs = view.getBigUint64(i)
    i += 8
    const availabilityFlags = view.getUint8(i)
    i += 1
    const exchangeFromWithdrawFeeFix = view.getFloat64(i)
    i += 8
    const exchangeFromWithdrawFeeRate = view.getInt16(i)
    i += 2
    const exchangeToDepositFeeRate = view.getInt16(i)
    i += 2

    const exchangeFromSymbolOK = ((availabilityFlags >> 3) & 1) !== 0
    const exchangeToSymbolOK = ((availabilityFlags >> 2) & 1) !== 0
    const exchangeFromWithdrawOK = ((availabilityFlags >> 1) & 1) !== 0
    const exchangeToDepositOK = ((availabilityFlags >> 0) & 1) !== 0

    // console.log("availabilityFlags", availabilityFlags, exchangeFromSymbolOK, exchangeToSymbolOK, exchangeFromWithdrawOK, exchangeToDepositOK)

    discrepancy.Metadata = {
        MetadataTimestamp: new Date(Number(metadataTimestampMs)).toISOString(),
        ExchangeOneSymbolOK: exchangeFromSymbolOK,
        ExchangeTwoSymbolOK: exchangeToSymbolOK,
        ExchangeOneWithdrawOK: exchangeFromWithdrawOK,
        ExchangeTwoDepositOK: exchangeToDepositOK,
        ExchangeOneWithdrawFeeFix: exchangeFromWithdrawFeeFix,
        ExchangeOneWithdrawFeeRate: exchangeFromWithdrawFeeRate / 1e4,
        ExchangeTwoDepositFeeRate: exchangeToDepositFeeRate / 1e4
    }
    return [discrepancy, i]
}

export const decodeCompositeDiscrepancies = (buf: ArrayBuffer): ExchangePairCoinDiscrepancyTimeseries => {
    const view = new DataView(buf)
    let i = 0
    let discrepancies: ExchangePairCoinDiscrepancyTimeseries = []
    while (i < buf.byteLength) {
        let [discrepancy, nextI] = _decodeCompositeDiscrepancy(view, i)
        i = nextI
        discrepancies.push(discrepancy)
    }
    // console.log("composite discrepancies", discrepancies)
    return discrepancies
}
