import { FC } from "react"
import { useAuthContext } from "../reducers/authReducer"
import { Avatar, Button, Popover } from "antd"
import { FlexCol, FlexRow } from "../common"
import { LoginWidget } from "./loginWidget"
import { UserOutlined } from "@ant-design/icons"

export const UserAvatarWidget: FC = () => {
    const { userInfo, authToken, setAuthToken } = useAuthContext()

    return (
        <div>
            <Popover
                content={
                    <>
                        {authToken ?
                            <FlexCol
                                style={{
                                    gap: 5,
                                    alignItems: "center"
                                }}
                            >
                                <span>
                                    Logged in as{" "}
                                    <b>
                                        {userInfo?.firstName} {userInfo?.lastName}
                                    </b>{" "}
                                    {userInfo?.username && (
                                        <a href={`https://t.me/${userInfo?.username}`} target="_blank">
                                            @{userInfo?.username}
                                        </a>
                                    )}
                                </span>
                                <FlexRow
                                    style={{
                                        // alignItems: 'center',
                                        justifyContent: "center"
                                    }}
                                >
                                    <LoginWidget />
                                    <Button
                                        size="large"
                                        onClick={() => {
                                            setAuthToken(null)
                                            window.location.href = "/app/home"
                                        }}
                                    >
                                        Logout
                                    </Button>
                                </FlexRow>
                            </FlexCol>
                        :   <FlexCol>
                                <span>
                                    Please login with <b>Telegram</b> to start using the service!
                                </span>
                                <LoginWidget />
                            </FlexCol>
                        }
                    </>
                }
            >
                <Avatar
                    icon={userInfo?.photoUrl === "" ? undefined : <UserOutlined />}
                    style={{
                        cursor: "pointer",
                        backgroundColor: "gray"
                    }}
                    src={userInfo?.photoUrl}
                    size={"large"}
                />
            </Popover>
        </div>
    )
}
