import { FC, useEffect, useRef, useState } from "react"
import {
    Coin,
    Exchange,
    PeriodicOpportunitiesResult,
    PeriodicOpportunity,
    Network,
    InstantOpportunity,
    WellKnownFeeRatesMap
} from "../types"
import {
    Alert,
    Button,
    Col,
    Input,
    InputNumber,
    InputRef,
    Row,
    Select,
    Space,
    Spin,
    Switch,
    Tooltip,
    Typography
} from "antd"
import Table, { ColumnsType } from "antd/es/table"
import {
    EyeOutlined,
    FundViewOutlined,
    InfoCircleTwoTone,
    QuestionCircleTwoTone,
    ReloadOutlined
} from "@ant-design/icons"
import { formatDurationApprox, getTotalFee, projectDepositOnProfitNonLinear, useMediaQuery } from "../utils"
import { BlinkOnUpdate, FlexCol, FlexRow, Paper, StretchedDashString } from "../common"
import { OpportunityFullInformationWidget } from "../widgets/opportunitiesWidgets"
import { OPPORTUNITIES_AUTO_REFRESH_INTERVAL_MS } from "../constants"
import { useAuthContext } from "../reducers/authReducer"
import { decodePeriodicOpportunities } from "../binary"
import { Link } from "react-router-dom"
import { useConfigContext } from "../reducers/configReducer"
import { RiskAssessmentWidget, WellKnownFeeRatesWidget } from "../widgets/dyorWidgets"
import { UnifiedExchangePairCoinTimeseriesWidget } from "../widgets/uplotCharts"

const getRowKey = (r: InstantOpportunity) => {
    return `${r.ExchangeFromID}_${r.ExchangeToID}_${r.CoinID}`
}

export const PeriodicOpportunitiesPage: FC = () => {
    const [coins, setCoins] = useState<Coin[] | null>(null)
    const [coinsMap, setCoinsMap] = useState<Record<Coin["ID"], Coin> | null>(null)

    const [exchanges, setExchanges] = useState<Exchange[] | null>(null)
    const [exchangesMap, setExchangesMap] = useState<Record<Exchange["ID"], Exchange> | null>(null)

    const [networks, setNetworks] = useState<Network[] | null>(null)
    const [networksMap, setNetworksMap] = useState<Record<Network["ID"], Network> | null>(null)

    const [wellKnowFeeRatesMap, setWellKnowFeeRatesMap] = useState<WellKnownFeeRatesMap | null>(null)

    const [columns, setColumns] = useState<ColumnsType<PeriodicOpportunity> | null>(null)

    const [opportunitiesResult, setOpportunitiesResult] = useState<PeriodicOpportunitiesResult | null>(null)
    const [opportunities, setOpportunities] = useState<PeriodicOpportunity[] | null>(null)
    const [filteredOpportunities, setFilteredOpportunities] = useState<PeriodicOpportunity[] | null>(null)

    const [filterOnlyRecentPositions, setFilterOnlyRecentPositions] = useState<boolean>(true)

    const [nEffectiveOpportunities, setNEffectiveOpportunities] = useState<number>(0)
    const [sumEffectiveProfit, setSumEffectiveProfit] = useState<number>(0)

    const [automaticRefreshEnabled, setAutomaticRefreshEnabled] = useState<boolean>(true)

    const coinFilterInputRef = useRef<InputRef>(null)

    // const [isLoading, setIsLoading] = useState<boolean>(false)
    const opportunitiesFetchMutexRef = useRef<boolean>(false)

    const [expandedRowKeys, setExpandedRowKeys] = useState<readonly string[]>([])
    const [currentPage, setCurrentPage] = useState<number>(1)

    const isMobile = useMediaQuery()

    const { authToken, callSecureAPI } = useAuthContext()

    const {
        depositAmount,
        minProfit,
        minCost,
        minMargin,
        filteredExchangesIncluded: filteredExchangesIn,
        filteredExchangesExcluded: filteredExchangesOut,
        hedgingPreference,
        showLowProfitOpportunities,
        setDepositAmount,
        setMinProfit,
        setMinCost,
        setMinMargin,
        setFilteredExchangesIncluded: setFilteredExchangesIn,
        setFilteredExchangesExcluded: setFilteredExchangesOut,
        setHedgingPreference,
        setShowLowProfitOpportunities
    } = useConfigContext()

    const loadOpportunities = () => {
        if (opportunitiesFetchMutexRef.current) {
            return
        }
        opportunitiesFetchMutexRef.current = true
        // setIsLoading(true)
        void callSecureAPI<ArrayBuffer | null>("/periodic/opportunities").then(
            ({ responseObject: encodedOpportunities }) => {
                if (encodedOpportunities === null) {
                    return
                }
                const opportunitiesResult = decodePeriodicOpportunities(encodedOpportunities)
                setOpportunitiesResult(opportunitiesResult)
                // setIsLoading(false)
                opportunitiesFetchMutexRef.current = false
            }
        )
    }

    useEffect(() => {
        if (opportunitiesResult === null) {
            return
        }
        setOpportunities(opportunitiesResult.Opportunities)
    }, [opportunitiesResult])

    useEffect(() => {
        callSecureAPI<WellKnownFeeRatesMap | null>("/well-known/coin/fee/rates").then(
            ({ responseObject: _feeRatesMap }) => {
                setWellKnowFeeRatesMap(_feeRatesMap)
            }
        )
    }, [])

    // Coins
    useEffect(() => {
        callSecureAPI<Coin[] | null>("/coins").then(({ responseObject: coins }) => {
            setCoins(coins)
        })
    }, [])
    // CoinsMap
    useEffect(() => {
        if (coins === null || coins.length === 0) {
            return
        }
        const _coinsMap: Record<Coin["ID"], Coin> = {}
        for (const coin of coins) {
            _coinsMap[coin.ID] = coin
        }
        setCoinsMap(_coinsMap)
    }, [coins])

    // Exchanges
    useEffect(() => {
        callSecureAPI<Exchange[] | null>("/exchanges").then(({ responseObject: exchanges }) => {
            setExchanges(exchanges)
        })
    }, [])

    // ExchangesMap
    useEffect(() => {
        if (exchanges === null || exchanges.length === 0) {
            return
        }
        if (filteredExchangesIn === null) {
            setFilteredExchangesIn(exchanges.map(e => e.Name))
        }
        // if (filteredExchangesOut === null) {
        //     setFilteredExchangesOut(exchanges.map((e) => e.Name))
        // }
        const _exchangesMap: Record<Exchange["ID"], Exchange> = {}
        for (const exchange of exchanges) {
            _exchangesMap[exchange.ID] = exchange
        }
        setExchangesMap(_exchangesMap)
    }, [exchanges, filteredExchangesIn, filteredExchangesOut])

    // Networks
    useEffect(() => {
        callSecureAPI<Network[] | null>("/networks").then(({ responseObject: networks }) => {
            setNetworks(networks)
        })
    }, [])

    // NetworksMap
    useEffect(() => {
        if (networks === null || networks.length === 0) {
            return
        }
        const _networksMap: Record<Network["ID"], Network> = {}
        for (const network of networks) {
            _networksMap[network.ID] = network
        }
        setNetworksMap(_networksMap)
    }, [networks])

    useEffect(() => {
        void loadOpportunities()
    }, [])

    useEffect(() => {
        if (automaticRefreshEnabled) {
            const interval = setInterval(() => {
                void loadOpportunities()
            }, OPPORTUNITIES_AUTO_REFRESH_INTERVAL_MS)
            return () => {
                clearInterval(interval)
            }
        }
    }, [automaticRefreshEnabled, authToken])

    useEffect(() => {
        if (opportunities === null) {
            return
        }
        if (exchangesMap === null) {
            return
        }
        let sumEffectiveProfit = 0
        let _filteredOpportunities = []
        for (let o of opportunities) {
            if (coinFilterInputRef.current !== null && coinsMap !== null) {
                if (
                    !coinsMap[o.CoinID]?.Name.toLowerCase().includes(
                        coinFilterInputRef.current!.input!.value.toLowerCase()
                    )
                ) {
                    continue
                }
            }

            let exFromName = exchangesMap[o.ExchangeFromID]?.Name
            let exToName = exchangesMap[o.ExchangeToID]?.Name

            if (filteredExchangesIn !== null) {
                if (!filteredExchangesIn.includes(exFromName) && !filteredExchangesIn.includes(exToName)) {
                    continue
                }
            }

            if (filteredExchangesOut !== null) {
                if (filteredExchangesOut.includes(exFromName) || filteredExchangesOut.includes(exToName)) {
                    continue
                }
            }

            if (o.DurationMs < 1000 * 15) {
                // 15 seconds - 3 confirmations
                continue
            }

            let effectiveProfit = o.CumulativeProfit - getTotalFee(o, depositAmount)
            let effectiveCost = o.CumulativeCost

            if (depositAmount !== null) {
                let { projectedProfit: pod } = projectDepositOnProfitNonLinear(
                    o.ProfitPoints,
                    o.CostPoints,
                    depositAmount
                )
                effectiveProfit = pod - getTotalFee(o, depositAmount)
                effectiveCost = Math.min(depositAmount, o.CumulativeCost)
            }
            let margin = 100 * (effectiveProfit / effectiveCost)

            if (showLowProfitOpportunities && effectiveProfit < 1.0) {
                continue
            }

            if (minProfit !== null) {
                if (effectiveProfit < minProfit) {
                    continue
                }
            }

            if (minCost !== null) {
                if (effectiveCost < minCost) {
                    continue
                }
            }
            if (minMargin !== null) {
                if (margin < minMargin) {
                    continue
                }
            }
            if (hedgingPreference) {
                if (!o.MarginTradeIsAvailableTo) {
                    continue
                }
            }
            if (filterOnlyRecentPositions) {
                if (o.EndedSinceMs > 1000 * 30) {
                    // 30 seconds
                    continue
                }
            }

            if (effectiveProfit > 0) {
                sumEffectiveProfit += effectiveProfit
            }

            _filteredOpportunities.push(o)
        }
        // if (alwaysSortByPP) {
        // }
        _filteredOpportunities.sort((a, b) => {
            if (depositAmount === null) {
                return 0
            }
            let { projectedProfit: podA } = projectDepositOnProfitNonLinear(a.ProfitPoints, a.CostPoints, depositAmount)
            podA -= getTotalFee(a, depositAmount)
            let { projectedProfit: podB } = projectDepositOnProfitNonLinear(b.ProfitPoints, b.CostPoints, depositAmount)
            podB -= getTotalFee(b, depositAmount)
            return podB - podA
        })
        setNEffectiveOpportunities(_filteredOpportunities.length)
        setSumEffectiveProfit(sumEffectiveProfit)
        setFilteredOpportunities(_filteredOpportunities)
    }, [
        opportunities,
        exchangesMap,
        minProfit,
        minCost,
        minMargin,
        hedgingPreference,
        // alwaysSortByPP,
        filterOnlyRecentPositions,
        filteredExchangesIn
    ])

    useEffect(() => {
        if (
            coinsMap === null ||
            exchangesMap === null ||
            exchanges === null ||
            coins === null ||
            networksMap === null
        ) {
            return
        }
        const columnWidthOnMobile = 80
        const _columns: ColumnsType<PeriodicOpportunity> = [
            {
                title: !isMobile ? "Opportunity" : <Tooltip overlay="Opportunity">Opp</Tooltip>,
                width: isMobile ? "30vw" : "20vw",
                render: (_, record) => {
                    let exFromName = exchangesMap[record.ExchangeFromID]?.Name
                    let exToName = exchangesMap[record.ExchangeToID]?.Name
                    let coinName = coinsMap[record.CoinID]?.Name
                    return (
                        <Typography.Text
                            style={
                                {
                                    // fontSize: isMobile ? 12 : 14
                                }
                            }
                        >
                            {exFromName} → {exToName} | <b>{coinName}</b>
                        </Typography.Text>
                    )
                }
            },
            {
                title: !isMobile ? "Liquidity [$]" : <Tooltip overlay="Liquidity [$]">L</Tooltip>,
                width: !isMobile ? undefined : columnWidthOnMobile,
                render: (_, record) => {
                    return <>{Math.round(record.CumulativeCost)}</>
                }
            }
        ]
        if (depositAmount !== null) {
            _columns.push(
                {
                    title: !isMobile ? "Profit [$]" : <Tooltip overlay="Profit [$]">P[%]</Tooltip>,
                    width: !isMobile ? undefined : columnWidthOnMobile,
                    render: (_, record) => {
                        if (depositAmount === null) {
                            return null
                        }
                        let { projectedProfit: pod } = projectDepositOnProfitNonLinear(
                            record.ProfitPoints,
                            record.CostPoints,
                            depositAmount
                        )
                        pod -= getTotalFee(record, depositAmount)
                        return <>{pod.toFixed(2)}</>
                    },
                    sorter: (a, b) => {
                        if (depositAmount === null) {
                            return 0
                        }
                        let { projectedProfit: podA } = projectDepositOnProfitNonLinear(
                            a.ProfitPoints,
                            a.CostPoints,
                            depositAmount
                        )
                        podA -= getTotalFee(a, depositAmount)
                        let { projectedProfit: podB } = projectDepositOnProfitNonLinear(
                            b.ProfitPoints,
                            b.CostPoints,
                            depositAmount
                        )
                        podB -= getTotalFee(b, depositAmount)
                        return podA - podB
                    }
                },
                {
                    title: "ROI [%]",
                    width: !isMobile ? undefined : columnWidthOnMobile,
                    render: (_, record) => {
                        if (depositAmount === null) {
                            return null
                        }
                        let { projectedProfit: pod } = projectDepositOnProfitNonLinear(
                            record.ProfitPoints,
                            record.CostPoints,
                            depositAmount
                        )
                        pod -= getTotalFee(record, depositAmount)
                        let personalizedCost = Math.min(depositAmount, record.CumulativeCost)
                        let m = (pod / personalizedCost) * 100
                        return <>{m.toFixed(2)}</>
                    },
                    sorter: (a, b) => {
                        if (depositAmount === null) {
                            return 0
                        }
                        let { projectedProfit: podA } = projectDepositOnProfitNonLinear(
                            a.ProfitPoints,
                            a.CostPoints,
                            depositAmount
                        )
                        podA -= getTotalFee(a, depositAmount)
                        let codA = Math.min(depositAmount, a.CumulativeCost)
                        let { projectedProfit: podB } = projectDepositOnProfitNonLinear(
                            b.ProfitPoints,
                            b.CostPoints,
                            depositAmount
                        )
                        podB -= getTotalFee(b, depositAmount)
                        let codB = Math.min(depositAmount, b.CumulativeCost)
                        let mA = (podA / codA) * 100
                        let mB = (podB / codB) * 100
                        return mA - mB
                    },
                    sortDirections: ["descend", "ascend"]
                }
            )
        } else {
            _columns.push(
                {
                    title: !isMobile ? "Profit [$]" : <Tooltip overlay="Profit [$]">P[%]</Tooltip>,
                    width: !isMobile ? undefined : columnWidthOnMobile,
                    render: (_, record) => {
                        let p = record.CumulativeProfit - getTotalFee(record, depositAmount)
                        return <>{p.toFixed(2)}</>
                    },
                    sorter: (a, b) => {
                        let pA = a.CumulativeProfit - getTotalFee(a, depositAmount)
                        let pB = b.CumulativeProfit - getTotalFee(b, depositAmount)
                        return pA - pB
                    },
                    sortDirections: ["descend", "ascend"]
                },
                {
                    title: !isMobile ? "Margin [%]" : <Tooltip overlay="Margin [%]">M[%]</Tooltip>,
                    width: !isMobile ? undefined : columnWidthOnMobile,
                    render: (_, record) => {
                        let p = record.CumulativeProfit - getTotalFee(record, depositAmount)
                        let m = (p / record.CumulativeCost) * 100
                        return <>{m.toPrecision(2)}</>
                    },
                    sorter: (a, b) => {
                        let pA = a.CumulativeProfit - getTotalFee(a, depositAmount)
                        let pB = b.CumulativeProfit - getTotalFee(b, depositAmount)
                        let mA = (pA / a.CumulativeCost) * 100
                        let mB = (pB / b.CumulativeCost) * 100
                        return mA - mB
                    },
                    sortDirections: ["descend", "ascend"]
                }
            )
        }
        if (!isMobile) {
            _columns.push({
                title: !isMobile ? "Duration" : <Tooltip overlay="Duration">D</Tooltip>,
                width: !isMobile ? undefined : columnWidthOnMobile,
                render: (_, record) => {
                    return formatDurationApprox(record.DurationMs)
                },
                sortDirections: ["descend", "ascend"],
                sorter: (a, b) => {
                    return a.DurationMs - b.DurationMs
                }
            })
        }
        if (!filterOnlyRecentPositions) {
            _columns.push({
                title: !isMobile ? "Since" : <Tooltip overlay="Since">S</Tooltip>,
                width: !isMobile ? undefined : columnWidthOnMobile,
                render: (_, record) => {
                    return formatDurationApprox(record.EndedSinceMs)
                },
                sortDirections: ["descend", "ascend"],
                sorter: (a, b) => {
                    return a.EndedSinceMs - b.EndedSinceMs
                }
            })
        }
        if (!isMobile) {
            _columns.push({
                title: "",
                width: 50,
                render: (_, record) => {
                    return (
                        <FlexRow
                            style={{
                                width: "100%",
                                justifyContent: "center"
                            }}
                        >
                            <Tooltip overlay={<>Monitor this position in real time</>}>
                                <Link
                                    to={`/opportunity?exchange_from_id=${record.ExchangeFromID}&exchange_to_id=${record.ExchangeToID}&coin_id=${record.CoinID}`}
                                    target="_blank"
                                >
                                    <Button icon={<FundViewOutlined />} size="large" type="default" />
                                </Link>
                            </Tooltip>
                        </FlexRow>
                    )
                }
            })
        }
        _columns.push({
            title: "",
            width: "4rem",
            render: (_, record) => {
                return (
                    <FlexRow
                        style={{
                            gap: 5
                        }}
                    >
                        <RiskAssessmentWidget
                            periodicOpportunity={record}
                            exchangesMap={exchangesMap}
                            coinsMap={coinsMap}
                            networksMap={networksMap}
                            fontSize="1.5rem"
                        />
                        <WellKnownFeeRatesWidget
                            periodicOpportunity={record}
                            coinsMap={coinsMap}
                            networksMap={networksMap}
                            wellKnownFeeRatesMap={wellKnowFeeRatesMap}
                            fontSize="1.5rem"
                        />
                    </FlexRow>
                )
            }
        })
        setColumns(_columns)
    }, [exchangesMap, coinsMap, networksMap, depositAmount, isMobile, filterOnlyRecentPositions])

    if (filteredOpportunities === null) {
        return (
            <Row
                style={{
                    width: "100%",
                    height: "100%"
                }}
                justify="center"
                align="middle"
            >
                <Col>
                    <Spin size="large" />
                </Col>
            </Row>
        )
    }

    if (
        coins === null ||
        exchanges === null ||
        coinsMap === null ||
        exchangesMap === null ||
        networksMap === null ||
        columns === null ||
        opportunities === null ||
        opportunitiesResult === null ||
        filteredOpportunities === null
    ) {
        return null
    }

    return (
        <Row gutter={[10, 10]} justify="end">
            <Col xs={12}>
                Last update: <BlinkOnUpdate value={new Date(opportunitiesResult.Timestamp).toLocaleTimeString()} />
            </Col>
            <Col xs={12}>
                <FlexRow
                    style={{
                        alignItems: "center",
                        justifyContent: "end"
                    }}
                >
                    <Button
                        type="text"
                        size="large"
                        icon={<ReloadOutlined />}
                        onClick={() => {
                            void loadOpportunities()
                        }}
                    />
                    <Tooltip overlay={<>Auto-refresh</>} placement="bottom">
                        <Switch
                            checked={automaticRefreshEnabled}
                            onChange={checked => {
                                setAutomaticRefreshEnabled(checked)
                            }}
                        />
                    </Tooltip>
                </FlexRow>
            </Col>
            <Col xs={24}>
                <Paper>
                    <FlexCol
                        style={{
                            alignItems: "start",
                            height: "100%"
                        }}
                    >
                        <FlexCol
                            style={{
                                width: "100%",
                                justifyContent: "space-between"
                            }}
                        >
                            <FlexRow
                                style={{
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <FlexRow>
                                    Deposit amount [$]
                                    <Tooltip
                                        overlay={
                                            <>
                                                Enter the amount of your deposit to estimate your personalized maximum
                                                profit.
                                                <br />
                                                <br />
                                                If the cost of the operation is higher than your deposit, the profit
                                                will be capped at the amount of your deposit.
                                                <br />
                                                <br />
                                                If the cost of the operation is lower than your deposit, the profit will
                                                be the same as the maximum profit of the operation.
                                            </>
                                        }
                                        placement="bottom"
                                        overlayInnerStyle={{
                                            minWidth: 400
                                        }}
                                    >
                                        <QuestionCircleTwoTone />
                                    </Tooltip>
                                </FlexRow>
                                <StretchedDashString />
                                <FlexRow>
                                    <InputNumber
                                        min={0}
                                        value={depositAmount}
                                        onChange={value => {
                                            setDepositAmount(value)
                                        }}
                                    />
                                    {/* <Tooltip
                                overlay={<>
                                    Always sort Profit [$] in descending order, even on refresh
                                </>}
                                placement="right"
                            >
                                <Switch
                                    checked={alwaysSortByPP}
                                    onChange={(checked) => {
                                        setAlwaysSortByPP(checked)
                                    }}
                                />
                            </Tooltip> */}
                                </FlexRow>
                            </FlexRow>
                            <FlexRow
                                style={{
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <FlexCol
                                    style={{
                                        gap: 0
                                    }}
                                >
                                    <span>
                                        Only <b>hedging-ready</b> positions
                                    </span>
                                    <i
                                        style={{
                                            fontSize: 9
                                        }}
                                    >
                                        (with MARGIN trade available on receiving exchange)
                                    </i>
                                </FlexCol>
                                <StretchedDashString />
                                <Switch
                                    checked={hedgingPreference}
                                    onChange={checked => {
                                        setHedgingPreference(checked)
                                    }}
                                />
                            </FlexRow>
                            <FlexRow
                                style={{
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <FlexCol
                                    style={{
                                        gap: 0
                                    }}
                                >
                                    <span>
                                        Only show <b>currently open</b> positions
                                    </span>
                                    <i
                                        style={{
                                            fontSize: 9
                                        }}
                                    >
                                        (profitable within {"<"} 30 sec)
                                    </i>
                                </FlexCol>
                                <StretchedDashString />
                                <Switch
                                    checked={filterOnlyRecentPositions}
                                    onChange={checked => {
                                        setFilterOnlyRecentPositions(checked)
                                    }}
                                />
                            </FlexRow>
                            <FlexRow
                                style={{
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <span>Min Profit</span>
                                <StretchedDashString />
                                <Tooltip overlay="Hide opportunities with low (<&nbsp;1$) profits">
                                    <Switch
                                        checked={showLowProfitOpportunities}
                                        onChange={checked => {
                                            setShowLowProfitOpportunities(checked)
                                        }}
                                    />
                                </Tooltip>
                                <InputNumber
                                    min={-100}
                                    value={minProfit}
                                    onChange={value => {
                                        setMinProfit(value)
                                    }}
                                    addonBefore="Min [$]"
                                />
                            </FlexRow>
                            <FlexRow
                                style={{
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <span>Min Investment</span>
                                <StretchedDashString />
                                <InputNumber
                                    min={0}
                                    value={minCost}
                                    onChange={value => {
                                        setMinCost(value)
                                    }}
                                    addonBefore="Min [$]"
                                />
                            </FlexRow>
                            <FlexRow
                                style={{
                                    alignItems: "center",
                                    gap: 2,
                                    justifyContent: "space-between"
                                }}
                            >
                                <span>Min ROI</span>
                                <StretchedDashString />
                                <InputNumber
                                    min={0}
                                    max={100}
                                    value={minMargin}
                                    onChange={value => {
                                        setMinMargin(value)
                                    }}
                                    addonBefore="Min [%]"
                                />
                            </FlexRow>
                            <FlexRow
                                style={{
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <span>Filter coins</span>
                                <StretchedDashString />
                                <Input
                                    ref={coinFilterInputRef}
                                    placeholder="Filter by coin name.."
                                    style={{
                                        minWidth: "20%",
                                        maxWidth: "50%"
                                    }}
                                    onChange={e => {
                                        let _filteredOpportunities = opportunities.filter(o => {
                                            return coinsMap[o.CoinID].Name.toLowerCase().includes(
                                                e.target.value.toLowerCase()
                                            )
                                        })
                                        setFilteredOpportunities(_filteredOpportunities)
                                    }}
                                />
                            </FlexRow>
                            <Row gutter={[10, 10]}>
                                <Col xs={24} md={12}>
                                    <FlexCol
                                        style={{
                                            alignItems: "start",
                                            gap: 1
                                        }}
                                    >
                                        <span>
                                            Filter exchanges (include){" "}
                                            <Tooltip overlay="INCLUDE opportunities that involve (in any direction) any of selected exchanges">
                                                <InfoCircleTwoTone />
                                            </Tooltip>
                                        </span>
                                        <FlexRow
                                            style={{
                                                gap: 2,
                                                width: "100%"
                                            }}
                                        >
                                            <Select
                                                mode="multiple"
                                                style={{
                                                    width: "100%"
                                                }}
                                                allowClear
                                                options={exchanges.map(e => ({
                                                    label: e.Name,
                                                    value: e.Name
                                                }))}
                                                value={filteredExchangesIn}
                                                onChange={value => {
                                                    setFilteredExchangesIn(value)
                                                }}
                                            />
                                            <Button
                                                icon={<ReloadOutlined />}
                                                onClick={() => {
                                                    setFilteredExchangesIn(exchanges.map(e => e.Name))
                                                }}
                                            />
                                        </FlexRow>
                                    </FlexCol>
                                </Col>
                                <Col xs={24} md={12}>
                                    <FlexCol
                                        style={{
                                            alignItems: "start",
                                            gap: 1
                                        }}
                                    >
                                        <span>
                                            Filter exchanges (exclude){" "}
                                            <Tooltip overlay="EXCLUDE opportunities that involve (in any direction) any of selected exchanges">
                                                <InfoCircleTwoTone />
                                            </Tooltip>
                                        </span>
                                        <Select
                                            mode="multiple"
                                            style={{
                                                width: "100%"
                                            }}
                                            allowClear
                                            options={exchanges.map(e => ({
                                                label: e.Name,
                                                value: e.Name
                                            }))}
                                            value={filteredExchangesOut || []}
                                            onChange={value => {
                                                setFilteredExchangesOut(value)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                            </Row>
                        </FlexCol>
                    </FlexCol>
                </Paper>
            </Col>
            <Col xs={24}>
                <Paper>
                    <FlexCol
                        style={{
                            gap: 2
                        }}
                    >
                        <FlexRow
                            style={{
                                gap: 5
                            }}
                        >
                            <span>
                                Total effective <b>profit</b>:
                            </span>
                            <span>${sumEffectiveProfit.toFixed(2)}</span>
                        </FlexRow>
                        <FlexRow
                            style={{
                                gap: 5
                            }}
                        >
                            <span>
                                Total effective <b>opportunities</b>:
                            </span>
                            <span>{nEffectiveOpportunities}</span>
                        </FlexRow>
                    </FlexCol>
                </Paper>
            </Col>
            <Col xs={24}>
                <Paper>
                    <Table
                        columns={columns}
                        dataSource={filteredOpportunities}
                        rowKey={r => getRowKey(r)}
                        size="small"
                        style={{
                            width: "100%"
                        }}
                        sticky={{
                            offsetHeader: 60
                        }}
                        bordered
                        scroll={{
                            x: isMobile ? "100%" : undefined
                        }}
                        expandable={{
                            expandedRowRender: record => {
                                return (
                                    <FlexCol
                                        style={{
                                            gap: 10,
                                            width: isMobile ? "calc(100vw - 70px)" : "100%"
                                        }}
                                    >
                                        <Link
                                            to={`/opportunity?exchange_from_id=${record.ExchangeFromID}&exchange_to_id=${record.ExchangeToID}&coin_id=${record.CoinID}`}
                                        >
                                            <Button block type="default" icon={<EyeOutlined />}>
                                                Monitor in real-time <FundViewOutlined />
                                            </Button>
                                        </Link>
                                        <OpportunityFullInformationWidget
                                            opportunity={record}
                                            exchangesMap={exchangesMap}
                                            coinsMap={coinsMap}
                                            networksMap={networksMap}
                                            wellKnownFeeRatesMap={wellKnowFeeRatesMap}
                                            depositAmount={depositAmount}
                                        />
                                        <Paper
                                            style={{
                                                width: "100%"
                                            }}
                                        >
                                            {/* Real-time charts */}
                                            <UnifiedExchangePairCoinTimeseriesWidget
                                                exchangeOne={exchangesMap[record.ExchangeFromID]}
                                                exchangeTwo={exchangesMap[record.ExchangeToID]}
                                                coinID={record.CoinID}
                                                networkID={record.NetworkID}
                                                depositAmount={depositAmount}
                                                isSimplifiedRender={true}
                                            />
                                        </Paper>
                                    </FlexCol>
                                )
                            },
                            expandedRowKeys: expandedRowKeys,
                            onExpand: (expanded, record) => {
                                if (expanded) {
                                    setExpandedRowKeys([getRowKey(record)])
                                } else {
                                    setExpandedRowKeys([])
                                }
                            },
                            expandRowByClick: false
                        }}
                        pagination={{
                            current: currentPage,
                            onChange: page => {
                                setCurrentPage(page)
                            },
                            defaultPageSize: 25,
                            showSizeChanger: true
                        }}
                    />
                </Paper>
            </Col>
        </Row>
    )
}
