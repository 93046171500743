import { createContext, useContext, useReducer } from "react"
import { Action } from "./common"
import { callAPI } from "../api"

const loadUserInfoFromLocalStorage = (): UserInfo | null => {
    const authToken = loadAuthTokenFromLocalStorage()
    if (authToken === null) {
        return null
    }
    const urlParams = new URLSearchParams(atob(authToken))
    const id = urlParams.get("id")
    const authDate = urlParams.get("auth_date")
    return {
        id: id ? parseInt(id) : 0,
        authDate: authDate ? parseInt(authDate) : 0,
        firstName: urlParams.get("first_name") ?? "",
        lastName: urlParams.get("last_name") ?? "",
        username: urlParams.get("username") ?? "",
        photoUrl: urlParams.get("photo_url") ?? ""
    }
}

const loadAuthTokenFromLocalStorage = (): string | null => {
    return localStorage.getItem("authToken")
}

const saveAuthTokenToLocalStorage = (authToken: string) => {
    localStorage.setItem("authToken", authToken)
}

interface UserInfo {
    id: number
    firstName: string
    lastName: string
    username: string
    authDate: number
    photoUrl: string
}

interface State {
    userInfo: UserInfo | null
    authToken: string | null
}

interface AuthContextType extends State {
    setAuthToken: (authToken: string | null) => void
    callSecureAPI: <T>(
        path: string,
        requestInit?: RequestInit
    ) => Promise<{
        responseHeaders: Headers
        responseObject: T | null
    }>
}

const initialState: State = {
    userInfo: loadUserInfoFromLocalStorage(),
    authToken: loadAuthTokenFromLocalStorage()
}

function authReducer(state: State, action: Action) {
    switch (action.type) {
        case "SET_USER_INFO":
            return {
                ...state,
                userInfo: action.payload
            }
        case "SET_AUTH_TOKEN":
            return {
                ...state,
                authToken: action.payload
            }
        default:
            return state
    }
}

export const AuthContext = createContext<AuthContextType | undefined>(undefined)

export const useAuthContext = () => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error("useAuthContext must be used within a AuthProvider")
    }
    return context
}

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(authReducer, initialState)

    const setAuthToken = (authToken: string | null) => {
        if (authToken === null) {
            localStorage.removeItem("authToken")
        } else {
            saveAuthTokenToLocalStorage(authToken)
        }
        dispatch({
            type: "SET_AUTH_TOKEN",
            payload: authToken
        })
        dispatch({
            type: "SET_USER_INFO",
            payload: loadUserInfoFromLocalStorage()
        })
    }

    const callSecureAPI = async <T,>(
        path: string,
        requestInit?: RequestInit
    ): Promise<{
        responseHeaders: Headers
        responseObject: T | null
    }> => {
        return await callAPI(path, state.authToken, setAuthToken, requestInit)
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                setAuthToken,
                callSecureAPI
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
