import { Row, Col, Typography, Divider } from "antd"
import {
    IExchangeDriver,
    CoinInfo,
    SymbolInfo,
    OrderBook,
    Amount,
    CoinNetworkInfo,
    Price
} from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/types"
import { FC, useEffect, useMemo, useState } from "react"
import { CommonNetworkNameMap } from "../../types"
import { symbolIsUSDTQuoted, isLeveraged } from "./utils"
import bigDecimal from "js-big-decimal"
import { useExecutorContext } from "../../reducers/executorReducer"
import { ARBITRAGE_COLOR_BUY, ARBITRAGE_COLOR_GREEN, ARBITRAGE_COLOR_RED, ARBITRAGE_COLOR_SELL } from "../../constants"
import { FlexCol } from "../../common"
import { gt, truncateFloatUsingDecimalStepHE } from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/utils"

export const OpportunityLiveCalculator: FC<{
    exchangeFromDriver: IExchangeDriver | null
    exchangeToDriver: IExchangeDriver | null
    commonCoinName: string | null
    commonNetworkNameMap: CommonNetworkNameMap
}> = ({ exchangeFromDriver, exchangeToDriver, commonCoinName, commonNetworkNameMap }) => {
    const [exchangeFromCoins, setExchangeFromCoins] = useState<CoinInfo[] | null>(null)
    const [exchangeToCoins, setExchangeToCoins] = useState<CoinInfo[] | null>(null)

    const [exchangeFromCoin, setExchangeFromCoin] = useState<CoinInfo | null>(null)
    const [exchangeToCoin, setExchangeToCoin] = useState<CoinInfo | null>(null)

    // const [exchangeFromSymbol, setExchangeFromSymbol] = useState<SymbolInfo | null>(null)
    // const [exchangeToSymbol, setExchangeToSymbol] = useState<SymbolInfo | null>(null)

    // const [exchangeFromOrderbook, setExchangeFromOrderbook] = useState<OrderBook | null>(null)
    // const [exchangeToOrderbook, setExchangeToOrderbook] = useState<OrderBook | null>(null)

    const [exchangeFromQuoteBalance, setExchangeFromQuoteBalance] = useState<Amount | null>("500")
    const [exchangeToQuoteBalance, setExchangeToQuoteBalance] = useState<Amount | null>("500")

    const [exchangeFromCoinNetworks, setExchangeFromCoinNetworks] = useState<CoinNetworkInfo[] | null>(null)
    const [exchangeToCoinNetworks, setExchangeToCoinNetworks] = useState<CoinNetworkInfo[] | null>(null)

    const [alignedNetworkNames, setAlignedNetworkNames] = useState<
        {
            commonNetworkName: string
            totalFee: Amount | null
        }[]
    >([])

    const [cumulativeProfit, setCumulativeProfit] = useState<number | null>(null)
    const [cumulativeVolume, setCumulativeVolume] = useState<number | null>(null)
    const [cumulativeCostAsk, setCumulativeCostAsk] = useState<number | null>(null)
    const [cumulativeCostBid, setCumulativeCostBid] = useState<number | null>(null)

    const [volumePoints, setVolumePoints] = useState<number[] | null>(null)
    const [costAskPoints, setCostAskPoints] = useState<number[] | null>(null)
    const [costBidPoints, setCostBidPoints] = useState<number[] | null>(null)

    const [effectiveCommonVolume, setEffectiveCommonVolume] = useState<number | null>(null)
    const [effectiveCostFrom, setEffectiveCostFrom] = useState<number | null>(null)
    const [effectiveCostTo, setEffectiveCostTo] = useState<number | null>(null)
    const [effectivePriceFrom, setEffectivePriceFrom] = useState<Price | null>(null)
    const [effectivePriceTo, setEffectivePriceTo] = useState<Price | null>(null)
    const [effectiveOrderIndexFrom, setEffectiveOrderIndexFrom] = useState<number | null>(null)
    const [effectiveOrderIndexTo, setEffectiveOrderIndexTo] = useState<number | null>(null)

    const {
        executorLeftmostOrderbook: exchangeFromOrderbook,
        executorRightmostOrderbook: exchangeToOrderbook,
        executorLinkEnabled,
        setExecutorLeftmostEffectiveVolume,
        setExecutorRightmostEffectiveVolume,
        setExecutorLeftmostEffectiveLimitPrice,
        setExecutorRightmostEffectiveLimitPrice
    } = useExecutorContext()

    // Get coins (exchangeFrom)
    useEffect(() => {
        if (exchangeFromDriver === null) {
            return
        }
        exchangeFromDriver
            .getCoins()
            .then(({ coins }) => {
                setExchangeFromCoins(coins)
            })
            .catch((e: any) => {
                console.warn(`ExecutorOpportunityCore: exchangeFrom (${exchangeFromDriver.name()}): getCoins error:`, e)
            })
    }, [exchangeFromDriver])

    // Get coin networks (exchangeFrom)
    useEffect(() => {
        if (exchangeFromDriver === null || commonCoinName === null || exchangeFromCoins === null) {
            return
        }
        let coin = exchangeFromCoins.find(coin => {
            return coin.coinName === commonCoinName
        })
        if (coin === undefined) {
            console.warn(`ExecutorOpportunityCore: exchangeFrom: coin not found: ${commonCoinName}`)
            return
        }
        setExchangeFromCoin(coin)
        exchangeFromDriver.getCoinNetworks(coin.ownName).then(({ networks }) => {
            setExchangeFromCoinNetworks(networks)
        })
    }, [exchangeFromDriver, exchangeFromCoins, commonCoinName])

    // Get coins (exchangeTo)
    useEffect(() => {
        if (exchangeToDriver === null) {
            return
        }
        exchangeToDriver
            .getCoins()
            .then(({ coins }) => {
                setExchangeToCoins(coins)
            })
            .catch((e: any) => {
                console.warn(`ExecutorOpportunityCore: exchangeTo (${exchangeToDriver.name()}): getCoins error:`, e)
            })
    }, [exchangeToDriver])

    // Get coin networks (exchangeTo)
    useEffect(() => {
        if (exchangeToDriver === null || commonCoinName === null || exchangeToCoins === null) {
            return
        }
        let coin = exchangeToCoins.find(coin => {
            return coin.coinName === commonCoinName
        })
        if (coin === undefined) {
            console.warn(`ExecutorOpportunityCore: exchangeTo: coin not found: ${commonCoinName}`)
            return
        }
        setExchangeToCoin(coin)
        exchangeToDriver.getCoinNetworks(coin.ownName).then(({ networks }) => {
            setExchangeToCoinNetworks(networks)
        })
    }, [exchangeToDriver, exchangeToCoins, commonCoinName])

    // Get quote (USDT) balance (exchangeFrom)
    // useEffect(() => {
    //     if (exchangeFromDriver === null) {
    //         return
    //     }
    //     exchangeFromDriver
    //         .getSpotCoinBalance("USDT")
    //         .then(({ balance }) => {
    //             // setExchangeFromQuoteBalance(balance.freeQty)
    //             setExchangeFromQuoteBalance("5000")
    //         })
    //         .catch((e: any) => {
    //             console.warn(
    //                 `ExecutorOpportunityCore: exchangeFrom (${exchangeFromDriver.name()}): getSpotBalances error:`,
    //                 e
    //             )
    //         })
    // }, [exchangeFromDriver])

    // // Get quote (USDT) balance (exchangeTo)
    // useEffect(() => {
    //     if (exchangeToDriver === null) {
    //         return
    //     }
    //     exchangeToDriver
    //         .getSpotCoinBalance("USDT")
    //         .then(({ balance }) => {
    //             // setExchangeToQuoteBalance(balance.freeQty)
    //             setExchangeToQuoteBalance("5000")
    //         })
    //         .catch((e: any) => {
    //             console.warn(
    //                 `ExecutorOpportunityCore: exchangeTo (${exchangeToDriver.name()}): getSpotBalances error:`,
    //                 e
    //             )
    //         })
    // }, [exchangeToDriver])

    // Aligned networks
    useEffect(() => {
        if (exchangeFromCoinNetworks === null || exchangeToCoinNetworks === null) {
            return
        }
        let _alignedNetworkNames: {
            commonNetworkName: string
            totalFee: Amount | null
        }[] = []
        for (let networkFrom of exchangeFromCoinNetworks) {
            let commonNetworkNameFrom = commonNetworkNameMap[networkFrom.networkName]
            if (commonNetworkNameFrom === undefined) {
                console.warn(`ExecutorOpportunityCore: commonNetworkName not found: ${networkFrom.networkName}`)
                continue
            }
            for (let networkTo of exchangeToCoinNetworks) {
                let commonNetworkNameTo = commonNetworkNameMap[networkTo.networkName]
                if (commonNetworkNameTo === undefined) {
                    console.warn(`ExecutorOpportunityCore: commonNetworkName not found: ${networkTo.networkName}`)
                    continue
                }
                if (commonNetworkNameFrom === commonNetworkNameTo) {
                    console.log(`ExecutorOpportunityCore: candidate for an aligned network: ${commonNetworkNameFrom}`)
                    if (networkFrom.withdrawOK && networkTo.depositOK) {
                        console.log(`ExecutorOpportunityCore: confirmed aligned network: ${commonNetworkNameFrom}`)
                        _alignedNetworkNames.push({
                            commonNetworkName: commonNetworkNameFrom,
                            totalFee: networkFrom.withdrawFeeCoins
                        })
                    }
                }
            }
        }
        // sort in ascending order by totalFee
        _alignedNetworkNames.sort((a, b) => {
            if (a.totalFee === null && b.totalFee === null) {
                return 0
            }
            if (a.totalFee === null) {
                return 1
            }
            if (b.totalFee === null) {
                return -1
            }
            return bigDecimal.compareTo(a.totalFee, b.totalFee)
        })
        setAlignedNetworkNames(_alignedNetworkNames)
    }, [exchangeFromCoinNetworks, exchangeToCoinNetworks])

    // Symbol (exchangeFrom)
    // useEffect(() => {
    //     if (exchangeFromDriver === null || exchangeFromCoin === null) {
    //         return
    //     }
    //     exchangeFromDriver
    //         .getSpotSymbols()
    //         .then(({ symbols }) => {
    //             for (let symbol of symbols) {
    //                 if (!symbolIsUSDTQuoted(symbol) || isLeveraged(symbol)) {
    //                     continue
    //                 }
    //                 if (symbol.baseAsset.toUpperCase() === exchangeFromCoin.ownName.toUpperCase()) {
    //                     setExchangeFromSymbol(symbol)
    //                     return
    //                 }
    //             }
    //             console.warn(`ExecutorOpportunityCore: exchangeFrom: symbol not found`)
    //         })
    //         .catch((e: any) => {
    //             console.warn(
    //                 `ExecutorOpportunityCore: exchangeFrom (${exchangeFromDriver.name()}): getSpotSymbols error:`,
    //                 e
    //             )
    //         })
    // }, [exchangeFromDriver, exchangeFromCoin])

    // // Symbol (exchangeTo)
    // useEffect(() => {
    //     if (exchangeToDriver === null || exchangeToCoin === null) {
    //         return
    //     }
    //     exchangeToDriver
    //         .getSpotSymbols()
    //         .then(({ symbols }) => {
    //             for (let symbol of symbols) {
    //                 if (!symbolIsUSDTQuoted(symbol) || isLeveraged(symbol)) {
    //                     continue
    //                 }
    //                 if (symbol.baseAsset.toUpperCase() === exchangeToCoin.ownName.toUpperCase()) {
    //                     setExchangeToSymbol(symbol)
    //                     return
    //                 }
    //             }
    //             console.warn(`ExecutorOpportunityCore: exchangeTo: symbol not found`)
    //         })
    //         .catch((e: any) => {
    //             console.warn(
    //                 `ExecutorOpportunityCore: exchangeTo (${exchangeToDriver.name()}): getSpotSymbols error:`,
    //                 e
    //             )
    //         })
    // }, [exchangeToDriver, exchangeToCoin])

    // Orderbook (exchangeFrom)
    // useEffect(() => {
    //     if (exchangeFromDriver === null || exchangeFromSymbol === null) {
    //         return
    //     }
    //     exchangeFromDriver
    //         .getSymbolOrderbook(exchangeFromSymbol.ownName)
    //         .then(({ orderbook }) => {
    //             setExchangeFromOrderbook(orderbook)
    //         })
    //         .catch((e: any) => {
    //             console.warn(
    //                 `ExecutorOpportunityCore: exchangeFrom (${exchangeFromDriver.name()}): getSymbolOrderbook error:`,
    //                 e
    //             )
    //         })
    // }, [exchangeFromDriver, exchangeFromSymbol])

    // // Orderbook (exchangeTo)
    // useEffect(() => {
    //     if (exchangeToDriver === null || exchangeToSymbol === null) {
    //         return
    //     }
    //     exchangeToDriver
    //         .getSymbolOrderbook(exchangeToSymbol.ownName)
    //         .then(({ orderbook }) => {
    //             setExchangeToOrderbook(orderbook)
    //         })
    //         .catch((e: any) => {
    //             console.warn(
    //                 `ExecutorOpportunityCore: exchangeTo (${exchangeToDriver.name()}): getSymbolOrderbook error:`,
    //                 e
    //             )
    //         })
    // }, [exchangeToDriver, exchangeToSymbol])

    // calculate profit/cost curve
    useEffect(() => {
        if (exchangeFromOrderbook === null || exchangeToOrderbook === null) {
            return
        }

        // Calculate curves and cumulative values
        let asks = exchangeFromOrderbook.asks
        let bids = exchangeToOrderbook.bids

        let totalProfit: number = 0
        let totalAskCost: number = 0
        let totalBidCost: number = 0
        let totalVolume: number = 0

        let askPricePoints: number[] = []
        let bidPricePoints: number[] = []

        let profitPoints: number[] = []
        let volumePoints: number[] = []
        let costAskPoints: number[] = []
        let costBidPoints: number[] = []

        let askIdx: number = 0
        let bidIdx: number = 0

        let residualAskQty: number = 0
        let residualBidQty: number = 0
        for (; askIdx < asks.length && bidIdx < bids.length; ) {
            let ask = asks[askIdx]
            let bid = bids[bidIdx]
            let askPriceFloat = parseFloat(ask.price)
            let bidPriceFloat = parseFloat(bid.price)

            if (askPriceFloat > bidPriceFloat) {
                break
            }

            let askQtyFloat = parseFloat(ask.amount)
            askQtyFloat -= residualAskQty
            let bidQtyFloat = parseFloat(bid.amount)
            bidQtyFloat -= residualBidQty

            let _profit: number = 0
            let _volume: number = 0
            let _askCost: number = 0
            let _bidCost: number = 0

            if (askQtyFloat > bidQtyFloat) {
                _profit = bidQtyFloat * (bidPriceFloat - askPriceFloat)
                _volume = bidQtyFloat
                _askCost = bidQtyFloat * askPriceFloat
                _bidCost = bidQtyFloat * bidPriceFloat

                totalProfit += _profit
                totalVolume += _volume
                totalAskCost += _askCost
                totalBidCost += _bidCost

                residualAskQty += bidQtyFloat
                residualBidQty = 0
                bidIdx++
            } else if (askQtyFloat < bidQtyFloat) {
                _profit = askQtyFloat * (bidPriceFloat - askPriceFloat)
                _volume = askQtyFloat
                _askCost = askQtyFloat * askPriceFloat
                _bidCost = askQtyFloat * bidPriceFloat

                totalProfit += _profit
                totalVolume += _volume
                totalAskCost += _askCost
                totalBidCost += _bidCost

                residualAskQty = 0
                residualBidQty += askQtyFloat
                askIdx++
            } else {
                _profit = askQtyFloat * (bidPriceFloat - askPriceFloat)
                _volume = askQtyFloat
                _askCost = askQtyFloat * askPriceFloat
                _bidCost = askQtyFloat * bidPriceFloat

                totalProfit += _profit
                totalVolume += _volume
                totalAskCost += _askCost
                totalBidCost += _bidCost

                residualAskQty = 0
                residualBidQty = 0
                askIdx++
                bidIdx++
            }

            askPricePoints.push(askPriceFloat)
            bidPricePoints.push(bidPriceFloat)

            profitPoints.push(totalProfit)
            volumePoints.push(totalVolume)
            costAskPoints.push(totalAskCost)
            costBidPoints.push(totalBidCost)
        }
        setCumulativeProfit(totalProfit)
        setCumulativeVolume(totalVolume)
        setCumulativeCostAsk(totalAskCost)
        setCumulativeCostBid(totalBidCost)

        setVolumePoints(volumePoints)
        setCostAskPoints(costAskPoints)
        setCostBidPoints(costBidPoints)
    }, [exchangeFromOrderbook, exchangeToOrderbook])

    // calculate effective volumes
    useEffect(() => {
        if (
            exchangeFromQuoteBalance === null ||
            exchangeToQuoteBalance === null ||
            volumePoints === null ||
            costAskPoints === null ||
            costBidPoints === null ||
            cumulativeCostAsk === null ||
            cumulativeCostBid === null
        ) {
            return
        }

        let senderMaxCost = parseFloat(exchangeFromQuoteBalance)
        let receiverMaxCost = parseFloat(exchangeToQuoteBalance) * 2 // At leverage ratio 2x, the receiver can afford to spend twice as much as the sender

        if (senderMaxCost > cumulativeCostAsk) {
            senderMaxCost = cumulativeCostAsk
        }

        if (receiverMaxCost > cumulativeCostBid) {
            receiverMaxCost = cumulativeCostBid
        }

        let receiverVolume = 0
        for (let i = 0; i < volumePoints.length; i++) {
            let vol = volumePoints[i]
            let costBid = costBidPoints[i]
            console.log(`calculateEffectiveVolume: step: ${i}: vol=${vol}, costBid=${costBid}`)
            if (costBid > receiverMaxCost) {
                // extrapolate
                let volPrev = volumePoints[i - 1]
                if (volPrev === undefined) {
                    volPrev = 0
                }
                let costBidPrev = costBidPoints[i - 1]
                if (costBidPrev === undefined) {
                    costBidPrev = 0
                }
                let volDiff = vol - volPrev
                let costBidDiff = costBid - costBidPrev
                let volCostSlope = costBidDiff / volDiff
                let residualCost = receiverMaxCost - costBidPrev
                let residualVol = residualCost / volCostSlope
                console.log(
                    `calculateEffectiveVolume: extrapolating: residualVol=${residualVol}, residualCost=${residualCost}`
                )
                receiverVolume = volPrev + residualVol
                break
            }
            receiverVolume = vol
        }
        console.log(`calculateEffectiveVolume: receiverVolume=${receiverVolume}`)

        let senderVolume = 0
        for (let i = 0; i < volumePoints.length; i++) {
            let vol = volumePoints[i]
            let costAsk = costAskPoints[i]
            console.log(`calculateEffectiveVolume: step: ${i}: vol=${vol}, costAsk=${costAsk}`)
            if (costAsk > senderMaxCost) {
                // extrapolate
                let volPrev = volumePoints[i - 1]
                if (volPrev === undefined) {
                    volPrev = 0
                }
                let costAskPrev = costAskPoints[i - 1]
                if (costAskPrev === undefined) {
                    costAskPrev = 0
                }
                let volDiff = vol - volPrev
                let costAskDiff = costAsk - costAskPrev
                let volCostSlope = costAskDiff / volDiff
                let residualCost = senderMaxCost - costAskPrev
                let residualVol = residualCost / volCostSlope
                senderVolume = volPrev + residualVol
                break
            }
            senderVolume = vol
        }
        console.log(`calculateEffectiveVolume: senderVolume=${senderVolume}`)

        let _effectiveCommonVolume = 0
        let _effectiveCostFrom = 0
        let _effectiveCostTo = 0
        if (senderVolume > receiverVolume) {
            _effectiveCommonVolume = receiverVolume
            _effectiveCostTo = receiverMaxCost
            for (let i = 0; i < volumePoints.length; i++) {
                let vol = volumePoints[i]
                let costAsk = costAskPoints[i]
                if (vol > receiverVolume) {
                    // extrapolate
                    let volPrev = volumePoints[i - 1]
                    if (volPrev === undefined) {
                        volPrev = 0
                    }
                    let costAskPrev = costAskPoints[i - 1]
                    if (costAskPrev === undefined) {
                        costAskPrev = 0
                    }
                    let volDiff = vol - volPrev
                    let costAskDiff = costAsk - costAskPrev
                    let volCostSlope = costAskDiff / volDiff
                    let residualVol = receiverVolume - volPrev
                    let residualCost = residualVol * volCostSlope
                    _effectiveCostFrom = costAskPrev + residualCost
                    break
                }
                _effectiveCostFrom = costAsk
            }
        } else if (senderVolume < receiverVolume) {
            _effectiveCommonVolume = senderVolume
            _effectiveCostFrom = senderMaxCost
            for (let i = 0; i < volumePoints.length; i++) {
                let vol = volumePoints[i]
                let costBid = costBidPoints[i]
                if (vol > senderVolume) {
                    // extrapolate
                    let volPrev = volumePoints[i - 1]
                    if (volPrev === undefined) {
                        volPrev = 0
                    }
                    let costBidPrev = costBidPoints[i - 1]
                    if (costBidPrev === undefined) {
                        costBidPrev = 0
                    }
                    let volDiff = vol - volPrev
                    let costBidDiff = costBid - costBidPrev
                    let volCostSlope = costBidDiff / volDiff
                    let residualVol = senderVolume - volPrev
                    let residualCost = residualVol * volCostSlope
                    _effectiveCostTo = costBidPrev + residualCost
                    break
                }
                _effectiveCostTo = costBid
            }
        } else {
            _effectiveCommonVolume = senderVolume
            _effectiveCostFrom = senderMaxCost
            _effectiveCostTo = receiverMaxCost
        }

        console.log(`calculateEffectiveVolume`, {
            _effectiveCommonVolume,
            _effectiveCostFrom,
            _effectiveCostTo
        })

        setEffectiveCommonVolume(_effectiveCommonVolume)
        setEffectiveCostFrom(_effectiveCostFrom)
        setEffectiveCostTo(_effectiveCostTo)
    }, [
        exchangeFromQuoteBalance,
        exchangeToQuoteBalance,
        volumePoints,
        costAskPoints,
        costBidPoints,
        cumulativeCostAsk,
        cumulativeCostBid
    ])

    // calculate effective limit prices
    useEffect(() => {
        if (exchangeFromOrderbook === null || exchangeToOrderbook === null || effectiveCommonVolume === null) {
            return
        }
        let _effectivePriceFrom = "0"
        let cumulatedAskQty = 0
        let _effectiveOrderIndexFrom = 0
        for (let ask of exchangeFromOrderbook.asks) {
            _effectiveOrderIndexFrom++
            let askQty = parseFloat(ask.amount)
            cumulatedAskQty += askQty
            if (cumulatedAskQty > effectiveCommonVolume) {
                _effectivePriceFrom = ask.price
                break
            }
        }

        let _effectivePriceTo = "0"
        let cumulatedBidQty = 0
        let _effectiveOrderIndexTo = 0
        for (let bid of exchangeToOrderbook.bids) {
            _effectiveOrderIndexTo++
            let bidQty = parseFloat(bid.amount)
            cumulatedBidQty += bidQty
            if (cumulatedBidQty > effectiveCommonVolume) {
                _effectivePriceTo = bid.price
                break
            }
        }
        setEffectivePriceFrom(_effectivePriceFrom)
        setEffectivePriceTo(_effectivePriceTo)
        setEffectiveOrderIndexFrom(_effectiveOrderIndexFrom)
        setEffectiveOrderIndexTo(_effectiveOrderIndexTo)
    }, [exchangeFromOrderbook, exchangeToOrderbook, effectiveCommonVolume])

    // linking
    // useEffect(() => {
    //     if (!executorLinkEnabled) {
    //         return
    //     }
    //     if (effectiveCommonVolume !== null) {
    //         setExecutorLeftmostEffectiveVolume(effectiveCommonVolume.toString())
    //         setExecutorRightmostEffectiveVolume(effectiveCommonVolume.toString())
    //     }
    //     if (effectivePriceFrom !== null) {
    //         setExecutorLeftmostEffectiveLimitPrice(effectivePriceFrom)
    //     }
    //     if (effectivePriceTo !== null) {
    //         setExecutorRightmostEffectiveLimitPrice(effectivePriceTo)
    //     }
    // }, [executorLinkEnabled, effectiveCommonVolume, effectivePriceFrom, effectivePriceTo])

    const memoOneCentEquivalentCoinLotStep = useMemo(() => {
        if (exchangeFromOrderbook === null || exchangeToOrderbook === null) {
            return null
        }
        let lowestAsk = exchangeFromOrderbook.asks[0]
        let highestBid = exchangeToOrderbook.bids[0]
        if (lowestAsk === undefined || highestBid === undefined) {
            return null
        }
        let avgPrice = bigDecimal.divide(bigDecimal.add(lowestAsk.price, highestBid.price), 2)
        let amount = "0.01" // 1 cent
        let oneCentEquivalentQty = bigDecimal.divide(amount, avgPrice)
        let coinLotStep = "0"
        if (!oneCentEquivalentQty.startsWith("0.")) {
            return coinLotStep
        }
        let nonZeroDigitIdx = 2 // skip "0."
        for (; nonZeroDigitIdx < oneCentEquivalentQty.length; nonZeroDigitIdx++) {
            let digit = oneCentEquivalentQty[nonZeroDigitIdx]
            if (digit !== "0") {
                let roundedLastDigitIdx = nonZeroDigitIdx
                if (gt(digit, "4")) {
                    roundedLastDigitIdx--
                }
                coinLotStep = oneCentEquivalentQty.slice(0, roundedLastDigitIdx) + "1"
                break
            }
        }
        // console.log(`memoOneCentEquivalentCoinLotStep: coinLotStep=${coinLotStep}`)
        return coinLotStep
    }, [exchangeFromOrderbook, exchangeToOrderbook])

    const memoEffectiveGrossProfit = useMemo(() => {
        if (effectiveCostFrom === null || effectiveCostTo === null) {
            return null
        }
        let effectiveGrossProfit = bigDecimal.subtract(effectiveCostTo, effectiveCostFrom)
        return effectiveGrossProfit
    }, [effectiveCostFrom, effectiveCostTo])

    const memoEffectiveTotalFeeUSDT = useMemo(() => {
        if (alignedNetworkNames.length === 0 || effectivePriceFrom === null) {
            return null
        }
        let effectiveTotalFeeUSDT = "0"
        for (let alignedNetworkName of alignedNetworkNames) {
            if (alignedNetworkName.totalFee === null) {
                continue
            }
            let totalFeeUSDT = bigDecimal.multiply(alignedNetworkName.totalFee, effectivePriceFrom)
            effectiveTotalFeeUSDT = bigDecimal.add(effectiveTotalFeeUSDT, totalFeeUSDT)
        }
        return effectiveTotalFeeUSDT
    }, [alignedNetworkNames, effectivePriceFrom])

    const memoEffectiveNetProfit = useMemo(() => {
        if (memoEffectiveGrossProfit === null) {
            return null
        }
        if (memoEffectiveTotalFeeUSDT === null) {
            return memoEffectiveGrossProfit
        }
        return bigDecimal.subtract(memoEffectiveGrossProfit, memoEffectiveTotalFeeUSDT)
    }, [memoEffectiveGrossProfit, memoEffectiveTotalFeeUSDT, alignedNetworkNames])

    const memoEffectiveNetProfitElement = useMemo(() => {
        if (memoEffectiveNetProfit === null) {
            return "N/A"
        }
        let color = ARBITRAGE_COLOR_GREEN
        let value = memoEffectiveNetProfit
        if (!gt(memoEffectiveNetProfit, 0)) {
            color = ARBITRAGE_COLOR_RED
            value =
                "− $" +
                bigDecimal.getPrettyValue(
                    truncateFloatUsingDecimalStepHE(bigDecimal.abs(memoEffectiveNetProfit), "0.01")
                )
        } else {
            value = "$" + bigDecimal.getPrettyValue(truncateFloatUsingDecimalStepHE(memoEffectiveNetProfit, "0.01"))
        }
        return (
            <b>
                <span
                    style={{
                        fontSize: "2rem",
                        borderTop: "1px solid #ccc",
                        color: gt(memoEffectiveNetProfit, 0) ? ARBITRAGE_COLOR_GREEN : ARBITRAGE_COLOR_RED
                    }}
                >
                    {value}
                </span>
            </b>
        )
    }, [memoEffectiveNetProfit])

    return (
        <Row justify="space-around" gutter={[10, 10]}>
            <Col>
                <FlexCol style={{ gap: 0, alignItems: "center" }}>
                    <span
                        style={{
                            fontSize: "2rem",
                            color: ARBITRAGE_COLOR_BUY
                        }}
                    >
                        {exchangeFromDriver?.name()}
                    </span>
                    <span style={{ fontSize: "1.5rem" }}>
                        {effectiveCostFrom !== null ?
                            bigDecimal.getPrettyValue(
                                truncateFloatUsingDecimalStepHE(effectiveCostFrom.toString(), "0.01")
                            )
                        :   "N/A"}{" "}
                        USDT
                    </span>
                    <span>
                        <b>Limit @ </b> {effectivePriceFrom ?? "N/A"}
                    </span>
                </FlexCol>
            </Col>
            <Col>
                <FlexCol style={{ gap: 0, alignItems: "center" }}>
                    <span style={{ fontSize: "2rem" }}>
                        <b>{commonCoinName}</b>
                    </span>
                    {effectiveCommonVolume !== null ?
                        <span style={{ fontSize: "1.5rem" }}>
                            {bigDecimal.getPrettyValue(
                                truncateFloatUsingDecimalStepHE(
                                    effectiveCommonVolume.toString(),
                                    memoOneCentEquivalentCoinLotStep
                                )
                            )}
                        </span>
                    :   "N/A"}
                </FlexCol>
            </Col>
            <Col>
                <FlexCol style={{ gap: 0, alignItems: "center" }}>
                    <span
                        style={{
                            fontSize: "2rem",
                            color: ARBITRAGE_COLOR_SELL
                        }}
                    >
                        {exchangeToDriver?.name()}
                    </span>
                    <span style={{ fontSize: "1.5rem" }}>
                        {effectiveCostTo !== null ?
                            bigDecimal.getPrettyValue(
                                truncateFloatUsingDecimalStepHE(effectiveCostTo.toString(), "0.01")
                            )
                        :   "N/A"}{" "}
                        USDT
                    </span>
                    <span>
                        <b>Limit @ </b> {effectivePriceTo ?? "N/A"}
                    </span>
                </FlexCol>
            </Col>
            <Divider />
            <Col>
                <ul>
                    <li>Cumulative volume: {cumulativeVolume}</li>
                    <li>
                        Cumulative cost:
                        <ul>
                            <li>Ask: {cumulativeCostAsk}</li>
                            <li>Bid: {cumulativeCostBid}</li>
                        </ul>
                    </li>
                    <li>Cumulative profit: {cumulativeProfit}</li>
                </ul>
            </Col>
            <Col>
                <FlexCol style={{ gap: 0, width: "fit-content", alignItems: "end" }}>
                    <span style={{ fontSize: "1.5rem" }}>
                        $
                        {memoEffectiveGrossProfit !== null ?
                            bigDecimal.getPrettyValue(
                                truncateFloatUsingDecimalStepHE(memoEffectiveGrossProfit.toString(), "0.01")
                            )
                        :   "N/A"}
                    </span>
                    <span style={{ fontSize: "1.5rem" }}>
                        − $
                        {memoEffectiveTotalFeeUSDT !== null ?
                            bigDecimal.getPrettyValue(
                                truncateFloatUsingDecimalStepHE(memoEffectiveTotalFeeUSDT.toString(), "0.01")
                            )
                        :   "N/A"}
                    </span>
                    {memoEffectiveNetProfitElement}
                </FlexCol>
            </Col>
        </Row>
    )
}
