import { FullscreenExitOutlined, ReloadOutlined } from "@ant-design/icons"
import {
    message,
    Spin,
    Row,
    Col,
    Space,
    Button,
    Select,
    Tooltip,
    Switch,
    Popconfirm,
    message as antdMessage,
    Alert
} from "antd"
import { BaseOptionType } from "antd/es/select"
import {
    IExchangeDriver,
    SymbolInfo,
    CoinInfo,
    CoinSpotBalance,
    OrderBook,
    Price,
    Amount,
    OrderSide,
    OrderType,
    OrderTimeInForce
} from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/types"
import { gt, truncateFloatUsingDecimalStepHE } from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/utils"
import bigDecimal from "js-big-decimal"
import { FC, useState, useRef, useCallback, useEffect, useMemo, ReactElement } from "react"
import { FlexRow, FlexCol } from "../../common"
import { colorHexToRGBA, ARBITRAGE_COLOR_BUY, ARBITRAGE_COLOR_SELL } from "../../constants"
import { useExecutorContext } from "../../reducers/executorReducer"
import { formattedNumberToFixedWithoutTrailingZeros } from "../../utils"
import { _OrdersHistoryList, OrderbookWidget, OrderHistoryMode, OrderQtyInput } from "./ordersCommon"
import { USDT_DECIMAL_STEP, isLeveraged, symbolIsUSDTQuoted } from "./utils"
import { ExecutorControllerSide } from "../../types"

const SpotOrdersHistoryList: FC<{
    exchangeDriver: IExchangeDriver | null
    symbol: SymbolInfo | null
    loadBalances: () => Promise<void>
}> = ({ exchangeDriver, symbol, loadBalances }) => {
    return (
        <_OrdersHistoryList
            exchangeDriver={exchangeDriver}
            symbol={symbol}
            mode={OrderHistoryMode.Spot}
            loadBalances={loadBalances}
        />
    )
}

export const SpotOrderController: FC<{
    exchangeDriver: IExchangeDriver | null
    executorControllerSide: ExecutorControllerSide
}> = ({ exchangeDriver, executorControllerSide }) => {
    const [coins, setCoins] = useState<CoinInfo[] | null>(null)
    const [symbols, setSymbols] = useState<SymbolInfo[] | null>(null)
    const [balances, setBalances] = useState<CoinSpotBalance[] | null>(null)

    const [selectedCoinName, setSelectedCoinName] = useState<string | null>(null)
    const [currentSymbol, setCurrentSymbol] = useState<SymbolInfo | null>(null)

    const [currentQuoteBalance, setCurrentQuoteBalance] = useState<CoinSpotBalance | null>(null)
    const [currentBaseBalance, setCurrentBaseBalance] = useState<CoinSpotBalance | null>(null)

    const [orderbook, setOrderbook] = useState<OrderBook | null>(null)
    const [limitPrice, setLimitPrice] = useState<Price | null>(null)

    const [baseQty, setBaseQty] = useState<Amount | null>(null)
    const [quoteQty, setQuoteQty] = useState<Amount | null>(null)

    const [orderSide, setOrderSide] = useState<OrderSide>(OrderSide.Buy)
    const [orderType, setOrderType] = useState<OrderType>(OrderType.Limit)
    const [orderTimeInForce, setOrderTimeInForce] = useState<OrderTimeInForce>(OrderTimeInForce.GoodTilCancelled)

    const [clockIsEnabled, setClockIsEnabled] = useState<boolean>(true)
    const [clockValue, setClockValue] = useState<number>(0)
    const orderbookMutexRef = useRef<boolean>(false)

    const {
        executorLinkEnabled,
        executorCommonCoin,
        executorLeftmostEffectiveVolume,
        executorLeftmostEffectiveLimitPrice,
        executorRightmostEffectiveVolume,
        executorRightmostEffectiveLimitPrice,
        setExecutorCommonCoin,
        setExecutorLeftmostOrderbook,
        setExecutorRightmostOrderbook
    } = useExecutorContext()

    const loadCoins = useCallback(async () => {
        if (exchangeDriver === null) {
            return
        }
        try {
            let { coins } = await exchangeDriver.getCoins()
            let _coins: CoinInfo[] = []
            for (let coin of coins) {
                if (isLeveraged(coin)) {
                    continue
                }
                _coins.push(coin)
            }
            setCoins(_coins)
        } catch (e: any) {
            console.log(`SpotOrderController: ${exchangeDriver.name()}: getCoins error`, e)
        }
    }, [exchangeDriver])

    const loadBalances = useCallback(async () => {
        if (exchangeDriver === null) {
            return
        }
        try {
            let { balances } = await exchangeDriver.getSpotBalances()
            setBalances(balances)
        } catch (e: any) {
            console.log(`SpotOrderController: ${exchangeDriver.name()}: getSpotBalances error`, e)
        }
    }, [exchangeDriver])

    const loadSymbols = useCallback(async () => {
        if (exchangeDriver === null) {
            return
        }
        try {
            let { symbols } = await exchangeDriver.getSpotSymbols()
            let unleveragedSymbols = symbols.filter(s => !isLeveraged(s))
            let unleveragedUsdtSymbols = unleveragedSymbols.filter(s => symbolIsUSDTQuoted(s))
            setSymbols(unleveragedUsdtSymbols)
        } catch (e: any) {
            console.log(`SpotOrderController: ${exchangeDriver.name()}: getSymbols error`, e)
        }
    }, [exchangeDriver])

    const loadQuoteCoinBalance = useCallback(async () => {
        if (exchangeDriver === null) {
            return
        }
        try {
            let { balance } = await exchangeDriver.getSpotCoinBalance("USDT")
            setCurrentQuoteBalance(balance)
        } catch (e: any) {
            console.warn(`SpotOrderController: ${exchangeDriver.name()}: loadQuoteCoinBalance error`, e)
        }
    }, [exchangeDriver])

    const loadBaseCoinBalance = useCallback(async () => {
        if (exchangeDriver === null || selectedCoinName === null) {
            return
        }
        try {
            let { balance } = await exchangeDriver.getSpotCoinBalance(selectedCoinName)
            setCurrentBaseBalance(balance)
        } catch (e: any) {
            console.warn(`SpotOrderController: ${exchangeDriver.name()}: loadBaseCoinBalance error`, e)
        }
    }, [exchangeDriver, selectedCoinName])

    // Load coins
    useEffect(() => {
        loadCoins()
    }, [exchangeDriver])

    // Load balances
    useEffect(() => {
        loadBalances()
    }, [exchangeDriver])

    // Load symbols
    useEffect(() => {
        loadSymbols()
    }, [exchangeDriver])

    // Set the current coin symbol
    useEffect(() => {
        if (exchangeDriver === null || symbols === null || selectedCoinName === null) {
            setCurrentSymbol(null)
            return
        }
        for (let symbol of symbols) {
            if (isLeveraged(symbol) || !symbolIsUSDTQuoted(symbol)) {
                continue
            }
            if (symbol.baseAsset.toUpperCase() === selectedCoinName.toUpperCase()) {
                setCurrentSymbol(symbol)
                return
            }
        }
        setCurrentSymbol(null)
    }, [exchangeDriver, symbols, selectedCoinName])

    // Current quote (USDT) balance
    useEffect(() => {
        loadQuoteCoinBalance()
    }, [exchangeDriver, balances, selectedCoinName])

    // Current base  (COIN) balance
    useEffect(() => {
        loadBaseCoinBalance()
    }, [exchangeDriver, balances, selectedCoinName])

    // Current Symbol
    useEffect(() => {
        if (symbols === null || selectedCoinName === null || exchangeDriver === null) {
            setCurrentSymbol(null)
            return
        }
        for (let symbol of symbols) {
            if (symbol.baseAsset === selectedCoinName) {
                setCurrentSymbol(symbol)
                return
            }
        }
        setCurrentSymbol(null)
    }, [exchangeDriver, selectedCoinName, symbols])

    // Linking
    useEffect(() => {
        if (!executorLinkEnabled) {
            return
        }
        if (executorCommonCoin !== null) {
            setSelectedCoinName(executorCommonCoin)
        }
        if (executorControllerSide === ExecutorControllerSide.Leftmost) {
            if (executorLeftmostEffectiveVolume) {
                setBaseQty(executorLeftmostEffectiveVolume)
            }
            if (executorLeftmostEffectiveLimitPrice) {
                setLimitPrice(executorLeftmostEffectiveLimitPrice)
            }
        } else if (executorControllerSide === ExecutorControllerSide.Rightmost) {
            if (executorRightmostEffectiveVolume) {
                setBaseQty(executorRightmostEffectiveVolume)
            }
            if (executorRightmostEffectiveLimitPrice) {
                setLimitPrice(executorRightmostEffectiveLimitPrice)
            }
        }
    }, [
        executorLinkEnabled,
        executorCommonCoin,
        executorControllerSide,
        executorLeftmostEffectiveLimitPrice,
        executorLeftmostEffectiveVolume,
        executorRightmostEffectiveLimitPrice,
        executorRightmostEffectiveVolume
    ])

    // Orderbook
    useEffect(() => {
        if (exchangeDriver === null || currentSymbol === null) {
            return
        }
        // console.debug(`SpotOrderController: clock tick received: ${clockValue}: ${orderbookMutexRef.current}`)
        if (orderbookMutexRef.current) {
            return
        }
        // console.debug(`SpotOrderController: ${exchangeDriver.name()}: getSymbolOrderbook: ${currentSymbol.ownName}`)
        orderbookMutexRef.current = true
        let t0 = Date.now()
        exchangeDriver
            .getSymbolOrderbook(currentSymbol.ownName)
            .then(({ orderbook }) => {
                setOrderbook(orderbook)
                if (executorControllerSide === ExecutorControllerSide.Leftmost) {
                    setExecutorLeftmostOrderbook(orderbook)
                } else if (executorControllerSide === ExecutorControllerSide.Rightmost) {
                    setExecutorRightmostOrderbook(orderbook)
                }
            })
            .finally(() => {
                let t1 = Date.now()
                let dT = t1 - t0
                let remainingTime = 1000 - dT
                // console.debug(
                //     `SpotOrderController: ${exchangeDriver.name()}: ${
                //         currentSymbol.ownName
                //     }'s orderbook: waiting for ${remainingTime} ms`
                // )
                setTimeout(() => {
                    orderbookMutexRef.current = false
                }, remainingTime)
            })
    }, [exchangeDriver, currentSymbol, clockValue])

    // Clock
    useEffect(() => {
        if (exchangeDriver === null || currentSymbol === null) {
            return
        }
        if (!clockIsEnabled) {
            return
        }
        let interval = setInterval(() => {
            let t = Date.now()
            setClockValue(t)
        }, 500)
        return () => {
            clearInterval(interval)
        }
    }, [exchangeDriver, currentSymbol, clockIsEnabled])

    const memoCanOrder = useMemo(() => {
        if (currentSymbol === null) {
            return false
        }
        if (orderSide === OrderSide.Buy) {
            // BUY
            return currentQuoteBalance !== null && gt(currentQuoteBalance.freeQty, 0)
        } else if (orderSide === OrderSide.Sell) {
            // SELL
            return currentBaseBalance !== null && gt(currentBaseBalance.freeQty, 0)
        }
    }, [currentBaseBalance, currentQuoteBalance, currentSymbol, orderSide])

    const memoCoinsOptions = useMemo((): BaseOptionType[] => {
        if (coins === null || balances === null || symbols === null) {
            return []
        }
        // console.debug(`SpotOrderController: getMemoCoinsOptions: coinNetworks: `, coins)
        let preOptions: {
            coinName: string
            coinBalance: Amount
            symbolExists: boolean
            symbolIsAvailable: boolean
        }[] = []
        for (let coin of coins) {
            let balance: CoinSpotBalance | undefined = balances.find(balance => {
                return balance.coinName === coin.coinName
            })
            let symbol: SymbolInfo | undefined = symbols.find(symbol => {
                return symbol.baseAsset === coin.coinName
            })
            let coinBalance = "0"
            if (balance !== undefined) {
                coinBalance = bigDecimal.add(balance.freeQty, balance.lockedQty)
            }
            preOptions.push({
                coinName: coin.coinName,
                coinBalance,
                symbolExists: symbol !== undefined,
                symbolIsAvailable: symbol?.isAvailable ?? false
            })
        }
        preOptions.sort((a, b) => {
            if (a.symbolExists && !b.symbolExists) {
                return -1
            } else if (!a.symbolExists && b.symbolExists) {
                return 1
            } else {
                if (a.symbolIsAvailable && !b.symbolIsAvailable) {
                    return -1
                } else if (!a.symbolIsAvailable && b.symbolIsAvailable) {
                    return 1
                } else {
                    let i = bigDecimal.compareTo(a.coinBalance, b.coinBalance)
                    if (i !== 0) {
                        return -i // descending
                    } else {
                        return a.coinName.localeCompare(b.coinName)
                    }
                }
            }
        })
        let options: BaseOptionType[] = []
        for (let preOption of preOptions) {
            if (!preOption.symbolExists) {
                continue
            }
            let children: ReactElement[] = [<>{preOption.coinName}</>]
            if (gt(preOption.coinBalance, 0)) {
                children.push(<span>{formattedNumberToFixedWithoutTrailingZeros(preOption.coinBalance)}</span>)
            }
            options.push({
                label: <FlexRow style={{ justifyContent: "space-between", marginRight: "1.5rem" }}>{children}</FlexRow>,
                value: preOption.coinName,
                disabled: !preOption.symbolExists || !preOption.symbolIsAvailable
            })
        }
        return options
    }, [coins, symbols, balances])

    const memoQuoteBalanceElement = useMemo(() => {
        if (currentQuoteBalance === null) {
            return <>N/A</>
        }
        let freeChildren: ReactElement[] = [
            <Tooltip overlay={currentQuoteBalance.freeQty}>
                <>{formattedNumberToFixedWithoutTrailingZeros(bigDecimal.round(currentQuoteBalance.freeQty, 2))}</>
            </Tooltip>
        ]
        let lockedChildren: ReactElement[] = []
        if (currentQuoteBalance.lockedQty !== null && gt(currentQuoteBalance.lockedQty, 0)) {
            lockedChildren.push(
                <Tooltip overlay={currentQuoteBalance.lockedQty}>
                    <>
                        {formattedNumberToFixedWithoutTrailingZeros(bigDecimal.round(currentQuoteBalance.lockedQty, 2))}
                    </>
                </Tooltip>
            )
        }
        return (
            <ul style={{ margin: 0 }}>
                <li>Free: {freeChildren}</li>
                {lockedChildren.length > 0 && <li>Lock: {lockedChildren}</li>}
            </ul>
        )
    }, [currentQuoteBalance])

    const memoBaseBalanceElement = useMemo(() => {
        if (currentBaseBalance === null || currentSymbol === null) {
            return <>N/A</>
        }
        // console.log(`SpotOrderController: memoBaseBalanceElement: `, currentBaseBalance, currentSymbol)
        // calculate avg price for approximate USDT value of coins' balances
        let avgPrice: Price | null = null
        if (orderbook !== null) {
            let hb = orderbook.bids[0]
            let la = orderbook.asks[0]
            if (hb !== undefined && la !== undefined) {
                avgPrice = bigDecimal.divide(bigDecimal.add(hb.price, la.price), 2)
            }
        }

        let freeChildren: ReactElement[] = []
        let lockedChildren: ReactElement[] = []

        // Free quantity in coins
        let formattedfreeQty = currentBaseBalance.freeQty
        if (currentSymbol.lotStep !== null) {
            formattedfreeQty = truncateFloatUsingDecimalStepHE(formattedfreeQty, currentSymbol.lotStep)
        }
        freeChildren.push(
            <Tooltip overlay={currentBaseBalance.freeQty}>
                <>{bigDecimal.getPrettyValue(formattedNumberToFixedWithoutTrailingZeros(formattedfreeQty))}</>
            </Tooltip>
        )

        // Free quantity in USDT (approximation)
        let freeQtyUSDT: Amount | null = null
        if (avgPrice !== null) {
            freeQtyUSDT = bigDecimal.multiply(currentBaseBalance.freeQty, avgPrice)
        }
        if (freeQtyUSDT !== null && gt(freeQtyUSDT, 0)) {
            let formattedFreeQtyUSDT = formattedNumberToFixedWithoutTrailingZeros(bigDecimal.round(freeQtyUSDT, 2))
            freeChildren.push(<span> (~ {bigDecimal.getPrettyValue(formattedFreeQtyUSDT)} USDT)</span>)
        }

        if (currentBaseBalance.lockedQty !== null && gt(currentBaseBalance.lockedQty, 0)) {
            // Locked quantity in coins
            let formattedLockedQty = currentBaseBalance.lockedQty
            if (currentSymbol.lotStep !== null) {
                formattedLockedQty = truncateFloatUsingDecimalStepHE(formattedLockedQty, currentSymbol.lotStep)
            }
            lockedChildren.push(
                <Tooltip overlay={currentBaseBalance.lockedQty}>
                    <>{bigDecimal.getPrettyValue(formattedNumberToFixedWithoutTrailingZeros(formattedLockedQty))} </>
                </Tooltip>
            )
            // Locked quantity in USDT (approximation)
            let lockedQtyUSDT: Amount | null = null
            if (avgPrice !== null) {
                lockedQtyUSDT = bigDecimal.multiply(currentBaseBalance.lockedQty, avgPrice)
            }
            if (lockedQtyUSDT !== null && gt(lockedQtyUSDT, 0)) {
                let formattedLockedQtyUSDT = formattedNumberToFixedWithoutTrailingZeros(
                    bigDecimal.round(lockedQtyUSDT, 2)
                )
                lockedChildren.push(<span> (~ {bigDecimal.getPrettyValue(formattedLockedQtyUSDT)} USDT)</span>)
            }
        }
        return (
            <ul style={{ margin: 0 }}>
                <li>Free: {freeChildren}</li>
                {lockedChildren.length > 0 && <li>Lock: {lockedChildren}</li>}
            </ul>
        )
    }, [currentBaseBalance, orderbook, currentSymbol])

    const placeOrder = useCallback(async () => {
        if (exchangeDriver === null || currentSymbol === null) {
            return
        }
        let _baseQty = baseQty
        let _quoteQty = quoteQty
        let _price = limitPrice
        let _orderTimeInForce: OrderTimeInForce | null = orderTimeInForce
        if (orderType === OrderType.Market) {
            _price = null
            _orderTimeInForce = null
            if (orderSide === OrderSide.Buy) {
                _baseQty = null
            } else {
                _quoteQty = null
            }
        } else {
            if (_price === null) {
                message.warning("MarginOrdercontroller: could not place a LIMIT order with null price")
                return
            }
            _quoteQty = null
        }
        if (_price !== null) {
            if (currentSymbol.priceMin !== null && _price < currentSymbol.priceMin) {
                message.warning(`MarginOrdercontroller: could not place an order with price less than priceMin`)
                return
            }
            if (currentSymbol.priceMax !== null && _price > currentSymbol.priceMax) {
                message.warning(`MarginOrdercontroller: could not place an order with price more than priceMax`)
                return
            }
            _price = truncateFloatUsingDecimalStepHE(_price, currentSymbol.priceTick)
        }

        console.log(
            `${exchangeDriver.name()}: placing order`,
            currentSymbol.ownName,
            orderSide,
            orderType,
            _orderTimeInForce,
            _baseQty,
            _quoteQty,
            _price
        )
        try {
            let order = await exchangeDriver.placeSpotOrder({
                symbolName: currentSymbol.ownName,
                orderSide: orderSide,
                orderType: orderType,
                timeInForce: _orderTimeInForce,
                price: _price,
                baseQty: _baseQty,
                quoteQty: _quoteQty
            })
            let msg = `${exchangeDriver.name()}: successfully placed order #${order.orderId}`
            console.log(msg)
            antdMessage.success(msg)
            await loadSymbols()
            await loadBalances()
        } catch (e: any) {
            let msg = `${exchangeDriver.name()}: could not place order: ${e.message}`
            console.error(msg)
            antdMessage.error(msg)
            await loadSymbols()
            await loadBalances()
        }
    }, [
        exchangeDriver,
        selectedCoinName,
        currentSymbol,
        orderSide,
        orderType,
        orderTimeInForce,
        limitPrice,
        baseQty,
        quoteQty
    ])

    const memoOrderHistory = useMemo(() => {
        if (exchangeDriver === null || currentSymbol === null) {
            return null
        }
        return (
            <SpotOrdersHistoryList
                exchangeDriver={exchangeDriver}
                symbol={currentSymbol}
                key={currentSymbol.ownName}
                loadBalances={loadBalances}
            />
        )
    }, [exchangeDriver, currentSymbol])

    const memoOrderbookWidget = useMemo(() => {
        if (currentSymbol === null || orderbook === null) {
            return null
        }
        return (
            <OrderbookWidget
                symbol={currentSymbol}
                orderbook={orderbook}
                orderSide={orderSide}
                quoteQty={quoteQty}
                baseQty={baseQty}
                setQuoteQty={setQuoteQty}
                setBaseQty={setBaseQty}
                setLimitPrice={setLimitPrice}
                colorAsks={ARBITRAGE_COLOR_BUY}
                colorBids={ARBITRAGE_COLOR_SELL}
            />
        )
    }, [currentSymbol, orderbook, orderSide, quoteQty, baseQty])

    const memoOrderPopconfirm = useMemo((): ReactElement => {
        if (exchangeDriver === null || currentSymbol === null) {
            return <></>
        }
        let children: ReactElement[] = []
        if (orderType === OrderType.Limit) {
            children.push(
                <>
                    <b>
                        {orderType} {orderSide}
                    </b>{" "}
                    ({orderTimeInForce}) of <b>{baseQty}</b> {currentSymbol.baseAsset} at limit price of{" "}
                    <b>{limitPrice}</b> USDT
                </>
            )
        } else if (orderType === OrderType.Market) {
            if (orderSide === OrderSide.Buy) {
                children.push(
                    <>
                        <b>
                            {orderType} {orderSide}
                        </b>{" "}
                        of <b>{quoteQty}</b> USDT worth of {currentSymbol.baseAsset}
                    </>
                )
            } else if (orderSide === OrderSide.Sell) {
                children.push(
                    <>
                        <b>
                            {orderType} {orderSide}
                        </b>{" "}
                        of <b>{baseQty}</b> {currentSymbol.baseAsset}
                    </>
                )
            }
        }
        return <>{children}</>
    }, [exchangeDriver, currentSymbol, orderSide, orderType, orderTimeInForce, limitPrice, baseQty, quoteQty])

    if (exchangeDriver === null || coins === null || balances === null || symbols === null) {
        return (
            <FlexCol
                style={{
                    minHeight: 200,
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Spin size="large" />
            </FlexCol>
        )
    }

    return (
        <div
            style={{
                backgroundColor:
                    orderSide === OrderSide.Buy ?
                        colorHexToRGBA(ARBITRAGE_COLOR_BUY, 0.1)
                    :   colorHexToRGBA(ARBITRAGE_COLOR_SELL, 0.1),
                padding: 10
            }}
        >
            <Row
                gutter={[10, 5]}
                style={{
                    height: "100%"
                }}
            >
                {/* Side Selector */}
                <Col xs={24}>
                    <Space.Compact
                        style={{
                            width: "100%",
                            justifyContent: "center",
                            marginTop: 5,
                            marginBottom: 5
                        }}
                    >
                        <Button
                            block
                            type={orderSide === OrderSide.Buy ? "primary" : "default"}
                            style={{
                                backgroundColor: orderSide === OrderSide.Buy ? ARBITRAGE_COLOR_BUY : undefined
                            }}
                            onClick={() => {
                                setOrderSide(OrderSide.Buy)
                            }}
                        >
                            BUY
                        </Button>
                        <Button
                            block
                            type={orderSide === OrderSide.Sell ? "primary" : "default"}
                            style={{
                                backgroundColor: orderSide === OrderSide.Sell ? ARBITRAGE_COLOR_SELL : undefined
                            }}
                            onClick={() => {
                                setOrderSide(OrderSide.Sell)
                            }}
                        >
                            SELL
                        </Button>
                    </Space.Compact>
                </Col>
                {/* Coin Selector */}
                <Col xs={24}>
                    <Select
                        style={{
                            width: "100%"
                        }}
                        loading={coins === null}
                        options={memoCoinsOptions}
                        value={selectedCoinName}
                        onChange={value => {
                            if (value === undefined) {
                                return
                            }
                            setSelectedCoinName(value)
                            if (executorLinkEnabled) {
                                setExecutorCommonCoin(value)
                            }
                        }}
                        placeholder="Select symbol"
                        showSearch
                        // suffixIcon={<> / USDT</>}
                    />
                </Col>
                {currentSymbol !== null && (
                    <Col xs={12}>
                        {currentSymbol.isAvailable ?
                            <FlexCol
                                style={{
                                    height: "100%"
                                }}
                            >
                                <FlexRow
                                    style={{
                                        gap: 3,
                                        alignItems: "center",
                                        width: "100%",
                                        justifyContent: "end",
                                        marginBottom: -44,
                                        zIndex: 10
                                    }}
                                >
                                    <Tooltip overlay="Consolidate balances on SPOT account">
                                        <Button
                                            icon={<FullscreenExitOutlined />}
                                            onClick={async () => {
                                                try {
                                                    await exchangeDriver.consolidateSpotBalances()
                                                    setBalances(null)
                                                    setSymbols(null)
                                                    await loadBalances()
                                                    await loadSymbols()
                                                    message.success("Balances consolidated!")
                                                } catch (e: any) {
                                                    message.info(`Could not consolidate balances: ${e.toString()}`)
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip overlay="Refresh balances">
                                        <Button
                                            icon={<ReloadOutlined />}
                                            onClick={async () => {
                                                setBalances(null)
                                                setSymbols(null)
                                                await loadBalances()
                                                await loadSymbols()
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip overlay="Orderbook auto-refresh">
                                        <Switch
                                            checked={clockIsEnabled}
                                            onChange={checked => {
                                                setClockIsEnabled(checked)
                                            }}
                                        />
                                    </Tooltip>
                                </FlexRow>
                                {/* Quote and Base balances */}
                                <Row justify="space-between" align="top">
                                    <Col>
                                        <FlexCol style={{ gap: 0, marginLeft: 5 }}>
                                            <span>
                                                <b>USDT:</b> {memoQuoteBalanceElement}
                                            </span>
                                            <span>
                                                <b>{currentSymbol.baseAsset}:</b> {memoBaseBalanceElement}
                                            </span>
                                        </FlexCol>
                                    </Col>
                                </Row>
                                <Row gutter={[5, 5]}>
                                    {/* OrderType & Price (if LIMIT) */}
                                    <Col xs={12} xl={"auto"}>
                                        <Select
                                            style={{
                                                width: "100%"
                                            }}
                                            options={[{ value: OrderType.Limit }, { value: OrderType.Market }]}
                                            value={orderType}
                                            onChange={value => {
                                                setOrderType(value)
                                            }}
                                        />
                                    </Col>
                                    <Col xs={12} xl={"auto"}>
                                        <Select
                                            style={{
                                                width: "100%"
                                            }}
                                            options={[
                                                { value: OrderTimeInForce.GoodTilCancelled },
                                                { value: OrderTimeInForce.ImmediateOrCancel },
                                                { value: OrderTimeInForce.FillOrKill }
                                            ]}
                                            value={orderTimeInForce}
                                            onChange={value => {
                                                setOrderTimeInForce(value)
                                            }}
                                            disabled={orderType === OrderType.Market}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <OrderQtyInput
                                            propValue={limitPrice}
                                            suffixValue={null}
                                            stepValue={currentSymbol.priceTick}
                                            prefix="Price"
                                            onChange={(value: string | null) => {
                                                if (value === null) {
                                                    setLimitPrice(null)
                                                    return
                                                }
                                                setLimitPrice(
                                                    formattedNumberToFixedWithoutTrailingZeros(
                                                        truncateFloatUsingDecimalStepHE(value, currentSymbol.priceTick)
                                                    )
                                                )
                                            }}
                                            subscript={
                                                <i style={{ fontSize: "0.8rem" }}>
                                                    Price step:{" "}
                                                    {currentSymbol.priceTick ?
                                                        formattedNumberToFixedWithoutTrailingZeros(
                                                            currentSymbol.priceTick
                                                        )
                                                    :   "N/A"}
                                                </i>
                                            }
                                            disabled={orderType === OrderType.Market}
                                        />
                                    </Col>
                                    {/* Order quantities (quote and base) */}
                                    <Col xs={24}>
                                        <OrderQtyInput
                                            propValue={quoteQty}
                                            suffixValue={
                                                orderSide === OrderSide.Buy && currentQuoteBalance !== null ?
                                                    currentQuoteBalance.freeQty
                                                :   null
                                            }
                                            stepValue={USDT_DECIMAL_STEP}
                                            prefix="USDT"
                                            onChange={(value: string | null) => {
                                                if (value === null) {
                                                    setQuoteQty(null)
                                                    return
                                                }
                                                setQuoteQty(
                                                    formattedNumberToFixedWithoutTrailingZeros(
                                                        truncateFloatUsingDecimalStepHE(value, USDT_DECIMAL_STEP)
                                                    )
                                                )
                                                if (limitPrice !== null && currentSymbol !== null) {
                                                    let _baseQty = bigDecimal.divide(value, limitPrice)
                                                    _baseQty = formattedNumberToFixedWithoutTrailingZeros(
                                                        truncateFloatUsingDecimalStepHE(_baseQty, currentSymbol.lotStep)
                                                    )
                                                    setBaseQty(_baseQty)
                                                } else {
                                                    setBaseQty(null)
                                                }
                                            }}
                                            subscript={
                                                <i style={{ fontSize: "0.8rem" }}>
                                                    Min ({orderType}):{" "}
                                                    {orderType === OrderType.Limit ?
                                                        currentSymbol.limitNotionalMin ?
                                                            formattedNumberToFixedWithoutTrailingZeros(
                                                                currentSymbol.limitNotionalMin
                                                            )
                                                        :   "N/A"
                                                    : currentSymbol.marketNotionalMin ?
                                                        formattedNumberToFixedWithoutTrailingZeros(
                                                            currentSymbol.marketNotionalMin
                                                        )
                                                    :   "N/A"}
                                                </i>
                                            }
                                            disabled={orderType === OrderType.Market && orderSide === OrderSide.Sell}
                                        />
                                    </Col>
                                    <Col xs={24}>
                                        <OrderQtyInput
                                            propValue={baseQty}
                                            suffixValue={
                                                orderSide === OrderSide.Sell && currentBaseBalance !== null ?
                                                    currentBaseBalance.freeQty
                                                :   null
                                            }
                                            stepValue={currentSymbol.lotStep}
                                            prefix={currentSymbol.baseAsset}
                                            onChange={(value: string | null) => {
                                                if (value === null) {
                                                    setBaseQty(null)
                                                    return
                                                }
                                                value = formattedNumberToFixedWithoutTrailingZeros(
                                                    truncateFloatUsingDecimalStepHE(value, currentSymbol.lotStep)
                                                )
                                                setBaseQty(value)
                                                if (limitPrice !== null && currentSymbol !== null) {
                                                    let _quoteQty = bigDecimal.multiply(value, limitPrice)
                                                    setQuoteQty(
                                                        formattedNumberToFixedWithoutTrailingZeros(
                                                            truncateFloatUsingDecimalStepHE(
                                                                _quoteQty,
                                                                USDT_DECIMAL_STEP
                                                            )
                                                        )
                                                    )
                                                } else {
                                                    setQuoteQty(null)
                                                }
                                            }}
                                            subscript={
                                                <FlexRow>
                                                    <i style={{ fontSize: "0.8rem" }}>
                                                        Min:{" "}
                                                        {currentSymbol.lotMin ?
                                                            formattedNumberToFixedWithoutTrailingZeros(
                                                                currentSymbol.lotMin
                                                            )
                                                        :   "N/A"}
                                                    </i>
                                                    <i style={{ fontSize: "0.8rem" }}>
                                                        Lot step:{" "}
                                                        {currentSymbol.lotStep ?
                                                            formattedNumberToFixedWithoutTrailingZeros(
                                                                currentSymbol.lotStep
                                                            )
                                                        :   "N/A"}
                                                    </i>
                                                </FlexRow>
                                            }
                                            disabled={orderType === OrderType.Market && orderSide === OrderSide.Buy}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={[5, 5]} style={{ flexGrow: 3 }} align={"bottom"}>
                                    <Col xs={24} order={orderSide === OrderSide.Sell ? 2 : 1}>
                                        <Popconfirm
                                            disabled={memoCanOrder === false}
                                            title={
                                                <>
                                                    Are you sure to place this order:
                                                    <br />
                                                    <br />
                                                    {memoOrderPopconfirm}?
                                                </>
                                            }
                                            onConfirm={placeOrder}
                                        >
                                            <Button
                                                block
                                                disabled={memoCanOrder === false}
                                                type="primary"
                                                style={{
                                                    backgroundColor:
                                                        orderSide === OrderSide.Buy ?
                                                            ARBITRAGE_COLOR_BUY
                                                        :   ARBITRAGE_COLOR_SELL
                                                }}
                                            >
                                                {orderSide}
                                            </Button>
                                        </Popconfirm>
                                    </Col>
                                </Row>
                            </FlexCol>
                        :   <FlexCol>
                                <Alert message="Symbol is not available for SPOT trading" type="warning" showIcon />
                            </FlexCol>
                        }
                    </Col>
                )}
                {currentSymbol !== null && orderbook !== null && <Col xs={12}>{memoOrderbookWidget}</Col>}
                {currentSymbol !== null && <Col xs={24}>{memoOrderHistory}</Col>}
            </Row>
        </div>
    )
}
