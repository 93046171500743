import { Tooltip, Divider, Row, Col, Alert, Spin, Empty } from "antd"
import { FC, useState, useEffect, ReactNode, useRef } from "react"
import {
    InstantOpportunity,
    Exchange,
    Network,
    Coin,
    PeriodicOpportunity,
    ExchangePairAlignedCoin,
    ExchangeNetwork,
    ExchangePairAlignedNetwork,
    WellKnownFeeRatesMap
} from "../types"
import {
    formatDurationApprox,
    formatDurationExact,
    getExchangeHref,
    getOperationDepth,
    getTotalFee,
    numToFixNTZp2,
    numberToFixedWithoutTrailingZeros,
    projectDepositOnProfitNonLinear,
    useMediaQuery
} from "../utils"
import { FlexCol, FlexRow, Paper, BlinkOnUpdate } from "../common"
import { CheckCircleFilled, CloseCircleFilled, MenuOutlined, WarningFilled } from "@ant-design/icons"
import {
    ARBITRAGE_COLOR_BUY,
    ARBITRAGE_COLOR_SELL,
    ARBITRAGE_COLOR_GREEN,
    ARBITRAGE_COLOR_WARN,
    COIN_METADATA_AUTO_REFRESH_INTERVAL_MS,
    ARBITRAGE_COLOR_NEUTRAL
} from "../constants"
import { useAuthContext } from "../reducers/authReducer"
import { Link } from "react-router-dom"
import { PriceDiscrepancyWidget, formatPrices } from "./priceDiscrepancyWidget"
import { RiskAssessmentWidget, WellKnownFeeRatesWidget } from "./dyorWidgets"


const AlignedNetworkInformationWidget: FC<{
    opportunity: InstantOpportunity | null
    depositAmount: number | null
    exchangesMap: Record<Exchange["ID"], Exchange>
    networksMap: Record<Network["ID"], Network>
}> = ({ opportunity, depositAmount, exchangesMap, networksMap }) => {
    const [networkName, setNetworkName] = useState<string | null>(null)
    const [networkBlockTime, setNetworkBlockTime] = useState<number | null>(null)
    const [exchangeFromName, setExchangeFromName] = useState<string | null>(null)
    const [exchangeToName, setExchangeToName] = useState<string | null>(null)

    const [metadataTimestamp, setMetadataTimestamp] = useState<string | null>(null)
    const [exchangeFromNetwork, setExchangeFromNetwork] = useState<ExchangeNetwork | null>(null)
    const [exchangeToNetwork, setExchangeToNetwork] = useState<ExchangeNetwork | null>(null)
    const [alternativeNetworks, setAlternativeNetworks] = useState<ExchangePairAlignedNetwork[]>([])

    const [currentCost, setCurrentCost] = useState<number>(0)
    const [currentTotalFee, setCurrentTotalFee] = useState<number>(0)

    const alignedCoinNetworksLoadMutexRef = useRef<boolean>(false)

    const { callSecureAPI } = useAuthContext()

    const loadAlignedCoinNetworks = () => {
        if (!opportunity) {
            return
        }
        if (alignedCoinNetworksLoadMutexRef.current) {
            return
        }
        alignedCoinNetworksLoadMutexRef.current = true
        callSecureAPI<ExchangePairAlignedCoin | null>(
            `/exchange/pair/aligned/coin?exchange_from_id=${opportunity.ExchangeFromID}&exchange_to_id=${opportunity.ExchangeToID}&coin_id=${opportunity.CoinID}`
        )
            .then(({ responseObject: resp }) => {
                if (resp === null) {
                    return
                }
                setMetadataTimestamp(resp.MetadataTimestamp)
                let _alternativeNetworks: ExchangePairAlignedNetwork[] = []
                for (let alignedNetwork of resp.Networks) {
                    if (alignedNetwork.NetworkID !== opportunity.NetworkID) {
                        _alternativeNetworks.push(alignedNetwork)
                        continue
                    }
                    setExchangeFromNetwork(alignedNetwork.ExchangeOne)
                    setExchangeToNetwork(alignedNetwork.ExchangeTwo)
                }
                setAlternativeNetworks(_alternativeNetworks)
                alignedCoinNetworksLoadMutexRef.current = false
            })
            .catch((e: any) => {
                console.error("erorr occurred while loading aligned coin networks", e.message)
            })
    }

    useEffect(() => {
        if (opportunity === null) {
            return
        }
        if (depositAmount === null) {
            setCurrentCost(opportunity.CumulativeCost)
        } else {
            let _v = Math.min(depositAmount, opportunity.CumulativeCost)
            setCurrentCost(_v)
        }
    }, [opportunity, depositAmount])

    useEffect(() => {
        if (opportunity === null) {
            return
        }
        setCurrentTotalFee(
            opportunity.WithdrawFeeFixUSDTFrom +
                opportunity.WithdrawFeeRateFrom * currentCost +
                opportunity.DepositFeeRateTo * currentCost * (1 - opportunity.WithdrawFeeRateFrom)
        )
    }, [opportunity, currentCost])

    useEffect(() => {
        if (opportunity === null) {
            return
        }
        setNetworkName(networksMap[opportunity.NetworkID]?.Name)
        setExchangeFromName(exchangesMap[opportunity.ExchangeFromID]?.Name)
        setExchangeToName(exchangesMap[opportunity.ExchangeToID]?.Name)
        setNetworkBlockTime(networksMap[opportunity.NetworkID]?.BlockTimeSec)
    }, [opportunity])

    useEffect(() => {
        loadAlignedCoinNetworks()
        let t = setInterval(() => {
            loadAlignedCoinNetworks()
        }, COIN_METADATA_AUTO_REFRESH_INTERVAL_MS)
        return () => {
            clearInterval(t)
        }
    }, [opportunity])

    if (opportunity === null || networkName === null || exchangeFromName === null || exchangeToName === null) {
        return null
    }

    return (
        <Paper>
            <Row
                justify="space-around"
                align="top"
                style={{
                    paddingBottom: 20,
                    marginBottom: 20,
                    borderBottom: "1px solid #eee"
                }}
            >
                <Col>
                    <FlexCol
                        style={{
                            gap: 2,
                            alignItems: "center"
                        }}
                    >
                        <span>
                            <b>Network</b>
                        </span>
                        <Tooltip
                            overlay={
                                <>
                                    {exchangeFromName}:{" "}
                                    <b>{exchangeFromNetwork ? exchangeFromNetwork.OwnNetworkName : "N/A"}</b>
                                    <br />
                                    {exchangeToName}:{" "}
                                    <b>{exchangeToNetwork ? exchangeToNetwork.OwnNetworkName : "N/A"}</b>
                                </>
                            }
                        >
                            <span style={{ fontSize: 20 }}>{networkName}</span>
                        </Tooltip>
                    </FlexCol>
                </Col>
                <Col>
                    <FlexCol
                        style={{
                            gap: 2,
                            alignItems: "center"
                        }}
                    >
                        <span>
                            <b>TX</b>
                        </span>
                        <span style={{ fontSize: 20 }}>
                            {!exchangeFromNetwork || !exchangeToNetwork ?
                                <Spin />
                            :   <Tooltip
                                    overlay={
                                        <div>
                                            <span>
                                                Withdraw from <b>{exchangeFromName}</b>:{" "}
                                                {exchangeFromNetwork.WithdrawOK ?
                                                    <CheckCircleFilled style={{ color: ARBITRAGE_COLOR_GREEN }} />
                                                :   <CloseCircleFilled />}
                                            </span>
                                            <br />
                                            <span>
                                                Deposit to <b>{exchangeToName}</b>:{" "}
                                                {exchangeToNetwork.DepositOK ?
                                                    <CheckCircleFilled style={{ color: ARBITRAGE_COLOR_GREEN }} />
                                                :   <CloseCircleFilled />}
                                            </span>
                                            <br />
                                            <br />
                                            {metadataTimestamp !== null && (
                                                <span>
                                                    Last check: {new Date(metadataTimestamp).toLocaleTimeString()} (
                                                    {formatDurationApprox(
                                                        Date.now() - new Date(metadataTimestamp).getTime()
                                                    )}
                                                    )
                                                </span>
                                            )}
                                        </div>
                                    }
                                >
                                    {exchangeFromNetwork.WithdrawOK && exchangeToNetwork.DepositOK ?
                                        <CheckCircleFilled style={{ color: ARBITRAGE_COLOR_GREEN }} />
                                    :   <CloseCircleFilled />}
                                </Tooltip>
                            }
                        </span>
                    </FlexCol>
                </Col>
                <Col>
                    <FlexCol
                        style={{
                            gap: 2,
                            alignItems: "center"
                        }}
                    >
                        <span>
                            <b>Hedging</b>
                        </span>
                        <Tooltip
                            overlay={
                                opportunity.MarginTradeIsAvailableTo ?
                                    <>
                                        MARGIN trade is available on <b>{exchangeToName}</b> for this coin
                                    </>
                                :   <>
                                        MARGIN trade is not available on <b>{exchangeToName}</b> for this coin
                                    </>
                            }
                        >
                            <span style={{ fontSize: 20 }}>
                                {opportunity.MarginTradeIsAvailableTo ?
                                    <CheckCircleFilled style={{ color: ARBITRAGE_COLOR_GREEN }} />
                                :   <CloseCircleFilled />}
                            </span>
                        </Tooltip>
                    </FlexCol>
                </Col>
                <Col>
                    <FlexCol
                        style={{
                            gap: 2,
                            alignItems: "center"
                        }}
                    >
                        <span>
                            <b>Total Fee</b>
                        </span>
                        <Tooltip
                            overlay={
                                <>
                                    <b>Withdraw fix:</b>{" "}
                                    {numToFixNTZp2(opportunity.WithdrawFeeFixUSDTFrom / opportunity.LowestAskFrom)}{" "}
                                    coins ⇒ <b>{numToFixNTZp2(opportunity.WithdrawFeeFixUSDTFrom)}$</b>
                                    <br />
                                    {opportunity.WithdrawFeeRateFrom > 0 && (
                                        <>
                                            <span>
                                                <b>Withdraw rate:</b>{" "}
                                                {numToFixNTZp2(100 * opportunity.WithdrawFeeRateFrom)}% ⇒{" "}
                                                <b>{numToFixNTZp2(opportunity.WithdrawFeeRateFrom * currentCost)}$</b>
                                            </span>
                                            <br />
                                        </>
                                    )}
                                    {opportunity.DepositFeeRateTo > 0 && (
                                        <>
                                            <span>
                                                <b>Deposit rate:</b> {numToFixNTZp2(100 * opportunity.DepositFeeRateTo)}
                                                % ⇒{" "}
                                                <b>
                                                    {numToFixNTZp2(
                                                        opportunity.DepositFeeRateTo *
                                                            (currentCost -
                                                                currentCost * opportunity.WithdrawFeeRateFrom -
                                                                opportunity.WithdrawFeeFixUSDTFrom)
                                                    )}
                                                    $
                                                </b>
                                            </span>
                                            <br />
                                        </>
                                    )}
                                </>
                            }
                        >
                            <span style={{ fontSize: 20 }}>{numToFixNTZp2(currentTotalFee)}$</span>
                        </Tooltip>
                    </FlexCol>
                </Col>
                {/* TX Time */}
                <Col>
                    <FlexCol
                        style={{
                            gap: 2,
                            alignItems: "center"
                        }}
                    >
                        <span>
                            <b>TX Time</b>
                        </span>
                        {networkBlockTime !== null && networkBlockTime > 0 ?
                            //  Block Time is available for the network
                            opportunity.DepositNbConfirmations > 0 ?
                                // If we hve Nb of confirmations, render estimated time
                                <Tooltip overlay={<>{opportunity.DepositNbConfirmations} confirmations</>}>
                                    <span style={{ fontSize: 20 }}>
                                        ~
                                        {formatDurationExact(
                                            1000 * opportunity.DepositNbConfirmations * networkBlockTime
                                        )}
                                    </span>
                                </Tooltip>
                                // Else, render "N/A"
                            :   <Tooltip overlay={<>Number of confirmations on {exchangeToName} is unknown</>}>
                                    <span style={{ fontSize: 20 }}>N/A</span>
                                </Tooltip>

                        :   <Tooltip
                                overlay={
                                    <>
                                        Network <b>Block Time</b> is unknown
                                        <br />
                                        {opportunity.DepositNbConfirmations > 0 && (
                                            <>[{opportunity.DepositNbConfirmations} confirmations]</>
                                        )}
                                    </>
                                }
                            >
                                <span style={{ fontSize: 20 }}>N/A</span>
                            </Tooltip>
                        }
                    </FlexCol>
                </Col>
            </Row>
            <Row justify="space-around" align="top">
                {/* Minimum withdrawal */}
                <Col>
                    <FlexCol
                        style={{
                            gap: 2,
                            alignItems: "center"
                        }}
                    >
                        <FlexCol
                            style={{
                                gap: 0,
                                alignItems: "center"
                            }}
                        >
                            <b>Min withdrawal</b>
                            <i style={{ fontSize: 11, marginTop: -2 }}>
                                on <b>{exchangeFromName}</b>
                            </i>
                        </FlexCol>
                        {exchangeFromNetwork && exchangeFromNetwork.WithdrawMin > 0 ?
                            <Tooltip overlay={<>{exchangeFromNetwork.WithdrawMin} coins</>}>
                                <FlexRow
                                    style={{
                                        gap: 2,
                                        alignItems: "center"
                                    }}
                                >
                                    <span style={{ fontSize: 20 }}>
                                        $
                                        {numberToFixedWithoutTrailingZeros(
                                            exchangeFromNetwork.WithdrawMin * opportunity.LowestAskFrom,
                                            2
                                        )}
                                    </span>
                                    {exchangeFromNetwork &&
                                        exchangeFromNetwork.WithdrawMin * opportunity.LowestAskFrom > currentCost && (
                                            <WarningFilled
                                                style={{
                                                    color: ARBITRAGE_COLOR_WARN
                                                }}
                                            />
                                        )}
                                </FlexRow>
                            </Tooltip>
                        :   <span style={{ fontSize: 20 }}>N/A</span>}
                    </FlexCol>
                </Col>
                <Col>
                    <FlexCol
                        style={{
                            gap: 2,
                            alignItems: "center"
                        }}
                    >
                        <span>
                            <FlexCol
                                style={{
                                    gap: 0,
                                    alignItems: "center"
                                }}
                            >
                                <b>Min deposit</b>
                                <i style={{ fontSize: 11, marginTop: -2 }}>
                                    on <b>{exchangeToName}</b>
                                </i>
                            </FlexCol>
                        </span>
                        {exchangeToNetwork && exchangeToNetwork.DepositMin > 0 ?
                            <Tooltip overlay={<>{exchangeToNetwork.DepositMin} coins</>}>
                                <FlexRow
                                    style={{
                                        gap: 2,
                                        alignItems: "center"
                                    }}
                                >
                                    <span style={{ fontSize: 20 }}>
                                        $
                                        {numberToFixedWithoutTrailingZeros(
                                            exchangeToNetwork.DepositMin * opportunity.HighestBidTo,
                                            2
                                        )}
                                    </span>
                                    {exchangeToNetwork &&
                                        exchangeToNetwork.DepositMin * opportunity.LowestAskFrom > currentCost && (
                                            <WarningFilled
                                                style={{
                                                    color: ARBITRAGE_COLOR_WARN
                                                }}
                                            />
                                        )}
                                </FlexRow>
                            </Tooltip>
                        :   <span style={{ fontSize: 20 }}>N/A</span>}
                    </FlexCol>
                </Col>
                {alternativeNetworks.length > 0 && (
                    <Col>
                        <Tooltip
                            overlay={
                                <>
                                    <FlexCol
                                        style={{
                                            gap: 2,
                                            alignItems: "center"
                                        }}
                                    >
                                        <span>Alternative networks</span>
                                        {alternativeNetworks.map(net => {
                                            return (
                                                <div>
                                                    {net.CommonNetworkName}
                                                    &nbsp;—&nbsp;
                                                    {net.ExchangeOne.WithdrawOK && net.ExchangeTwo.DepositOK ?
                                                        <CheckCircleFilled style={{ color: ARBITRAGE_COLOR_GREEN }} />
                                                    :   <CloseCircleFilled />}
                                                    &nbsp;—&nbsp;
                                                    {numToFixNTZp2(
                                                        net.ExchangeOne.WithdrawFeeFix * opportunity.LowestAskFrom
                                                    )}
                                                    $
                                                    {net.ExchangeOne.WithdrawFeeRate > 0 && (
                                                        <>
                                                            {" "}
                                                            +{" "}
                                                            {numToFixNTZp2(
                                                                net.ExchangeOne.WithdrawFeeRate * currentCost
                                                            )}
                                                            $
                                                        </>
                                                    )}
                                                    {net.ExchangeTwo.DepositFeeRate > 0 && (
                                                        <>
                                                            {" "}
                                                            +{" "}
                                                            {numToFixNTZp2(
                                                                net.ExchangeTwo.DepositFeeRate *
                                                                    currentCost *
                                                                    (1 - net.ExchangeOne.WithdrawFeeRate)
                                                            )}
                                                            $
                                                        </>
                                                    )}
                                                    &nbsp;—&nbsp;
                                                    {formatDurationExact(
                                                        1000 * net.BlockTimeSec * net.ExchangeTwo.DepositNbConfirmations
                                                    )}
                                                </div>
                                            )
                                        })}
                                    </FlexCol>
                                </>
                            }
                            placement="left"
                            overlayInnerStyle={{
                                maxWidth: 500
                            }}
                        >
                            <FlexCol
                                style={{
                                    gap: 2,
                                    alignItems: "center"
                                }}
                            >
                                <FlexCol
                                    style={{
                                        gap: 0,
                                        alignItems: "center"
                                    }}
                                >
                                    <b>Alternative</b>
                                    <br />
                                    <i style={{ fontSize: 11, marginTop: -2 }}>networks</i>
                                </FlexCol>
                                <MenuOutlined style={{ fontSize: 20, height: "100%" }} />
                            </FlexCol>
                        </Tooltip>
                    </Col>
                )}
            </Row>
        </Paper>
    )
}

export const ExpandedRowDetails: FC<{
    opportunity: PeriodicOpportunity | null
    exchangesMap: Record<Exchange["ID"], Exchange>
    coinsMap: Record<Coin["ID"], Coin>
    totalFee: number
    depositAmount: number | null
}> = ({ opportunity, exchangesMap, coinsMap, totalFee, depositAmount }) => {
    const [exFromName, setExFromName] = useState<string | null>(null)
    const [exToName, setExToName] = useState<string | null>(null)
    const [coinName, setCoinName] = useState<string | null>(null)

    const [netPersonalizedProfit, setNetPersonalizedProfit] = useState<number | null>(null)
    const [netPersonalizedVolume, setNetPersonalizedVolume] = useState<number | null>(null)
    const [netPersonalizedCost, setNetPersonalizedCost] = useState<number | null>(null)
    const [netPersonalizedIndex, setNetPersonalizedIndex] = useState<number | null>(null)

    const [netTotalProfit, setNetTotalProfit] = useState<number | null>(null)
    const [netTotalVolume, setNetTotalVolume] = useState<number | null>(null)
    const [netTotalCost, setNetTotalCost] = useState<number | null>(null)

    const [buyDepth, setBuyDepth] = useState<number>(0)
    const [sellDepth, setSellDepth] = useState<number>(0)

    const [priceFromNode, setPriceFromNode] = useState<ReactNode | null>(null)
    const [priceToNode, setPriceToNode] = useState<ReactNode | null>(null)
    const [priceNDigits, setPriceNDigits] = useState<number>(0)

    const [isOutdated, setIsOutdated] = useState<boolean>(false)

    useEffect(() => {
        if (opportunity === null) {
            return
        }
        setIsOutdated(opportunity.EndedSinceMs / 1000 > 30)
    }, [opportunity])

    useEffect(() => {
        if (opportunity === null) {
            return
        }
        setExFromName(exchangesMap[opportunity.ExchangeFromID]?.Name)
        setExToName(exchangesMap[opportunity.ExchangeToID]?.Name)
        setCoinName(coinsMap[opportunity.CoinID]?.Name)
        let { priceFromNode, priceToNode, nDigits } = formatPrices(opportunity.LowestAskFrom, opportunity.HighestBidTo)
        setPriceFromNode(priceFromNode)
        setPriceToNode(priceToNode)
        setPriceNDigits(nDigits)
    }, [opportunity])

    useEffect(() => {
        if (opportunity === null) {
            return
        }

        if (isOutdated) {
            setNetTotalProfit(opportunity.AvgCumulativeProfit - totalFee)
            setNetTotalVolume(opportunity.AvgCumulativeVolume)
            setNetTotalCost(opportunity.AvgCumulativeCost)
        } else {
            setNetTotalProfit(opportunity.CumulativeProfit - totalFee)
            setNetTotalVolume(opportunity.CumulativeVolume)
            setNetTotalCost(opportunity.CumulativeCost)
        }

        if (depositAmount === null) {
            if (isOutdated) {
                setNetPersonalizedProfit(opportunity.AvgCumulativeProfit - totalFee)
                setNetPersonalizedVolume(opportunity.AvgCumulativeVolume)
                setNetPersonalizedCost(opportunity.AvgCumulativeCost)
            } else {
                setNetPersonalizedProfit(opportunity.CumulativeProfit - totalFee)
                setNetPersonalizedVolume(opportunity.CumulativeVolume)
                setNetPersonalizedCost(opportunity.CumulativeCost)
            }
            setNetPersonalizedIndex(opportunity.ProfitPoints.length - 1)
            setBuyDepth(getOperationDepth(opportunity.AskPricePoints))
            setSellDepth(getOperationDepth(opportunity.BidPricePoints))

            return
        }
        let { projectedProfit, index: _i } = projectDepositOnProfitNonLinear(
            opportunity.ProfitPoints,
            opportunity.CostPoints,
            depositAmount
        )
        let netProfit = projectedProfit - totalFee
        setNetPersonalizedProfit(netProfit)
        setNetPersonalizedVolume(0)
        setNetPersonalizedIndex(_i)
        if (depositAmount > opportunity.CumulativeCost) {
            setNetPersonalizedCost(opportunity.CumulativeCost)
        } else {
            setNetPersonalizedCost(depositAmount)
        }
        setBuyDepth(getOperationDepth(opportunity.AskPricePoints.slice(0, _i + 1)))
        setSellDepth(getOperationDepth(opportunity.BidPricePoints.slice(0, _i + 1)))
    }, [opportunity, depositAmount, totalFee, isOutdated])

    if (opportunity === null) {
        return null
    }

    if (
        netPersonalizedIndex === null ||
        netPersonalizedProfit === null ||
        netPersonalizedVolume === null ||
        netPersonalizedCost === null ||
        netTotalProfit === null ||
        netTotalCost === null
    ) {
        return null
    }

    if (
        exFromName === null ||
        exToName === null ||
        coinName === null ||
        priceFromNode === null ||
        priceToNode === null
    ) {
        return null
    }

    return (
        <Paper
            style={{
                width: "100%",
                height: "100%"
            }}
        >
            <Row gutter={[10, 0]} justify="space-around">
                <Col>
                    <FlexCol
                        style={{
                            width: "100%",
                            alignItems: "center",
                            gap: 0
                        }}
                    >
                        <span>
                            <b>
                                {isOutdated && <span style={{ fontSize: "1.1rem" }}>Ø </span>}
                                Investment
                            </b>
                        </span>
                        <span
                            style={{
                                fontSize: 18
                            }}
                        >
                            {netPersonalizedCost.toFixed(0)}$
                        </span>
                        <span style={{ fontSize: 11 }}>
                            <span>Total: {netTotalCost.toFixed(0)}$</span>
                            {depositAmount !== null && (
                                <Tooltip overlay="relative to your deposit">
                                    ({(netTotalCost / depositAmount).toFixed(1)}x)
                                </Tooltip>
                            )}
                        </span>
                    </FlexCol>
                </Col>
                <Col>
                    <FlexCol
                        style={{
                            width: "100%",
                            alignItems: "center",
                            gap: 0
                        }}
                    >
                        <span>
                            <b>
                                {isOutdated && <>Ø </>}
                                Profit
                            </b>
                        </span>
                        <span style={{ fontSize: 18 }}>
                            <b>{numToFixNTZp2(netPersonalizedProfit)}$</b>
                        </span>
                        <span
                            style={{
                                fontSize: 11
                            }}
                        >
                            Total: {numToFixNTZp2(netTotalProfit)}$
                        </span>
                    </FlexCol>
                </Col>
                <Col>
                    <FlexCol
                        style={{
                            width: "100%",
                            alignItems: "center",
                            gap: 0
                        }}
                    >
                        <span>
                            <b>
                                {isOutdated && <>Ø </>}
                                ROI
                            </b>
                        </span>
                        <span
                            style={{
                                fontSize: 18
                            }}
                        >
                            <b>{numToFixNTZp2((100 * netPersonalizedProfit) / netPersonalizedCost)}%</b>
                        </span>
                        <span
                            style={{
                                fontSize: 11
                            }}
                        >
                            Total: {numToFixNTZp2((100 * netTotalProfit) / netTotalCost)}%
                        </span>
                    </FlexCol>
                </Col>
            </Row>
            {!isOutdated ?
                <>
                    <Divider />
                    <PriceDiscrepancyWidget
                        exchangeFromName={exchangesMap[opportunity.ExchangeFromID].Name}
                        exchangeToName={exchangesMap[opportunity.ExchangeToID].Name}
                        priceFromNode={priceFromNode}
                        priceToNode={priceToNode}
                        nDigits={priceNDigits}
                        relativeDiscrepancy={opportunity.RelativeDiscrepancy}
                        absoluteDiscrepancy={opportunity.AbsoluteDiscrepancy}
                    />
                    <Divider />
                    <Row>
                        <Col xs={12}>
                            <FlexCol
                                style={{
                                    width: "100%",
                                    alignItems: "center",
                                    gap: 2
                                }}
                            >
                                <span style={{ textAlign: "center" }}>
                                    BUY {coinName} for <b>{netPersonalizedCost.toFixed(2)}</b>$
                                </span>
                                <span style={{ textAlign: "center" }}>
                                    LIMIT <b>{opportunity.AskPricePoints[netPersonalizedIndex - 1]}</b> (order&nbsp;
                                    <b>#{buyDepth}</b>)
                                </span>
                                <span
                                    style={{
                                        marginTop: 10
                                    }}
                                />
                                {buyDepth > 1 ?
                                    <FlexCol
                                        style={{
                                            gap: 0,
                                            alignItems: "center"
                                        }}
                                    >
                                        <span>{opportunity.AskPricePoints[netPersonalizedIndex - 1]}</span>
                                        <span>
                                            <Tooltip overlay={<>{buyDepth - 2}+2 orders</>} placement="right">
                                                ↑ ... ↑
                                            </Tooltip>
                                        </span>
                                        <span>{priceFromNode}</span>
                                    </FlexCol>
                                :   <span>{priceFromNode}</span>}
                            </FlexCol>
                        </Col>
                        <Col
                            xs={12}
                            style={{
                                borderLeft: "1px solid black"
                            }}
                        >
                            <FlexCol
                                style={{
                                    width: "100%",
                                    alignItems: "center",
                                    gap: 2
                                }}
                            >
                                <span style={{ textAlign: "center" }}>SELL</span>
                                <span style={{ textAlign: "center" }}>
                                    LIMIT <b>{opportunity.BidPricePoints[netPersonalizedIndex - 1]}</b> (order&nbsp;
                                    <b>#{sellDepth}</b>)
                                </span>
                                <span
                                    style={{
                                        marginTop: 10
                                    }}
                                />
                                {sellDepth > 1 ?
                                    <FlexCol
                                        style={{
                                            gap: 0,
                                            alignItems: "center"
                                        }}
                                    >
                                        <span>{priceToNode}</span>
                                        <span>
                                            <Tooltip overlay={<>{sellDepth - 2}+2 orders</>} placement="right">
                                                ↓ ... ↓
                                            </Tooltip>
                                        </span>
                                        <span>{opportunity.BidPricePoints[netPersonalizedIndex - 1]}</span>
                                    </FlexCol>
                                :   <span>{priceToNode}</span>}
                            </FlexCol>
                        </Col>
                    </Row>
                </>
            :   <Empty
                    style={{
                        marginTop: 50
                    }}
                    description="Price/Volume details are not available for inactive opportunities"
                />
            }
        </Paper>
    )
}

export const OpportunityFullInformationWidget: FC<{
    opportunity: PeriodicOpportunity | null
    exchangesMap: Record<Exchange["ID"], Exchange>
    coinsMap: Record<Coin["ID"], Coin>
    networksMap: Record<Network["ID"], Network>
    wellKnownFeeRatesMap: WellKnownFeeRatesMap | null
    depositAmount: number | null
}> = ({ opportunity, exchangesMap, coinsMap, networksMap, wellKnownFeeRatesMap, depositAmount }) => {
    const [totalFee, setTotalFee] = useState<number>(0)

    const [isOutdated, setIsOutdated] = useState<boolean>(false)
    const [exFromName, setExFromName] = useState<string | null>(null)
    const [exToName, setExToName] = useState<string | null>(null)
    const [coinName, setCoinName] = useState<string | null>(null)

    const isMobile = useMediaQuery()

    useEffect(() => {
        if (opportunity === null) {
            return
        }
        setTotalFee(getTotalFee(opportunity, depositAmount))
    }, [opportunity, depositAmount])

    useEffect(() => {
        if (opportunity === null) {
            return
        }
        setIsOutdated(opportunity.EndedSinceMs / 1000 > 30)
        setExFromName(exchangesMap[opportunity.ExchangeFromID]?.Name)
        setExToName(exchangesMap[opportunity.ExchangeToID]?.Name)
        setCoinName(coinsMap[opportunity.CoinID]?.Name)
    }, [opportunity])

    if (opportunity === null || exFromName === null || exToName === null || coinName === null) {
        return null
    }

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <Paper>
                    <FlexCol
                        style={{
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 2
                        }}
                    >
                        <span
                            style={{
                                fontSize: "2rem",
                                textAlign: "center"
                            }}
                        >
                            <a
                                href={getExchangeHref(
                                    exchangesMap[opportunity.ExchangeFromID],
                                    coinsMap[opportunity.CoinID]
                                )}
                                target="_blank"
                                style={{
                                    color: ARBITRAGE_COLOR_BUY
                                }}
                            >
                                {exFromName}
                            </a>{" "}
                            →{" "}
                            <a
                                href={getExchangeHref(
                                    exchangesMap[opportunity.ExchangeToID],
                                    coinsMap[opportunity.CoinID]
                                )}
                                target="_blank"
                                style={{
                                    color: ARBITRAGE_COLOR_SELL
                                }}
                            >
                                {exToName}
                            </a>{" "}
                            | <b>{coinName}</b>
                        </span>
                        <span
                            style={{
                                fontSize: "1.2rem"
                            }}
                        >
                            Total liquidity: <b>{opportunity.CumulativeCost.toFixed(0)} $</b>
                        </span>
                        <div
                            style={{
                                position: !isMobile ? "absolute" : "inherit",
                                top: 0,
                                right: 10,
                                height: "100%",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 2
                            }}
                        >
                            <RiskAssessmentWidget
                                periodicOpportunity={opportunity}
                                exchangesMap={exchangesMap}
                                coinsMap={coinsMap}
                                networksMap={networksMap}
                                fontSize="2rem"
                            />
                            <WellKnownFeeRatesWidget
                                periodicOpportunity={opportunity}
                                coinsMap={coinsMap}
                                networksMap={networksMap}
                                wellKnownFeeRatesMap={wellKnownFeeRatesMap}
                                fontSize="2rem"
                            />
                            <Link to={`/dyor?coin_id=${opportunity.CoinID}`} style={{ textDecoration: "none" }}>
                                <Tooltip
                                    overlay={
                                        <>
                                            DYOR
                                            <br />
                                            <b>D</b>o <b>Y</b>our <b>O</b>wn <b>R</b>esearch
                                        </>
                                    }
                                    overlayInnerStyle={{ textAlign: "center" }}
                                    placement="bottom"
                                >
                                    <span
                                        style={{
                                            fontSize: "2rem",
                                            color: ARBITRAGE_COLOR_NEUTRAL
                                        }}
                                    >
                                        🔬
                                    </span>
                                </Tooltip>
                            </Link>
                        </div>
                    </FlexCol>
                </Paper>
            </Col>
            {isOutdated && (
                <Col xs={24}>
                    <Alert
                        style={{
                            fontSize: 18,
                            textAlign: "center"
                        }}
                        type="warning"
                        message={
                            <>
                                <b>Attention: </b> details of this position are{" "}
                                <b>{formatDurationApprox(opportunity.EndedSinceMs)}</b> old
                                <br />
                                Average <b>(Ø)</b> over opportunity duration values are shown
                            </>
                        }
                    />
                </Col>
            )}
            <Col xs={24} lg={12}>
                <ExpandedRowDetails
                    opportunity={opportunity}
                    exchangesMap={exchangesMap}
                    coinsMap={coinsMap}
                    totalFee={totalFee}
                    depositAmount={depositAmount}
                />
            </Col>
            <Col xs={24} lg={12}>
                <Row
                    gutter={[10, 10]}
                    align="stretch"
                    style={{
                        height: "100%"
                    }}
                >
                    <Col xs={24}>
                        <AlignedNetworkInformationWidget
                            opportunity={opportunity}
                            depositAmount={depositAmount}
                            exchangesMap={exchangesMap}
                            networksMap={networksMap}
                        />
                    </Col>
                    <Col xs={24}>
                        <Paper>
                            <Row justify={"space-evenly"} gutter={[10, 10]}>
                                {!isOutdated && (
                                    <Col>
                                        <FlexCol
                                            style={{
                                                gap: 2,
                                                alignItems: "center"
                                            }}
                                        >
                                            <FlexCol
                                                style={{
                                                    gap: 0,
                                                    alignItems: "center"
                                                }}
                                            >
                                                <b>Orderbook updated</b>
                                                <i style={{ fontSize: 11, marginTop: -2 }}>
                                                    on <b>{exFromName}</b>
                                                </i>
                                            </FlexCol>
                                            <span>
                                                <BlinkOnUpdate
                                                    value={`${new Date(
                                                        opportunity.OrderbookLastUpdatedTimestampFrom
                                                    ).toLocaleTimeString()}`}
                                                />{" "}
                                                (
                                                {formatDurationExact(
                                                    Date.now() -
                                                        new Date(
                                                            opportunity.OrderbookLastUpdatedTimestampFrom
                                                        ).getTime()
                                                )}{" "}
                                                ago)
                                            </span>
                                        </FlexCol>
                                    </Col>
                                )}
                                <Col>
                                    <FlexCol
                                        style={{
                                            gap: 2,
                                            alignItems: "center"
                                        }}
                                    >
                                        <FlexCol
                                            style={{
                                                gap: 0,
                                                alignItems: "center"
                                            }}
                                        >
                                            <b>Opportunity</b>
                                            <i style={{ fontSize: 11, marginTop: -2 }}>updated</i>
                                        </FlexCol>
                                        <span>
                                            <BlinkOnUpdate
                                                value={`${new Date(
                                                    opportunity.OrderbookTimestamp
                                                ).toLocaleTimeString()}`}
                                            />{" "}
                                            (
                                            {formatDurationApprox(
                                                Date.now() - new Date(opportunity.OrderbookTimestamp).getTime()
                                            )}
                                            )
                                        </span>
                                    </FlexCol>
                                </Col>
                                {!isOutdated && (
                                    <Col>
                                        <FlexCol
                                            style={{
                                                gap: 2,
                                                alignItems: "center"
                                            }}
                                        >
                                            <FlexCol
                                                style={{
                                                    gap: 0,
                                                    alignItems: "center"
                                                }}
                                            >
                                                <b>Orderbook updated</b>
                                                <i style={{ fontSize: 11, marginTop: -2 }}>
                                                    on <b>{exToName}</b>
                                                </i>
                                            </FlexCol>
                                            <span>
                                                <BlinkOnUpdate
                                                    value={`${new Date(
                                                        opportunity.OrderbookLastUpdatedTimestampTo
                                                    ).toLocaleTimeString()}`}
                                                />{" "}
                                                (
                                                {formatDurationExact(
                                                    Date.now() -
                                                        new Date(opportunity.OrderbookLastUpdatedTimestampTo).getTime()
                                                )}{" "}
                                                ago)
                                            </span>
                                        </FlexCol>
                                    </Col>
                                )}
                            </Row>
                        </Paper>
                    </Col>
                    <Col xs={24}>
                        <Paper>
                            <Row justify={"space-evenly"} gutter={[10, 10]}>
                                <Col>
                                    <FlexCol
                                        style={{
                                            gap: 2,
                                            alignItems: "center"
                                        }}
                                    >
                                        <FlexCol
                                            style={{
                                                gap: 0,
                                                alignItems: "center"
                                            }}
                                        >
                                            <b>Duration</b>
                                            <i style={{ fontSize: 11, marginTop: -2 }}>since last inactivity</i>
                                        </FlexCol>
                                        <span>
                                            <BlinkOnUpdate
                                                value={`${formatDurationApprox(opportunity.DurationMs)}`}
                                            ></BlinkOnUpdate>
                                        </span>
                                    </FlexCol>
                                </Col>
                                <Col>
                                    <FlexCol
                                        style={{
                                            gap: 2,
                                            alignItems: "center"
                                        }}
                                    >
                                        <FlexCol
                                            style={{
                                                gap: 0,
                                                alignItems: "center"
                                            }}
                                        >
                                            <b>Time since</b>
                                            <i style={{ fontSize: 11, marginTop: -2 }}>last positive profit</i>
                                        </FlexCol>
                                        <span>
                                            <BlinkOnUpdate
                                                value={`${formatDurationExact(opportunity.EndedSinceMs)}`}
                                            ></BlinkOnUpdate>
                                        </span>
                                    </FlexCol>
                                </Col>
                            </Row>
                        </Paper>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
