import { FC, useEffect, useState } from "react"
import { setQueryParam, useQuery } from "../utils"
import { Coin } from "../types"
import { DYORWidget } from "../widgets/dyorWidgets"
import { FlexCol, Paper } from "../common"
import { useAuthContext } from "../reducers/authReducer"
import { Select, Spin } from "antd"

export const DYORPage: FC = () => {
    const query = useQuery()

    const [coins, setCoins] = useState<Coin[] | null>(null)
    const [coinID, setCoinID] = useState<Coin["ID"] | null>(null)

    const { callSecureAPI } = useAuthContext()

    useEffect(() => {
        callSecureAPI<Coin[] | null>("/coins").then(({ responseObject: coins }) => {
            setCoins(coins)
        })
    }, [])
    useEffect(() => {
        const coinID = query.get("coin_id")
        if (coinID === null) {
            return
        }
        setCoinID(parseInt(coinID))
    }, [query])

    if (coins === null) {
        return (
            <FlexCol
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%"
                }}
            >
                <Spin size="large" />
            </FlexCol>
        )
    }

    return (
        <FlexCol>
            <Paper>
                <FlexCol>
                    <span
                        style={{
                            fontSize: "1.1rem",
                            color: "#333"
                        }}
                    >
                        Select cryptocurrency:
                    </span>
                    <Select
                        options={coins?.map(coin => ({
                            label: coin.Name,
                            value: coin.ID
                        }))}
                        value={coinID}
                        onChange={coinID => {
                            setCoinID(coinID)
                            setQueryParam("coin_id", coinID.toString())
                        }}
                        placeholder="Select a coin"
                        showSearch
                        style={{
                            width: "100%"
                        }}
                        size="large"
                        optionFilterProp="label"
                    />
                </FlexCol>
            </Paper>
            {coinID !== null && (
                <Paper>
                    <DYORWidget coinID={coinID} />
                </Paper>
            )}
        </FlexCol>
    )
}
