import { FC } from "react"
import { FlexCol } from "../common"
import { Button, Typography } from "antd"
import { useAuthContext } from "../reducers/authReducer"
import { Link } from "react-router-dom"
import { LoginWidget } from "../widgets/loginWidget"

interface SubscriptionStatus {
    IsSubscribed: boolean
    IsTrial: boolean
}

export const HomePage: FC = () => {
    const { authToken } = useAuthContext()

    return (
        <FlexCol
            style={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Typography.Title level={1} style={{ textAlign: "center" }}>
                Welcome to ArbitrageFlow!
            </Typography.Title>
            <Typography.Text style={{ textAlign: "center", fontSize: 18 }}>
                Here you won't miss a single cryptoexchange arbitrage opportunity!
            </Typography.Text>
            <div style={{ marginTop: "20px" }}>
                {authToken ?
                    <Button type="primary" size="large">
                        <Link to="/dashboard">Get started</Link>
                    </Button>
                :   <FlexCol>
                        <span>
                            Please login with your <b>Telegram</b> account to get started
                        </span>
                        <LoginWidget />
                    </FlexCol>
                }
            </div>
            {/* <div style={{textAlign: 'center'}}>
            If you are not subscribed yet, you can do it using our <b><a href={`https://t.me/${getTelegramBotName()}`}>bot</a></b>!
        </div> */}
            {/* <SubscriptionWidget/> */}
        </FlexCol>
    )
}
