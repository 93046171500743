import { NetworkDetails } from "./types"

export type ColorTuple = [number, number, number]

export const OPPORTUNITIES_AUTO_REFRESH_INTERVAL_MS = 1000
export const COIN_METADATA_AUTO_REFRESH_INTERVAL_MS = 10000

export const ARBITRAGE_COLOR_GREEN = "#3B940C"
export const ARBITRAGE_COLOR_RED = "#E0282F"

export const ARBITRAGE_COLOR_BUY = "#eb2f96"
export const ARBITRAGE_COLOR_SELL = "#13c2c2"

export const ARBITRAGE_COLOR_LONG = "#9E2065"
export const ARBITRAGE_COLOR_SHORT = "#0C7575"

export const ARBITRAGE_COLOR_WARN = "#faad14"
export const ARBITRAGE_COLOR_NEUTRAL = "#2F3C4E"

export const ARBITRAGE_COLOR_WITHDRAWAL = "#9254de"
export const ARBITRAGE_COLOR_DEPOSIT = "#4096ff"

export const ARBITRAGE_COLOR_FEE = "#000000" // black
export const ARBITRAGE_COLOR_TOTAL_PROFIT = "#ee82ee" // violet-ish
export const ARBITRAGE_COLOR_PERSONALIZED_PROFIT = "#FFC0CB" // pink
export const ARBITRAGE_COLOR_TOTAL_MARGIN = "#A0522D" // sienna
export const ARBITRAGE_COLOR_PERSONALIZED_MARGIN = "#DEB887" // burlywood
export const ARBITRAGE_COLOR_RELATIVE_DISCREPANCY = "#3696ff" // blue-ish
export const ARBITRAGE_COLOR_VIRTUAL_ANTI_PROFIT = "#85ED51"

export const ARBITRAGE_COLOR_EXCHANGE_BINANCE = "#F0B90B"
export const ARBITRAGE_COLOR_EXCHANGE_BITGET = "#1AA9D5"
export const ARBITRAGE_COLOR_EXCHANGE_BITMART = "#B8BDDB"
export const ARBITRAGE_COLOR_EXCHANGE_KUCOIN = "#4EB35C"
export const ARBITRAGE_COLOR_EXCHANGE_HTX = "#427DE6"
export const ARBITRAGE_COLOR_EXCHANGE_OKX = "#AFAFAF"
export const ARBITRAGE_COLOR_EXCHANGE_GATE = "#380AE6"
export const ARBITRAGE_COLOR_EXCHANGE_POLONIEX = "#219E0B"
export const ARBITRAGE_COLOR_EXCHANGE_MEXC = "#0B31F0"
export const ARBITRAGE_COLOR_EXCHANGE_BYBIT = "#CC4708"
export const ARBITRAGE_COLOR_EXCHANGE_XT = "#F5C37A"
export const ARBITRAGE_COLOR_EXCHANGE_COINEX = "#0BDBC3"
export const ARBITRAGE_COLOR_EXCHANGE_DIGIFINEX = "#8F34C7"
export const ARBITRAGE_COLOR_EXCHANGE_PROBIT = "#B81997"

export const ExchangeNameColorsMap: Record<string, string> = {
    BINANCE: ARBITRAGE_COLOR_EXCHANGE_BINANCE,
    BITGET: ARBITRAGE_COLOR_EXCHANGE_BITGET,
    BITMART: ARBITRAGE_COLOR_EXCHANGE_BITMART,
    KUCOIN: ARBITRAGE_COLOR_EXCHANGE_KUCOIN,
    HTX: ARBITRAGE_COLOR_EXCHANGE_HTX,
    OKX: ARBITRAGE_COLOR_EXCHANGE_OKX,
    GATE: ARBITRAGE_COLOR_EXCHANGE_GATE,
    POLONIEX: ARBITRAGE_COLOR_EXCHANGE_POLONIEX,
    MEXC: ARBITRAGE_COLOR_EXCHANGE_MEXC,
    BYBIT: ARBITRAGE_COLOR_EXCHANGE_BYBIT,
    XT: ARBITRAGE_COLOR_EXCHANGE_XT,
    COINEX: ARBITRAGE_COLOR_EXCHANGE_COINEX,
    DIGIFINEX: ARBITRAGE_COLOR_EXCHANGE_DIGIFINEX,
    PROBIT: ARBITRAGE_COLOR_EXCHANGE_PROBIT
}

export const getTelegramBotName = () => {
    if (window.location.hostname.startsWith("dev")) {
        return "arbitrage_ngineera_dev_bot"
    } else if (window.location.hostname.startsWith("aws")) {
        return "arbitrage_ngineera_aws_bot"
    } else {
        return "ngineera_arbitrage_bot"
    }
}

export const colorTupleToRGBA = (color: ColorTuple, a: number): string => {
    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${a})`
}

export const colorHexToTuple = (color: string): ColorTuple => {
    return [parseInt(color.slice(1, 3), 16), parseInt(color.slice(3, 5), 16), parseInt(color.slice(5, 7), 16)]
}
export const colorHexToRGBA = (color: string, a: number): string => {
    return colorTupleToRGBA(colorHexToTuple(color), a)
}
