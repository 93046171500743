export interface ErrorType {
    Reason: string
}

export interface User {
    ID: number
    Username: string
    FirstName: string
    LastName: string
    LanguageCode: string
    SubscribedUntil: string
    TrialPeriodUntil: string
}

export interface Network {
    ID: number
    Name: string
    BlockTimeSec: number
}
export interface Exchange {
    ID: number
    Name: string
}
export interface Coin {
    ID: number
    Name: string
    SymbolName: string
}

export interface Symbol {
    ID: number
    CommonSymbolName: string
    OwnSymbolName: string
}

export type ExchangePair = [Exchange, Exchange]

export interface ExchangePairAlignedCoin {
    CoinID: number
    MetadataTimestamp: string
    Networks: ExchangePairAlignedNetwork[]
}

export interface ExchangePairAlignedNetwork {
    NetworkID: number
    CommonNetworkName: string
    BlockTimeSec: number
    ExchangeOne: ExchangeNetwork
    ExchangeTwo: ExchangeNetwork
}

export interface ExchangeNetwork {
    OwnNetworkName: string
    OwnSymbolName: string
    NetworkIsAvailable: boolean
    SymbolIsAvailable: boolean

    DepositOK: boolean
    DepositNbConfirmations: number
    DepositMin: number
    DepositMax: number
    DepositFeeRate: number
    DepositFeeFix: number

    WithdrawOK: boolean
    WithdrawMin: number
    WithdrawMax: number
    WithdrawFeeRate: number
    WithdrawFeeFix: number
}

export interface ExchangePairCoinNetworkMetadata {
    MetadataTimestamp: string

    ExchangeOneSymbolOK: boolean
    ExchangeTwoSymbolOK: boolean
    ExchangeOneWithdrawOK: boolean
    ExchangeTwoDepositOK: boolean

    ExchangeOneWithdrawFeeFix: number
    ExchangeOneWithdrawFeeRate: number
    ExchangeTwoDepositFeeRate: number
}

export interface ExchangePairCoinDiscrepancy {
    ExchangeOneID: number
    ExchangeTwoID: number
    CoinID: number
    OrderbookTimestamp: string

    Discrepancy: {
        ExchangeOneLAPrice: number
        ExchangeTwoLAPrice: number
        ExchangeOneHBPrice: number
        ExchangeTwoHBPrice: number
    }
    Metadata: ExchangePairCoinNetworkMetadata | null
}

export type ExchangePairCoinDiscrepancyTimeseries = ExchangePairCoinDiscrepancy[] | null

export interface InstantOpportunity {
    ExchangeFromID: number
    ExchangeToID: number
    CoinID: number
    NetworkID: number
    OrderbookTimestamp: string
    OrderbookLastUpdatedTimestampFrom: string
    OrderbookLastUpdatedTimestampTo: string
    MetadataTimestamp: string
    TransactionOK: boolean

    DepositNbConfirmations: number
    DepositFeeFixUSDTTo: number
    DepositFeeRateTo: number

    WithdrawFeeFixUSDTFrom: number
    WithdrawFeeRateFrom: number

    MarginTradeIsAvailableTo: boolean
    MarginLoanCeilingQuoteAmountTo: number | null

    AbsoluteDiscrepancy: number
    RelativeDiscrepancy: number
    LowestAskFrom: number
    HighestBidTo: number

    ContractAddressFrom: string
    ContractAddressTo: string

    CumulativeProfit: number
    CumulativeVolume: number
    CumulativeCost: number
    AskPricePoints: number[]
    BidPricePoints: number[]
    ProfitPoints: number[]
    CostPoints: number[]
    VirtualAntiProfit: number
    ProfitToVirtualAntiProfitRatio: number
    RetrospectiveAverageVirtualAntiProfit: number
    ProfitToRetrospectiveAverageVirtualAntiProfitRatio: number
}

export interface InstantOpportunitiesResult {
    Timestamp: string
    Opportunities: InstantOpportunity[]
}

export type OpportunityTimeseries = InstantOpportunity[] | null

export interface PeriodicOpportunity extends InstantOpportunity {
    TimestampStart: string
    TimestampEnd: string
    DurationMs: number
    EndedSinceMs: number
    AvgCumulativeProfit: number
    AvgCumulativeVolume: number
    AvgCumulativeCost: number
    AvgFeeAdjustedROI: number | null
    AvgVirtualAntiProfit: number
    AvgProfitToVirtualAntiProfitRatio: number
    AvgRetrospectiveAverageVirtualAntiProfit: number
    AvgProfitToRetrospectiveAverageVirtualAntiProfitRatio: number
    NbPoints: number
}

export interface PeriodicOpportunitiesResult {
    Timestamp: string
    Opportunities: PeriodicOpportunity[]
}

export interface PeriodicOpportunityResult {
    Timestamp: string
    Opportunity: PeriodicOpportunity
}

export interface APIKeySet {
    apiKey: string
    secretKey: string
    passphrase: string | null
}

export interface USDTReturnAlignedNetwork {
    NetworkCommonCode: string
    WithdrawDepositOK: boolean
    WithdrawFee: number // float64
    DepositNbConfirmations: number // int64
    DepositTxTimeS: number // float64
}

export interface USDTReturnExchangePair {
    ExchangeFromName: string
    ExchangeToName: string
    AlignedNetworks: USDTReturnAlignedNetwork[]
}

export interface NetworkDetails {
    BlockTimeSec: number // seconds
    ExplorerURL: string
}

export type CommonNetworkNameMap = Record<string, string>
export type WellKnownNetworkBlockTimesMap = Record<string, number>
export type WellKnownUSDTReturnAlignedNetworks = USDTReturnExchangePair[]
export type WellKnownCoinFeeRatesMap = Record<
    Coin["Name"],
    {
        WithdrawFeeRate: number
        DepositFeeRate: number
    }
>
export type WellKnwonMarginLoanCeilingExchangeCoinMap = Record<string, Record<string, number>>

// CoinMarketCap
export interface CMCCoinInfo {
    ID: number
    Name: string
    Symbol: string
    Slug: string
    Category: string
    Description: string
    DateAdded: string // RFC3339
    DateLaunched: string // RFC3339
    LatestUpdateTime: string // RFC3339
    Status: string // active | inactive
    Notice: string // markdown string
    AlertType: number // 0 | 1 | 2
    AlertLink: string
    IsAudited: boolean
    Statistics: {
        Rank: number
    }
    Platforms: {
        contractId: number
        ContractAddress: string
        ContractPlatform: string
        ContractExplorerUrl: string
        PlatformCommonCode: string
    }[]
}

export interface CMCMarketPairExchange {
    Rank: number
    exchangeId: number
    ExchangeName: string
    ExchangeCommonName: string
    ExchangeSlug: string
    ExchangeNotice: string
    OutlierDetected: number // 0 | 1
    PriceExcluded: number // 0 | 1
    VolumeExcluded: number // 0 | 1
    marketId: number
    MarketPair: string // "BTC/USDT"
    marketUrl: string
    Price: number
    VolumeUsd: number
}

export interface CMCFullCoinInfo {
    CoinInfo: CMCCoinInfo
    MarketPairs: CMCMarketPairExchange[]
}

// CoinGecko
export interface CGTicker {
    Base: string // e.g. "AIDOGE"
    Target: string // e.g. "USDT"
    Market: {
        Name: string // e.g. "MEXC"
        Identifier: string // e.g. "mxc"
        has_trading_incentive: boolean // `json:"has_trading_incentive"`
        // Added by this API for compatibility
        ExchangeCommonName: string
    }
    Last: number
    Volume: number
    // ConvertedLast {}
    // ConvertedVolume {}
    trust_score: string // `json:"trust_score"` // e.g. "green", "yellow", "red"
    bid_ask_spread_percentage: number //  `json:"bid_ask_spread_percentage"`
    Timestamp: string
    last_traded_at: string // `json:"last_traded_at"`
    last_fetch_at: string // `json:"last_fetch_at"`
    is_anomaly: boolean // `json:"is_anomaly"`
    is_stale: boolean // `json:"is_stale"`
    trade_url: string // `json:"trade_url"`
    token_info_url: string | null // `json:"token_info_url"`
    coin_id: string // `json:"coin_id"`        // e.g. "arbdoge-ai"
    target_coin_id: string // `json:"target_coin_id"` // e.g. "tether"
}

export interface CGPlatform {
    // decimal_place: number //    `json:"decimal_place"`
    contract_address: string // `json:"contract_address"`
    // added by this API for compatibility
    PlatformCommonCode: string
}

export interface CGCoinInfo {
    ID: string // e.g. "arbdoge-ai"
    Symbol: string // e.g. "aidoge"
    Name: string // e.g. "ArbDoge AI"
    web_slug: string // `json:"web_slug"`
    asset_platform_id: string // `json:"asset_platform_id"`
    platforms: Record<string, string> // e.g. {"arbitrum-one": "0x09e18590e8f76b6cf471b3cd75fe1a1a9d2b2c2b"}
    detail_platforms: Record<string, CGPlatform> | null
    block_time_in_minutes: number // `json:"block_time_in_minutes"`
    hashing_algorithm: string // `json:"hashing_algorithm"`
    categories: string[] // `json:"categories"`
    preview_listing: boolean // `json:"preview_listing"`
    public_notice: string // `json:"public_notice"`
    additional_notices: string[] // `json:"additional_notices"`
    Description: Record<string, string>
    Links: {
        homepage: string[]
        blockchain_site: string[]
        twitter_screen_name: string | null
        facebook_username: string | null
        telegram_channel_identifier: string | null
    }
    Image: {
        Thumb: string
        Small: string
        Large: string
    }
    country_origin: string // `json:"country_origin"`
    genesis_date: string // `json:"genesis_date"` // e.g. 2015-07-30
    contract_address: string // `json:"contract_address"`
    last_updated: string // `json:"last_updated"`
    market_cap_rank: number // `json:"market_cap_rank"`
    Tickers: CGTicker[]
}

export interface CoinExchangeNetwork {
    ID: number
    Name: string
    OwnNetworkName: string
    ContractAddress: string
}

export interface CoinExchange {
    CoinID: number
    CoinName: string

    ExchangeID: number
    ExchangeName: string

    OwnCoinName: string
    OwnSymbolName: string

    Networks: CoinExchangeNetwork[]
}

export type CoinExchanges = CoinExchange[]

export interface WellKnownFeeRates {
    WithdrawFeeRate: number
    DepositFeeRate: number
}
export type WellKnownFeeRatesMap = Record<string, WellKnownFeeRates>

export interface WellKnownCoinNetworkExchangeContractAddress {
    CoinName: string
    NetworkName: string
    ExchangeName: string
    ContractAddress: string | null
}

export enum ExecutorControllerSide {
    Leftmost = "leftmost",
    Rightmost = "rightmost"
}

// Excludes these fields:
//
// AbsoluteDiscrepancy: number
// RelativeDiscrepancy: number
// LowestAskFrom: number
// HighestBidTo: number
// CumulativeProfit: number
// CumulativeVolume: number
// CumulativeCost: number
// AskPricePoints: number[]
// BidPricePoints: number[]
// ProfitPoints: number[]
// CostPoints: number[]
export interface PersistentPeriodicOpportunity extends PeriodicOpportunity {
    UUID: string
}

export interface PersistentPeriodicOpportunitiesResult {
    Timestamp: string
    Opportunities: PersistentPeriodicOpportunity[]
}

export enum EEffectiveMarginLoanCeilingQuoteAmountSource {
    External = 0,
    Internal = 1
}

export interface PersistentPeriodicOpportunityProcessed extends PersistentPeriodicOpportunity {
    ExchangeFrom: Exchange
    ExchangeTo: Exchange
    Coin: Coin
    Network: Network

    DepositAmount: number | null
    EffectiveProfit: number
    EffectiveCost: number
    EffectiveROI: number
    EffectiveUSDTReturnAlignedNetwork: USDTReturnAlignedNetwork | null
    TxTimeFwdSec: number | null
    TxTimeBwdSec: number | null
    TxTimeRoundTripSec: number | null
    EffectiveProfitRatePerHour: number | null
    EffectiveProfitToFeeRatio: number | null
    EffectiveTotalFeeWDFwdUSDT: number
    EffectiveTotalFeeWDBwdUSDT: number
    EffectiveTotalFeeTakerUSDT: number
    EffectiveTotalFeeTakerRate: number
    EffectiveTotalFeeUSDT: number

    EffectiveMarginLoanCeilingQuoteAmount: {
        Value: number
        Source: EEffectiveMarginLoanCeilingQuoteAmountSource
    } | null
}
