import { ReactNode, useEffect, useMemo, useState } from "react"
import {
    Exchange,
    Coin,
    InstantOpportunity,
    PeriodicOpportunity,
    USDTReturnExchangePair,
    USDTReturnAlignedNetwork
} from "./types"
import { useLocation } from "react-router-dom"
import { _getKucoinTakerFeeRate } from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/kucoin"

export const getExchangeHref = (exchange: Exchange, coin: Coin) => {
    switch (exchange.Name) {
        case "BINANCE":
            return `https://www.binance.com/en/trade/${coin.Name}_USDT?type=spot`
        case "BITGET":
            return `https://www.bitget.com/spot/${coin.Name}USDT?type=spot`
        case "BYBIT":
            return `https://www.bybit.com/en-US/trade/spot/${coin.Name}/USDT`
        case "GATE":
            return `https://www.gate.io/trade/${coin.Name}_USDT`
        case "HTX":
            return `https://www.htx.com/en-us/trade/${coin.Name.toLowerCase()}_usdt`
        case "MEXC":
            return `https://www.mexc.com/exchange/${coin.Name}_USDT`
        case "OKX":
            return `https://www.okx.com/trade-spot/${coin.Name.toLowerCase()}-usdt`
        case "POLONIEX":
            return `https://poloniex.com/trade/${coin.Name}_USDT?type=spot`
        case "KUCOIN":
            return `https://www.kucoin.com/trade/${coin.Name}-USDT`
        case "XT":
            return `https://www.xt.com/en/trade/${coin.Name.toLowerCase()}_usdt`
        case "DIGIFINEX":
            return `https://www.digifinex.com/en-ww/trade/USDT/${coin.Name}`
        case "BITMART":
            return `https://www.bitmart.com/trade/en?symbol=${coin.Name}_USDT`
        case "PROBIT":
            return `https://www.probit.com/app/exchange/${coin.Name}-USDT`
        case "COINEX":
            return `https://www.coinex.com/exchange/${coin.Name}-usdt`
        default:
            return ""
    }
}

const projectDepositOnProfitLinear = (profit: number, cost: number, _depositAmount: number) => {
    if (_depositAmount === null) {
        return 0
    }
    if (cost > _depositAmount) {
        return (_depositAmount * profit) / cost
    } else {
        return profit
    }
}

const _projectNonLinear = (
    pointsA: number[],
    pointsB: number[],
    value: number | null
): {
    projectedA: number
    projectedB: number
    index: number
} => {
    if (value === null) {
        return {
            projectedA: 0,
            projectedB: 0,
            index: 0
        }
    }
    let accumulatedA = 0
    let accumulatedB = 0
    let i = 0
    for (; i < pointsA.length; i++) {
        let currentA = pointsA[i]
        let currentB = pointsB[i]
        if (currentA === undefined || currentB === undefined) {
            break
        }
        if (accumulatedB + currentB > value) {
            let remainingB = value - accumulatedB
            let remainingA = (remainingB * currentA) / currentB
            return {
                projectedA: accumulatedA + remainingA,
                projectedB: value,
                index: i
            }
        }
        accumulatedA += currentA
        accumulatedB += currentB
    }
    return {
        projectedA: accumulatedA,
        projectedB: accumulatedB,
        index: i
    }
}

export const projectDepositOnProfitNonLinear = (
    profitPoints: number[],
    costPoints: number[],
    _depositAmount: number | null
): {
    projectedProfit: number
    projectedCost: number
    index: number
} => {
    let {
        projectedA: projectedProfit,
        projectedB: projectedCost,
        index
    } = _projectNonLinear(profitPoints, costPoints, _depositAmount)
    return {
        projectedProfit,
        projectedCost,
        index
    }
}

export const projectFeeOnCostNonLinear = (
    profitPoints: number[],
    costPoints: number[],
    totalFee: number
): {
    projectedProfit: number
    projectedCost: number
    index: number
} => {
    let {
        projectedA: projectedCost,
        projectedB: projectedProfit,
        index
    } = _projectNonLinear(costPoints, profitPoints, totalFee)
    return {
        projectedProfit,
        projectedCost,
        index
    }
}

export const getTotalFee = (opportunity: InstantOpportunity, depositAmount: number | null): number => {
    if (depositAmount === null) {
        depositAmount = opportunity.CumulativeCost
    }
    depositAmount = Math.min(depositAmount, opportunity.CumulativeCost)
    let totalWithdrawFee = opportunity.WithdrawFeeFixUSDTFrom + opportunity.WithdrawFeeRateFrom * depositAmount
    let txBody = depositAmount - totalWithdrawFee
    let totalDepositFee = opportunity.DepositFeeFixUSDTTo + opportunity.DepositFeeRateTo * txBody
    return totalWithdrawFee + totalDepositFee
}

interface IGetTotalFeeAvgPeriodicOpportunity {
    AvgCumulativeCost: number
    WithdrawFeeFixUSDTFrom: number
    WithdrawFeeRateFrom: number
    DepositFeeFixUSDTTo: number
    DepositFeeRateTo: number
}

export const getTotalFeeAvg = (
    opportunity: IGetTotalFeeAvgPeriodicOpportunity,
    depositAmount: number | null
): number => {
    if (depositAmount === null) {
        depositAmount = opportunity.AvgCumulativeCost
    }
    depositAmount = Math.min(depositAmount, opportunity.AvgCumulativeCost)
    let totalWithdrawFee = opportunity.WithdrawFeeFixUSDTFrom + opportunity.WithdrawFeeRateFrom * depositAmount
    let txBody = depositAmount - totalWithdrawFee
    let totalDepositFee = opportunity.DepositFeeFixUSDTTo + opportunity.DepositFeeRateTo * txBody
    return totalWithdrawFee + totalDepositFee
}

const getExchangeTakerFeeRate = (exchange: Exchange, baseAsset: string): number => {
    switch (exchange.Name) {
        case "MEXC":
            return 0
        case "BINANCE":
            return 0.001
        case "BITGET":
            return 0.001
        case "GATE":
            return 0.001
        case "OKX":
            return 0.001
        case "POLONIEX":
            return 0.00155
        case "BYBIT":
            return 0.0018
        case "HTX":
            return 0.002
        case "XT":
            return 0.002
        case "KUCOIN":
            return parseFloat(_getKucoinTakerFeeRate(baseAsset))
        case "BITMART":
            return 0.002 // TODO: implement asset-based fee as in case of Kucoin
        // TODO: unverified values
        case "PROBIT":
        case "COINEX":
        case "DIGIFINEX":
            return 0.002
        default:
            return 0
    }
}

interface IGetTotalTakerFeePercPeriodicOpportunity {
    ExchangeFrom: Exchange
    ExchangeTo: Exchange
    Coin: Coin
}

export const getTotalTakerFeeRate = (opportunity: IGetTotalTakerFeePercPeriodicOpportunity) => {
    let takerFeeFrom = getExchangeTakerFeeRate(opportunity.ExchangeFrom, opportunity.Coin.Name)
    let takerFeeTo = getExchangeTakerFeeRate(opportunity.ExchangeTo, opportunity.Coin.Name)
    return takerFeeFrom + takerFeeTo
}

interface IGetUSDTReturnAlignedNetworks {
    ExchangeFromID: number
    ExchangeToID: number
}
export const getUSDTReturnAlignedNetwork = (
    opportunity: IGetUSDTReturnAlignedNetworks | null,
    exchangesMap: Record<string, Exchange> | null,
    usdtReturnExchangePairs: USDTReturnExchangePair[] | null
): USDTReturnAlignedNetwork | null => {
    if (opportunity === null || exchangesMap === null || usdtReturnExchangePairs === null) {
        return null
    }
    let exchangeFromName = exchangesMap[opportunity.ExchangeFromID]?.Name
    let exchangeToName = exchangesMap[opportunity.ExchangeToID]?.Name
    if (exchangeFromName === undefined || exchangeToName === undefined) {
        return null
    }
    let usdtReturnFee: USDTReturnAlignedNetwork | null = null
    for (let exchangePair of usdtReturnExchangePairs) {
        // Attention: inverse exchange pair (return is from exchangeTo to exchangeFrom)
        if (exchangePair.ExchangeFromName === exchangeToName && exchangePair.ExchangeToName === exchangeFromName) {
            for (let alignedNetwork of exchangePair.AlignedNetworks) {
                usdtReturnFee = alignedNetwork
                break
            }
        }
    }
    return usdtReturnFee
}

export const getUSDTReturnFee = (
    opportunity: IGetUSDTReturnAlignedNetworks | null,
    exchangesMap: Record<string, Exchange> | null,
    usdtReturnExchangePairs: USDTReturnExchangePair[] | null
): number => {
    let usdtReturnAlignedNetwork = getUSDTReturnAlignedNetwork(opportunity, exchangesMap, usdtReturnExchangePairs)
    if (usdtReturnAlignedNetwork === null) {
        return 0
    }
    return usdtReturnAlignedNetwork.WithdrawFee
}

export const useMediaQuery = () => {
    const mediaMatch = window.matchMedia("(max-width: 768px)")
    const [matches, setMatches] = useState(mediaMatch.matches)

    useEffect(() => {
        const handler = (e: any) => {
            setMatches(e.matches)
        }
        mediaMatch.addEventListener("change", handler)
        return () => mediaMatch.removeEventListener("change", handler)
    }, [])

    return matches
}

export function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
}

export const setQueryParam = (key: string, value: string | null) => {
    const url = new URL(window.location.href)
    if (value === null) {
        url.searchParams.delete(key)
    } else {
        url.searchParams.set(key, value)
    }
    window.history.pushState({}, "", url.toString())
}

export const formatDurationApprox = (durationMs: number): string => {
    let durationSeconds = Math.floor(durationMs / 1000)
    let durationMinutes = Math.floor(durationSeconds / 60)

    durationSeconds -= durationMinutes * 60
    if (durationMinutes === 0) {
        if (durationSeconds < 30) {
            return `<30s`
        }
        return `${durationSeconds}`
    }
    if (durationMinutes >= 29) {
        return ">30m"
    }
    return `:${durationMinutes}:${durationSeconds}`
}

export const formatDurationExact = (durationMs: number): string => {
    let durationSeconds = Math.floor(durationMs / 1000)
    let durationMinutes = Math.floor(durationSeconds / 60)
    let durationHours = Math.floor(durationMinutes / 60)
    let durationDays = Math.floor(durationHours / 24)

    durationHours -= durationDays * 24
    durationMinutes -= durationDays * 24 * 60 + durationHours * 60
    durationSeconds -= durationDays * 24 * 60 * 60 + durationHours * 60 * 60 + durationMinutes * 60
    if (durationDays > 0) {
        return `${durationDays}:${durationHours}:${durationMinutes}:${durationSeconds}`
    }
    if (durationHours > 0) {
        return `${durationHours}:${durationMinutes}:${durationSeconds}`
    }
    if (durationMinutes > 0) {
        return `:${durationMinutes}:${durationSeconds}`
    }
    return `::${durationSeconds}`
}

export const formattedNumberToFixedWithoutTrailingZeros = (s: string): string => {
    if (!s.includes(".")) {
        //  no decimal point
        return s
    }
    while (s.endsWith("0")) {
        s = s.slice(0, -1)
    }
    if (s.endsWith(".")) {
        s = s.slice(0, -1)
    }
    return s
}

export const numberToFixedWithoutTrailingZeros = (number: number, precision: number): string => {
    let s = number.toFixed(precision)
    return formattedNumberToFixedWithoutTrailingZeros(s)
}

export const formatNumberLog1e3 = (nStr: string, decimals: number): string => {
    // format number so that each thousand is encoded as k, M, B, T, Q
    let n = parseFloat(nStr)
    if (n < 1e3) {
        return numberToFixedWithoutTrailingZeros(n, decimals)
    } else if (n < 1e6) {
        let l = n / 1e3
        return `${numberToFixedWithoutTrailingZeros(l, decimals)}k`
    } else if (n < 1e9) {
        let l = n / 1e6
        return `${numberToFixedWithoutTrailingZeros(l, decimals)}M`
    } else if (n < 1e12) {
        let l = n / 1e9
        return `${numberToFixedWithoutTrailingZeros(l, decimals)}B`
    } else if (n < 1e15) {
        let l = n / 1e12
        return `${numberToFixedWithoutTrailingZeros(l, decimals)}T`
    } else {
        let l = n / 1e15
        return `${numberToFixedWithoutTrailingZeros(l, decimals)}Q`
    }
}

export const getNumberParts = (
    x: number
): {
    sign: number
    exponent: number
    mantissa: number
} => {
    var float = new Float64Array(1),
        bytes = new Uint8Array(float.buffer)

    float[0] = x

    var sign = bytes[7] >> 7,
        exponent = (((bytes[7] & 0x7f) << 4) | (bytes[6] >> 4)) - 0x3ff

    bytes[7] = 0x3f
    bytes[6] |= 0xf0

    return {
        sign: sign,
        exponent: exponent,
        mantissa: float[0]
    }
}

export const numToFixNTZp2 = (v: number) => numberToFixedWithoutTrailingZeros(v, 2)

export const getOperationDepth = (points: number[]) => {
    return Array.from(new Set(points)).length
}

export const contractAddressesMatch = (coinName: string, a: string, b: string) => {
    let caOne = a.toLowerCase()
    let caTwo = b.toLowerCase()
    if (caOne === "" || caTwo === "") {
        return false
    }
    // if (caOne === "" && caTwo === "") {
    //     return false
    // }
    if (caOne === caTwo) {
        return true
    }
    if (caOne.includes(caTwo) || caTwo.includes(caOne)) {
        return true
    }
    if (coinName === "WMT") {
        let caOneParts = caOne.split("_")
        if (caOneParts.length === 1) {
            caOneParts = caOne.split("#")
        }
        let caTwoParts = caTwo.split("_")
        if (caTwoParts.length === 1) {
            caTwoParts = caTwo.split("#")
        }
        for (let caOnePart of caOneParts) {
            for (let caTwoPart of caTwoParts) {
                if (caOnePart.includes(caTwoPart) || caTwoPart.includes(caOnePart)) {
                    return true
                }
            }
        }
    }
    return false
}
