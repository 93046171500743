import { Tooltip } from "antd"
import { ReactNode } from "react"

export const formatTimeOfTheDayEmoji = (timestamp: number | Date | null): ReactNode => {
    let dt: Date = new Date()
    if (timestamp === null) {
    } else if (typeof timestamp === "number") {
        dt = new Date(dt)
    } else {
        dt = timestamp
    }

    let hours = dt.getHours()
    let emoji = ""
    if (hours >= 0 && hours < 6) {
        emoji = "🌙"
    } else if (hours >= 6 && hours < 12) {
        emoji = "🌅"
    } else if (hours >= 12 && hours < 18) {
        emoji = "☀️"
    } else if (hours >= 18 && hours < 24) {
        emoji = "🌇"
    }

    return <Tooltip title={dt.toLocaleString()}>{emoji}</Tooltip>
}
