import {
    InfoCircleTwoTone,
    QuestionCircleTwoTone,
    ReloadOutlined,
    WarningFilled,
    WarningOutlined
} from "@ant-design/icons"
import {
    Alert,
    Button,
    Col,
    DatePicker,
    Divider,
    Input,
    InputNumber,
    message as antdMessage,
    Row,
    Select,
    Spin,
    Switch,
    Tooltip,
    Typography
} from "antd"
import Table, { ColumnsType } from "antd/es/table"
import { NewMexcExchangeDriver } from "arbitrage-ts-exchange-apis"
import { SymbolInfo } from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/types"
import dayjs, { Dayjs } from "dayjs"
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { ReactElement } from "react-markdown/lib/react-markdown"
import { decodePersistentPeriodicOpportunities } from "../binary"
import { BlinkOnUpdate, FlexCol, FlexRow, Paper, StretchedDashString } from "../common"
import { useAuthContext } from "../reducers/authReducer"
import { useConfigContext } from "../reducers/configReducer"
import {
    Coin,
    EEffectiveMarginLoanCeilingQuoteAmountSource,
    Exchange,
    Network,
    PersistentPeriodicOpportunity,
    PersistentPeriodicOpportunityProcessed,
    USDTReturnExchangePair,
    WellKnownNetworkBlockTimesMap,
    WellKnwonMarginLoanCeilingExchangeCoinMap
} from "../types"
import {
    formatDurationExact,
    getTotalFeeAvg,
    useMediaQuery,
    getUSDTReturnFee,
    numberToFixedWithoutTrailingZeros,
    getUSDTReturnAlignedNetwork,
    contractAddressesMatch,
    getTotalTakerFeeRate
} from "../utils"
import { formatTimeOfTheDayEmoji } from "../utils_tsx"
import { RiskAssessmentWidget } from "../widgets/dyorWidgets"
import { StatsBarChart, StatsLineChart, StatsStackedBarChart, StatsTimeseriesChart } from "../widgets/statsCharts"
import { UnifiedExchangePairCoinTimeseriesWidget } from "../widgets/uplotCharts"

const getRowKey = (e: PersistentPeriodicOpportunity) => {
    return e.UUID
}

// Stats Chart Widget: Bar Chart with variable Quantity/Category types
const _createZeroArray = (length: number) => {
    let arr = new Array(length).fill(0).map(() => 0)
    // console.log(`_createZeroArray: arr`, arr)
    return arr
}

enum StatsChartWidgetCategoryType {
    Exchange = 1,
    ExchangePairDirectional = 3,
    Coin = 4,
    Network = 5
}

enum StatsChartWidgetQuantityType {
    NbOpportunities = 1,
    NbUniqueCoins = 2,
    NbUniqueNetworks = 3,
    ProfitSum = 4,
    CostMean = 5,
    ProfitRateMean = 6
}

const StatsChartWidget: FC<{
    filteredPersistentPeriodicOpportunities: PersistentPeriodicOpportunityProcessed[] | null
    depositAmount: number | null
}> = ({ filteredPersistentPeriodicOpportunities, depositAmount }) => {
    const [selectedCategoryType, setSelectedCategoryType] = useState<StatsChartWidgetCategoryType>(
        StatsChartWidgetCategoryType.ExchangePairDirectional
    )
    const [selectedQuantityType, setSelectedQuantityType] = useState<StatsChartWidgetQuantityType>(
        StatsChartWidgetQuantityType.ProfitSum
    )
    const [selectedSecondaryCategoryType, setSelectedSecondaryCategoryType] =
        useState<StatsChartWidgetCategoryType | null>(null)
    const [shouldAdjustToMonth, setShouldAdjustToMonth] = useState<boolean>(true)
    const [currentDtDays, setCurrentDtDays] = useState<number>(0)

    // Data
    const [categories, setCategories] = useState<string[]>([])
    const [quantities, setQuantities] = useState<number[]>([])
    const [datasets, setDatasets] = useState<Record<string, number[]>>({}) // in case of secondary category is non-null

    // Titles
    const [categoryTitle, setCategoryTitle] = useState<string>("")
    const [quantityTitle, setQuantityTitle] = useState<string>("")

    const [maxY, setMaxY] = useState<number | undefined>(undefined)

    useEffect(() => {
        if (filteredPersistentPeriodicOpportunities === null) {
            return
        }
        // set secondary category type to null if too many opportunities
        // to avoid browser blocking as chart would struggle to render
        if (filteredPersistentPeriodicOpportunities.length > 20e3) {
            setSelectedSecondaryCategoryType(null)
        }
    }, [filteredPersistentPeriodicOpportunities])

    useEffect(() => {
        if (filteredPersistentPeriodicOpportunities === null) {
            return
        }
        let t0 = Date.now()

        let _categories: string[] = []
        let _quantities: number[] = []
        let _datasets: Record<string, number[]> = {}
        let categoryTitle: string = ""
        let quantityTitle: string = ""

        switch (selectedCategoryType) {
            case StatsChartWidgetCategoryType.Exchange:
                categoryTitle = "Exchange"
                let uniqueExchangeNames = new Set<string>()
                for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                    let exFrom = persistentPO.ExchangeFrom
                    if (exFrom !== undefined) {
                        uniqueExchangeNames.add(exFrom.Name)
                    }
                    let exTo = persistentPO.ExchangeTo
                    if (exTo !== undefined) {
                        uniqueExchangeNames.add(exTo.Name)
                    }
                }
                _categories = Array.from(uniqueExchangeNames).sort()
                break
            case StatsChartWidgetCategoryType.ExchangePairDirectional:
                categoryTitle = "Exchange Pair (Directional)"
                let uniqueExchangePairNames = new Set<string>()
                for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                    let exFrom = persistentPO.ExchangeFrom
                    let exTo = persistentPO.ExchangeTo
                    if (exFrom !== undefined && exTo !== undefined) {
                        uniqueExchangePairNames.add(`${exFrom.Name}_${exTo.Name}`)
                    }
                }
                _categories = Array.from(uniqueExchangePairNames).sort()
                break
            case StatsChartWidgetCategoryType.Coin:
                categoryTitle = "Coin"
                let uniqueCoinNames = new Set<string>()
                for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                    let coin = persistentPO.Coin
                    if (coin !== undefined) {
                        uniqueCoinNames.add(coin.Name)
                    }
                }
                _categories = Array.from(uniqueCoinNames).sort()
                break
            case StatsChartWidgetCategoryType.Network:
                categoryTitle = "Network"
                let uniqueNetworkNames = new Set<string>()
                for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                    let network = persistentPO.Network
                    if (network !== undefined) {
                        uniqueNetworkNames.add(network.Name)
                    }
                }
                _categories = Array.from(uniqueNetworkNames).sort()
                break
            default:
                break
        }

        let categoryIdx = 0
        switch (selectedQuantityType) {
            case StatsChartWidgetQuantityType.NbOpportunities:
                quantityTitle = "Nb Opportunities"
                categoryIdx = 0
                for (let category of _categories) {
                    let count = 0
                    switch (selectedCategoryType) {
                        case StatsChartWidgetCategoryType.Exchange:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let exFrom = persistentPO.ExchangeFrom
                                let exTo = persistentPO.ExchangeTo
                                if (
                                    (exFrom !== undefined && exFrom.Name === category) ||
                                    (exTo !== undefined && exTo.Name === category)
                                ) {
                                    count++
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Coin:
                                            let coin = persistentPO.Coin
                                            if (coin !== undefined) {
                                                _datasets[coin.Name] =
                                                    _datasets[coin.Name] || _createZeroArray(_categories.length)
                                                _datasets[coin.Name][categoryIdx]++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Network:
                                            let network = persistentPO.Network
                                            if (network !== undefined) {
                                                _datasets[network.Name] =
                                                    _datasets[network.Name] || _createZeroArray(_categories.length)
                                                _datasets[network.Name][_categories.indexOf(category)]++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let exFrom = persistentPO.ExchangeFrom
                                            let exTo = persistentPO.ExchangeTo
                                            if (exFrom !== undefined && exTo !== undefined) {
                                                _datasets[`${exFrom.Name}_${exTo.Name}`] =
                                                    _datasets[`${exFrom.Name}_${exTo.Name}`] ||
                                                    _createZeroArray(_categories.length)
                                                _datasets[`${exFrom.Name}_${exTo.Name}`][
                                                    _categories.indexOf(category)
                                                ]++
                                            }
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                            let [exFromName, exToName] = category.split("_")
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let exFrom = persistentPO.ExchangeFrom
                                let exTo = persistentPO.ExchangeTo
                                if (
                                    exFrom !== undefined &&
                                    exTo !== undefined &&
                                    exFrom.Name === exFromName &&
                                    exTo.Name === exToName
                                ) {
                                    count++
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Coin:
                                            let coin = persistentPO.Coin
                                            if (coin !== undefined) {
                                                _datasets[coin.Name] =
                                                    _datasets[coin.Name] || _createZeroArray(_categories.length)
                                                _datasets[coin.Name][_categories.indexOf(category)]++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Network:
                                            let network = persistentPO.Network
                                            if (network !== undefined) {
                                                _datasets[network.Name] =
                                                    _datasets[network.Name] || _createZeroArray(_categories.length)
                                                _datasets[network.Name][_categories.indexOf(category)]++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Exchange:
                                            if (exFrom !== undefined && exTo !== undefined) {
                                                _datasets[exFrom.Name] =
                                                    _datasets[exFrom.Name] || _createZeroArray(_categories.length)
                                                _datasets[exFrom.Name][_categories.indexOf(category)]++
                                                _datasets[exTo.Name] =
                                                    _datasets[exTo.Name] || _createZeroArray(_categories.length)
                                                _datasets[exTo.Name][_categories.indexOf(category)]++
                                            }
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.Coin:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let coin = persistentPO.Coin
                                if (coin !== undefined && coin.Name === category) {
                                    count++
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Exchange:
                                            let exOne = persistentPO.ExchangeFrom
                                            let exTwo = persistentPO.ExchangeTo
                                            if (exOne !== undefined) {
                                                _datasets[exOne.Name] =
                                                    _datasets[exOne.Name] || _createZeroArray(_categories.length)
                                                _datasets[exOne.Name][_categories.indexOf(category)]++
                                            }
                                            if (exTwo !== undefined) {
                                                _datasets[exTwo.Name] =
                                                    _datasets[exTwo.Name] || _createZeroArray(_categories.length)
                                                _datasets[exTwo.Name][_categories.indexOf(category)]++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let exFrom = persistentPO.ExchangeFrom
                                            let exTo = persistentPO.ExchangeTo
                                            if (exFrom !== undefined && exTo !== undefined) {
                                                _datasets[`${exFrom.Name}_${exTo.Name}`] =
                                                    _datasets[`${exFrom.Name}_${exTo.Name}`] ||
                                                    _createZeroArray(_categories.length)
                                                _datasets[`${exFrom.Name}_${exTo.Name}`][
                                                    _categories.indexOf(category)
                                                ]++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Network:
                                            let network = persistentPO.Network
                                            if (network !== undefined) {
                                                _datasets[network.Name] =
                                                    _datasets[network.Name] || _createZeroArray(_categories.length)
                                                _datasets[network.Name][_categories.indexOf(category)]++
                                            }
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.Network:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let network = persistentPO.Network
                                if (network !== undefined && network.Name === category) {
                                    count++
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Exchange:
                                            let exOne = persistentPO.ExchangeFrom
                                            let exTwo = persistentPO.ExchangeTo
                                            if (exOne !== undefined) {
                                                _datasets[exOne.Name] =
                                                    _datasets[exOne.Name] || _createZeroArray(_categories.length)
                                                _datasets[exOne.Name][_categories.indexOf(category)]++
                                            }
                                            if (exTwo !== undefined) {
                                                _datasets[exTwo.Name] =
                                                    _datasets[exTwo.Name] || _createZeroArray(_categories.length)
                                                _datasets[exTwo.Name][_categories.indexOf(category)]++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let exFrom = persistentPO.ExchangeFrom
                                            let exTo = persistentPO.ExchangeTo
                                            if (exFrom !== undefined && exTo !== undefined) {
                                                _datasets[`${exFrom.Name}_${exTo.Name}`] =
                                                    _datasets[`${exFrom.Name}_${exTo.Name}`] ||
                                                    _createZeroArray(_categories.length)
                                                _datasets[`${exFrom.Name}_${exTo.Name}`][
                                                    _categories.indexOf(category)
                                                ]++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Coin:
                                            let coin = persistentPO.Coin
                                            if (coin !== undefined) {
                                                _datasets[coin.Name] =
                                                    _datasets[coin.Name] || _createZeroArray(_categories.length)
                                                _datasets[coin.Name][_categories.indexOf(category)]++
                                            }
                                            break
                                    }
                                }
                            }
                            break
                        default:
                            break
                    }
                    _quantities.push(count)
                    categoryIdx++
                }
                break
            case StatsChartWidgetQuantityType.NbUniqueCoins:
                quantityTitle = "Nb Unique Coins"
                categoryIdx = 0
                for (let category of _categories) {
                    let _datasetsUniqueCoins: Record<string, Set<string>> = {}
                    let uniqueCoins = new Set<string>()
                    switch (selectedCategoryType) {
                        case StatsChartWidgetCategoryType.Exchange:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let exFrom = persistentPO.ExchangeFrom
                                let exTo = persistentPO.ExchangeTo
                                let coin = persistentPO.Coin
                                if (
                                    (exFrom !== undefined && exFrom.Name === category && coin !== undefined) ||
                                    (exTo !== undefined && exTo.Name === category && coin !== undefined)
                                ) {
                                    uniqueCoins.add(coin.Name)
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Network:
                                            let network = persistentPO.Network
                                            if (network !== undefined) {
                                                _datasetsUniqueCoins[network.Name] =
                                                    _datasetsUniqueCoins[network.Name] || new Set()
                                                _datasetsUniqueCoins[network.Name].add(coin.Name)
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let exFrom = persistentPO.ExchangeFrom
                                            let exTo = persistentPO.ExchangeTo
                                            if (exFrom !== undefined && exTo !== undefined) {
                                                _datasetsUniqueCoins[`${exFrom.Name}_${exTo.Name}`] =
                                                    _datasetsUniqueCoins[`${exFrom.Name}_${exTo.Name}`] || new Set()
                                                _datasetsUniqueCoins[`${exFrom.Name}_${exTo.Name}`].add(coin.Name)
                                            }
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                            let [exFromName, exToName] = category.split("_")
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let exFrom = persistentPO.ExchangeFrom
                                let exTo = persistentPO.ExchangeTo
                                let coin = persistentPO.Coin
                                if (
                                    exFrom !== undefined &&
                                    exTo !== undefined &&
                                    exFrom.Name === exFromName &&
                                    exTo.Name === exToName &&
                                    coin !== undefined
                                ) {
                                    uniqueCoins.add(coin.Name)
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Network:
                                            let network = persistentPO.Network
                                            if (network !== undefined) {
                                                _datasetsUniqueCoins[network.Name] =
                                                    _datasetsUniqueCoins[network.Name] || new Set()
                                                _datasetsUniqueCoins[network.Name].add(coin.Name)
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Exchange:
                                            if (exFrom !== undefined) {
                                                _datasetsUniqueCoins[exFrom.Name] =
                                                    _datasetsUniqueCoins[exFrom.Name] || new Set()
                                                _datasetsUniqueCoins[exFrom.Name].add(coin.Name)
                                            }
                                            if (exTo !== undefined) {
                                                _datasetsUniqueCoins[exTo.Name] =
                                                    _datasetsUniqueCoins[exTo.Name] || new Set()
                                                _datasetsUniqueCoins[exTo.Name].add(coin.Name)
                                            }
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.Coin:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let coin = persistentPO.Coin
                                if (coin !== undefined && coin.Name === category) {
                                    uniqueCoins.add(coin.Name)
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Network:
                                            let network = persistentPO.Network
                                            if (network !== undefined) {
                                                _datasetsUniqueCoins[network.Name] =
                                                    _datasetsUniqueCoins[network.Name] || new Set()
                                                _datasetsUniqueCoins[network.Name].add(coin.Name)
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Exchange:
                                            let exOne = persistentPO.ExchangeFrom
                                            let exTwo = persistentPO.ExchangeTo
                                            if (exOne !== undefined) {
                                                _datasetsUniqueCoins[exOne.Name] =
                                                    _datasetsUniqueCoins[exOne.Name] || new Set()
                                                _datasetsUniqueCoins[exOne.Name].add(coin.Name)
                                            }
                                            if (exTwo !== undefined) {
                                                _datasetsUniqueCoins[exTwo.Name] =
                                                    _datasetsUniqueCoins[exTwo.Name] || new Set()
                                                _datasetsUniqueCoins[exTwo.Name].add(coin.Name)
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let exFrom = persistentPO.ExchangeFrom
                                            let exTo = persistentPO.ExchangeTo
                                            if (exFrom !== undefined && exTo !== undefined) {
                                                let directionalExchangePair = `${exFrom.Name}_${exTo.Name}`
                                                _datasetsUniqueCoins[directionalExchangePair] =
                                                    _datasetsUniqueCoins[directionalExchangePair] || new Set()
                                                _datasetsUniqueCoins[directionalExchangePair].add(coin.Name)
                                            }
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.Network:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let network = persistentPO.Network
                                let coin = persistentPO.Coin
                                if (network !== undefined && network.Name === category && coin !== undefined) {
                                    uniqueCoins.add(coin.Name)
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Exchange:
                                            let exOne = persistentPO.ExchangeFrom
                                            let exTwo = persistentPO.ExchangeTo
                                            if (exOne !== undefined) {
                                                _datasetsUniqueCoins[exOne.Name] =
                                                    _datasetsUniqueCoins[exOne.Name] || new Set()
                                                _datasetsUniqueCoins[exOne.Name].add(coin.Name)
                                            }
                                            if (exTwo !== undefined) {
                                                _datasetsUniqueCoins[exTwo.Name] =
                                                    _datasetsUniqueCoins[exTwo.Name] || new Set()
                                                _datasetsUniqueCoins[exTwo.Name].add(coin.Name)
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let exFrom = persistentPO.ExchangeFrom
                                            let exTo = persistentPO.ExchangeTo
                                            if (exFrom !== undefined && exTo !== undefined) {
                                                let directionalExchangePair = `${exFrom.Name}_${exTo.Name}`
                                                _datasetsUniqueCoins[directionalExchangePair] =
                                                    _datasetsUniqueCoins[directionalExchangePair] || new Set()
                                                _datasetsUniqueCoins[directionalExchangePair].add(coin.Name)
                                            }
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        default:
                            break
                    }
                    _quantities.push(uniqueCoins.size)
                    for (let [label, data] of Object.entries(_datasetsUniqueCoins)) {
                        _datasets[label] = _datasets[label] || _createZeroArray(_categories.length)
                        _datasets[label][categoryIdx] = data.size
                    }
                    categoryIdx++
                }
                break
            case StatsChartWidgetQuantityType.NbUniqueNetworks:
                quantityTitle = "Nb Unique Networks"
                categoryIdx = 0
                for (let category of _categories) {
                    let _datasetsUniqueNetworks: Record<string, Set<string>> = {}
                    let uniqueNetworks = new Set<string>()
                    switch (selectedCategoryType) {
                        case StatsChartWidgetCategoryType.Exchange:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let exFrom = persistentPO.ExchangeFrom
                                let exTo = persistentPO.ExchangeTo
                                let network = persistentPO.Network
                                if (
                                    (exFrom !== undefined && exFrom.Name === category && network !== undefined) ||
                                    (exTo !== undefined && exTo.Name === category && network !== undefined)
                                ) {
                                    uniqueNetworks.add(network.Name)
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Coin:
                                            let coin = persistentPO.Coin
                                            if (coin !== undefined) {
                                                _datasetsUniqueNetworks[coin.Name] =
                                                    _datasetsUniqueNetworks[coin.Name] || new Set()
                                                _datasetsUniqueNetworks[coin.Name].add(network.Name)
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let exFrom = persistentPO.ExchangeFrom
                                            let exTo = persistentPO.ExchangeTo
                                            if (exFrom !== undefined && exTo !== undefined) {
                                                _datasetsUniqueNetworks[`${exFrom.Name}_${exTo.Name}`] =
                                                    _datasetsUniqueNetworks[`${exFrom.Name}_${exTo.Name}`] || new Set()
                                                _datasetsUniqueNetworks[`${exFrom.Name}_${exTo.Name}`].add(network.Name)
                                            }
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                            let [exFromName, exToName] = category.split("_")
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let exFrom = persistentPO.ExchangeFrom
                                let exTo = persistentPO.ExchangeTo
                                let network = persistentPO.Network
                                if (
                                    exFrom !== undefined &&
                                    exTo !== undefined &&
                                    exFrom.Name === exFromName &&
                                    exTo.Name === exToName &&
                                    network !== undefined
                                ) {
                                    uniqueNetworks.add(network.Name)
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Coin:
                                            let coin = persistentPO.Coin
                                            if (coin !== undefined) {
                                                _datasetsUniqueNetworks[coin.Name] =
                                                    _datasetsUniqueNetworks[coin.Name] || new Set()
                                                _datasetsUniqueNetworks[coin.Name].add(network.Name)
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Exchange:
                                            if (exFrom !== undefined) {
                                                _datasetsUniqueNetworks[exFrom.Name] =
                                                    _datasetsUniqueNetworks[exFrom.Name] || new Set()
                                                _datasetsUniqueNetworks[exFrom.Name].add(network.Name)
                                            }
                                            if (exTo !== undefined) {
                                                _datasetsUniqueNetworks[exTo.Name] =
                                                    _datasetsUniqueNetworks[exTo.Name] || new Set()
                                                _datasetsUniqueNetworks[exTo.Name].add(network.Name)
                                            }
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.Coin:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let coin = persistentPO.Coin
                                let network = persistentPO.Network
                                if (coin !== undefined && coin.Name === category && network !== undefined) {
                                    uniqueNetworks.add(network.Name)
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Exchange:
                                            let exOne = persistentPO.ExchangeFrom
                                            let exTwo = persistentPO.ExchangeTo
                                            if (exOne !== undefined) {
                                                _datasetsUniqueNetworks[exOne.Name] =
                                                    _datasetsUniqueNetworks[exOne.Name] || new Set()
                                                _datasetsUniqueNetworks[exOne.Name].add(network.Name)
                                            }
                                            if (exTwo !== undefined) {
                                                _datasetsUniqueNetworks[exTwo.Name] =
                                                    _datasetsUniqueNetworks[exTwo.Name] || new Set()
                                                _datasetsUniqueNetworks[exTwo.Name].add(network.Name)
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let exFrom = persistentPO.ExchangeFrom
                                            let exTo = persistentPO.ExchangeTo
                                            if (exFrom !== undefined && exTo !== undefined) {
                                                let directionalExchangePair = `${exFrom.Name}_${exTo.Name}`
                                                _datasetsUniqueNetworks[directionalExchangePair] =
                                                    _datasetsUniqueNetworks[directionalExchangePair] || new Set()
                                                _datasetsUniqueNetworks[directionalExchangePair].add(network.Name)
                                            }
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.Network:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let network = persistentPO.Network
                                if (network !== undefined && network.Name === category) {
                                    uniqueNetworks.add(network.Name)
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Exchange:
                                            let exOne = persistentPO.ExchangeFrom
                                            let exTwo = persistentPO.ExchangeTo
                                            if (exOne !== undefined) {
                                                _datasetsUniqueNetworks[exOne.Name] =
                                                    _datasetsUniqueNetworks[exOne.Name] || new Set()
                                                _datasetsUniqueNetworks[exOne.Name].add(network.Name)
                                            }
                                            if (exTwo !== undefined) {
                                                _datasetsUniqueNetworks[exTwo.Name] =
                                                    _datasetsUniqueNetworks[exTwo.Name] || new Set()
                                                _datasetsUniqueNetworks[exTwo.Name].add(network.Name)
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let exFrom = persistentPO.ExchangeFrom
                                            let exTo = persistentPO.ExchangeTo
                                            if (exFrom !== undefined && exTo !== undefined) {
                                                let directionalExchangePair = `${exFrom.Name}_${exTo.Name}`
                                                _datasetsUniqueNetworks[directionalExchangePair] =
                                                    _datasetsUniqueNetworks[directionalExchangePair] || new Set()
                                                _datasetsUniqueNetworks[directionalExchangePair].add(network.Name)
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Coin:
                                            let coin = persistentPO.Coin
                                            if (coin !== undefined) {
                                                _datasetsUniqueNetworks[coin.Name] =
                                                    _datasetsUniqueNetworks[coin.Name] || new Set()
                                                _datasetsUniqueNetworks[coin.Name].add(network.Name)
                                            }
                                            break
                                    }
                                }
                            }
                            break
                        default:
                            break
                    }
                    _quantities.push(uniqueNetworks.size)
                    for (let [label, data] of Object.entries(_datasetsUniqueNetworks)) {
                        _datasets[label] = _datasets[label] || _createZeroArray(_categories.length)
                        _datasets[label][categoryIdx] = data.size
                    }
                    categoryIdx++
                }
                break
            case StatsChartWidgetQuantityType.ProfitSum:
                quantityTitle = "Profit Sum [$]"
                categoryIdx = 0
                for (let category of _categories) {
                    let sum = 0
                    switch (selectedCategoryType) {
                        case StatsChartWidgetCategoryType.Exchange:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let projectedProfit = persistentPO.EffectiveProfit
                                let exFrom = persistentPO.ExchangeFrom
                                let exTo = persistentPO.ExchangeTo
                                if (
                                    (exFrom !== undefined && exFrom.Name === category) ||
                                    (exTo !== undefined && exTo.Name === category)
                                ) {
                                    sum += projectedProfit
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Coin:
                                            let coin = persistentPO.Coin
                                            if (coin !== undefined) {
                                                _datasets[coin.Name] =
                                                    _datasets[coin.Name] || _createZeroArray(_categories.length)
                                                _datasets[coin.Name][categoryIdx] += projectedProfit
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Network:
                                            let network = persistentPO.Network
                                            if (network !== undefined) {
                                                _datasets[network.Name] =
                                                    _datasets[network.Name] || _createZeroArray(_categories.length)
                                                _datasets[network.Name][categoryIdx] += projectedProfit
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let directionalExchangePair = `${exFrom.Name}_${exTo.Name}`
                                            _datasets[directionalExchangePair] =
                                                _datasets[directionalExchangePair] ||
                                                _createZeroArray(_categories.length)
                                            _datasets[directionalExchangePair][categoryIdx] += projectedProfit
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                            let [exFromName, exToName] = category.split("_")
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let projectedProfit = persistentPO.EffectiveProfit
                                let exFrom = persistentPO.ExchangeFrom
                                let exTo = persistentPO.ExchangeTo
                                if (
                                    exFrom !== undefined &&
                                    exTo !== undefined &&
                                    exFrom.Name === exFromName &&
                                    exTo.Name === exToName
                                ) {
                                    sum += projectedProfit
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Coin:
                                            let coin = persistentPO.Coin
                                            if (coin !== undefined) {
                                                _datasets[coin.Name] =
                                                    _datasets[coin.Name] || _createZeroArray(_categories.length)
                                                _datasets[coin.Name][categoryIdx] += projectedProfit
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Network:
                                            let network = persistentPO.Network
                                            if (network !== undefined) {
                                                _datasets[network.Name] =
                                                    _datasets[network.Name] || _createZeroArray(_categories.length)
                                                _datasets[network.Name][categoryIdx] += projectedProfit
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Exchange:
                                            if (exFrom !== undefined) {
                                                _datasets[exFrom.Name] =
                                                    _datasets[exFrom.Name] || _createZeroArray(_categories.length)
                                                _datasets[exFrom.Name][categoryIdx] += projectedProfit / 2
                                            }
                                            if (exTo !== undefined) {
                                                _datasets[exTo.Name] =
                                                    _datasets[exTo.Name] || _createZeroArray(_categories.length)
                                                _datasets[exTo.Name][categoryIdx] += projectedProfit / 2
                                            }
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.Coin:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let projectedProfit = persistentPO.EffectiveProfit
                                let coin = persistentPO.Coin
                                if (coin !== undefined && coin.Name === category) {
                                    sum += projectedProfit
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Exchange:
                                            let exOne = persistentPO.ExchangeFrom
                                            let exTwo = persistentPO.ExchangeTo
                                            if (exOne !== undefined) {
                                                _datasets[exOne.Name] =
                                                    _datasets[exOne.Name] || _createZeroArray(_categories.length)
                                                _datasets[exOne.Name][categoryIdx] += projectedProfit / 2
                                            }
                                            if (exTwo !== undefined) {
                                                _datasets[exTwo.Name] =
                                                    _datasets[exTwo.Name] || _createZeroArray(_categories.length)
                                                _datasets[exTwo.Name][categoryIdx] += projectedProfit / 2
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Network:
                                            let network = persistentPO.Network
                                            if (network !== undefined) {
                                                _datasets[network.Name] =
                                                    _datasets[network.Name] || _createZeroArray(_categories.length)
                                                _datasets[network.Name][categoryIdx] += projectedProfit
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let [exFromName, exToName] = category.split("_")
                                            let exFrom = persistentPO.ExchangeFrom
                                            let exTo = persistentPO.ExchangeTo
                                            let directionalExchangePair = `${exFrom.Name}_${exTo.Name}`
                                            _datasets[directionalExchangePair] =
                                                _datasets[directionalExchangePair] ||
                                                _createZeroArray(_categories.length)
                                            _datasets[directionalExchangePair][categoryIdx] += projectedProfit
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.Network:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let projectedProfit = persistentPO.EffectiveProfit
                                let network = persistentPO.Network
                                if (network !== undefined && network.Name === category) {
                                    sum += projectedProfit
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Exchange:
                                            let exOne = persistentPO.ExchangeFrom
                                            let exTwo = persistentPO.ExchangeTo
                                            if (exOne !== undefined) {
                                                _datasets[exOne.Name] =
                                                    _datasets[exOne.Name] || _createZeroArray(_categories.length)
                                                _datasets[exOne.Name][categoryIdx] += projectedProfit / 2
                                            }
                                            if (exTwo !== undefined) {
                                                _datasets[exTwo.Name] =
                                                    _datasets[exTwo.Name] || _createZeroArray(_categories.length)
                                                _datasets[exTwo.Name][categoryIdx] += projectedProfit / 2
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Coin:
                                            let coin = persistentPO.Coin
                                            if (coin !== undefined) {
                                                _datasets[coin.Name] =
                                                    _datasets[coin.Name] || _createZeroArray(_categories.length)
                                                _datasets[coin.Name][categoryIdx] += projectedProfit
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let [exFromName, exToName] = category.split("_")
                                            let exFrom = persistentPO.ExchangeFrom
                                            let exTo = persistentPO.ExchangeTo
                                            let directionalExchangePair = `${exFrom.Name}_${exTo.Name}`
                                            _datasets[directionalExchangePair] =
                                                _datasets[directionalExchangePair] ||
                                                _createZeroArray(_categories.length)
                                            _datasets[directionalExchangePair][categoryIdx] += projectedProfit
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        default:
                            break
                    }
                    _quantities.push(sum)
                    categoryIdx++
                }
                break
            case StatsChartWidgetQuantityType.CostMean:
                quantityTitle = "Cost Mean [$]"
                categoryIdx = 0
                for (let category of _categories) {
                    let sum = 0
                    let count = 0
                    let _datasetsCosts: Record<string, { sum: number; count: number }> = {}
                    switch (selectedCategoryType) {
                        case StatsChartWidgetCategoryType.Exchange:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let projectedCost = persistentPO.AvgCumulativeCost
                                if (depositAmount !== null) {
                                    projectedCost = Math.min(persistentPO.AvgCumulativeCost, depositAmount)
                                }
                                let exFrom = persistentPO.ExchangeFrom
                                let exTo = persistentPO.ExchangeTo
                                if (
                                    (exFrom !== undefined && exFrom.Name === category) ||
                                    (exTo !== undefined && exTo.Name === category)
                                ) {
                                    sum += projectedCost
                                    count++
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Coin:
                                            let coin = persistentPO.Coin
                                            if (coin !== undefined) {
                                                _datasetsCosts[coin.Name] = _datasetsCosts[coin.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsCosts[coin.Name].sum += projectedCost
                                                _datasetsCosts[coin.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Network:
                                            let network = persistentPO.Network
                                            if (network !== undefined) {
                                                _datasetsCosts[network.Name] = _datasetsCosts[network.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsCosts[network.Name].sum += projectedCost
                                                _datasetsCosts[network.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let directionalExchangePair = `${exFrom.Name}_${exTo.Name}`
                                            _datasetsCosts[directionalExchangePair] = _datasetsCosts[
                                                directionalExchangePair
                                            ] || { sum: 0, count: 0 }
                                            _datasetsCosts[directionalExchangePair].sum += projectedCost
                                            _datasetsCosts[directionalExchangePair].count++
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                            let [exFromName, exToName] = category.split("_")
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let projectedCost = persistentPO.AvgCumulativeCost
                                if (depositAmount !== null) {
                                    projectedCost = Math.min(persistentPO.AvgCumulativeCost, depositAmount)
                                }
                                let exFrom = persistentPO.ExchangeFrom
                                let exTo = persistentPO.ExchangeTo
                                if (
                                    exFrom !== undefined &&
                                    exTo !== undefined &&
                                    exFrom.Name === exFromName &&
                                    exTo.Name === exToName
                                ) {
                                    sum += projectedCost
                                    count++
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Coin:
                                            let coin = persistentPO.Coin
                                            if (coin !== undefined) {
                                                _datasetsCosts[coin.Name] = _datasetsCosts[coin.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsCosts[coin.Name].sum += projectedCost
                                                _datasetsCosts[coin.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Network:
                                            let network = persistentPO.Network
                                            if (network !== undefined) {
                                                _datasetsCosts[network.Name] = _datasetsCosts[network.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsCosts[network.Name].sum += projectedCost
                                                _datasetsCosts[network.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Exchange:
                                            if (exFrom !== undefined) {
                                                _datasetsCosts[exFrom.Name] = _datasetsCosts[exFrom.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsCosts[exFrom.Name].sum += projectedCost / 2
                                                _datasetsCosts[exFrom.Name].count++
                                            }
                                            if (exTo !== undefined) {
                                                _datasetsCosts[exTo.Name] = _datasetsCosts[exTo.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsCosts[exTo.Name].sum += projectedCost / 2
                                                _datasetsCosts[exTo.Name].count++
                                            }
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.Coin:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let projectedCost = persistentPO.AvgCumulativeCost
                                if (depositAmount !== null) {
                                    projectedCost = Math.min(persistentPO.AvgCumulativeCost, depositAmount)
                                }
                                let coin = persistentPO.Coin
                                let exOne = persistentPO.ExchangeFrom
                                let exTwo = persistentPO.ExchangeTo
                                if (coin !== undefined && coin.Name === category) {
                                    sum += projectedCost
                                    count++
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Exchange:
                                            if (exOne !== undefined) {
                                                _datasetsCosts[exOne.Name] = _datasetsCosts[exOne.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsCosts[exOne.Name].sum += projectedCost / 2
                                                _datasetsCosts[exOne.Name].count++
                                            }
                                            if (exTwo !== undefined) {
                                                _datasetsCosts[exTwo.Name] = _datasetsCosts[exTwo.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsCosts[exTwo.Name].sum += projectedCost / 2
                                                _datasetsCosts[exTwo.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Network:
                                            let network = persistentPO.Network
                                            if (network !== undefined) {
                                                _datasetsCosts[network.Name] = _datasetsCosts[network.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsCosts[network.Name].sum += projectedCost
                                                _datasetsCosts[network.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let directionalExchangePair = `${exOne.Name}_${exTwo.Name}`
                                            _datasetsCosts[directionalExchangePair] = _datasetsCosts[
                                                directionalExchangePair
                                            ] || { sum: 0, count: 0 }
                                            _datasetsCosts[directionalExchangePair].sum += projectedCost
                                            _datasetsCosts[directionalExchangePair].count++
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.Network:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let projectedCost = persistentPO.AvgCumulativeCost
                                if (depositAmount !== null) {
                                    projectedCost = Math.min(persistentPO.AvgCumulativeCost, depositAmount)
                                }
                                let network = persistentPO.Network
                                let exOne = persistentPO.ExchangeFrom
                                let exTwo = persistentPO.ExchangeTo
                                if (network !== undefined && network.Name === category) {
                                    sum += projectedCost
                                    count++
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Exchange:
                                            if (exOne !== undefined) {
                                                _datasetsCosts[exOne.Name] = _datasetsCosts[exOne.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsCosts[exOne.Name].sum += projectedCost / 2
                                                _datasetsCosts[exOne.Name].count++
                                            }
                                            if (exTwo !== undefined) {
                                                _datasetsCosts[exTwo.Name] = _datasetsCosts[exTwo.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsCosts[exTwo.Name].sum += projectedCost / 2
                                                _datasetsCosts[exTwo.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Coin:
                                            let coin = persistentPO.Coin
                                            if (coin !== undefined) {
                                                _datasetsCosts[coin.Name] = _datasetsCosts[coin.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsCosts[coin.Name].sum += projectedCost
                                                _datasetsCosts[coin.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let directionalExchangePair = `${exOne.Name}_${exTwo.Name}`
                                            _datasetsCosts[directionalExchangePair] = _datasetsCosts[
                                                directionalExchangePair
                                            ] || { sum: 0, count: 0 }
                                            _datasetsCosts[directionalExchangePair].sum += projectedCost
                                            _datasetsCosts[directionalExchangePair].count++
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        default:
                            break
                    }
                    _quantities.push(sum / count)
                    for (let [label, { sum, count }] of Object.entries(_datasetsCosts)) {
                        _datasets[label] = _datasets[label] || _createZeroArray(_categories.length)
                        _datasets[label][categoryIdx] = sum / count
                    }
                    categoryIdx++
                }
                break
            case StatsChartWidgetQuantityType.ProfitRateMean:
                quantityTitle = "Profit Rate Mean [%]"
                categoryIdx = 0
                for (let category of _categories) {
                    let sum = 0
                    let count = 0
                    let _datasetsProfitRates: Record<string, { sum: number; count: number }> = {}
                    switch (selectedCategoryType) {
                        case StatsChartWidgetCategoryType.Exchange:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let projectedProfitRate = persistentPO.EffectiveProfitRatePerHour
                                if (projectedProfitRate === null) {
                                    // do not count opportunities with null profit rate
                                    continue
                                }
                                let exFrom = persistentPO.ExchangeFrom
                                let exTo = persistentPO.ExchangeTo
                                if (
                                    (exFrom !== undefined && exFrom.Name === category) ||
                                    (exTo !== undefined && exTo.Name === category)
                                ) {
                                    sum += projectedProfitRate
                                    count++
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Coin:
                                            let coin = persistentPO.Coin
                                            if (coin !== undefined) {
                                                _datasetsProfitRates[coin.Name] = _datasetsProfitRates[coin.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsProfitRates[coin.Name].sum += projectedProfitRate
                                                _datasetsProfitRates[coin.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Network:
                                            let network = persistentPO.Network
                                            if (network !== undefined) {
                                                _datasetsProfitRates[network.Name] = _datasetsProfitRates[
                                                    network.Name
                                                ] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsProfitRates[network.Name].sum += projectedProfitRate
                                                _datasetsProfitRates[network.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let directionalExchangePair = `${exFrom.Name}_${exTo.Name}`
                                            _datasetsProfitRates[directionalExchangePair] = _datasetsProfitRates[
                                                directionalExchangePair
                                            ] || { sum: 0, count: 0 }
                                            _datasetsProfitRates[directionalExchangePair].sum += projectedProfitRate
                                            _datasetsProfitRates[directionalExchangePair].count++
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                            let [exFromName, exToName] = category.split("_")
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let projectedProfitRate = persistentPO.EffectiveProfitRatePerHour
                                if (projectedProfitRate === null) {
                                    // do not count opportunities with null profit rate
                                    continue
                                }
                                let exFrom = persistentPO.ExchangeFrom
                                let exTo = persistentPO.ExchangeTo
                                if (
                                    exFrom !== undefined &&
                                    exTo !== undefined &&
                                    exFrom.Name === exFromName &&
                                    exTo.Name === exToName
                                ) {
                                    sum += projectedProfitRate
                                    count++
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Coin:
                                            let coin = persistentPO.Coin
                                            if (coin !== undefined) {
                                                _datasetsProfitRates[coin.Name] = _datasetsProfitRates[coin.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsProfitRates[coin.Name].sum += projectedProfitRate
                                                _datasetsProfitRates[coin.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Network:
                                            let network = persistentPO.Network
                                            if (network !== undefined) {
                                                _datasetsProfitRates[network.Name] = _datasetsProfitRates[
                                                    network.Name
                                                ] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsProfitRates[network.Name].sum += projectedProfitRate
                                                _datasetsProfitRates[network.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Exchange:
                                            if (exFrom !== undefined) {
                                                _datasetsProfitRates[exFrom.Name] = _datasetsProfitRates[
                                                    exFrom.Name
                                                ] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsProfitRates[exFrom.Name].sum += projectedProfitRate / 2
                                                _datasetsProfitRates[exFrom.Name].count++
                                            }
                                            if (exTo !== undefined) {
                                                _datasetsProfitRates[exTo.Name] = _datasetsProfitRates[exTo.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsProfitRates[exTo.Name].sum += projectedProfitRate / 2
                                                _datasetsProfitRates[exTo.Name].count++
                                            }
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.Coin:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let projectedProfitRate = persistentPO.EffectiveProfitRatePerHour
                                if (projectedProfitRate === null) {
                                    // do not count opportunities with null profit rate
                                    continue
                                }
                                let coin = persistentPO.Coin
                                let exOne = persistentPO.ExchangeFrom
                                let exTwo = persistentPO.ExchangeTo
                                if (coin !== undefined && coin.Name === category) {
                                    sum += projectedProfitRate
                                    count++
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Exchange:
                                            if (exOne !== undefined) {
                                                _datasetsProfitRates[exOne.Name] = _datasetsProfitRates[exOne.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsProfitRates[exOne.Name].sum += projectedProfitRate / 2
                                                _datasetsProfitRates[exOne.Name].count++
                                            }
                                            if (exTwo !== undefined) {
                                                _datasetsProfitRates[exTwo.Name] = _datasetsProfitRates[exTwo.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsProfitRates[exTwo.Name].sum += projectedProfitRate / 2
                                                _datasetsProfitRates[exTwo.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Network:
                                            let network = persistentPO.Network
                                            if (network !== undefined) {
                                                _datasetsProfitRates[network.Name] = _datasetsProfitRates[
                                                    network.Name
                                                ] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsProfitRates[network.Name].sum += projectedProfitRate
                                                _datasetsProfitRates[network.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let [exFromName, exToName] = category.split("_")
                                            let exFrom = persistentPO.ExchangeFrom
                                            let exTo = persistentPO.ExchangeTo
                                            let directionalExchangePair = `${exFrom.Name}_${exTo.Name}`
                                            _datasetsProfitRates[directionalExchangePair] = _datasetsProfitRates[
                                                directionalExchangePair
                                            ] || { sum: 0, count: 0 }
                                            _datasetsProfitRates[directionalExchangePair].sum += projectedProfitRate
                                            _datasetsProfitRates[directionalExchangePair].count++
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        case StatsChartWidgetCategoryType.Network:
                            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                                let projectedProfitRate = persistentPO.EffectiveProfitRatePerHour
                                if (projectedProfitRate === null) {
                                    // do not count opportunities with null profit rate
                                    continue
                                }
                                let network = persistentPO.Network
                                let exOne = persistentPO.ExchangeFrom
                                let exTwo = persistentPO.ExchangeTo
                                if (network !== undefined && network.Name === category) {
                                    sum += projectedProfitRate
                                    count++
                                    switch (selectedSecondaryCategoryType) {
                                        case StatsChartWidgetCategoryType.Exchange:
                                            if (exOne !== undefined) {
                                                _datasetsProfitRates[exOne.Name] = _datasetsProfitRates[exOne.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsProfitRates[exOne.Name].sum += projectedProfitRate / 2
                                                _datasetsProfitRates[exOne.Name].count++
                                            }
                                            if (exTwo !== undefined) {
                                                _datasetsProfitRates[exTwo.Name] = _datasetsProfitRates[exTwo.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsProfitRates[exTwo.Name].sum += projectedProfitRate / 2
                                                _datasetsProfitRates[exTwo.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.Coin:
                                            let coin = persistentPO.Coin
                                            if (coin !== undefined) {
                                                _datasetsProfitRates[coin.Name] = _datasetsProfitRates[coin.Name] || {
                                                    sum: 0,
                                                    count: 0
                                                }
                                                _datasetsProfitRates[coin.Name].sum += projectedProfitRate
                                                _datasetsProfitRates[coin.Name].count++
                                            }
                                            break
                                        case StatsChartWidgetCategoryType.ExchangePairDirectional:
                                            let [exFromName, exToName] = category.split("_")
                                            let exFrom = persistentPO.ExchangeFrom
                                            let exTo = persistentPO.ExchangeTo
                                            let directionalExchangePair = `${exFrom.Name}_${exTo.Name}`
                                            _datasetsProfitRates[directionalExchangePair] = _datasetsProfitRates[
                                                directionalExchangePair
                                            ] || { sum: 0, count: 0 }
                                            _datasetsProfitRates[directionalExchangePair].sum += projectedProfitRate
                                            _datasetsProfitRates[directionalExchangePair].count++
                                            break
                                        default:
                                            break
                                    }
                                }
                            }
                            break
                        default:
                            break
                    }
                    _quantities.push(sum / count)
                    for (let [label, { sum, count }] of Object.entries(_datasetsProfitRates)) {
                        _datasets[label] = _datasets[label] || _createZeroArray(_categories.length)
                        _datasets[label][categoryIdx] = sum / count
                    }
                    categoryIdx++
                }
                break
            default:
                break
        }

        let globalTsStartMs = 0
        let globalTsEndMs = 0
        for (let persistentPO of filteredPersistentPeriodicOpportunities) {
            let tsStartMs = new Date(persistentPO.TimestampStart).getTime()
            let tsEndMs = new Date(persistentPO.TimestampEnd).getTime()
            if (globalTsStartMs === 0) {
                globalTsStartMs = tsStartMs
            }
            if (globalTsEndMs === 0) {
                globalTsEndMs = tsEndMs
            }
            globalTsStartMs = Math.min(globalTsStartMs, tsStartMs)
            globalTsEndMs = Math.max(globalTsEndMs, tsEndMs)
        }
        let dt = globalTsEndMs - globalTsStartMs // ms
        let dtDays = dt / (1000 * 60 * 60 * 24) // days
        let k = dtDays / 30
        setCurrentDtDays(dtDays)
        if (
            shouldAdjustToMonth &&
            (selectedQuantityType === StatsChartWidgetQuantityType.NbOpportunities ||
                selectedQuantityType === StatsChartWidgetQuantityType.ProfitSum)
        ) {
            for (let i = 0; i < _quantities.length; i++) {
                _quantities[i] /= k
            }
            for (let key of Object.keys(_datasets)) {
                for (let i = 0; i < _datasets[key].length; i++) {
                    _datasets[key][i] /= k
                }
            }
        }

        setCategories(_categories)
        setQuantities(_quantities)
        setCategoryTitle(categoryTitle)
        setQuantityTitle(quantityTitle)
        setDatasets(_datasets)

        // console.log(`StatsWidget: binning done in ${Date.now() - t0} ms`)
    }, [
        filteredPersistentPeriodicOpportunities,
        depositAmount,
        selectedCategoryType,
        selectedQuantityType,
        selectedSecondaryCategoryType,
        shouldAdjustToMonth
    ])

    const memoSecondaryCategoryTypeOptions = useMemo(() => {
        let options = [
            {
                label: "Exchange Pair (Directional)",
                value: StatsChartWidgetCategoryType.ExchangePairDirectional
            },
            { label: "Exchange", value: StatsChartWidgetCategoryType.Exchange },
            { label: "Coin", value: StatsChartWidgetCategoryType.Coin },
            { label: "Network", value: StatsChartWidgetCategoryType.Network }
        ]
        options = options.filter(option => option.value !== selectedCategoryType)
        return options
    }, [selectedCategoryType])

    const memoQuantityTypeOptions = useMemo(() => {
        let options: {
            label: ReactNode
            value: StatsChartWidgetQuantityType
        }[] = [
            { label: "Nb Opportunities", value: StatsChartWidgetQuantityType.NbOpportunities },
            { label: "Nb Unique Coins", value: StatsChartWidgetQuantityType.NbUniqueCoins },
            { label: "Nb Unique Networks", value: StatsChartWidgetQuantityType.NbUniqueNetworks },
            { label: "Profit Sum [$]", value: StatsChartWidgetQuantityType.ProfitSum },
            { label: "Cost Mean [$]", value: StatsChartWidgetQuantityType.CostMean },
            { label: "Profit Rate Mean [%]", value: StatsChartWidgetQuantityType.ProfitRateMean }
        ]
        for (let option of options) {
            if (
                shouldAdjustToMonth &&
                (option.value === StatsChartWidgetQuantityType.NbOpportunities ||
                    option.value === StatsChartWidgetQuantityType.ProfitSum)
            ) {
                option.label += " (adj. monthly)"
            }
            if (option.value === selectedQuantityType) {
                option.label = <b>{option.label}</b>
            }
        }
        return options
    }, [shouldAdjustToMonth, selectedQuantityType])

    return (
        <Row gutter={[10, 10]} justify="center" align={"bottom"}>
            <Col xs={12} md={2}>
                <FlexCol
                    style={{
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <span>Adjust</span>
                    <Switch
                        checkedChildren="Monthly"
                        unCheckedChildren={<>{Math.round(currentDtDays)} days</>}
                        defaultChecked={shouldAdjustToMonth}
                        onChange={v => setShouldAdjustToMonth(v)}
                    />
                </FlexCol>
            </Col>
            <Col xs={12} md={2}>
                <FlexCol
                    style={{
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <span>Scale</span>
                    <InputNumber value={maxY} onChange={v => setMaxY(v ?? undefined)} />
                </FlexCol>
            </Col>
            <Col xs={24} md={8}>
                <FlexCol
                    style={{
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <span>Measure what:</span>
                    <Select
                        options={memoQuantityTypeOptions}
                        value={selectedQuantityType}
                        onChange={v => setSelectedQuantityType(v)}
                        style={{ width: "100%" }}
                    />
                </FlexCol>
            </Col>
            <Col xs={24} md={6}>
                <FlexCol
                    style={{
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <span>Categorize by</span>
                    <Select
                        options={[
                            {
                                label: "Exchange Pair (Directional)",
                                value: StatsChartWidgetCategoryType.ExchangePairDirectional
                            },
                            { label: "Exchange", value: StatsChartWidgetCategoryType.Exchange },
                            { label: "Coin", value: StatsChartWidgetCategoryType.Coin },
                            { label: "Network", value: StatsChartWidgetCategoryType.Network }
                        ]}
                        value={selectedCategoryType}
                        onChange={v => setSelectedCategoryType(v)}
                        style={{ width: "100%" }}
                    />
                </FlexCol>
            </Col>
            <Col xs={24} md={6}>
                <FlexCol
                    style={{
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <span>Color by</span>
                    <Select
                        allowClear
                        options={memoSecondaryCategoryTypeOptions}
                        value={selectedSecondaryCategoryType}
                        onChange={v => setSelectedSecondaryCategoryType(v)}
                        style={{ width: "100%" }}
                    />
                </FlexCol>
            </Col>
            {selectedSecondaryCategoryType === StatsChartWidgetCategoryType.Exchange &&
                (selectedQuantityType === StatsChartWidgetQuantityType.NbOpportunities ||
                    selectedQuantityType === StatsChartWidgetQuantityType.NbUniqueCoins ||
                    selectedQuantityType === StatsChartWidgetQuantityType.NbUniqueNetworks) && (
                    <Col>
                        <Alert
                            style={{ width: "100%" }}
                            type="warning"
                            message={
                                <>
                                    N.B.: bar <b>height is doubled</b> as each occurrence is counted <b>twice</b> (once
                                    for each exchange)
                                </>
                            }
                        />
                    </Col>
                )}
            {(selectedSecondaryCategoryType === StatsChartWidgetCategoryType.Coin ||
                selectedSecondaryCategoryType === StatsChartWidgetCategoryType.Network ||
                selectedSecondaryCategoryType === StatsChartWidgetCategoryType.ExchangePairDirectional) &&
                (selectedQuantityType === StatsChartWidgetQuantityType.CostMean ||
                    selectedQuantityType === StatsChartWidgetQuantityType.ProfitRateMean) && (
                    <Col>
                        <Alert
                            style={{ width: "100%" }}
                            type="warning"
                            message={
                                <>
                                    N.B.: pay attention to the <b>bar heights</b>: stacked mean values{" "}
                                    <b>may look like they sum up</b> but they do not!
                                </>
                            }
                        />
                    </Col>
                )}
            {filteredPersistentPeriodicOpportunities !== null ?
                <Col xs={18}>
                    {selectedSecondaryCategoryType ?
                        <StatsStackedBarChart
                            categories={categories}
                            categoryTitle={categoryTitle}
                            datasets={datasets}
                            quantityTitle={quantityTitle}
                            maxY={maxY}
                        />
                    :   <StatsBarChart
                            categories={categories}
                            categoryTitle={categoryTitle}
                            quantities={quantities}
                            quantityTitle={quantityTitle}
                            maxY={maxY}
                        />
                    }
                </Col>
            :   <FlexRow
                    style={{
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <Spin size="large" />
                </FlexRow>
            }
        </Row>
    )
}
// Stats Chart Widget: END

// Profit/Deposit Chart Widget: START
// Line Chart of exchange pair profit as a function of deposit amount
//
const ProfitVsDepositChartWidget: FC<{
    filterOpportunitiesCallback: (depositAmount: number) => PersistentPeriodicOpportunityProcessed[] | null
    currentDepositAmount: number | null
}> = ({ filterOpportunitiesCallback, currentDepositAmount }) => {
    const [depositAmountMin, setDepositAmountMin] = useState<number>(10)
    const [depositAmountMax, setDepositAmountMax] = useState<number>(1000)
    const [depositAmountNSteps, setDepositAmountNSteps] = useState<number>(10)

    const [maxY, setMaxY] = useState<number | undefined>(undefined)
    const [shouldAdjustToMonth, setShouldAdjustToMonth] = useState<boolean>(true)
    const [currentDtDays, setCurrentDtDays] = useState<number>(0)

    const [categories, setCategories] = useState<number[]>([]) // deposit amounts
    const [datasets, setDatasets] = useState<Record<string, number[]>>({}) // exchange pair name -> profit

    useEffect(() => {
        if (filterOpportunitiesCallback === null) {
            return
        }

        let _categories: number[] = _createZeroArray(depositAmountNSteps)
        let _datasets: Record<string, number[]> = {}

        let globalTsStartMs = 0
        let globalTsEndMs = 0

        let _step = (depositAmountMax - depositAmountMin) / depositAmountNSteps
        for (let i = 0; i < depositAmountNSteps; i++) {
            let _d = depositAmountMin + i * _step
            _categories[i] = Math.round(_d)
            let filteredPersistentPeriodicOpportunities = filterOpportunitiesCallback(_d)
            if (filteredPersistentPeriodicOpportunities === null) {
                console.warn(
                    `DepositAmountFunctionChartWidget: filterPeriodicOpportunitiesCallback returned null; will not continue the loop`
                )
                return
            }
            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                let exPair = `${persistentPO.ExchangeFrom.Name}_${persistentPO.ExchangeTo.Name}`
                if (_datasets[exPair] === undefined) {
                    _datasets[exPair] = _createZeroArray(categories.length)
                }
                _datasets[exPair][i] += persistentPO.EffectiveProfit

                // global time range
                let tsStartMs = new Date(persistentPO.TimestampStart).getTime()
                let tsEndMs = new Date(persistentPO.TimestampEnd).getTime()
                if (globalTsStartMs === 0) {
                    globalTsStartMs = tsStartMs
                }
                if (globalTsEndMs === 0) {
                    globalTsEndMs = tsEndMs
                }
                globalTsStartMs = Math.min(globalTsStartMs, tsStartMs)
                globalTsEndMs = Math.max(globalTsEndMs, tsEndMs)
            }
        }

        let dt = globalTsEndMs - globalTsStartMs // ms
        let dtDays = dt / (1000 * 60 * 60 * 24) // days
        let k = dtDays / 30
        setCurrentDtDays(dtDays)
        if (shouldAdjustToMonth) {
            for (let key of Object.keys(_datasets)) {
                for (let i = 0; i < _datasets[key].length; i++) {
                    _datasets[key][i] /= k
                }
            }
        }

        setDatasets(_datasets)
        setCategories(_categories)
    }, [filterOpportunitiesCallback, depositAmountMin, depositAmountMax, depositAmountNSteps, shouldAdjustToMonth])

    return (
        <Row gutter={[10, 10]} justify="center" align={"bottom"}>
            <Col xs={12} md={4}>
                <FlexCol
                    style={{
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <span>Adjust</span>
                    <Switch
                        checkedChildren="Monthly"
                        unCheckedChildren={<>{Math.round(currentDtDays)} days</>}
                        defaultChecked={shouldAdjustToMonth}
                        onChange={v => setShouldAdjustToMonth(v)}
                    />
                </FlexCol>
            </Col>
            <Col xs={12} md={4}>
                <FlexCol
                    style={{
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <span>Scale</span>
                    <InputNumber value={maxY} onChange={v => setMaxY(v ?? undefined)} />
                </FlexCol>
            </Col>
            <Col xs={12} md={4}>
                <FlexCol
                    style={{
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <span>Min</span>
                    <InputNumber value={depositAmountMin} onChange={v => setDepositAmountMin(v ?? 0)} />
                </FlexCol>
            </Col>
            <Col xs={12} md={4}>
                <FlexCol
                    style={{
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <span>Max</span>
                    <InputNumber value={depositAmountMax} onChange={v => setDepositAmountMax(v ?? 0)} />
                </FlexCol>
            </Col>
            <Col xs={12} md={4}>
                <FlexCol
                    style={{
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <span>Steps</span>
                    <InputNumber value={depositAmountNSteps} onChange={v => setDepositAmountNSteps(v ?? 0)} />
                </FlexCol>
            </Col>
            <Col xs={24}>
                <StatsLineChart
                    categories={categories}
                    categoryTitle={"Deposit Amount [$]"}
                    datasets={datasets}
                    quantityTitle={"Profit [$]"}
                    maxY={maxY}
                />
            </Col>
        </Row>
    )
}
// Profit/Deposit Chart Widget: END

// Profit/Time Chart Widget: START
const ProfitVsTimeChartWidget: FC<{
    filteredPersistentPeriodicOpportunities: PersistentPeriodicOpportunityProcessed[] | null
}> = ({ filteredPersistentPeriodicOpportunities }) => {
    const [categories, setCategories] = useState<Dayjs[]>([]) // datetime
    const [datasets, setDatasets] = useState<Record<string, number[]>>({}) // exchange pair name -> profit
    const [timeIncrement, setTimeIncrement] = useState<"hour" | "day" | "week">("day")
    const [isCumulative, setIsCumulative] = useState<boolean>(false)

    const [maxY, setMaxY] = useState<number | undefined>(undefined)

    useEffect(() => {
        if (filteredPersistentPeriodicOpportunities === null) {
            return
        }

        let _categories: Dayjs[] = []
        let _datasets: Record<string, number[]> = {}

        let globalTsStartMs = 0
        let globalTsEndMs = 0

        for (let persistentPO of filteredPersistentPeriodicOpportunities) {
            // global time range
            let tsStartMs = new Date(persistentPO.TimestampStart).getTime()
            let tsEndMs = new Date(persistentPO.TimestampEnd).getTime()
            if (globalTsStartMs === 0) {
                globalTsStartMs = tsStartMs
            }
            if (globalTsEndMs === 0) {
                globalTsEndMs = tsEndMs
            }
            globalTsStartMs = Math.min(globalTsStartMs, tsStartMs)
            globalTsEndMs = Math.max(globalTsEndMs, tsEndMs)
        }

        let uniqueExPairs = new Set<string>()
        for (let persistentPO of filteredPersistentPeriodicOpportunities) {
            let exPair = `${persistentPO.ExchangeFrom.Name}_${persistentPO.ExchangeTo.Name}`
            uniqueExPairs.add(exPair)
        }
        for (let exPair of Array.from(uniqueExPairs)) {
            _datasets[exPair] = []
        }

        let globalTsStartDayjs = dayjs(globalTsStartMs).startOf(timeIncrement)
        let globalTsEndDayjs = dayjs(globalTsEndMs).endOf(timeIncrement)
        let currentDj = globalTsStartDayjs
        while (currentDj < globalTsEndDayjs) {
            let nextDj = currentDj.add(1, timeIncrement)
            _categories.push(dayjs(currentDj))
            for (let exPair of Array.from(uniqueExPairs)) {
                _datasets[exPair].push(0)
            }

            for (let persistentPO of filteredPersistentPeriodicOpportunities) {
                let tsStartDj = dayjs(persistentPO.TimestampStart)
                if (tsStartDj >= currentDj && tsStartDj < nextDj) {
                    let exPair = `${persistentPO.ExchangeFrom.Name}_${persistentPO.ExchangeTo.Name}`
                    _datasets[exPair][_categories.length - 1] += persistentPO.EffectiveProfit
                }
            }

            currentDj = nextDj
        }

        if (isCumulative) {
            for (let exPair of Object.keys(_datasets)) {
                let _cumulative = 0
                for (let i = 0; i < _datasets[exPair].length; i++) {
                    _cumulative += _datasets[exPair][i]
                    _datasets[exPair][i] = 0 + _cumulative
                }
            }
        }

        setDatasets(_datasets)
        setCategories(_categories)
    }, [filteredPersistentPeriodicOpportunities, timeIncrement, isCumulative])

    return (
        <Row gutter={[10, 10]} justify="center" align={"bottom"}>
            <Col xs={12} md={4}>
                <FlexCol
                    style={{
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <span>Scale</span>
                    <InputNumber value={maxY} onChange={v => setMaxY(v ?? undefined)} />
                </FlexCol>
            </Col>
            <Col xs={12} md={6}>
                <FlexCol
                    style={{
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <span>Increment</span>
                    <Select
                        options={[
                            { label: "Hour", value: "hour" },
                            { label: "Day", value: "day" },
                            { label: "Week", value: "week" }
                        ]}
                        value={timeIncrement}
                        onChange={v => setTimeIncrement(v)}
                        // style={{ width: "100%" }}
                    />
                </FlexCol>
            </Col>
            <Col>
                <FlexCol
                    style={{
                        gap: 2,
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <span>Cumulative</span>
                    <Switch
                        checkedChildren="Yes"
                        unCheckedChildren="No"
                        defaultChecked={isCumulative}
                        onChange={v => setIsCumulative(v)}
                    />
                </FlexCol>
            </Col>
            <Col xs={24}>
                <StatsTimeseriesChart
                    categories={categories}
                    categoryTitle={"Time"}
                    datasets={datasets}
                    quantityTitle={"Profit [$]"}
                    maxY={maxY}
                />
            </Col>
        </Row>
    )
}
// Profit/Time Chart Widget: END

const PersistentTimeseriesWidget: FC<{
    record: PersistentPeriodicOpportunityProcessed
    isSimplifiedRender: boolean
    exchangesMap: Record<Exchange["ID"], Exchange>
    depositAmount: number | null
}> = ({ record, isSimplifiedRender, exchangesMap, depositAmount }) => {
    const isMobile = useMediaQuery()
    const [timeMargins, setTimeMargins] = useState<number>(10)
    return (
        <FlexCol
            style={{
                gap: 10,
                width: isMobile ? "calc(100vw - 70px)" : "100%"
            }}
        >
            <Paper
                style={{
                    width: "100%"
                }}
            >
                <FlexRow
                    style={{
                        alignItems: "center"
                    }}
                >
                    <div>Time margins (min): </div>
                    <InputNumber
                        min={10}
                        value={timeMargins}
                        onChange={value => {
                            if (typeof value === "number") {
                                setTimeMargins(value)
                            }
                        }}
                    />
                </FlexRow>
                {/* Real-time charts */}
                <UnifiedExchangePairCoinTimeseriesWidget
                    exchangeOne={exchangesMap[record.ExchangeFromID]}
                    exchangeTwo={exchangesMap[record.ExchangeToID]}
                    coinID={record.CoinID}
                    networkID={record.NetworkID}
                    depositAmount={depositAmount}
                    isSimplifiedRender={isSimplifiedRender}
                    isPersistentStorage={true}
                    persistentPeriodicOpportunityUUID={record.UUID}
                    persistentTimeMargins={timeMargins}
                />
            </Paper>
        </FlexCol>
    )
}

export const PersistentPeriodicOpportunitiesPage: FC = () => {
    const [coins, setCoins] = useState<Coin[] | null>(null)
    const [coinsMap, setCoinsMap] = useState<Record<Coin["ID"], Coin> | null>(null)

    const [exchanges, setExchanges] = useState<Exchange[] | null>(null)
    const [exchangesMap, setExchangesMap] = useState<Record<Exchange["ID"], Exchange> | null>(null)

    const [networks, setNetworks] = useState<Network[] | null>(null)
    const [networksMap, setNetworksMap] = useState<Record<Network["ID"], Network> | null>(null)

    const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState<string>("")
    const [persistentPeriodicOpportunities, setPersistentPeriodicOpportunities] = useState<
        PersistentPeriodicOpportunity[] | null
    >(null)
    const [filteredPersistentPeriodicOpportunities, setFilteredPersistentPeriodicOpportunities] = useState<
        PersistentPeriodicOpportunityProcessed[] | null
    >(null)

    const [wellKnownNetworkBlockTimesMap, setWellKnownNetworkBlockTimesMap] =
        useState<WellKnownNetworkBlockTimesMap | null>(null)
    const [wellKnownUSDTReturnExchangePairs, setWellKnownUSDTReturnExchangePairs] = useState<
        USDTReturnExchangePair[] | null
    >(null)
    const [wellKnownMarginLoanCeilingExchangeCoinMap, setWellKnownMarginLoanCeilingExchangeCoinMap] =
        useState<WellKnwonMarginLoanCeilingExchangeCoinMap | null>(null)

    // MEXC symbols map for double-checking symbol availability for API trade
    const [mexcSymbolsMap, setMexcSymbolsMap] = useState<Record<SymbolInfo["symbolName"], SymbolInfo> | null>(null)

    const [coinFilterInputValue, setCoinFilterInputValue] = useState<string>("")
    const [isSimplifiedRender, setIsSimplifiedRender] = useState<boolean>(true)
    const [shouldAccountForTakerFees, setShouldAccountForTakerFees] = useState<boolean>(true)
    const [shouldAccountMarginLoanCeiling, setShouldAccountMarginLoanCeiling] = useState<boolean>(true)
    const [isVip1OnGateAndHtx, setIsVip1OnGateAndHtx] = useState<boolean>(false)
    const [expandedRowKeys, setExpandedRowKeys] = useState<readonly string[]>([])
    const [currentPage, setCurrentPage] = useState<number>(1)
    const isMobile = useMediaQuery()
    const { callSecureAPI } = useAuthContext()

    const {
        depositAmount,
        hedgingPreference,
        minProfit,
        minCost,
        minMargin,
        minDuration,
        minNbPoints,
        maxTxTime,
        minProfitToFeeRatio,
        filteredExchangesIncluded,
        filteredExchangesExcluded,
        filteredExchangesIncludedExclusivelyFrom,
        filteredExchangesIncludedExclusivelyTo,
        excludedCoins,
        excludedNetworks,
        dateTimeRange,
        shouldCountUSDTReturnFee,
        shouldExcludeOppoortunitiesWithRatedFees,
        shouldExcludeAmbiguousContractAddressMatches,
        forceContractAddressMatchCoinNetworkPairs,
        isSimplifiedScheme,

        setDepositAmount,
        setHedgingPreference,
        setMinProfit,
        setMinCost,
        setMinMargin,
        setMinDuration,
        setMinNbPoints,
        setMaxTxTime,
        setMinProfitToFeeRatio,
        setFilteredExchangesIncluded,
        setFilteredExchangesExcluded,
        setFilteredExchangesIncludedExclusivelyFrom,
        setFilteredExchangesIncludedExclusivelyTo,
        setExcludedCoins,
        setExcludedNetworks,
        setDateTimeRange,
        setShouldCountUSDTReturnFee,
        setShouldExcludeOppoortunitiesWithRatedFees,
        setShouldExcludeAmbiguousContractAddressMatches,
        setForceContractAddressMatchCoinNetworkPairs,
        setIsSimplifiedScheme
    } = useConfigContext()

    const loadPersistentPeriodicOpportunitiesMutCB = useCallback(async () => {
        setPersistentPeriodicOpportunities(null)
        try {
            let uri = `/persistent/periodic/opportunities`
            let query = new URLSearchParams()
            if (dateTimeRange[0] === null) {
                console.log(`dateTimeRange[0] is null, will not send the request..`)
                return
            }
            query.set("from", dateTimeRange[0].toISOString())
            if (dateTimeRange[1] !== null) {
                query.set("to", dateTimeRange[1].toISOString())
            }
            if (hedgingPreference) {
                query.set("margin_trade_is_available_to", "true")
            }
            if (minDuration !== null) {
                query.set("min_duration", minDuration.toString())
            }
            if (minNbPoints !== null) {
                query.set("min_nb_points", minNbPoints.toString())
            }
            uri += `?${query.toString()}`
            let { responseObject: responseArrayBuffer } = await callSecureAPI<ArrayBuffer | null>(uri)
            if (responseArrayBuffer === null) {
                return
            }
            let persistentEvents = decodePersistentPeriodicOpportunities(responseArrayBuffer)
            setPersistentPeriodicOpportunities(persistentEvents.Opportunities)
            setLastUpdatedTimestamp(persistentEvents.Timestamp)
        } catch (e) {
            console.error(`Failed to get persistent events: ${e}`)
            antdMessage.error(`Failed to get persistent events: ${e}`)
        }
    }, [dateTimeRange, hedgingPreference, minDuration, minNbPoints])

    // Well-known network block times map
    useEffect(() => {
        callSecureAPI<WellKnownNetworkBlockTimesMap | null>("/well-known/network/block/time/map").then(
            ({ responseObject: wellKnownNetworkBlockTimes }) => {
                setWellKnownNetworkBlockTimesMap(wellKnownNetworkBlockTimes)
            }
        )
    }, [])

    // Well-known USDT return aligned networks
    useEffect(() => {
        callSecureAPI<USDTReturnExchangePair[] | null>("/well-known/exchange/pairs/usdt-return/aligned-networks").then(
            ({ responseObject: exchangePairs }) => {
                // console.log(`Well-known USDT return aligned networks`, exchangePairs)
                setWellKnownUSDTReturnExchangePairs(exchangePairs)
            }
        )
    }, [])

    // Well-known margin loan ceiling exchange-coin map
    useEffect(() => {
        callSecureAPI<WellKnwonMarginLoanCeilingExchangeCoinMap | null>(
            "/well-known/exchange/coin/margin-loan-ceiling"
        ).then(({ responseObject: exchangeCoinMap }) => {
            console.log(`Well-known margin loan ceiling exchange-coin map`, exchangeCoinMap)
            setWellKnownMarginLoanCeilingExchangeCoinMap(exchangeCoinMap)
        })
    }, [])

    // Coins
    useEffect(() => {
        callSecureAPI<Coin[] | null>("/coins").then(({ responseObject: coins }) => {
            setCoins(coins)
        })
    }, [])
    // CoinsMap
    useEffect(() => {
        if (coins === null || coins.length === 0) {
            return
        }
        const _coinsMap: Record<Coin["ID"], Coin> = {}
        for (const coin of coins) {
            _coinsMap[coin.ID] = coin
        }
        setCoinsMap(_coinsMap)
    }, [coins])

    // Exchanges
    useEffect(() => {
        callSecureAPI<Exchange[] | null>("/exchanges").then(({ responseObject: exchanges }) => {
            setExchanges(exchanges)
        })
    }, [])

    // ExchangesMap
    useEffect(() => {
        if (exchanges === null || exchanges.length === 0) {
            return
        }
        if (filteredExchangesIncluded === null) {
            setFilteredExchangesIncluded(exchanges.map(e => e.Name))
        }
        // if (filteredExchangesOut === null) {
        //     setFilteredExchangesOut(exchanges.map((e) => e.Name))
        // }
        const _exchangesMap: Record<Exchange["ID"], Exchange> = {}
        for (const exchange of exchanges) {
            _exchangesMap[exchange.ID] = exchange
        }
        setExchangesMap(_exchangesMap)
    }, [exchanges])

    // Networks
    useEffect(() => {
        callSecureAPI<Network[] | null>("/networks").then(({ responseObject: networks }) => {
            setNetworks(networks)
        })
    }, [])

    // NetworksMap
    useEffect(() => {
        if (networks === null || networks.length === 0) {
            return
        }
        const _networksMap: Record<Network["ID"], Network> = {}
        for (const network of networks) {
            _networksMap[network.ID] = network
        }
        setNetworksMap(_networksMap)
    }, [networks])

    // MEXC symbols
    useEffect(() => {
        let mexcDriver = NewMexcExchangeDriver({
            apiKey: "",
            secretKey: "",
            passphrase: ""
        })
        mexcDriver.getSpotSymbols().then(({ symbols: _spotSymbols }) => {
            let spotSymbolsMap: Record<SymbolInfo["symbolName"], SymbolInfo> = {}
            for (let symbol of _spotSymbols) {
                if (symbol.quoteAsset !== "USDT") {
                    continue
                }
                spotSymbolsMap[symbol.symbolName] = symbol
            }
            // console.log(`PersistenPeriodicOpportunitiesPage: MEXC symbols`, spotSymbolsMap)
            setMexcSymbolsMap(spotSymbolsMap)
        })
    }, [])

    useEffect(() => {
        void loadPersistentPeriodicOpportunitiesMutCB()
    }, [dateTimeRange])

    // useEffect(() => {
    //     if (automaticRefreshEnabled) {
    //         const interval = setInterval(() => {
    //             void loadPersistentEventsMutCB()
    //         }, OPPORTUNITIES_AUTO_REFRESH_INTERVAL_MS)
    //         return () => {
    //             clearInterval(interval)
    //         }
    //     }
    // }, [automaticRefreshEnabled, authToken])

    const _filterPerisitentOpportunitiesDependencies = [
        persistentPeriodicOpportunities,
        exchangesMap,
        coinsMap,
        networksMap,
        wellKnownNetworkBlockTimesMap,
        wellKnownUSDTReturnExchangePairs,
        wellKnownMarginLoanCeilingExchangeCoinMap,

        minProfit,
        minCost,
        minMargin,
        minDuration,
        minNbPoints,
        maxTxTime,
        minProfitToFeeRatio,

        hedgingPreference,
        filteredExchangesIncluded,
        filteredExchangesExcluded,

        coinFilterInputValue,

        filteredExchangesIncludedExclusivelyFrom,
        filteredExchangesIncludedExclusivelyTo,
        excludedCoins,
        dateTimeRange,

        shouldCountUSDTReturnFee,
        shouldExcludeOppoortunitiesWithRatedFees,
        shouldExcludeAmbiguousContractAddressMatches,
        forceContractAddressMatchCoinNetworkPairs,
        isSimplifiedScheme,
        shouldAccountForTakerFees,
        shouldAccountMarginLoanCeiling,
        isVip1OnGateAndHtx
    ]
    const filterPersistentOpportunitiesCallback = useCallback(
        (_depositAmount: number | null): PersistentPeriodicOpportunityProcessed[] | null => {
            if (
                persistentPeriodicOpportunities === null ||
                exchangesMap === null ||
                coinsMap === null ||
                networksMap === null
            ) {
                return null
            }
            let _filteredOpportunities: PersistentPeriodicOpportunityProcessed[] = []
            for (let persistentPO of persistentPeriodicOpportunities) {
                if (coinFilterInputValue !== "") {
                    let coinName = coinsMap[persistentPO.CoinID]?.Name
                    let matchesCoinName = coinName.toLowerCase().includes(coinFilterInputValue.toLowerCase())
                    let matchesUUID = persistentPO.UUID.toLowerCase().includes(coinFilterInputValue.toLowerCase())
                    if (!matchesCoinName && !matchesUUID) {
                        continue
                    }
                }

                let exchangeFrom = exchangesMap[persistentPO.ExchangeFromID]
                let exchangeTo = exchangesMap[persistentPO.ExchangeToID]
                let coin = coinsMap[persistentPO.CoinID]
                let network = networksMap[persistentPO.NetworkID]

                if (mexcSymbolsMap !== null && (exchangeFrom.Name === "MEXC" || exchangeTo.Name === "MEXC")) {
                    let symbolInfo = mexcSymbolsMap[coin.SymbolName]
                    if (symbolInfo === undefined) {
                        console.debug(
                            `PersistentPeriodicOpportunitiesPage: MEXC symbol not found in mexcSymbolsMap for ${coin.SymbolName}`
                        )
                        continue
                    }
                    if (symbolInfo.isAvailable === false) {
                        continue
                    }
                }

                if (hedgingPreference) {
                    if (!persistentPO.MarginTradeIsAvailableTo) {
                        continue
                    }
                }

                if (filteredExchangesIncluded !== null && filteredExchangesIncluded.length > 0) {
                    if (
                        !filteredExchangesIncluded.includes(exchangeFrom.Name) &&
                        !filteredExchangesIncluded.includes(exchangeTo.Name)
                    ) {
                        continue
                    }
                }
                if (filteredExchangesExcluded !== null && filteredExchangesExcluded.length > 0) {
                    if (
                        filteredExchangesExcluded.includes(exchangeFrom.Name) ||
                        filteredExchangesExcluded.includes(exchangeTo.Name)
                    ) {
                        continue
                    }
                }
                if (
                    filteredExchangesIncludedExclusivelyFrom !== null &&
                    filteredExchangesIncludedExclusivelyFrom.length > 0
                ) {
                    if (!filteredExchangesIncludedExclusivelyFrom.includes(exchangeFrom.Name)) {
                        continue
                    }
                }
                if (
                    filteredExchangesIncludedExclusivelyTo !== null &&
                    filteredExchangesIncludedExclusivelyTo.length > 0
                ) {
                    if (!filteredExchangesIncludedExclusivelyTo.includes(exchangeTo.Name)) {
                        continue
                    }
                }

                if (shouldExcludeOppoortunitiesWithRatedFees) {
                    if (
                        persistentPO.AvgFeeAdjustedROI === null ||
                        persistentPO.WithdrawFeeRateFrom > 0 ||
                        persistentPO.DepositFeeRateTo > 0
                    ) {
                        continue
                    }
                }

                if (excludedCoins !== null) {
                    let coin = coinsMap[persistentPO.CoinID]
                    if (coin !== undefined) {
                        if (excludedCoins.includes(coin.Name)) {
                            continue
                        }
                    }
                }

                if (dateTimeRange[0] !== null) {
                    if (new Date(persistentPO.TimestampStart).getTime() < dateTimeRange[0].valueOf()) {
                        continue
                    }
                }

                if (dateTimeRange[1] !== null) {
                    if (new Date(persistentPO.TimestampEnd).getTime() > dateTimeRange[1].valueOf()) {
                        continue
                    }
                }

                // ContractAddress match
                // Case 0: Coin@Network are forced to match contract addresses => OK
                // Case 1: CoinName === NetworkName => native coin => OK
                // Case 2: CoinName !== NetworkName => token => check contract addresseses
                if (shouldExcludeAmbiguousContractAddressMatches) {
                    let coinNetworkPairStr = `${coin}@${network}`
                    if (!forceContractAddressMatchCoinNetworkPairs.includes(coinNetworkPairStr)) {
                        if (
                            coin.Name !== network.Name &&
                            !contractAddressesMatch(
                                coin.Name,
                                persistentPO.ContractAddressFrom,
                                persistentPO.ContractAddressTo
                            )
                        ) {
                            continue
                        }
                    }
                }

                let durationMs =
                    new Date(persistentPO.TimestampEnd).getTime() - new Date(persistentPO.TimestampStart).getTime()
                if (minDuration !== null) {
                    if (durationMs < minDuration * 1e3) {
                        continue
                    }
                }

                let txTimeSecFwd: number | null = null
                if (wellKnownNetworkBlockTimesMap !== null) {
                    let networkBlockTime = wellKnownNetworkBlockTimesMap[network.Name]
                    if (persistentPO.DepositNbConfirmations > 0 && networkBlockTime !== undefined) {
                        txTimeSecFwd = persistentPO.DepositNbConfirmations * networkBlockTime
                    }
                    if (maxTxTime !== null && txTimeSecFwd !== null && txTimeSecFwd > maxTxTime) {
                        continue
                    }
                }
                let txTimeSecBwd: number | null = null
                let usdtReturnAlignedNetwork = getUSDTReturnAlignedNetwork(
                    persistentPO,
                    exchangesMap,
                    wellKnownUSDTReturnExchangePairs
                )
                if (usdtReturnAlignedNetwork !== null) {
                    txTimeSecBwd = usdtReturnAlignedNetwork.DepositTxTimeS
                }

                let txTimeSecRoundTrip: number | null = null
                if (txTimeSecFwd !== null && txTimeSecBwd !== null) {
                    txTimeSecRoundTrip = 60 + (txTimeSecFwd + txTimeSecBwd)
                }

                if (minNbPoints !== null) {
                    if (persistentPO.NbPoints < minNbPoints) {
                        continue
                    }
                }

                let effectiveProfit = 0
                let effectiveCost = 0
                let effectiveROI = 0

                if (_depositAmount === null || _depositAmount === 0) {
                    effectiveCost = persistentPO.AvgCumulativeCost
                    effectiveProfit = persistentPO.AvgCumulativeProfit
                } else {
                    effectiveCost = Math.min(_depositAmount, persistentPO.AvgCumulativeCost)
                    effectiveProfit =
                        effectiveCost * (persistentPO.AvgCumulativeProfit / persistentPO.AvgCumulativeCost)
                }

                let effectiveMarginLoanCeiling:
                    | PersistentPeriodicOpportunityProcessed["EffectiveMarginLoanCeilingQuoteAmount"]
                    | null = null
                if (shouldAccountMarginLoanCeiling) {
                }
                if (persistentPO.MarginLoanCeilingQuoteAmountTo !== null) {
                    effectiveMarginLoanCeiling = {
                        Value: persistentPO.MarginLoanCeilingQuoteAmountTo,
                        Source: EEffectiveMarginLoanCeilingQuoteAmountSource.Internal
                    }
                } else {
                    let _marginLoanCeilingExternalValue =
                        wellKnownMarginLoanCeilingExchangeCoinMap?.[exchangeTo.Name]?.[coin.Name] ?? null
                    if (_marginLoanCeilingExternalValue !== null) {
                        effectiveMarginLoanCeiling = {
                            Value: _marginLoanCeilingExternalValue,
                            Source: EEffectiveMarginLoanCeilingQuoteAmountSource.External
                        }
                    }
                }
                if (effectiveMarginLoanCeiling !== null) {
                    if (exchangeTo.Name === "HTX" && effectiveMarginLoanCeiling.Value === 22) {
                        effectiveMarginLoanCeiling.Value = 0
                    }
                    if (!isVip1OnGateAndHtx) {
                        switch (exchangeTo.Name) {
                            case "GATE":
                                effectiveMarginLoanCeiling.Value /= 10
                                break
                            case "HTX":
                                effectiveMarginLoanCeiling.Value /= 2
                                break
                        }
                    }
                }
                if (effectiveMarginLoanCeiling !== null && shouldAccountMarginLoanCeiling) {
                    if (effectiveCost > effectiveMarginLoanCeiling.Value) {
                        effectiveCost = effectiveMarginLoanCeiling.Value
                        effectiveProfit =
                            effectiveCost * (persistentPO.AvgCumulativeProfit / persistentPO.AvgCumulativeCost)
                    }
                }
                let totalFeeUSDT: number = 0
                let totalFeeWDFwdUSDT: number = 0
                let totalFeeWDBwdUSDT: number = 0
                let totalFeeTakerRate: number = 0
                let totalFeeTakerUSDT: number = 0

                // Calculate W/D fwd/bwd fees in any case
                totalFeeWDFwdUSDT = getTotalFeeAvg(persistentPO, effectiveCost)
                if (shouldCountUSDTReturnFee) {
                    totalFeeWDBwdUSDT = getUSDTReturnFee(persistentPO, exchangesMap, wellKnownUSDTReturnExchangePairs)
                }
                if (!isSimplifiedScheme) {
                    // classic arbitrage scheme
                    if (shouldAccountForTakerFees) {
                        totalFeeTakerRate = getTotalTakerFeeRate({
                            ExchangeFrom: exchangeFrom,
                            ExchangeTo: exchangeTo,
                            Coin: coin
                        })
                        totalFeeTakerUSDT = totalFeeTakerRate * effectiveCost
                    }
                    totalFeeUSDT = totalFeeWDFwdUSDT + totalFeeWDBwdUSDT + totalFeeTakerUSDT
                } else {
                    if (shouldAccountForTakerFees) {
                        totalFeeTakerRate = getTotalTakerFeeRate({
                            ExchangeFrom: exchangeFrom,
                            ExchangeTo: exchangeTo,
                            Coin: coin
                        })
                        totalFeeTakerRate *= 2 // both ways
                        totalFeeTakerUSDT = totalFeeTakerRate * effectiveCost
                    }
                    totalFeeUSDT = totalFeeTakerUSDT
                }
                effectiveProfit -= totalFeeUSDT
                effectiveROI = 100 * (effectiveProfit / effectiveCost)

                if (minProfit !== null) {
                    if (effectiveProfit < minProfit) {
                        continue
                    }
                }

                if (minCost !== null) {
                    if (effectiveCost < minCost) {
                        continue
                    }
                }
                if (minMargin !== null && effectiveROI !== null) {
                    if (effectiveROI < minMargin) {
                        continue
                    }
                }

                let effectiveProfitRatePerHour: number | null = null
                if (txTimeSecRoundTrip !== null && txTimeSecRoundTrip > 0) {
                    effectiveProfitRatePerHour = effectiveProfit / (txTimeSecRoundTrip / 3600)
                }

                let effectiveProfitToFeeRatio: number | null = null
                if (effectiveProfit !== null && totalFeeUSDT !== 0) {
                    effectiveProfitToFeeRatio = effectiveProfit / totalFeeUSDT
                }

                if (minProfitToFeeRatio !== null && effectiveProfitToFeeRatio !== null) {
                    if (effectiveProfitToFeeRatio < minProfitToFeeRatio) {
                        continue
                    }
                }

                _filteredOpportunities.push({
                    ...persistentPO,

                    ExchangeFrom: exchangeFrom,
                    ExchangeTo: exchangeTo,
                    Coin: coin,
                    Network: network,

                    DepositAmount: _depositAmount,
                    EffectiveProfit: effectiveProfit,
                    EffectiveCost: effectiveCost,
                    EffectiveROI: effectiveROI,
                    TxTimeFwdSec: txTimeSecFwd,
                    TxTimeBwdSec: txTimeSecBwd,
                    TxTimeRoundTripSec: txTimeSecRoundTrip,
                    EffectiveProfitRatePerHour: effectiveProfitRatePerHour,
                    EffectiveUSDTReturnAlignedNetwork: usdtReturnAlignedNetwork,
                    EffectiveProfitToFeeRatio: effectiveProfitToFeeRatio,
                    EffectiveTotalFeeWDFwdUSDT: totalFeeWDFwdUSDT,
                    EffectiveTotalFeeWDBwdUSDT: totalFeeWDBwdUSDT,
                    EffectiveTotalFeeTakerUSDT: totalFeeTakerUSDT,
                    EffectiveTotalFeeTakerRate: totalFeeTakerRate,
                    EffectiveTotalFeeUSDT: totalFeeUSDT,
                    EffectiveMarginLoanCeilingQuoteAmount: effectiveMarginLoanCeiling
                })
            }
            return _filteredOpportunities
        },
        _filterPerisitentOpportunitiesDependencies
    )

    useEffect(() => {
        setFilteredPersistentPeriodicOpportunities(filterPersistentOpportunitiesCallback(depositAmount))
    }, [..._filterPerisitentOpportunitiesDependencies, depositAmount])

    const memoColumns = useMemo(() => {
        let _columns: ColumnsType<PersistentPeriodicOpportunityProcessed> = []
        if (
            exchangesMap === null ||
            coinsMap === null ||
            networksMap === null ||
            wellKnownNetworkBlockTimesMap === null
        ) {
            return _columns
        }
        _columns = [
            {
                title: "Title",
                ellipsis: true,
                width: isMobile ? "30vw" : "15vw",
                render: (_, record) => {
                    let exFromName = exchangesMap[record.ExchangeFromID]?.Name
                    let exToName = exchangesMap[record.ExchangeToID]?.Name
                    let coinName = coinsMap[record.CoinID]?.Name
                    return (
                        <Typography.Text>
                            {exFromName} → {exToName} | <b>{coinName}</b>
                        </Typography.Text>
                    )
                }
            },
            // {
            //     title: "TxOK",
            //     render: (_, record) => {
            //         if (record.TransactionOK === false) {
            //             return <Typography.Text type="danger">❌</Typography.Text>
            //         }
            //         return <Typography.Text type="success">✅</Typography.Text>
            //     },
            //     sorter: (a, b) => {
            //         return (
            //             a.TransactionOK === b.TransactionOK ? 0
            //             : a.TransactionOK ? -1
            //             : 1
            //         )
            //     }
            // },
            {
                title: "Profit [$]",
                render: (_, record) => {
                    return (
                        <Typography.Text>
                            {numberToFixedWithoutTrailingZeros(record.EffectiveProfit, 2)}
                        </Typography.Text>
                    )
                },
                sorter: (a, b) => {
                    return a.EffectiveProfit - b.EffectiveProfit
                },
                sortDirections: ["descend", "ascend"]
            },
            {
                title: "TLiq [$]",
                ellipsis: true,
                render: (_, record) => {
                    let values: ReactElement[] = [
                        <Typography.Text>{record.AvgCumulativeCost.toFixed(2)}</Typography.Text>
                    ]
                    if (record.EffectiveMarginLoanCeilingQuoteAmount !== null) {
                        let color = "green"
                        if (record.EffectiveMarginLoanCeilingQuoteAmount.Value < record.AvgCumulativeCost) {
                            color = "red"
                        }
                        let icon = <WarningOutlined style={{ color }} />
                        if (
                            record.EffectiveMarginLoanCeilingQuoteAmount.Source ===
                            EEffectiveMarginLoanCeilingQuoteAmountSource.Internal
                        ) {
                            icon = <WarningFilled style={{ color }} />
                        }
                        values.push(
                            <Tooltip
                                title={<span>{record.EffectiveMarginLoanCeilingQuoteAmount.Value.toFixed(0)}</span>}
                            >
                                {icon}
                            </Tooltip>
                        )
                    }
                    return <FlexRow style={{ gap: 3 }}>{values}</FlexRow>
                },
                sorter: (a, b) => a.AvgCumulativeCost - b.AvgCumulativeCost,
                sortDirections: ["descend", "ascend"]
            },
            {
                title: "ELiq [$]",
                ellipsis: true,
                render: (_, record) => {
                    let values: ReactElement[] = [<Typography.Text>{record.EffectiveCost.toFixed(2)}</Typography.Text>]
                    if (record.EffectiveMarginLoanCeilingQuoteAmount !== null) {
                        let color = "green"
                        if (record.EffectiveMarginLoanCeilingQuoteAmount.Value < record.EffectiveCost) {
                            color = "red"
                        }
                        let icon = <WarningOutlined style={{ color }} />
                        if (
                            record.EffectiveMarginLoanCeilingQuoteAmount.Source ===
                            EEffectiveMarginLoanCeilingQuoteAmountSource.Internal
                        ) {
                            icon = <WarningFilled style={{ color }} />
                        }
                        values.push(
                            <Tooltip
                                title={<span>{record.EffectiveMarginLoanCeilingQuoteAmount.Value.toFixed(0)}</span>}
                            >
                                {icon}
                            </Tooltip>
                        )
                    }
                    return <FlexRow style={{ gap: 3 }}>{values}</FlexRow>
                },
                sorter: (a, b) => a.EffectiveCost - b.EffectiveCost,
                sortDirections: ["descend", "ascend"]
            },
            {
                title: "ROI [%]",
                render: (_, record) => {
                    if (record.EffectiveROI === null) {
                        return <Typography.Text>N/A</Typography.Text>
                    }
                    return <Typography.Text>{record.EffectiveROI.toFixed(2)}</Typography.Text>
                },
                sorter: (a, b) => {
                    if (a.EffectiveROI === null && b.EffectiveROI === null) {
                        return 0
                    } else if (a.EffectiveROI === null) {
                        return -1
                    } else if (b.EffectiveROI === null) {
                        return 1
                    }
                    return a.EffectiveROI - b.EffectiveROI
                },
                sortDirections: ["descend", "ascend"]
            },
            {
                title: "Start",
                render: (_, record) => {
                    let d = new Date(record.TimestampStart)
                    let agoMs = Date.now() - d.getTime()
                    let agoStr = formatDurationExact(agoMs)
                    return (
                        <Typography.Text>
                            {agoStr} ago {formatTimeOfTheDayEmoji(d)}
                        </Typography.Text>
                    )
                },
                sorter: (a, b) => {
                    return new Date(a.TimestampStart).getTime() - new Date(b.TimestampStart).getTime()
                },
                sortDirections: ["descend", "ascend"]
            },
            {
                title: "End",
                render: (_, record) => {
                    let d = new Date(record.TimestampEnd)
                    let agoMs = Date.now() - d.getTime()
                    if (agoMs < 30e3) {
                        return <Typography.Text>🟢</Typography.Text>
                    }
                    let agoStr = formatDurationExact(agoMs)
                    return (
                        <Typography.Text>
                            {agoStr} ago {formatTimeOfTheDayEmoji(d)}
                        </Typography.Text>
                    )
                },
                sorter: (a, b) => {
                    return new Date(a.TimestampEnd).getTime() - new Date(b.TimestampEnd).getTime()
                },
                sortDirections: ["descend", "ascend"]
            }
            // {
            //     title: "dT",
            //     render: (_, record) => {
            //         let durationMs = new Date(record.TimestampEnd).getTime() - new Date(record.TimestampStart).getTime()
            //         return <Typography.Text>{formatDurationExact(durationMs)}</Typography.Text>
            //     },
            //     sorter: (a, b) => {
            //         let dA = new Date(a.TimestampEnd).getTime() - new Date(a.TimestampStart).getTime()
            //         let dB = new Date(b.TimestampEnd).getTime() - new Date(b.TimestampStart).getTime()
            //         return dA - dB
            //     },
            //     sortDirections: ["descend", "ascend"]
            // },
            // {
            //     title: "#P",
            //     render: (_, record) => {
            //         return <Typography.Text>{record.NbPoints}</Typography.Text>
            //     },
            //     sorter: (a, b) => a.NbPoints - b.NbPoints,
            //     sortDirections: ["descend", "ascend"]
            // }
        ]

        if (!isSimplifiedScheme) {
            _columns = [
                ..._columns,
                // {
                //     title: "Fwd Tx Time",
                //     render: (_, record) => {
                //         let network = networksMap[record.NetworkID]
                //         if (network === undefined) {
                //             return null
                //         }
                //         let networkBlockTime = wellKnownNetworkBlockTimesMap?.[network.Name]
                //         if (networkBlockTime === undefined && record.DepositNbConfirmations > 0) {
                //             return (
                //                 <Tooltip title={<>{record.DepositNbConfirmations} confs</>}>
                //                     <Typography.Text>N/A [noBT]</Typography.Text>
                //                 </Tooltip>
                //             )
                //         }
                //         if (networkBlockTime !== undefined && record.DepositNbConfirmations === 0) {
                //             return (
                //                 <Tooltip title={<>{networkBlockTime} s/block</>}>
                //                     <Typography.Text>NA [noCN]</Typography.Text>
                //                 </Tooltip>
                //             )
                //         }
                //         if (record.TxTimeFwdSec === null) {
                //             return <Typography.Text>N/A</Typography.Text>
                //         }
                //         return (
                //             <Tooltip
                //                 title={
                //                     <>
                //                         {network.Name}: {record.DepositNbConfirmations} confs
                //                     </>
                //                 }
                //             >
                //                 <Typography.Text>{formatDurationExact(record.TxTimeFwdSec * 1e3)}</Typography.Text>
                //             </Tooltip>
                //         )
                //     },
                //     sorter: (a, b) => {
                //         if (a.TxTimeFwdSec === null && b.TxTimeFwdSec === null) {
                //             return 0
                //         } else if (a.TxTimeFwdSec === null) {
                //             return -1
                //         } else if (b.TxTimeFwdSec === null) {
                //             return 1
                //         }
                //         return a.TxTimeFwdSec - b.TxTimeFwdSec
                //     },
                //     sortDirections: ["descend", "ascend"]
                // },
                {
                    title: "Net",
                    render: (_, record) => {
                        let network = networksMap[record.NetworkID]
                        if (network === undefined) {
                            return <Typography.Text>N/A</Typography.Text>
                        }
                        return <Typography.Text>{network?.Name}</Typography.Text>
                    },
                    sorter: (a, b) => {
                        let networkA = networksMap[a.NetworkID]
                        let networkB = networksMap[b.NetworkID]
                        if (networkA === undefined || networkB === undefined) {
                            return 0
                        }
                        return networkA.Name.localeCompare(networkB.Name)
                    },
                    sortDirections: ["descend", "ascend"]
                },
                {
                    title: "Tx Time",
                    render: (_, record) => {
                        if (record.TxTimeRoundTripSec === null) {
                            return <Typography.Text>N/A</Typography.Text>
                        }
                        if (record.TxTimeFwdSec === null || record.TxTimeBwdSec === null) {
                            return (
                                <Typography.Text>
                                    {formatDurationExact(record.TxTimeRoundTripSec * 1e3)}
                                </Typography.Text>
                            )
                        }
                        return (
                            <Tooltip
                                title={
                                    <>
                                        :1 + {formatDurationExact(record.TxTimeFwdSec * 1e3)} +{" "}
                                        {formatDurationExact(record.TxTimeBwdSec * 1e3)}
                                    </>
                                }
                            >
                                <Typography.Text>
                                    {formatDurationExact(record.TxTimeRoundTripSec * 1e3)}
                                </Typography.Text>
                            </Tooltip>
                        )
                    },
                    sorter: (a, b) => {
                        if (a.TxTimeRoundTripSec === null && b.TxTimeRoundTripSec === null) {
                            return 0
                        } else if (a.TxTimeRoundTripSec === null) {
                            return -1
                        } else if (b.TxTimeRoundTripSec === null) {
                            return 1
                        }
                        return a.TxTimeRoundTripSec - b.TxTimeRoundTripSec
                    },
                    sortDirections: ["descend", "ascend"]
                },
                {
                    title: "Profit Rate [$/h]",
                    render: (_, record) => {
                        if (record.EffectiveProfitRatePerHour === null) {
                            return <Typography.Text>N/A</Typography.Text>
                        }
                        return <Typography.Text>{record.EffectiveProfitRatePerHour.toFixed(2)}</Typography.Text>
                    },
                    sorter: (a, b) => {
                        if (a.EffectiveProfitRatePerHour === null && b.EffectiveProfitRatePerHour === null) {
                            return 0
                        } else if (a.EffectiveProfitRatePerHour === null) {
                            return -1
                        } else if (b.EffectiveProfitRatePerHour === null) {
                            return 1
                        }
                        return a.EffectiveProfitRatePerHour - b.EffectiveProfitRatePerHour
                    },
                    sortDirections: ["descend", "ascend"]
                }
            ]
        } else {
            _columns.push({
                title: "cos(mP, mVAP)",
                render: (_, record) => {
                    if (record.AvgVirtualAntiProfit === 0) {
                        return <Typography.Text>N/A</Typography.Text>
                    }
                    let value =
                        record.AvgCumulativeProfit /
                        Math.sqrt(Math.pow(record.AvgCumulativeProfit, 2) + Math.pow(record.AvgVirtualAntiProfit, 2))
                    let valueStr = value.toFixed(2)
                    return <Typography.Text>{valueStr}</Typography.Text>
                },
                sorter: (a, b) => {
                    let aValue =
                        a.AvgCumulativeProfit /
                        Math.sqrt(Math.pow(a.AvgCumulativeProfit, 2) + Math.pow(a.AvgVirtualAntiProfit, 2))
                    let bValue =
                        b.AvgCumulativeProfit /
                        Math.sqrt(Math.pow(b.AvgCumulativeProfit, 2) + Math.pow(b.AvgVirtualAntiProfit, 2))
                    return aValue - bValue
                }
            })
            _columns.push({
                title: "m[P/VAP]",
                render: (_, record) => {
                    if (record.AvgProfitToVirtualAntiProfitRatio === 0) {
                        return <Typography.Text>N/A</Typography.Text>
                    }
                    let valueStr = record.AvgProfitToVirtualAntiProfitRatio.toFixed(2)
                    return <Typography.Text>{valueStr}</Typography.Text>
                },
                sorter: (a, b) => {
                    if (a.AvgVirtualAntiProfit === 0 && b.AvgVirtualAntiProfit === 0) {
                        return 0
                    } else if (a.AvgVirtualAntiProfit === 0) {
                        return -1
                    } else if (b.AvgVirtualAntiProfit === 0) {
                        return 1
                    }
                    return a.AvgProfitToVirtualAntiProfitRatio - b.AvgProfitToVirtualAntiProfitRatio
                }
            })
        }

        _columns = [
            ..._columns,
            {
                title: <Tooltip title="Already deducted from Profit">Total Fee</Tooltip>,
                render: (_, record) => {
                    return (
                        <Tooltip
                            overlay={
                                <>
                                    <ul>
                                        <li>
                                            W/D Forward fees: $
                                            {numberToFixedWithoutTrailingZeros(record.EffectiveTotalFeeWDFwdUSDT, 2)}
                                        </li>
                                        <li>
                                            W/D Backward fees: $
                                            {numberToFixedWithoutTrailingZeros(record.EffectiveTotalFeeWDBwdUSDT, 2)}
                                        </li>
                                        <li>
                                            Taker Fees: $
                                            {numberToFixedWithoutTrailingZeros(record.EffectiveTotalFeeTakerUSDT, 2)} (
                                            {numberToFixedWithoutTrailingZeros(
                                                record.EffectiveTotalFeeTakerRate * 100,
                                                2
                                            )}
                                            %)
                                        </li>
                                    </ul>
                                </>
                            }
                        >
                            <Typography.Text>
                                {numberToFixedWithoutTrailingZeros(record.EffectiveTotalFeeUSDT, 2)}
                            </Typography.Text>
                        </Tooltip>
                    )
                },
                sorter: (a, b) => {
                    return a.EffectiveTotalFeeUSDT - b.EffectiveTotalFeeUSDT
                },
                sortDirections: ["descend", "ascend"]
            },
            {
                title: "P/F",
                render: (_, record) => {
                    if (record.EffectiveProfitToFeeRatio === null) {
                        return <Typography.Text>N/A</Typography.Text>
                    }
                    let valueStr = record.EffectiveProfitToFeeRatio.toFixed(2)
                    if (record.EffectiveProfitToFeeRatio > 10) {
                        return <Tooltip title={valueStr}>&gt;10</Tooltip>
                    } else {
                        return <Typography.Text>{valueStr}</Typography.Text>
                    }
                },
                sorter: (a, b) => {
                    if (a.EffectiveProfitToFeeRatio === null && b.EffectiveProfitToFeeRatio === null) {
                        return 0
                    } else if (a.EffectiveProfitToFeeRatio === null) {
                        return -1
                    } else if (b.EffectiveProfitToFeeRatio === null) {
                        return 1
                    }
                    return a.EffectiveProfitToFeeRatio - b.EffectiveProfitToFeeRatio
                },
                sortDirections: ["descend", "ascend"]
            },
            {
                title: "",
                width: "4rem",
                render: (_, record) => {
                    return (
                        <FlexRow
                            style={{
                                gap: 5
                            }}
                        >
                            <RiskAssessmentWidget
                                periodicOpportunity={record}
                                exchangesMap={exchangesMap}
                                coinsMap={coinsMap}
                                networksMap={networksMap}
                                fontSize="1.5rem"
                                forceCoinNetworkPairMatch={forceContractAddressMatchCoinNetworkPairs}
                            />
                        </FlexRow>
                    )
                }
            }
        ]
        return _columns
    }, [
        exchangesMap,
        coinsMap,
        networksMap,
        wellKnownNetworkBlockTimesMap,
        isMobile,
        forceContractAddressMatchCoinNetworkPairs,
        isSimplifiedScheme,
        wellKnownMarginLoanCeilingExchangeCoinMap
    ])

    const memoLastUpdateTimestamp = useMemo((): string => {
        if (lastUpdatedTimestamp === null) {
            return "N/A"
        }
        return new Date(lastUpdatedTimestamp).toLocaleTimeString()
    }, [lastUpdatedTimestamp])

    const expandedRowRenderCB = useCallback(
        (record: PersistentPeriodicOpportunityProcessed) => {
            if (exchangesMap === null) {
                return null
            }
            return (
                <Row gutter={[10, 10]}>
                    <Col xs={24} md={12}>
                        <ul>
                            <li>
                                <Typography.Text copyable>{record.UUID}</Typography.Text>
                            </li>
                            <li>
                                <b>Duration: </b>
                                {formatDurationExact(
                                    new Date(record.TimestampEnd).getTime() - new Date(record.TimestampStart).getTime()
                                )}{" "}
                                | <b># Points: </b>
                                {record.NbPoints}
                            </li>
                            <li>
                                <b>Avg Profit: </b>
                                {record.AvgCumulativeProfit.toFixed(2)} | <b>Avg VAP: </b>
                                {record.AvgVirtualAntiProfit.toFixed(2)} (
                                {(record.AvgCumulativeProfit / record.AvgVirtualAntiProfit).toFixed(2)})
                            </li>
                            <li>
                                <b>Taker Fee(s): </b>${record.EffectiveTotalFeeTakerUSDT.toFixed(2)}
                            </li>
                        </ul>
                    </Col>
                    <Col xs={24} md={12}>
                        <ul>
                            <li>
                                <b>W/D Tx: </b> {record.TransactionOK ? "✅" : "❌"}
                            </li>
                            <li>
                                <b>W/D Network: </b> {record.Network.Name} ⇒ (⇐
                                {record.EffectiveUSDTReturnAlignedNetwork?.NetworkCommonCode})
                            </li>
                            <li>
                                <b>W/D Fee: </b> ${record.EffectiveTotalFeeWDFwdUSDT.toFixed(2)} + $
                                {record.EffectiveTotalFeeWDBwdUSDT.toFixed(2)}
                            </li>
                            <li>
                                <b>W/D Time: </b>{" "}
                                {record.TxTimeRoundTripSec ?
                                    formatDurationExact(record.TxTimeRoundTripSec * 1e3)
                                :   "N/A"}{" "}
                                ({record.DepositNbConfirmations}confs x {record.Network.BlockTimeSec}sec)
                            </li>
                        </ul>
                    </Col>
                    <Col xs={24}>
                        <PersistentTimeseriesWidget
                            record={record}
                            depositAmount={depositAmount}
                            isSimplifiedRender={isSimplifiedRender}
                            exchangesMap={exchangesMap}
                        />
                    </Col>
                </Row>
            )
        },
        [exchangesMap, depositAmount, isSimplifiedRender]
    )

    const onExpandRowCB = useCallback(
        (expanded: boolean, record: PersistentPeriodicOpportunityProcessed) => {
            if (expanded) {
                setExpandedRowKeys([getRowKey(record)])
            } else {
                setExpandedRowKeys([])
            }
        },
        [setExpandedRowKeys]
    )

    const memoForceContractAddressMatchCoinNetworkPairsOptions = useMemo(() => {
        let _options: { label: string; value: string }[] = []
        for (let forcedPair of forceContractAddressMatchCoinNetworkPairs) {
            _options.push({
                label: forcedPair,
                value: forcedPair
            })
        }

        let uniqueCoinNetworkPairs = new Set<string>()
        for (let opp of persistentPeriodicOpportunities ?? []) {
            let coinName = coinsMap?.[opp.CoinID]?.Name
            let networkName = networksMap?.[opp.NetworkID]?.Name
            if (coinName !== undefined && networkName !== undefined) {
                uniqueCoinNetworkPairs.add(`${coinName}@${networkName}`)
            }
        }
        let uniqueCoinNetworkPairsArr = Array.from(uniqueCoinNetworkPairs)
        uniqueCoinNetworkPairsArr.sort()
        for (let pair of uniqueCoinNetworkPairsArr) {
            _options.push({
                label: pair,
                value: pair
            })
        }

        return _options
    }, [persistentPeriodicOpportunities, forceContractAddressMatchCoinNetworkPairs])

    if (
        coins === null ||
        coinsMap === null ||
        exchanges === null ||
        exchangesMap === null ||
        networks === null ||
        networksMap === null ||
        memoColumns === null
    ) {
        return null
    }

    return (
        <Row gutter={[10, 10]} justify="end">
            <Col xs={12}>
                Last update: <BlinkOnUpdate value={memoLastUpdateTimestamp} />
            </Col>
            <Col xs={12}>
                <FlexRow
                    style={{
                        alignItems: "center",
                        justifyContent: "end"
                    }}
                >
                    <Button
                        type="text"
                        size="large"
                        icon={<ReloadOutlined />}
                        onClick={() => {
                            void loadPersistentPeriodicOpportunitiesMutCB()
                        }}
                    />
                    {/* <Tooltip overlay={<>Auto-refresh</>} placement="bottom">
                        <Switch
                            checked={automaticRefreshEnabled}
                            onChange={checked => {
                                setAutomaticRefreshEnabled(checked)
                            }}
                        />
                    </Tooltip> */}
                </FlexRow>
            </Col>
            <Col xs={24}>
                <Paper>
                    <FlexCol
                        style={{
                            alignItems: "start",
                            height: "100%"
                        }}
                    >
                        <FlexCol
                            style={{
                                width: "100%",
                                justifyContent: "space-between"
                            }}
                        >
                            <FlexRow
                                style={{
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <FlexRow>
                                    Deposit amount [$]
                                    <Tooltip
                                        overlay={
                                            <>
                                                Enter the amount of your deposit to estimate your personalized maximum
                                                profit.
                                                <br />
                                                <br />
                                                If the cost of the operation is higher than your deposit, the profit
                                                will be capped at the amount of your deposit.
                                                <br />
                                                <br />
                                                If the cost of the operation is lower than your deposit, the profit will
                                                be the same as the maximum profit of the operation.
                                            </>
                                        }
                                        placement="bottom"
                                        overlayInnerStyle={{
                                            minWidth: 400
                                        }}
                                    >
                                        <QuestionCircleTwoTone />
                                    </Tooltip>
                                </FlexRow>
                                <StretchedDashString />
                                <FlexRow>
                                    <InputNumber
                                        min={0}
                                        value={depositAmount}
                                        onChange={value => {
                                            setDepositAmount(value)
                                        }}
                                    />
                                </FlexRow>
                            </FlexRow>
                            <FlexRow
                                style={{
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                            >
                                <FlexCol
                                    style={{
                                        gap: 0
                                    }}
                                >
                                    <span>
                                        Only <b>hedging-ready</b> positions
                                    </span>
                                    <i
                                        style={{
                                            fontSize: 9
                                        }}
                                    >
                                        (with MARGIN trade available on receiving exchange)
                                    </i>
                                </FlexCol>
                                <StretchedDashString />
                                <Switch
                                    checked={hedgingPreference}
                                    onChange={checked => {
                                        setHedgingPreference(checked)
                                    }}
                                />
                            </FlexRow>
                            <Divider />
                            <Row gutter={[10, 10]}>
                                <Col>
                                    <FlexCol style={{ gap: 0 }}>
                                        <b>Min Duration</b>
                                        <InputNumber
                                            min={0}
                                            value={minDuration}
                                            onChange={value => {
                                                setMinDuration(value)
                                            }}
                                            suffix="s"
                                        />
                                    </FlexCol>
                                </Col>
                                <Col>
                                    <FlexCol style={{ gap: 0 }}>
                                        <b>Max TX Time</b>
                                        <InputNumber
                                            min={0}
                                            value={maxTxTime}
                                            onChange={value => {
                                                setMaxTxTime(value)
                                            }}
                                            suffix="s"
                                        />
                                    </FlexCol>
                                </Col>
                                <Col>
                                    <FlexCol style={{ gap: 0 }}>
                                        <b>Min Nb Points</b>
                                        <InputNumber
                                            min={0}
                                            value={minNbPoints}
                                            onChange={value => {
                                                setMinNbPoints(value)
                                            }}
                                            suffix=""
                                        />
                                    </FlexCol>
                                </Col>
                                <Col>
                                    <FlexCol style={{ gap: 0 }}>
                                        <b>Min Profit</b>
                                        <InputNumber
                                            min={0}
                                            value={minProfit}
                                            onChange={value => {
                                                setMinProfit(value)
                                            }}
                                            suffix="$"
                                        />
                                    </FlexCol>
                                </Col>
                                <Col>
                                    <FlexCol style={{ gap: 0 }}>
                                        <b>Min Investment</b>
                                        <InputNumber
                                            min={0}
                                            value={minCost}
                                            onChange={value => {
                                                setMinCost(value)
                                            }}
                                            suffix="$"
                                        />
                                    </FlexCol>
                                </Col>
                                <Col>
                                    <FlexCol style={{ gap: 0 }}>
                                        <b>Min ROI</b>
                                        <InputNumber
                                            min={0}
                                            value={minMargin}
                                            onChange={value => {
                                                setMinMargin(value)
                                            }}
                                            suffix="%"
                                        />
                                    </FlexCol>
                                </Col>
                                <Col>
                                    <FlexCol style={{ gap: 0 }}>
                                        <b>Min P/F</b>
                                        <InputNumber
                                            min={0}
                                            value={minProfitToFeeRatio}
                                            onChange={value => {
                                                setMinProfitToFeeRatio(value)
                                            }}
                                            suffix=""
                                        />
                                    </FlexCol>
                                </Col>
                                <Col flex="auto">
                                    <FlexCol
                                        style={{
                                            gap: 0
                                        }}
                                    >
                                        <b>Search UUID or Coin</b>
                                        <Input
                                            value={coinFilterInputValue}
                                            placeholder="Filter by UUID coin name.."
                                            style={{
                                                minWidth: "50%",
                                                maxWidth: "100%"
                                            }}
                                            onChange={e => {
                                                setCoinFilterInputValue(e.target.value)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                            </Row>
                            {/* <Flex */}
                            <Row gutter={[10, 10]}>
                                {/* Include exchanges (From & To) */}
                                <Col xs={24} md={12}>
                                    <FlexCol
                                        style={{
                                            alignItems: "start",
                                            gap: 1
                                        }}
                                    >
                                        <span>
                                            Filter exchanges (include){" "}
                                            <Tooltip overlay="INCLUDE opportunities that involve (in any direction) any of selected exchanges">
                                                <InfoCircleTwoTone />
                                            </Tooltip>
                                        </span>
                                        <FlexRow
                                            style={{
                                                gap: 2,
                                                width: "100%"
                                            }}
                                        >
                                            <Select
                                                mode="multiple"
                                                style={{
                                                    width: "100%"
                                                }}
                                                allowClear
                                                options={exchanges.map(e => ({
                                                    label: e.Name,
                                                    value: e.Name
                                                }))}
                                                value={filteredExchangesIncluded}
                                                onChange={value => {
                                                    setFilteredExchangesIncluded(value)
                                                }}
                                            />
                                            <Button
                                                icon={<ReloadOutlined />}
                                                onClick={() => {
                                                    setFilteredExchangesIncluded(exchanges.map(e => e.Name))
                                                }}
                                            />
                                        </FlexRow>
                                    </FlexCol>
                                </Col>
                                {/* Exclude exchanges (From & To) */}
                                <Col xs={24} md={12}>
                                    <FlexCol
                                        style={{
                                            alignItems: "start",
                                            gap: 1
                                        }}
                                    >
                                        <span>
                                            Filter exchanges (exclude){" "}
                                            <Tooltip overlay="EXCLUDE opportunities that involve (in any direction) any of selected exchanges">
                                                <InfoCircleTwoTone />
                                            </Tooltip>
                                        </span>
                                        <Select
                                            mode="multiple"
                                            style={{
                                                width: "100%"
                                            }}
                                            allowClear
                                            options={exchanges.map(e => ({
                                                label: e.Name,
                                                value: e.Name
                                            }))}
                                            value={filteredExchangesExcluded || []}
                                            onChange={value => {
                                                setFilteredExchangesExcluded(value)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                                {/* Exclusively include exchanges (From) */}
                                <Col xs={24} md={12}>
                                    <FlexCol>
                                        <span>
                                            Only include exchanges (FROM){" "}
                                            <Tooltip overlay="ONLY INCLUDE opportunities that involve only selected exchanges FROM">
                                                <InfoCircleTwoTone />
                                            </Tooltip>
                                        </span>
                                        <Select
                                            mode="multiple"
                                            style={{
                                                width: "100%"
                                            }}
                                            allowClear
                                            options={exchanges.map(e => ({
                                                label: e.Name,
                                                value: e.Name
                                            }))}
                                            value={filteredExchangesIncludedExclusivelyFrom ?? []}
                                            onChange={value => {
                                                setFilteredExchangesIncludedExclusivelyFrom(value)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                                {/* Exclusively include exchanges (To) */}
                                <Col xs={24} md={12}>
                                    <FlexCol>
                                        <span>
                                            Only include exchanges (TO){" "}
                                            <Tooltip overlay="ONLY INCLUDE opportunities that involve only selected exchanges TO">
                                                <InfoCircleTwoTone />
                                            </Tooltip>
                                        </span>
                                        <Select
                                            mode="multiple"
                                            style={{
                                                width: "100%"
                                            }}
                                            allowClear
                                            options={exchanges.map(e => ({
                                                label: e.Name,
                                                value: e.Name
                                            }))}
                                            value={filteredExchangesIncludedExclusivelyTo ?? []}
                                            onChange={value => {
                                                setFilteredExchangesIncludedExclusivelyTo(value)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                                {/* Exclude Coins */}
                                <Col xs={24} md={12}>
                                    <FlexCol
                                        style={{
                                            alignItems: "start",
                                            gap: 1
                                        }}
                                    >
                                        <span>
                                            Exclude coins{" "}
                                            <Tooltip overlay="Exclude opportunities that involve selected coins">
                                                <InfoCircleTwoTone />
                                            </Tooltip>
                                        </span>
                                        <Select
                                            mode="multiple"
                                            style={{
                                                width: "100%"
                                            }}
                                            allowClear
                                            options={coins.map(e => ({
                                                label: e.Name,
                                                value: e.Name
                                            }))}
                                            value={excludedCoins ?? []}
                                            onChange={value => {
                                                setExcludedCoins(value)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                                {/* Exclude networks */}
                                <Col xs={24} md={12}>
                                    <FlexCol
                                        style={{
                                            alignItems: "start",
                                            gap: 1
                                        }}
                                    >
                                        <span>
                                            Exclude networks{" "}
                                            <Tooltip overlay="Exclude opportunities that involve selected networks">
                                                <InfoCircleTwoTone />
                                            </Tooltip>
                                        </span>
                                        <Select
                                            mode="multiple"
                                            style={{
                                                width: "100%"
                                            }}
                                            allowClear
                                            options={networks.map(e => ({
                                                label: e.Name,
                                                value: e.Name
                                            }))}
                                            value={excludedNetworks ?? []}
                                            onChange={value => {
                                                setExcludedNetworks(value)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                                {/* Force Contract Address Match */}
                                <Col xs={24} md={12}>
                                    <FlexCol
                                        style={{
                                            alignItems: "start",
                                            gap: 1
                                        }}
                                    >
                                        <span>
                                            Exclude networks{" "}
                                            <Tooltip overlay="Force Coin@Network pairs to match CA">
                                                <InfoCircleTwoTone />
                                            </Tooltip>
                                        </span>
                                        <Select
                                            mode="multiple"
                                            style={{
                                                width: "100%"
                                            }}
                                            allowClear
                                            options={memoForceContractAddressMatchCoinNetworkPairsOptions}
                                            value={forceContractAddressMatchCoinNetworkPairs ?? []}
                                            onChange={value => {
                                                setForceContractAddressMatchCoinNetworkPairs(value)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                                {/* Search period */}
                                <Col xs={24} md={12}>
                                    <FlexCol
                                        style={{
                                            alignItems: "start",
                                            gap: 1
                                        }}
                                    >
                                        <span>Search period</span>
                                        <DatePicker.RangePicker
                                            style={{
                                                width: "100%"
                                            }}
                                            presets={[
                                                {
                                                    label: "Last 3h",
                                                    value: [dayjs().subtract(3, "hour"), dayjs()]
                                                },
                                                {
                                                    label: "Last 6h",
                                                    value: [dayjs().subtract(6, "hour"), dayjs()]
                                                },
                                                {
                                                    label: "Last 12h",
                                                    value: [dayjs().subtract(12, "hour"), dayjs()]
                                                },
                                                {
                                                    label: "Last 24h",
                                                    value: [dayjs().subtract(1, "day"), dayjs()]
                                                },
                                                {
                                                    label: "Last 3 days",
                                                    value: [dayjs().subtract(3, "day"), dayjs()]
                                                },
                                                {
                                                    label: "Last week",
                                                    value: [dayjs().subtract(1, "week"), dayjs()]
                                                },
                                                {
                                                    label: "Last 2 weeks",
                                                    value: [dayjs().subtract(2, "week"), dayjs()]
                                                },
                                                {
                                                    label: "Last 3 weeks",
                                                    value: [dayjs().subtract(3, "week"), dayjs()]
                                                },
                                                {
                                                    label: "Last month",
                                                    value: [dayjs().subtract(1, "month"), dayjs()]
                                                }
                                            ]}
                                            format="MM-DD HH:mm"
                                            allowClear
                                            showTime
                                            allowEmpty={[true, true]}
                                            showNow={true}
                                            value={dateTimeRange}
                                            onChange={value => {
                                                if (value === null) {
                                                    setDateTimeRange([null, null])
                                                    return
                                                }
                                                setDateTimeRange(value)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                                <Col>
                                    <FlexCol
                                        style={{
                                            gap: 5,
                                            alignItems: "start",
                                            justifyContent: "start"
                                        }}
                                    >
                                        <span>Simple Charts</span>
                                        <Switch
                                            checked={isSimplifiedRender}
                                            onChange={checked => {
                                                setIsSimplifiedRender(checked)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                                <Col>
                                    <FlexCol
                                        style={{
                                            gap: 5,
                                            alignItems: "start",
                                            justifyContent: "start"
                                        }}
                                    >
                                        <span>Exclude Rated Fees</span>
                                        <Switch
                                            checked={shouldExcludeOppoortunitiesWithRatedFees}
                                            onChange={checked => {
                                                setShouldExcludeOppoortunitiesWithRatedFees(checked)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                                <Col>
                                    <FlexCol
                                        style={{
                                            gap: 5,
                                            alignItems: "start",
                                            justifyContent: "start"
                                        }}
                                    >
                                        <span>Strict CA match</span>
                                        <Switch
                                            checked={shouldExcludeAmbiguousContractAddressMatches}
                                            onChange={checked => {
                                                setShouldExcludeAmbiguousContractAddressMatches(checked)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                                <Col>
                                    <FlexCol
                                        style={{
                                            gap: 5,
                                            alignItems: "start",
                                            justifyContent: "start"
                                        }}
                                    >
                                        <span>Arbitrage Scheme</span>
                                        <Switch
                                            checkedChildren="Simple"
                                            unCheckedChildren="Classic"
                                            checked={isSimplifiedScheme}
                                            onChange={checked => {
                                                setIsSimplifiedScheme(checked)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                                <Col>
                                    <FlexCol
                                        style={{
                                            gap: 5,
                                            alignItems: "start",
                                            justifyContent: "start"
                                        }}
                                    >
                                        <span>W/ USDT ⇐ Fee</span>
                                        <Switch
                                            disabled={isSimplifiedScheme}
                                            checked={shouldCountUSDTReturnFee}
                                            onChange={checked => {
                                                setShouldCountUSDTReturnFee(checked)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                                <Col>
                                    <FlexCol
                                        style={{
                                            gap: 5,
                                            alignItems: "start",
                                            justifyContent: "start"
                                        }}
                                    >
                                        <span>W/ Taker Fees</span>
                                        <Switch
                                            checked={shouldAccountForTakerFees}
                                            onChange={checked => {
                                                setShouldAccountForTakerFees(checked)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                                <Col>
                                    <FlexCol
                                        style={{
                                            gap: 5,
                                            alignItems: "start",
                                            justifyContent: "start"
                                        }}
                                    >
                                        <span>W/ MarginLoanCeiling</span>
                                        <Switch
                                            checked={shouldAccountMarginLoanCeiling}
                                            onChange={checked => {
                                                setShouldAccountMarginLoanCeiling(checked)
                                            }}
                                        />
                                    </FlexCol>
                                </Col>
                                <Col>
                                    <FlexCol
                                        style={{
                                            gap: 5,
                                            alignItems: "start",
                                            justifyContent: "start"
                                        }}
                                    >
                                        <span>
                                            VIP<i style={{ fontSize: "0.5rem" }}>Gate, HTX</i>
                                        </span>
                                        <Switch
                                            checked={isVip1OnGateAndHtx}
                                            onChange={checked => {
                                                setIsVip1OnGateAndHtx(checked)
                                            }}
                                            checkedChildren="VIP1"
                                            unCheckedChildren="VIP0"
                                        />
                                    </FlexCol>
                                </Col>
                            </Row>
                        </FlexCol>
                    </FlexCol>
                </Paper>
            </Col>
            <Col xs={24}>
                <Paper>
                    <FlexCol
                        style={{
                            gap: 2
                        }}
                    >
                        <FlexRow
                            style={{
                                gap: 5
                            }}
                        >
                            <span>
                                Showing{" "}
                                <b>
                                    {(filteredPersistentPeriodicOpportunities ?? []).length} /{" "}
                                    {(persistentPeriodicOpportunities ?? []).length}
                                </b>{" "}
                                opportunities
                            </span>
                        </FlexRow>
                    </FlexCol>
                </Paper>
            </Col>
            {/* Statistics: Bar */}
            <Col xs={24}>
                <Paper>
                    <Row gutter={[10, 10]}>
                        <Col xs={24}>
                            <StatsChartWidget
                                filteredPersistentPeriodicOpportunities={filteredPersistentPeriodicOpportunities}
                                depositAmount={depositAmount}
                            />
                        </Col>
                    </Row>
                </Paper>
            </Col>
            {/* Statistics: Profit(Deposit) Line */}
            <Col xs={12}>
                <Paper>
                    <ProfitVsDepositChartWidget
                        filterOpportunitiesCallback={filterPersistentOpportunitiesCallback}
                        currentDepositAmount={depositAmount}
                    />
                </Paper>
            </Col>
            <Col xs={12}>
                <Paper>
                    <ProfitVsTimeChartWidget
                        filteredPersistentPeriodicOpportunities={filteredPersistentPeriodicOpportunities}
                    />
                </Paper>
            </Col>
            {/* Statistics */}
            <Col xs={24}>
                <Paper>
                    <Table
                        columns={memoColumns}
                        loading={filteredPersistentPeriodicOpportunities === null}
                        dataSource={filteredPersistentPeriodicOpportunities ?? []}
                        rowKey={r => getRowKey(r)}
                        size="small"
                        style={{
                            width: "100%"
                        }}
                        bordered
                        scroll={{
                            x: true //isMobile ? "100%" : undefined
                        }}
                        pagination={{
                            current: currentPage,
                            onChange: page => {
                                setCurrentPage(page)
                            },
                            defaultPageSize: 25,
                            showSizeChanger: true,
                            hideOnSinglePage: true
                        }}
                        expandable={{
                            expandedRowRender: expandedRowRenderCB,
                            // onExpand: onExpandRowCB,
                            // expandedRowKeys: expandedRowKeys,
                            expandRowByClick: false
                        }}
                    />
                </Paper>
            </Col>
        </Row>
    )
}
