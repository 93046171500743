import { FC, ReactElement, useCallback, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import {
    Exchange,
    Coin,
    Network,
    OpportunityTimeseries,
    PeriodicOpportunitiesResult,
    PeriodicOpportunityResult,
    InstantOpportunity,
    WellKnownFeeRatesMap,
    PersistentPeriodicOpportunity
} from "../types"
import { FlexCol as div, FlexCol, FlexRow, Paper } from "../common"
import { OpportunityFullInformationWidget } from "../widgets/opportunitiesWidgets"
import { useAuthContext } from "../reducers/authReducer"
import {
    Avatar,
    Badge,
    Button,
    Col,
    Empty,
    InputNumber,
    Row,
    Spin,
    Switch,
    Tooltip,
    message as antMessage,
    List,
    Divider
} from "antd"
import { getExchangePairCoinTimeseriesParams } from "../api"
import { decodePeriodicOpportunity } from "../binary"
import { QuestionCircleTwoTone, ReloadOutlined } from "@ant-design/icons"
import { OPPORTUNITIES_AUTO_REFRESH_INTERVAL_MS } from "../constants"
import { useConfigContext } from "../reducers/configReducer"
import { formatDurationExact, useQuery } from "../utils"
import { UnifiedExchangePairCoinTimeseriesWidget } from "../widgets/uplotCharts"

const OpportunityPersistentEventsWidget: FC<{
    exchangeFromID: Exchange["ID"] | null
    exchangeToID: Exchange["ID"] | null
    coinID: Coin["ID"] | null

    exchangesMap: Record<Exchange["ID"], Exchange>
    coinsMap: Record<Coin["ID"], Coin>
}> = ({ exchangeFromID, exchangeToID, coinID, exchangesMap, coinsMap }) => {
    const [persistentEvents, setPersistentEvents] = useState<PersistentPeriodicOpportunity[] | null>(null)
    const { callSecureAPI } = useAuthContext()
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const loadPersistentPeriodicOpportunitiesMutCB = useCallback(async () => {
        if (coinID === null || coinID === 0) {
            return
        }
        try {
            setIsLoading(true)
            let { responseObject: persistentEvents } = await callSecureAPI<PersistentPeriodicOpportunity[] | null>(
                `/persistent/periodic/opportunities?coin_id=${coinID}`
            )
            setPersistentEvents(persistentEvents)
        } catch (e) {
            antMessage.error("Failed to get persistent events")
        } finally {
            setIsLoading(false)
        }
    }, [coinID])

    useEffect(() => {
        if (coinID === null || coinID === 0) {
            return
        }
        loadPersistentPeriodicOpportunitiesMutCB()
    }, [coinID])

    const memoEventsList = useMemo(() => {
        if (persistentEvents === null) {
            return null
        }
        return (
            <List
                loading={isLoading}
                pagination={{
                    pageSize: 10,
                    hideOnSinglePage: true
                }}
                dataSource={persistentEvents}
                renderItem={event => {
                    let exchangeFromName: string | undefined = exchangesMap[event.ExchangeFromID].Name
                    let exchangeToName: string | undefined = exchangesMap[event.ExchangeToID].Name
                    let coinName: string | undefined = coinsMap[event.CoinID].Name
                    let eventTitle: string = `${exchangeFromName} → ${exchangeToName} | ${coinName}`

                    let startedAgoStr: string = formatDurationExact(
                        Date.now() - new Date(event.TimestampStart).getTime()
                    )
                    let durationStr: string = formatDurationExact(
                        new Date(event.TimestampEnd).getTime() - new Date(event.TimestampStart).getTime()
                    )
                    let roi = event.AvgFeeAdjustedROI
                    if (roi === null) {
                        roi = 0
                    }
                    let eventDescription: ReactElement = (
                        <FlexRow>
                            <div>
                                <div>
                                    Started <b>{startedAgoStr}</b> ago (
                                    {new Date(event.TimestampStart).toLocaleTimeString()})
                                </div>
                                <div>
                                    Lasted <b>{durationStr}</b>
                                </div>
                            </div>
                            <Divider type="vertical" />
                            <div>
                                <div>
                                    <b>{roi.toFixed(2)}%</b> ROI @ {event.AvgCumulativeCost.toFixed(2)}$ MAX
                                </div>
                            </div>
                        </FlexRow>
                    )
                    return (
                        <List.Item>
                            <List.Item.Meta title={eventTitle} description={eventDescription} />
                        </List.Item>
                    )
                }}
            />
        )
    }, [persistentEvents, exchangesMap, coinsMap])

    const memoCoinName = useMemo(() => {
        if (coinID === null || coinID === 0) {
            return null
        }
        return coinsMap[coinID].Name
    }, [coinID, coinsMap])

    return (
        <Row gutter={[10, 10]} justify="end">
            <Col xs={24}>
                <Row justify="space-between" align="middle">
                    <Col>
                        Other opportunities with <b>{memoCoinName}</b>
                    </Col>
                    <Col>
                        <Button
                            icon={<ReloadOutlined />}
                            onClick={() => {
                                void loadPersistentPeriodicOpportunitiesMutCB()
                            }}
                        />
                    </Col>
                </Row>
            </Col>
            <Col xs={24}>{memoEventsList}</Col>
        </Row>
    )
}

export const OpportunityPage: FC = () => {
    const query = useQuery()

    const [automaticRefreshEnabled, setAutomaticRefreshEnabled] = useState<boolean>(true)

    const [exchangesMap, setExchangesMap] = useState<Record<Exchange["ID"], Exchange> | null>(null)
    const [coinsMap, setCoinsMap] = useState<Record<Coin["ID"], Coin> | null>(null)
    const [networksMap, setNetworksMap] = useState<Record<Network["ID"], Network> | null>(null)

    const [exchangeFromID, setExchangeFromID] = useState<Exchange["ID"] | null>(null)
    const [exchangeToID, setExchangeToID] = useState<Exchange["ID"] | null>(null)
    const [coinID, setCoinID] = useState<number | null>(null)
    const [networkID, setNetworkID] = useState<Network["ID"] | null>(null)

    const [wellKnowFeeRatesMap, setWellKnowFeeRatesMap] = useState<WellKnownFeeRatesMap | null>(null)
    const [opportunityViews, setOpportunityViews] = useState<number | null>(null)
    const [exchangeFrom, setExchangeFrom] = useState<Exchange | null>(null)
    const [exchangeTo, setExchangeTo] = useState<Exchange | null>(null)

    const [periodicOpportunity, setPeriodicOpportunity] = useState<PeriodicOpportunityResult | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { authToken, callSecureAPI } = useAuthContext()
    const { depositAmount, setDepositAmount } = useConfigContext()

    const loadPeriodicOpportunityMutCB = useCallback(() => {
        if (exchangeFromID === null || exchangeToID === null || coinID === null) {
            return
        }
        callSecureAPI<ArrayBuffer | null>(
            `/periodic/opportunity` +
                getExchangePairCoinTimeseriesParams(exchangeFromID, exchangeToID, coinID, null, null)
        )
            .then(({ responseObject: periodicOpportunityEncoded, responseHeaders }) => {
                if (periodicOpportunityEncoded === null) {
                    // antMessage.error('Failed to get periodic opportunity')
                    console.log("setting automatic refresh to false")
                    setAutomaticRefreshEnabled(false)
                    return
                }
                let periodicOpportunity = decodePeriodicOpportunity(periodicOpportunityEncoded)
                if (periodicOpportunity === null) {
                    antMessage.error("Failed to decode periodic opportunity")
                    return
                }
                setPeriodicOpportunity(periodicOpportunity)
                let _opportunityViews = responseHeaders.get("X-Opportunity-Views")
                if (_opportunityViews !== null) {
                    setOpportunityViews(parseInt(_opportunityViews))
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }, [exchangeFromID, exchangeToID, coinID])

    // WellKnownFeeRatesMap
    useEffect(() => {
        callSecureAPI<WellKnownFeeRatesMap | null>("/well-known/coin/fee/rates").then(
            ({ responseObject: _feeRatesMap }) => {
                setWellKnowFeeRatesMap(_feeRatesMap)
            }
        )
    }, [])

    // Excahgnes
    useEffect(() => {
        callSecureAPI<Exchange[] | null>("/exchanges").then(({ responseObject: exchanges }) => {
            if (exchanges !== null) {
                const exchangesMap: Record<Exchange["ID"], Exchange> = {}
                exchanges.forEach(exchange => {
                    exchangesMap[exchange.ID] = exchange
                })
                setExchangesMap(exchangesMap)
            }
        })
    }, [])

    // Coins
    useEffect(() => {
        callSecureAPI<Coin[] | null>("/coins").then(({ responseObject: coins }) => {
            if (coins !== null) {
                const coinsMap: Record<Coin["ID"], Coin> = {}
                coins.forEach(coin => {
                    coinsMap[coin.ID] = coin
                })
                setCoinsMap(coinsMap)
            }
        })
    }, [])

    // Networks
    useEffect(() => {
        callSecureAPI<Network[] | null>("/networks").then(({ responseObject: networks }) => {
            if (networks !== null) {
                const networksMap: Record<Network["ID"], Network> = {}
                networks.forEach(network => {
                    networksMap[network.ID] = network
                })
                setNetworksMap(networksMap)
            }
        })
    }, [])

    // Parse query params
    useEffect(() => {
        const exchangeFromID = query.get("exchange_from_id")
        const exchangeToID = query.get("exchange_to_id")
        const coinID = query.get("coin_id")
        if (exchangeFromID === null || exchangeToID === null || coinID === null) {
            return
        }
        setExchangeFromID(parseInt(exchangeFromID))
        setExchangeToID(parseInt(exchangeToID))
        setCoinID(parseInt(coinID))
    }, [query])

    useEffect(() => {
        if (exchangeFromID === null || exchangeToID === null) {
            return
        }
        if (exchangesMap === null) {
            return
        }
        const exchangeOne = exchangesMap[exchangeFromID]
        const exchangeTwo = exchangesMap[exchangeToID]
        setExchangeFrom(exchangeOne)
        setExchangeTo(exchangeTwo)
    }, [exchangeFromID, exchangeToID, exchangesMap])

    useEffect(() => {
        void loadPeriodicOpportunityMutCB()
        if (automaticRefreshEnabled) {
            // console.log("setting interval for periodic opportunity loaing")
            const i = setInterval(() => {
                void loadPeriodicOpportunityMutCB()
            }, OPPORTUNITIES_AUTO_REFRESH_INTERVAL_MS)
            return () => {
                console.log("clearing interval for periodic opportunity loaing")
                clearInterval(i)
            }
        }
    }, [automaticRefreshEnabled, authToken, exchangeFromID, exchangeToID, coinID])

    useEffect(() => {
        if (periodicOpportunity !== null) {
            setNetworkID(periodicOpportunity.Opportunity.NetworkID)
        }
    }, [periodicOpportunity])

    const memoViewsElement = useMemo(() => {
        if (opportunityViews === null || opportunityViews === undefined || opportunityViews === 0) {
            return null
        }
        let badgeColor: string = "red"
        let tooltipOverlay: ReactElement | null = null
        if (opportunityViews === 1) {
            badgeColor = "green"
            tooltipOverlay = <>You are the only one viewing this opportunity</>
        } else {
            tooltipOverlay = (
                <>
                    <b>{opportunityViews - 1}</b> other people are viewing this opportunity
                </>
            )
        }
        return (
            <Tooltip overlay={tooltipOverlay} overlayInnerStyle={{ textAlign: "center" }} placement="left">
                <Badge count={opportunityViews - 1} color={badgeColor}>
                    <Avatar
                        shape="circle"
                        size="large"
                        icon={<span>👀</span>}
                        style={{
                            backgroundColor: "#ddd"
                        }}
                    />
                </Badge>
            </Tooltip>
        )
    }, [opportunityViews])

    if (isLoading) {
        return (
            <FlexCol
                style={{
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center"
                }}
            >
                <Spin size="large" />
            </FlexCol>
        )
    }

    if (exchangesMap === null || coinsMap === null || networksMap === null) {
        return null
    }

    if (exchangeFrom === null || exchangeTo === null || coinID === null) {
        return null
    }

    return (
        <FlexCol
            style={{
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Row
                justify="space-between"
                align="middle"
                style={{
                    width: "100%"
                }}
            >
                <Col>
                    <FlexRow
                        style={{
                            alignItems: "center",
                            justifyContent: "start"
                        }}
                    >
                        Deposit amount
                        <Tooltip
                            overlay={
                                <>
                                    Enter the amount of your deposit to estimate your personalized maximum profit.
                                    <br />
                                    <br />
                                    If the cost of the operation is higher than your deposit, the profit will be capped
                                    at the amount of your deposit.
                                    <br />
                                    <br />
                                    If the cost of the operation is lower than your deposit, the profit will be the same
                                    as the maximum profit of the operation.
                                </>
                            }
                            placement="bottom"
                            overlayInnerStyle={{
                                minWidth: 400
                            }}
                        >
                            <QuestionCircleTwoTone />
                        </Tooltip>
                        <InputNumber
                            min={0}
                            value={depositAmount}
                            onChange={value => {
                                setDepositAmount(value)
                            }}
                        />
                    </FlexRow>
                </Col>
                <Col>
                    <FlexRow
                        style={{
                            alignItems: "center"
                        }}
                    >
                        {memoViewsElement}
                        <Button
                            type="text"
                            size="large"
                            icon={<ReloadOutlined />}
                            onClick={() => {
                                void loadPeriodicOpportunityMutCB()
                            }}
                        />
                        <Tooltip overlay={<>Auto-refresh</>} placement="bottom">
                            <Switch
                                checked={automaticRefreshEnabled}
                                onChange={checked => {
                                    setAutomaticRefreshEnabled(checked)
                                }}
                            />
                        </Tooltip>
                    </FlexRow>
                </Col>
            </Row>
            {periodicOpportunity !== null ?
                <OpportunityFullInformationWidget
                    key={`${exchangeFromID}_${exchangeToID}_${coinID}`}
                    opportunity={periodicOpportunity !== null ? periodicOpportunity.Opportunity : null}
                    networksMap={networksMap}
                    coinsMap={coinsMap}
                    exchangesMap={exchangesMap}
                    wellKnownFeeRatesMap={wellKnowFeeRatesMap}
                    depositAmount={depositAmount}
                />
            :   <Paper
                    style={{
                        width: "100%"
                    }}
                >
                    <Empty
                        description={
                            <>
                                Opportunity{" "}
                                <b>
                                    {exchangeFrom.Name} → {exchangeTo.Name} | {coinsMap[coinID].Name}
                                </b>{" "}
                                was not found.
                                <br />
                                It was probably inactive for <b>more than half an hour</b> so it is no longer available
                                <br />
                                <br />
                                Live chart of the coin's price on both exchanges is availabale below.
                            </>
                        }
                    />
                </Paper>
            }
            <Paper
                style={{
                    width: "100%"
                }}
            >
                {/* Real-time charts */}
                <UnifiedExchangePairCoinTimeseriesWidget
                    exchangeOne={exchangeFrom}
                    exchangeTwo={exchangeTo}
                    coinID={coinID}
                    networkID={networkID}
                    depositAmount={depositAmount}
                    isSimplifiedRender={false}
                />
            </Paper>
            {/* Persistent Events */}
            <Paper
                style={{
                    width: "100%"
                }}
            >
                <OpportunityPersistentEventsWidget
                    exchangeFromID={exchangeFromID}
                    exchangeToID={exchangeToID}
                    coinID={coinID}
                    exchangesMap={exchangesMap}
                    coinsMap={coinsMap}
                />
            </Paper>
        </FlexCol>
    )
}
