"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupportedExchanges = exports.NewXtExchangeDriver = exports.NewGateExchangeDriver = exports.NewHtxExchangeDriver = exports.NewPoloniexExchangeDriver = exports.NewBybitExchangeDriver = exports.NewBitgetExchangeDriver = exports.NewKucoinExchangeDriver = exports.NewOkxExchangeDriver = exports.NewMexcExchangeDriver = exports.NewBinanceExchangeDriver = void 0;
const binance_1 = require("./exchangeAPIs/binance");
Object.defineProperty(exports, "NewBinanceExchangeDriver", { enumerable: true, get: function () { return binance_1.NewBinanceExchangeDriver; } });
const mexc_1 = require("./exchangeAPIs/mexc");
Object.defineProperty(exports, "NewMexcExchangeDriver", { enumerable: true, get: function () { return mexc_1.NewMexcExchangeDriver; } });
const okx_1 = require("./exchangeAPIs/okx");
Object.defineProperty(exports, "NewOkxExchangeDriver", { enumerable: true, get: function () { return okx_1.NewOkxExchangeDriver; } });
const kucoin_1 = require("./exchangeAPIs/kucoin");
Object.defineProperty(exports, "NewKucoinExchangeDriver", { enumerable: true, get: function () { return kucoin_1.NewKucoinExchangeDriver; } });
const bitget_1 = require("./exchangeAPIs/bitget");
Object.defineProperty(exports, "NewBitgetExchangeDriver", { enumerable: true, get: function () { return bitget_1.NewBitgetExchangeDriver; } });
const bybit_1 = require("./exchangeAPIs/bybit");
Object.defineProperty(exports, "NewBybitExchangeDriver", { enumerable: true, get: function () { return bybit_1.NewBybitExchangeDriver; } });
const poloniex_1 = require("./exchangeAPIs/poloniex");
Object.defineProperty(exports, "NewPoloniexExchangeDriver", { enumerable: true, get: function () { return poloniex_1.NewPoloniexExchangeDriver; } });
const htx_1 = require("./exchangeAPIs/htx");
Object.defineProperty(exports, "NewHtxExchangeDriver", { enumerable: true, get: function () { return htx_1.NewHtxExchangeDriver; } });
const gate_1 = require("./exchangeAPIs/gate");
Object.defineProperty(exports, "NewGateExchangeDriver", { enumerable: true, get: function () { return gate_1.NewGateExchangeDriver; } });
const xt_1 = require("./exchangeAPIs/xt");
Object.defineProperty(exports, "NewXtExchangeDriver", { enumerable: true, get: function () { return xt_1.NewXtExchangeDriver; } });
const SupportedExchanges = [
    "BINANCE",
    "MEXC",
    "OKX",
    "KUCOIN",
    "BITGET",
    "BYBIT",
    "POLONIEX",
    "HTX",
    "GATE",
    "XT",
];
exports.SupportedExchanges = SupportedExchanges;
