"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericExchangeDriver = exports.MarginTradeType = exports.MarginAccountMode = exports.OrderTimeInForce = exports.OrderType = exports.OrderSide = exports.StatusSeverity = exports.RELAY_ENDPOINT = void 0;
exports.RELAY_ENDPOINT = "https://arbitrage.ngineera.com/relay";
var StatusSeverity;
(function (StatusSeverity) {
    StatusSeverity[StatusSeverity["Neutral"] = 0] = "Neutral";
    StatusSeverity[StatusSeverity["Warning"] = 1] = "Warning";
    StatusSeverity[StatusSeverity["Success"] = 2] = "Success";
    StatusSeverity[StatusSeverity["Error"] = 3] = "Error";
})(StatusSeverity = exports.StatusSeverity || (exports.StatusSeverity = {}));
var OrderSide;
(function (OrderSide) {
    OrderSide["Buy"] = "BUY";
    OrderSide["Sell"] = "SELL";
})(OrderSide = exports.OrderSide || (exports.OrderSide = {}));
var OrderType;
(function (OrderType) {
    OrderType["Market"] = "MARKET";
    OrderType["Limit"] = "LIMIT";
})(OrderType = exports.OrderType || (exports.OrderType = {}));
var OrderTimeInForce;
(function (OrderTimeInForce) {
    OrderTimeInForce["GoodTilCancelled"] = "GTC";
    OrderTimeInForce["ImmediateOrCancel"] = "IOC";
    OrderTimeInForce["FillOrKill"] = "FOK";
})(OrderTimeInForce = exports.OrderTimeInForce || (exports.OrderTimeInForce = {}));
var MarginAccountMode;
(function (MarginAccountMode) {
    MarginAccountMode[MarginAccountMode["None"] = 0] = "None";
    MarginAccountMode[MarginAccountMode["Unified"] = 1] = "Unified";
    MarginAccountMode[MarginAccountMode["Separate"] = 2] = "Separate";
})(MarginAccountMode = exports.MarginAccountMode || (exports.MarginAccountMode = {}));
var MarginTradeType;
(function (MarginTradeType) {
    MarginTradeType[MarginTradeType["Cross"] = 0] = "Cross";
    MarginTradeType[MarginTradeType["Isolated"] = 1] = "Isolated";
})(MarginTradeType = exports.MarginTradeType || (exports.MarginTradeType = {}));
exports.GenericExchangeDriver = {
    name() {
        return "GENERIC";
    },
    config() {
        return {
            withdrawalIsImmediate: false,
            withdrawalWhitelist: false,
            marginTradeTypes: [],
            marginAccountMode: MarginAccountMode.None,
            timeInForceIsSupported: false,
        };
    },
    async init() {
        throw new Error("init not implemented.");
    },
    async checkAPI() {
        throw new Error("checkAPI not implemented.");
    },
    async getCoins() {
        throw new Error("getCoins not implemented.");
    },
    async getCoinNetworks() {
        throw new Error("getCoinNetworks not implemented.");
    },
    async getSpotSymbols() {
        throw new Error("getSymbols not implemented.");
    },
    async getSpotBalances() {
        throw new Error("getBalances not implemented.");
    },
    async getSpotCoinBalance() {
        throw new Error("getCoinBalance not implemented.");
    },
    async getCoinNetworkDepositAddress() {
        throw new Error("getCoinNetworkDepositAddress not implemented.");
    },
    async withdrawApply() {
        throw new Error("withdrawApply not implemented.");
    },
    async withdrawCancel() {
        throw new Error("withdrawCancel not implemented.");
    },
    async getWithdrawHistory() {
        throw new Error("getWithdrawHistory not implemented.");
    },
    async getDepositHistory() {
        throw new Error("getDepositHistory not implemented.");
    },
    // Spot trade
    async consolidateSpotBalances() {
        throw new Error("consolidateSpotBalances not implemented.");
    },
    async getSymbolOrderbook() {
        throw new Error("getSpotSymbolOrderbook not implemented.");
    },
    async getSpotSymbolOrdersHistory() {
        throw new Error("getSpotSymbolOrdersHistory not implemented.");
    },
    async placeSpotOrder() {
        throw new Error("placeSpotOrder not implemented.");
    },
    async cancelSpotOrder() {
        throw new Error("cancelSpotOrder not implemented.");
    },
    async getSpotOrder() {
        throw new Error("getSpotOrder not implemented.");
    },
    // Margin trade
    async getMarginSymbols() {
        throw new Error("getMarginSymbols not implemented.");
    },
    async getMarginBalances() {
        throw new Error("getMarginBalances not implemented.");
    },
    async getMarginCoinBalance() {
        throw new Error("getMarginCoinBalance not implemented.");
    },
    async getMarginSymbolOrdersHistory() {
        throw new Error("getMarginSymbolOrdersHistory not implemented.");
    },
    async marginLoanBorrow() {
        throw new Error("marginLoanBorrow not implemented.");
    },
    async marginLoanRepay() {
        throw new Error("marginLoanRepay not implemented.");
    },
    async placeMarginOrder() {
        throw new Error("placeMarginOrder not implemented.");
    },
    async cancelMarginOrder() {
        throw new Error("cancelMarginOrder not implemented.");
    },
    async getMarginOrder() {
        throw new Error("getMarginOrder not implemented.");
    },
    async transferCoinSpotToMargin() {
        throw new Error("transferMarginCollateral not implemented.");
    },
    async transferCoinMarginToSpot() {
        throw new Error("transferSpotCollateral not implemented.");
    },
    async getMarginMaxBorrowAmount() {
        throw new Error("getMarginMaxBorrowAmount not implemented.");
    },
    async getMarginTransferableBalance() {
        throw new Error("getMarginTransferableBalance not implemented.");
    },
    async getMarginLoanRecord() {
        throw new Error("getMarginLoanRecord not implemented.");
    },
    async getMarginLoanCeilingCoinMap() {
        throw new Error("getMarginLoanCeilingCoinMap not implemented.");
    },
    wsStateMachine() {
        throw new Error("wsStateMachine not implemented.");
    },
};
