import dayjs, { Dayjs } from "dayjs"
import { FC, createContext, useContext, useReducer } from "react"
import { Action } from "./common"

const loadDepositAmountFromLocalStorage = () => {
    const depositAmount = localStorage.getItem("depositAmount")
    if (depositAmount === null) {
        return null
    }
    return parseInt(depositAmount)
}

const saveDepositAmountToLocalStorage = (depositAmount: number | null) => {
    if (depositAmount === null) {
        localStorage.removeItem("depositAmount")
        return
    }
    localStorage.setItem("depositAmount", depositAmount.toString())
}

const loadHedgingPreferenceFromLocalStorage = (): boolean => {
    const hedgingPreference = localStorage.getItem("hedgingPreference")
    if (hedgingPreference === null) {
        return true // defaults to true
    }
    return JSON.parse(hedgingPreference)
}

const saveHedgingPreferenceToLocalStorage = (hedgingPreference: boolean) => {
    localStorage.setItem("hedgingPreference", JSON.stringify(hedgingPreference))
}

const loadHideLowProfitOpportunitiesFromLocalStorage = (): boolean => {
    const hideLowProfitOpportunities = localStorage.getItem("hideLowProfitOpportunities")
    if (hideLowProfitOpportunities === null) {
        return false
    }
    return JSON.parse(hideLowProfitOpportunities)
}

const saveHideLowProfitOpportunitiesToLocalStorage = (hideLowProfitOpportunities: boolean) => {
    localStorage.setItem("hideLowProfitOpportunities", JSON.stringify(hideLowProfitOpportunities))
}

const loadMinProfitFromLocalStorage = () => {
    const minProfit = localStorage.getItem("minProfit")
    if (minProfit === null) {
        return null
    }
    return parseInt(minProfit)
}

const saveMinProfitToLocalStorage = (minProfit: number | null) => {
    if (minProfit === null) {
        localStorage.removeItem("minProfit")
        return
    }
    localStorage.setItem("minProfit", minProfit.toString())
}

const loadMinCostFromLocalStorage = () => {
    const minCost = localStorage.getItem("minCost")
    if (minCost === null) {
        return null
    }
    return parseInt(minCost)
}

const saveMinCostToLocalStorage = (minCost: number | null) => {
    if (minCost === null) {
        localStorage.removeItem("minCost")
        return
    }
    localStorage.setItem("minCost", minCost.toString())
}

const loadMinMarginFromLocalStorage = () => {
    const minMargin = localStorage.getItem("minMargin")
    if (minMargin === null) {
        return null
    }
    return parseInt(minMargin)
}

const saveMinMarginToLocalStorage = (minMargin: number | null) => {
    if (minMargin === null) {
        localStorage.removeItem("minMargin")
        return
    }
    localStorage.setItem("minMargin", minMargin.toString())
}

const loadMinDurationFromLocalStorage = () => {
    const minDuration = localStorage.getItem("minDuration")
    if (minDuration === null) {
        return 10 // defaults to 10
    }
    return parseInt(minDuration)
}

const saveMinDurationToLocalStorage = (minDuration: number | null) => {
    if (minDuration === null) {
        localStorage.removeItem("minDuration")
        return
    }
    localStorage.setItem("minDuration", minDuration.toString())
}

const loadMinNbPointsFromLocalStorage = () => {
    const minNbPoints = localStorage.getItem("minNbPoints")
    if (minNbPoints === null) {
        return 5 // defaults to 5
    }
    return parseInt(minNbPoints)
}

const saveMinNbPointsToLocalStorage = (minNbPoints: number | null) => {
    if (minNbPoints === null) {
        localStorage.removeItem("minNbPoints")
        return
    }
    localStorage.setItem("minNbPoints", minNbPoints.toString())
}

const loadMaxTxTimeFromLocalStorage = () => {
    const maxTxTime = localStorage.getItem("maxTxTime")
    if (maxTxTime === null) {
        return null
    }
    return parseInt(maxTxTime)
}

const saveMaxTxTimeToLocalStorage = (maxTxTime: number | null) => {
    if (maxTxTime === null) {
        localStorage.removeItem("maxTxTime")
        return
    }
    localStorage.setItem("maxTxTime", maxTxTime.toString())
}

const loadMinProfitToFeeRatioFromLocalStorage = () => {
    const minProfitToFeeRatio = localStorage.getItem("minProfitToFeeRatio")
    if (minProfitToFeeRatio === null) {
        return null
    }
    return parseInt(minProfitToFeeRatio)
}

const saveMinProfitToFeeRatioToLocalStorage = (minProfitToFeeRatio: number | null) => {
    if (minProfitToFeeRatio === null) {
        localStorage.removeItem("minProfitToFeeRatio")
        return
    }
    localStorage.setItem("minProfitToFeeRatio", minProfitToFeeRatio.toString())
}

const loadFilteredExchangesInFromLocalStorage = () => {
    const filteredExchangesStr = localStorage.getItem("filteredExchangesIn")
    if (filteredExchangesStr === null) {
        return null
    }
    return filteredExchangesStr.split(",")
}

const saveFilteredExchangesInToLocalStorage = (filteredExchangesIn: string[] | null) => {
    localStorage.removeItem("filteredExchanges")
    if (filteredExchangesIn === null || filteredExchangesIn.length === 0) {
        localStorage.removeItem("filteredExchangesIn")
        return
    }
    localStorage.setItem("filteredExchangesIn", filteredExchangesIn.join(","))
}

const loadFilteredExchangesInExclusivelyFromLocalStorage = () => {
    const filteredExchangesStr = localStorage.getItem("filteredExchangesIncludedExclusivelyFrom")
    if (filteredExchangesStr === null) {
        return null
    }
    return filteredExchangesStr.split(",")
}

const saveFilteredExchangesInExclusivelyFromLocalStorage = (filteredExchangesIn: string[] | null) => {
    if (filteredExchangesIn === null || filteredExchangesIn.length === 0) {
        localStorage.removeItem("filteredExchangesIncludedExclusivelyFrom")
        return
    }
    localStorage.setItem("filteredExchangesIncludedExclusivelyFrom", filteredExchangesIn.join(","))
}

const loadFilteredExchangesInExclusivelyToLocalStorage = () => {
    const filteredExchangesStr = localStorage.getItem("filteredExchangesIncludedExclusivelyTo")
    if (filteredExchangesStr === null) {
        return null
    }
    return filteredExchangesStr.split(",")
}

const saveFilteredExchangesInExclusivelyToLocalStorage = (filteredExchangesIn: string[] | null) => {
    if (filteredExchangesIn === null || filteredExchangesIn.length === 0) {
        localStorage.removeItem("filteredExchangesIncludedExclusivelyTo")
        return
    }
    localStorage.setItem("filteredExchangesIncludedExclusivelyTo", filteredExchangesIn.join(","))
}

const loadExcludedCoinsFromLocalStorage = () => {
    const excludedCoinsStr = localStorage.getItem("excludedCoins")
    if (excludedCoinsStr === null) {
        return null
    }
    return excludedCoinsStr.split(",")
}

const saveExcludedCoinsToLocalStorage = (excludedCoins: string[] | null) => {
    if (excludedCoins === null || excludedCoins.length === 0) {
        localStorage.removeItem("excludedCoins")
        return
    }
    localStorage.setItem("excludedCoins", excludedCoins.join(","))
}

const loadExcludedNetworksFromLocalStorage = () => {
    const excludedNetworksStr = localStorage.getItem("excludedNetworks")
    if (excludedNetworksStr === null) {
        return null
    }
    return excludedNetworksStr.split(",")
}

const saveExcludedNetworksToLocalStorage = (excludedNetworks: string[] | null) => {
    if (excludedNetworks === null || excludedNetworks.length === 0) {
        localStorage.removeItem("excludedNetworks")
        return
    }
    localStorage.setItem("excludedNetworks", excludedNetworks.join(","))
}

const loadDateTimeRangeFromLocalStorage = (): [Dayjs | null, Dayjs | null] => {
    const dateTimeRangeStr = localStorage.getItem("dateTimeRange")
    if (dateTimeRangeStr === null) {
        return [null, null]
    }
    const [startStr, endStr] = dateTimeRangeStr.split(",")
    let start = null
    if (startStr !== "null") {
        start = dayjs(startStr)
    }
    let end = null
    if (endStr !== "null") {
        end = dayjs(endStr)
    }
    return [start, end]
}

const saveDateTimeRangeToLocalStorage = (dateTimeRange: [Dayjs | null, Dayjs | null]) => {
    const startStr = dateTimeRange[0] === null ? "null" : dateTimeRange[0].format()
    const endStr = dateTimeRange[1] === null ? "null" : dateTimeRange[1].format()
    localStorage.setItem("dateTimeRange", `${startStr},${endStr}`)
}

const loadFilteredExchangesOutFromLocalStorage = () => {
    const filteredExchangesStr = localStorage.getItem("filteredExchangesOut")
    if (filteredExchangesStr === null) {
        return null
    }
    return filteredExchangesStr.split(",")
}

const saveFilteredExchangesOutToLocalStorage = (filteredExchangesOut: string[] | null) => {
    if (filteredExchangesOut === null || filteredExchangesOut.length === 0) {
        localStorage.removeItem("filteredExchangesOut")
        return
    }
    localStorage.setItem("filteredExchangesOut", filteredExchangesOut.join(","))
}

const loadShouldCountUSDTReturnFee = () => {
    const shouldCountUSDTReturnFee = localStorage.getItem("shouldCountUSDTReturnFee")
    if (shouldCountUSDTReturnFee === null) {
        return false
    }
    return JSON.parse(shouldCountUSDTReturnFee)
}

const saveShouldCountUSDTReturnFee = (shouldCountUSDTReturnFee: boolean) => {
    localStorage.setItem("shouldCountUSDTReturnFee", JSON.stringify(shouldCountUSDTReturnFee))
}

const loadShouldExcludeOpportunitiesWithRatedFees = () => {
    const shouldExcludeOPPORTUNITIESWithRatedFees = localStorage.getItem("shouldExcludeOPPORTUNITIESWithRatedFees")
    if (shouldExcludeOPPORTUNITIESWithRatedFees === null) {
        return false
    }
    return JSON.parse(shouldExcludeOPPORTUNITIESWithRatedFees)
}

const saveShouldExcludeOpportunitiesWithRatedFees = (shouldExcludeOPPORTUNITIESWithRatedFees: boolean) => {
    localStorage.setItem(
        "shouldExcludeOPPORTUNITIESWithRatedFees",
        JSON.stringify(shouldExcludeOPPORTUNITIESWithRatedFees)
    )
}

const loadShouldExcludeAmbiguousContractAddressMatches = () => {
    const shouldExcludeAmbiguousContractAddressMatches = localStorage.getItem(
        "shouldExcludeAmbiguousContractAddressMatches"
    )
    if (shouldExcludeAmbiguousContractAddressMatches === null) {
        return false
    }
    return JSON.parse(shouldExcludeAmbiguousContractAddressMatches)
}

const saveShouldExcludeAmbiguousContractAddressMatches = (shouldExcludeAmbiguousContractAddressMatches: boolean) => {
    localStorage.setItem(
        "shouldExcludeAmbiguousContractAddressMatches",
        JSON.stringify(shouldExcludeAmbiguousContractAddressMatches)
    )
}

const loadForceContractAddressMatchCoinNetworkPairsFromLocalStorage = () => {
    const forceContractAddressMatchCoinNetworkPairs = localStorage.getItem("forceContractAddressMatchCoinNetworkPairs")
    if (forceContractAddressMatchCoinNetworkPairs === null) {
        return []
    }
    return forceContractAddressMatchCoinNetworkPairs.split(",")
}

const saveForceContractAddressMatchCoinNetworkPairsToLocalStorage = (
    forceContractAddressMatchCoinNetworkPairs: string[]
) => {
    if (forceContractAddressMatchCoinNetworkPairs.length === 0) {
        localStorage.removeItem("forceContractAddressMatchCoinNetworkPairs")
        return
    }
    localStorage.setItem(
        "forceContractAddressMatchCoinNetworkPairs",
        forceContractAddressMatchCoinNetworkPairs.join(",")
    )
}

const loadIsSimplifiedSchemeFromLocalStorage = () => {
    const isSimplifiedScheme = localStorage.getItem("isSimplifiedScheme")
    if (isSimplifiedScheme === null) {
        return false
    }
    return JSON.parse(isSimplifiedScheme)
}

const saveIsSimplifiedSchemeToLocalStorage = (isSimplifiedScheme: boolean) => {
    localStorage.setItem("isSimplifiedScheme", JSON.stringify(isSimplifiedScheme))
}

interface State {
    depositAmount: number | null
    hedgingPreference: boolean
    showLowProfitOpportunities: boolean
    minProfit: number | null
    minCost: number | null
    minMargin: number | null
    minDuration: number | null
    minNbPoints: number | null
    maxTxTime: number | null
    minProfitToFeeRatio: number | null

    filteredExchangesIncluded: string[] | null
    filteredExchangesExcluded: string[] | null
    filteredExchangesIncludedExclusivelyFrom: string[] | null
    filteredExchangesIncludedExclusivelyTo: string[] | null

    excludedCoins: string[] | null
    excludedNetworks: string[] | null
    dateTimeRange: [Dayjs | null, Dayjs | null]

    shouldCountUSDTReturnFee: boolean
    shouldExcludeOppoortunitiesWithRatedFees: boolean
    shouldExcludeAmbiguousContractAddressMatches: boolean
    forceContractAddressMatchCoinNetworkPairs: string[]

    isSimplifiedScheme: boolean
}

const initialState: State = {
    depositAmount: loadDepositAmountFromLocalStorage(),
    hedgingPreference: loadHedgingPreferenceFromLocalStorage(),
    showLowProfitOpportunities: loadHideLowProfitOpportunitiesFromLocalStorage(),
    minProfit: loadMinProfitFromLocalStorage(),
    minCost: loadMinCostFromLocalStorage(),
    minMargin: loadMinMarginFromLocalStorage(),
    minDuration: loadMinDurationFromLocalStorage(),
    minNbPoints: loadMinNbPointsFromLocalStorage(),
    maxTxTime: loadMaxTxTimeFromLocalStorage(),
    minProfitToFeeRatio: loadMinProfitToFeeRatioFromLocalStorage(),
    filteredExchangesIncluded: loadFilteredExchangesInFromLocalStorage(),
    filteredExchangesExcluded: loadFilteredExchangesOutFromLocalStorage(),
    filteredExchangesIncludedExclusivelyFrom: loadFilteredExchangesInExclusivelyFromLocalStorage(),
    filteredExchangesIncludedExclusivelyTo: loadFilteredExchangesInExclusivelyToLocalStorage(),
    excludedCoins: loadExcludedCoinsFromLocalStorage(),
    excludedNetworks: loadExcludedNetworksFromLocalStorage(),
    dateTimeRange: loadDateTimeRangeFromLocalStorage(),
    shouldCountUSDTReturnFee: loadShouldCountUSDTReturnFee(),
    shouldExcludeOppoortunitiesWithRatedFees: loadShouldExcludeOpportunitiesWithRatedFees(),
    shouldExcludeAmbiguousContractAddressMatches: loadShouldExcludeAmbiguousContractAddressMatches(),
    forceContractAddressMatchCoinNetworkPairs: loadForceContractAddressMatchCoinNetworkPairsFromLocalStorage(),
    isSimplifiedScheme: loadIsSimplifiedSchemeFromLocalStorage()
}

interface ConfigContextType extends State {
    setDepositAmount: (depositAmount: number | null) => void
    setHedgingPreference: (hedgingPreference: boolean | null) => void
    setShowLowProfitOpportunities: (showLowProfitOpportunities: boolean | null) => void
    setMinProfit: (minProfit: number | null) => void
    setMinCost: (minCost: number | null) => void
    setMinMargin: (minMargin: number | null) => void
    setMinDuration: (minDuration: number | null) => void
    setMinNbPoints: (minNbPoints: number | null) => void
    setMaxTxTime: (maxTxTime: number | null) => void
    setMinProfitToFeeRatio: (minProfitToFeeRatio: number | null) => void
    setFilteredExchangesIncluded: (filteredExchangesIn: string[] | null) => void
    setFilteredExchangesExcluded: (filteredExchangesOut: string[] | null) => void
    setFilteredExchangesIncludedExclusivelyFrom: (filteredExchangesIncludedExclusivelyFrom: string[] | null) => void
    setFilteredExchangesIncludedExclusivelyTo: (filteredExchangesIncludedExclusivelyTo: string[] | null) => void
    setExcludedCoins: (excludedCoins: string[] | null) => void
    setExcludedNetworks: (excludedNetworks: string[] | null) => void
    setDateTimeRange: (dateTimeRange: [Dayjs | null, Dayjs | null]) => void
    setShouldCountUSDTReturnFee: (shouldCountUSDTReturnFee: boolean) => void
    setShouldExcludeOppoortunitiesWithRatedFees: (shouldExcludeOppoortunitiesWithRatedFees: boolean) => void
    setShouldExcludeAmbiguousContractAddressMatches: (shouldExcludeAmbiguousContractAddressMatches: boolean) => void
    setForceContractAddressMatchCoinNetworkPairs: (forceContractAddressMatchCoinNetworkPairs: string[]) => void
    setIsSimplifiedScheme: (isSimplifiedScheme: boolean) => void
}

function configReducer(state: State, action: Action): State {
    switch (action.type) {
        case "SET_DEPOSIT_AMOUNT":
            return {
                ...state,
                depositAmount: action.payload
            }
        case "SET_HEDGING_PREFERENCE":
            return {
                ...state,
                hedgingPreference: action.payload
            }
        case "SET_SHOW_LOW_PROFIT_OPPORTUNITIES":
            return {
                ...state,
                showLowProfitOpportunities: action.payload
            }
        case "SET_MIN_PROFIT":
            return {
                ...state,
                minProfit: action.payload
            }
        case "SET_MIN_COST":
            return {
                ...state,
                minCost: action.payload
            }
        case "SET_MIN_MARGIN":
            return {
                ...state,
                minMargin: action.payload
            }
        case "SET_MIN_DURATION":
            return {
                ...state,
                minDuration: action.payload
            }
        case "SET_MIN_NB_POINTS":
            return {
                ...state,
                minNbPoints: action.payload
            }
        case "SET_MAX_TX_TIME":
            return {
                ...state,
                maxTxTime: action.payload
            }
        case "SET_MIN_PROFIT_TO_FEE_RATIO":
            return {
                ...state,
                minProfitToFeeRatio: action.payload
            }
        case "SET_FILTERED_EXCHANGES_IN":
            return {
                ...state,
                filteredExchangesIncluded: action.payload
            }
        case "SET_FILTERED_EXCHANGES_OUT":
            return {
                ...state,
                filteredExchangesExcluded: action.payload
            }
        case "SET_FILTERED_EXCHANGES_INCLUDED_EXCLUSIVELY_FROM":
            return {
                ...state,
                filteredExchangesIncludedExclusivelyFrom: action.payload
            }
        case "SET_FILTERED_EXCHANGES_INCLUDED_EXCLUSIVELY_TO":
            return {
                ...state,
                filteredExchangesIncludedExclusivelyTo: action.payload
            }
        case "SET_EXCLUDED_COINS":
            return {
                ...state,
                excludedCoins: action.payload
            }
        case "SET_EXCLUDED_NETWORKS":
            return {
                ...state,
                excludedNetworks: action.payload
            }
        case "SET_DATE_TIME_RANGE":
            return {
                ...state,
                dateTimeRange: action.payload
            }
        case "SET_SHOULD_COUNT_USDT_RETURN_FEE":
            return {
                ...state,
                shouldCountUSDTReturnFee: action.payload
            }
        case "SET_SHOULD_EXCLUDE_OPPORTUNITIES_WITH_RATED_FEES":
            return {
                ...state,
                shouldExcludeOppoortunitiesWithRatedFees: action.payload
            }
        case "SET_SHOULD_EXCLUDE_AMBIGUOUS_CONTRACT_ADDRESS_MATCHES":
            return {
                ...state,
                shouldExcludeAmbiguousContractAddressMatches: action.payload
            }
        case "SET_FORCE_CONTRACT_ADDRESS_MATCH_COIN_NETWORK_PAIRS":
            return {
                ...state,
                forceContractAddressMatchCoinNetworkPairs: action.payload
            }
        case "SET_IS_SIMPLIFIED_SCHEME":
            return {
                ...state,
                isSimplifiedScheme: action.payload
            }
        default:
            return state
    }
}

export const ConfigContext = createContext<ConfigContextType | undefined>(undefined)

export const useConfigContext = () => {
    const context = useContext(ConfigContext)
    if (context === undefined) {
        throw new Error("useConfigContext must be used within a ConfigProvider")
    }
    return context
}

export const ConfigProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(configReducer, initialState)

    const setDepositAmount = (depositAmount: number | null) => {
        if (depositAmount !== null) {
            saveDepositAmountToLocalStorage(depositAmount)
        } else {
            localStorage.removeItem("depositAmount")
        }
        dispatch({
            type: "SET_DEPOSIT_AMOUNT",
            payload: depositAmount
        })
    }

    const setHedgingPreference = (hedgingPreference: boolean | null) => {
        if (hedgingPreference !== null) {
            saveHedgingPreferenceToLocalStorage(hedgingPreference)
        } else {
            localStorage.removeItem("hedgingPreference")
        }
        dispatch({
            type: "SET_HEDGING_PREFERENCE",
            payload: hedgingPreference
        })
    }

    const setShowLowProfitOpportunities = (showLowProfitOpportunities: boolean | null) => {
        if (showLowProfitOpportunities !== null) {
            saveHideLowProfitOpportunitiesToLocalStorage(showLowProfitOpportunities)
        } else {
            localStorage.removeItem("hideLowProfitOpportunities")
        }
        dispatch({
            type: "SET_SHOW_LOW_PROFIT_OPPORTUNITIES",
            payload: showLowProfitOpportunities
        })
    }

    const setMinProfit = (minProfit: number | null) => {
        if (minProfit !== null) {
            saveMinProfitToLocalStorage(minProfit)
        } else {
            localStorage.removeItem("minProfit")
        }
        dispatch({
            type: "SET_MIN_PROFIT",
            payload: minProfit
        })
    }

    const setMinCost = (minCost: number | null) => {
        if (minCost !== null) {
            saveMinCostToLocalStorage(minCost)
        } else {
            localStorage.removeItem("minCost")
        }
        dispatch({
            type: "SET_MIN_COST",
            payload: minCost
        })
    }

    const setMinMargin = (minMargin: number | null) => {
        if (minMargin !== null) {
            saveMinMarginToLocalStorage(minMargin)
        } else {
            localStorage.removeItem("minMargin")
        }
        dispatch({
            type: "SET_MIN_MARGIN",
            payload: minMargin
        })
    }

    const setMinDuration = (minDuration: number | null) => {
        if (minDuration !== null) {
            saveMinDurationToLocalStorage(minDuration)
        } else {
            localStorage.removeItem("minDuration")
        }
        dispatch({
            type: "SET_MIN_DURATION",
            payload: minDuration
        })
    }

    const setMinNbPoints = (minNbPoints: number | null) => {
        if (minNbPoints !== null) {
            saveMinNbPointsToLocalStorage(minNbPoints)
        } else {
            localStorage.removeItem("minNbPoints")
        }
        dispatch({
            type: "SET_MIN_NB_POINTS",
            payload: minNbPoints
        })
    }

    const setMaxTxTime = (maxTxTime: number | null) => {
        if (maxTxTime !== null) {
            saveMaxTxTimeToLocalStorage(maxTxTime)
        } else {
            localStorage.removeItem("maxTxTime")
        }
        dispatch({
            type: "SET_MAX_TX_TIME",
            payload: maxTxTime
        })
    }

    const setMinProfitToFeeRatio = (minProfitToFeeRatio: number | null) => {
        if (minProfitToFeeRatio !== null) {
            saveMinProfitToFeeRatioToLocalStorage(minProfitToFeeRatio)
        } else {
            localStorage.removeItem("minProfitToFeeRatio")
        }
        dispatch({
            type: "SET_MIN_PROFIT_TO_FEE_RATIO",
            payload: minProfitToFeeRatio
        })
    }

    const setFilteredExchangesIncluded = (filteredExchangesIn: string[] | null) => {
        if (filteredExchangesIn !== null) {
            saveFilteredExchangesInToLocalStorage(filteredExchangesIn)
        } else {
            localStorage.removeItem("filteredExchangesIn")
        }
        dispatch({
            type: "SET_FILTERED_EXCHANGES_IN",
            payload: filteredExchangesIn
        })
    }

    const setFilteredExchangesExcluded = (filteredExchangesOut: string[] | null) => {
        if (filteredExchangesOut !== null) {
            saveFilteredExchangesOutToLocalStorage(filteredExchangesOut)
        } else {
            localStorage.removeItem("filteredExchangesOut")
        }
        dispatch({
            type: "SET_FILTERED_EXCHANGES_OUT",
            payload: filteredExchangesOut
        })
    }

    const setFilteredExchangesIncludedExclusivelyFrom = (filteredExchangesIncludedExclusivelyFrom: string[] | null) => {
        if (filteredExchangesIncludedExclusivelyFrom !== null) {
            saveFilteredExchangesInExclusivelyFromLocalStorage(filteredExchangesIncludedExclusivelyFrom)
        } else {
            localStorage.removeItem("filteredExchangesIncludedExclusivelyFrom")
        }
        dispatch({
            type: "SET_FILTERED_EXCHANGES_INCLUDED_EXCLUSIVELY_FROM",
            payload: filteredExchangesIncludedExclusivelyFrom
        })
    }

    const setFilteredExchangesIncludedExclusivelyTo = (filteredExchangesIncludedExclusivelyTo: string[] | null) => {
        if (filteredExchangesIncludedExclusivelyTo !== null) {
            saveFilteredExchangesInExclusivelyToLocalStorage(filteredExchangesIncludedExclusivelyTo)
        } else {
            localStorage.removeItem("filteredExchangesIncludedExclusivelyTo")
        }
        dispatch({
            type: "SET_FILTERED_EXCHANGES_INCLUDED_EXCLUSIVELY_TO",
            payload: filteredExchangesIncludedExclusivelyTo
        })
    }

    const setExcludedCoins = (excludedCoins: string[] | null) => {
        if (excludedCoins !== null) {
            saveExcludedCoinsToLocalStorage(excludedCoins)
        } else {
            localStorage.removeItem("excludedCoins")
        }
        dispatch({
            type: "SET_EXCLUDED_COINS",
            payload: excludedCoins
        })
    }

    const setExcludedNetworks = (excludedNetworks: string[] | null) => {
        if (excludedNetworks !== null) {
            saveExcludedNetworksToLocalStorage(excludedNetworks)
        } else {
            localStorage.removeItem("excludedNetworks")
        }
        dispatch({
            type: "SET_EXCLUDED_NETWORKS",
            payload: excludedNetworks
        })
    }

    const setDateTimeRange = (dateTimeRange: [Dayjs | null, Dayjs | null]) => {
        saveDateTimeRangeToLocalStorage(dateTimeRange)
        dispatch({
            type: "SET_DATE_TIME_RANGE",
            payload: dateTimeRange
        })
    }

    const setShouldCountUSDTReturnFee = (shouldCountUSDTReturnFee: boolean) => {
        saveShouldCountUSDTReturnFee(shouldCountUSDTReturnFee)
        dispatch({
            type: "SET_SHOULD_COUNT_USDT_RETURN_FEE",
            payload: shouldCountUSDTReturnFee
        })
    }

    const setShouldExcludeOppoortunitiesWithRatedFees = (shouldExcludeOppoortunitiesWithRatedFees: boolean) => {
        saveShouldExcludeOpportunitiesWithRatedFees(shouldExcludeOppoortunitiesWithRatedFees)
        dispatch({
            type: "SET_SHOULD_EXCLUDE_OPPORTUNITIES_WITH_RATED_FEES",
            payload: shouldExcludeOppoortunitiesWithRatedFees
        })
    }

    const setShouldExcludeAmbiguousContractAddressMatches = (shouldExcludeAmbiguousContractAddressMatches: boolean) => {
        saveShouldExcludeAmbiguousContractAddressMatches(shouldExcludeAmbiguousContractAddressMatches)
        dispatch({
            type: "SET_SHOULD_EXCLUDE_AMBIGUOUS_CONTRACT_ADDRESS_MATCHES",
            payload: shouldExcludeAmbiguousContractAddressMatches
        })
    }

    const setForceContractAddressMatchCoinNetworkPairs = (forceContractAddressMatchCoinNetworkPairs: string[]) => {
        saveForceContractAddressMatchCoinNetworkPairsToLocalStorage(forceContractAddressMatchCoinNetworkPairs)
        dispatch({
            type: "SET_FORCE_CONTRACT_ADDRESS_MATCH_COIN_NETWORK_PAIRS",
            payload: forceContractAddressMatchCoinNetworkPairs
        })
    }

    const setIsSimplifiedScheme = (isSimplifiedScheme: boolean) => {
        saveIsSimplifiedSchemeToLocalStorage(isSimplifiedScheme)
        dispatch({
            type: "SET_IS_SIMPLIFIED_SCHEME",
            payload: isSimplifiedScheme
        })
    }

    return (
        <ConfigContext.Provider
            value={{
                ...state,
                setDepositAmount,
                setHedgingPreference,
                setShowLowProfitOpportunities,
                setMinProfit,
                setMinCost,
                setMinMargin,
                setMinDuration,
                setMinNbPoints,
                setMaxTxTime,
                setMinProfitToFeeRatio,
                setFilteredExchangesIncluded,
                setFilteredExchangesExcluded,
                setFilteredExchangesIncludedExclusivelyFrom,
                setFilteredExchangesIncludedExclusivelyTo,
                setExcludedCoins,
                setExcludedNetworks,
                setDateTimeRange,
                setShouldCountUSDTReturnFee,
                setShouldExcludeOppoortunitiesWithRatedFees,
                setShouldExcludeAmbiguousContractAddressMatches,
                setForceContractAddressMatchCoinNetworkPairs,
                setIsSimplifiedScheme
            }}
        >
            {children}
        </ConfigContext.Provider>
    )
}
