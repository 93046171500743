import {
    SaveOutlined,
    DeleteOutlined,
    ApiOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined,
    CloseOutlined,
    ReloadOutlined
} from "@ant-design/icons"
import {
    Spin,
    Tooltip,
    Button,
    Typography,
    Input,
    Popconfirm,
    Row,
    Col,
    Tabs,
    message as antdMessage,
    Empty,
    Collapse
} from "antd"
import {
    IExchangeDriver,
    CoinSpotBalance,
    CoinMarginBalance,
    MarginAccountMode,
    MarginTradeType,
    Amount
} from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/types"
import bigDecimal from "js-big-decimal"
import { FC, useState, useCallback, useEffect } from "react"
import { FlexRow, Paper, FlexCol } from "../../common"
import { loadExchangeAPIKeySet, storeExchangeAPIKeySet, deleteExchangeAPIKeySet } from "../../store"
import {
    Exchange,
    CommonNetworkNameMap,
    WellKnownCoinFeeRatesMap,
    WellKnownNetworkBlockTimesMap,
    ExecutorControllerSide
} from "../../types"
import { formattedNumberToFixedWithoutTrailingZeros } from "../../utils"
import { getExchangeDriverByName, getExchangeDriverIconSrcUrl } from "./utils"
import { SpotOrderController } from "./spotOrderController"
import { DepositController } from "./depositController"
import { WithdrawController } from "./withdrawController"
import { MarginOrderController } from "./marginOrderController"
import { useExecutorContext } from "../../reducers/executorReducer"

export enum APIControllerTab {
    Deposit = "Deposit",
    Withdraw = "Withdraw",
    SpotTrade = "Spot Trade",
    MarginTrade = "Margin Trade"
}

const ExchangeAPIBalanceWidget: FC<{
    exchangeDriver: IExchangeDriver | null
    externalTrigger: number
}> = ({ exchangeDriver, externalTrigger }) => {
    const [spotBalanceUSDT, setSpotBalanceUSDT] = useState<CoinSpotBalance | null>(null)
    const [marginBalanceUSDT, setMarginBalanceUSDT] = useState<CoinMarginBalance | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const loadSpotBalanceUSDT = useCallback(async () => {
        if (exchangeDriver === null) {
            return
        }
        try {
            let { balance } = await exchangeDriver.getSpotCoinBalance("USDT")
            setSpotBalanceUSDT(balance)
        } catch (e: any) {
            console.log(`ExchangeAPIKeysWidget: ${exchangeDriver.name()}: getSpotCoinBalance error`, e)
        }
    }, [exchangeDriver])

    const loadMarginBalanceUSDT = useCallback(async () => {
        if (exchangeDriver === null) {
            return
        }
        if (exchangeDriver.config().marginAccountMode === MarginAccountMode.Unified) {
            return
        }
        let hasCrossMarginTradeType = false
        for (let tradeType of exchangeDriver.config().marginTradeTypes) {
            if (tradeType === MarginTradeType.Cross) {
                hasCrossMarginTradeType = true
                break
            }
        }
        if (!hasCrossMarginTradeType) {
            return
        }
        try {
            let { balance } = await exchangeDriver.getMarginCoinBalance({ coinName: "USDT", isolatedSymbolName: null })
            setMarginBalanceUSDT(balance)
        } catch (e: any) {
            console.log(`ExchangeAPIKeysWidget: ${exchangeDriver.name()}: getMarginCoinBalance error`, e)
        }
    }, [exchangeDriver])

    useEffect(() => {
        setIsLoading(true)
        Promise.all([loadSpotBalanceUSDT(), loadMarginBalanceUSDT()]).finally(() => {
            setIsLoading(false)
        })
    }, [exchangeDriver, externalTrigger])

    const _formatBalance = (qty: Amount | null): string => {
        return bigDecimal.getPrettyValue(formattedNumberToFixedWithoutTrailingZeros(bigDecimal.round(qty, 2)))
    }

    if (isLoading) {
        return (
            <FlexRow>
                <div style={{ minHeight: "1.2rem" }}></div>
                <Spin size="default" />
            </FlexRow>
        )
    }

    if (exchangeDriver === null || spotBalanceUSDT === null) {
        return null
    }
    return (
        <FlexRow style={{ alignItems: "center", gap: 5 }}>
            <Tooltip
                overlay={
                    <>
                        {" "}
                        <b>SPOT account</b>
                        <br />
                        <b>Free: </b> ${_formatBalance(spotBalanceUSDT.freeQty)}
                        <br />
                        <b>Locked: </b> ${_formatBalance(spotBalanceUSDT.lockedQty)}
                        <br />
                    </>
                }
            >
                <span style={{ fontSize: "1.2rem" }}>
                    ${_formatBalance(bigDecimal.add(spotBalanceUSDT.freeQty, spotBalanceUSDT.lockedQty))}
                </span>
            </Tooltip>
            {marginBalanceUSDT !== null && (
                <Tooltip
                    overlay={
                        <>
                            <b>MARGIN account</b>
                            <br />
                            <b>Free: </b> ${_formatBalance(marginBalanceUSDT.freeQty)}
                            <br />
                            <b>Locked: </b> ${_formatBalance(marginBalanceUSDT.lockedQty)}
                            <br />
                            <b>Borrowed: </b> ${_formatBalance(marginBalanceUSDT.borrowedQty)}
                            <br />
                            <b>Net: </b> ${_formatBalance(marginBalanceUSDT.netQty)}
                        </>
                    }
                >
                    <span
                        style={{
                            fontSize: "1.2rem"
                        }}
                    >
                        | ${_formatBalance(bigDecimal.add(marginBalanceUSDT.freeQty, marginBalanceUSDT.lockedQty))}
                    </span>
                </Tooltip>
            )}
            <Button
                size="small"
                icon={<ReloadOutlined />}
                onClick={() => {
                    setIsLoading(true)
                    Promise.all([loadSpotBalanceUSDT(), loadMarginBalanceUSDT()]).finally(() => {
                        setIsLoading(false)
                    })
                }}
            />
        </FlexRow>
    )
}

export const ExchangeAPIKeysWidget: FC<{
    exchangeName: Exchange["Name"] | undefined
    setCurrentLeftExchangeDriver: (v: IExchangeDriver | null) => void
    setCurrentRightExchangeDriver: (v: IExchangeDriver | null) => void
    deleteExchange: (v: Exchange["Name"]) => void
    setRawContent: (v: any) => void
    setAbstractContent: (v: any) => void
    balancesExternalTrigger: number
    isVisible: boolean
}> = ({
    exchangeName,
    setCurrentLeftExchangeDriver,
    setCurrentRightExchangeDriver,
    deleteExchange,
    setRawContent,
    setAbstractContent,
    balancesExternalTrigger,
    isVisible
}) => {
    const [apiKeyValue, setAPIKeyValue] = useState<string>("")
    const [secretKeyValue, setSecretKeyValue] = useState<string>("")
    const [passphraseValue, setPassphraseValue] = useState<string>("")

    const [exchangeDriver, setExchangeDriver] = useState<IExchangeDriver | null>(null)

    useEffect(() => {
        if (!exchangeName) {
            return
        }
        let apiKeySet = {
            apiKey: apiKeyValue,
            secretKey: secretKeyValue,
            passphrase: passphraseValue
        }
        if (apiKeyValue === "" || secretKeyValue === "") {
            return
        }
        let _exchangeDriver = getExchangeDriverByName(exchangeName, apiKeySet)
        setExchangeDriver(_exchangeDriver)
    }, [exchangeName, apiKeyValue, secretKeyValue, passphraseValue])

    useEffect(() => {
        if (!exchangeName) {
            return
        }
        loadExchangeAPIKeySet(exchangeName).then(apiKeySet => {
            if (apiKeySet === null) {
                return
            }
            let { apiKey, secretKey, passphrase } = apiKeySet
            setAPIKeyValue(apiKey)
            setSecretKeyValue(secretKey)
            if (passphrase !== null) {
                setPassphraseValue(passphrase)
            }
        })
    }, [exchangeName])

    if (!exchangeName) {
        return null
    }

    return (
        <Paper>
            <FlexCol
                style={{
                    alignItems: "center"
                }}
            >
                <FlexRow style={{ alignItems: "center" }}>
                    <img width={32} height={32} src={getExchangeDriverIconSrcUrl(exchangeName)} />
                    <Typography.Text style={{ fontSize: "1.3rem" }}>{exchangeName}</Typography.Text>
                </FlexRow>
                <Input
                    style={{
                        width: "100%"
                    }}
                    type={isVisible ? "text" : "password"}
                    placeholder="API Key"
                    value={apiKeyValue}
                    onChange={e => {
                        setAPIKeyValue(e.target.value)
                    }}
                />
                <Input
                    style={{
                        width: "100%"
                    }}
                    placeholder="Secret Key"
                    value={secretKeyValue}
                    type={isVisible ? "text" : "password"}
                    onChange={e => {
                        setSecretKeyValue(e.target.value)
                    }}
                />
                <Input
                    style={{
                        width: "100%"
                    }}
                    placeholder="Passphrase"
                    value={passphraseValue}
                    type={isVisible ? "text" : "password"}
                    onChange={e => {
                        setPassphraseValue(e.target.value)
                    }}
                />
                <FlexRow>
                    <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        onClick={async () => {
                            await storeExchangeAPIKeySet(exchangeName, {
                                apiKey: apiKeyValue,
                                secretKey: secretKeyValue,
                                passphrase: passphraseValue
                            })
                            antdMessage.success(`${exchangeName} API keys saved`)
                        }}
                    />
                    <Popconfirm
                        title="Are you sure to delete API Keys for this exchange?"
                        onConfirm={async () => {
                            await deleteExchangeAPIKeySet(exchangeName)
                            deleteExchange(exchangeName)
                        }}
                    >
                        <Button danger icon={<DeleteOutlined />} />
                    </Popconfirm>
                    <Tooltip overlay="Check API keys">
                        <Button
                            icon={<ApiOutlined />}
                            onClick={async () => {
                                try {
                                    if (exchangeDriver === null) {
                                        return
                                    }
                                    let { raw, check } = await exchangeDriver.checkAPI()
                                    setRawContent(raw)
                                    setAbstractContent(check)
                                    if (check.ok) {
                                        antdMessage.success(`${exchangeName} API: OK`)
                                    } else {
                                        antdMessage.error(`${exchangeName} API: Not OK`)
                                    }
                                } catch (e: any) {
                                    antdMessage.error(`${exchangeName} API: Not OK: ${e.message}`)
                                }
                            }}
                        />
                    </Tooltip>
                </FlexRow>
                <FlexRow>
                    <Tooltip overlay="Set as LEFT exchange">
                        <Button
                            icon={<DoubleLeftOutlined />}
                            onClick={() => {
                                setCurrentLeftExchangeDriver(null)
                                setCurrentLeftExchangeDriver(exchangeDriver)
                            }}
                        />
                    </Tooltip>
                    <Tooltip overlay="Set as RIGHT exchange">
                        <Button
                            icon={<DoubleRightOutlined />}
                            onClick={() => {
                                setCurrentRightExchangeDriver(null)
                                setCurrentRightExchangeDriver(exchangeDriver)
                            }}
                        />
                    </Tooltip>
                </FlexRow>
                <FlexRow style={{ justifyContent: "end", width: "100%" }}>
                    <ExchangeAPIBalanceWidget
                        exchangeDriver={exchangeDriver}
                        externalTrigger={balancesExternalTrigger}
                    />
                </FlexRow>
            </FlexCol>
        </Paper>
    )
}

export const ExchangeAPIControlWidget: FC<{
    exchangeDriver: IExchangeDriver | null
    clearExchangeName: () => void
    commonNetworkNameMap: CommonNetworkNameMap
    wellKnownCoinFeeRatesMap: WellKnownCoinFeeRatesMap | null
    wellKnownNetworkBlockTimesMap: WellKnownNetworkBlockTimesMap | null
    activeTabKey: APIControllerTab
    setActiveTabKey: (v: APIControllerTab) => void
    executorControllerSide: ExecutorControllerSide
}> = ({
    exchangeDriver,
    clearExchangeName,
    commonNetworkNameMap,
    wellKnownCoinFeeRatesMap,
    wellKnownNetworkBlockTimesMap,
    activeTabKey,
    setActiveTabKey,
    executorControllerSide
}) => {
    if (exchangeDriver === null) {
        return null
    }

    return (
        <Paper
            style={{
                height: "100%"
            }}
        >
            <Row gutter={[10, 10]}>
                <Col xs={24}>
                    <Row
                        justify="space-between"
                        style={{
                            width: "100%"
                        }}
                    >
                        <Col>
                            <FlexRow style={{ alignItems: "center" }}>
                                <img width={32} height={32} src={getExchangeDriverIconSrcUrl(exchangeDriver.name())} />
                                <Typography.Text style={{ fontSize: "1.3rem" }}>
                                    <b>{exchangeDriver.name()}</b>
                                </Typography.Text>
                            </FlexRow>
                        </Col>
                        <Col>
                            <FlexRow>
                                <Button
                                    icon={<CloseOutlined />}
                                    onClick={() => {
                                        clearExchangeName()
                                    }}
                                />
                            </FlexRow>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24}>
                    <Tabs
                        activeKey={activeTabKey}
                        onChange={v => {
                            setActiveTabKey(v as APIControllerTab)
                        }}
                        destroyInactiveTabPane
                        style={{
                            width: "100%",
                            height: "100%"
                        }}
                        items={[
                            {
                                key: APIControllerTab.Withdraw,
                                label: APIControllerTab.Withdraw,
                                children: (
                                    <WithdrawController
                                        exchangeDriver={exchangeDriver}
                                        commonNetworkNameMap={commonNetworkNameMap}
                                        wellKnownCoinFeeRatesMap={wellKnownCoinFeeRatesMap}
                                    />
                                )
                            },
                            {
                                key: APIControllerTab.Deposit,
                                label: APIControllerTab.Deposit,
                                children: (
                                    <DepositController
                                        exchangeDriver={exchangeDriver}
                                        commonNetworkNameMap={commonNetworkNameMap}
                                        wellKnownCoinFeeRatesMap={wellKnownCoinFeeRatesMap}
                                        wellKnownNetworkBlockTimesMap={wellKnownNetworkBlockTimesMap}
                                    />
                                )
                            },
                            {
                                key: APIControllerTab.SpotTrade,
                                label: APIControllerTab.SpotTrade,
                                children: (
                                    <SpotOrderController
                                        exchangeDriver={exchangeDriver}
                                        executorControllerSide={executorControllerSide}
                                    />
                                )
                            },
                            {
                                key: APIControllerTab.MarginTrade,
                                label: APIControllerTab.MarginTrade,
                                children:
                                    (
                                        exchangeDriver !== null &&
                                        exchangeDriver.config().marginAccountMode !== MarginAccountMode.None
                                    ) ?
                                        <MarginOrderController
                                            exchangeDriver={exchangeDriver}
                                            executorControllerSide={executorControllerSide}
                                        />
                                    :   <Empty
                                            description={
                                                <>
                                                    <b>MARGIN</b> trading is not supported on{" "}
                                                    <b>{exchangeDriver.name()}</b>
                                                </>
                                            }
                                        />
                            }
                        ]}
                    />
                </Col>
            </Row>
        </Paper>
    )
}
