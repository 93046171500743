import {
    WarningFilled,
    ExclamationCircleFilled,
    AlertOutlined,
    FullscreenExitOutlined,
    ReloadOutlined,
    InfoCircleTwoTone,
    QuestionCircleTwoTone,
    CloseOutlined,
    DeleteOutlined,
    ExclamationOutlined,
    WarningOutlined,
    CheckCircleFilled
} from "@ant-design/icons"
import {
    List,
    Switch,
    Button,
    Tag,
    Tooltip,
    Typography,
    message,
    Spin,
    Row,
    Col,
    Select,
    Input,
    InputNumber,
    Popconfirm,
    Alert
} from "antd"
import { BaseOptionType } from "antd/es/select"
import {
    IExchangeDriver,
    WithdrawHistoryElement,
    StatusSeverity,
    CoinInfo,
    SymbolInfo,
    CoinSpotBalance,
    CoinNetworkInfo,
    Price,
    Amount
} from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/types"
import { gt, lt } from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/utils"
import bigDecimal from "js-big-decimal"
import { FC, useState, useCallback, useEffect, useMemo, ReactElement } from "react"
import { FlexRow, FlexCol } from "../../common"
import {
    ARBITRAGE_COLOR_GREEN,
    ARBITRAGE_COLOR_RED,
    ARBITRAGE_COLOR_WARN,
    ARBITRAGE_COLOR_WITHDRAWAL,
    colorHexToRGBA
} from "../../constants"
import { useExecutorContext } from "../../reducers/executorReducer"
import {
    CommonNetworkNameMap,
    WellKnownCoinFeeRatesMap,
    WellKnownCoinNetworkExchangeContractAddress
} from "../../types"
import { contractAddressesMatch, formattedNumberToFixedWithoutTrailingZeros } from "../../utils"
import { getDirectNetworkCommonName, getReverseNetworkCommonName, isLeveraged, symbolIsUSDTQuoted } from "./utils"
import { useAuthContext } from "../../reducers/authReducer"

const WithdrawHistoryList: FC<{
    exchangeDriver: IExchangeDriver | null
    coinName: string | null
    commonNetworkNameMap: CommonNetworkNameMap
}> = ({ exchangeDriver, coinName, commonNetworkNameMap }) => {
    const [withdrawHistory, setWithdrawHistory] = useState<WithdrawHistoryElement[] | null>(null)
    const [isOnlyCurrentCoinName, setIsOnlyCurrentCoinName] = useState<boolean>(true)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const loadHistory = useCallback(async () => {
        if (exchangeDriver === null) {
            return
        }
        try {
            let { history } = await exchangeDriver.getWithdrawHistory({
                coinName: isOnlyCurrentCoinName ? coinName : null,
                networkName: null
            })
            history.sort((a, b) => {
                return b.timestamp - a.timestamp
            })
            setWithdrawHistory(history)
        } catch (e: any) {
            console.log(exchangeDriver.name(), ": getWithdrawHistory error", e)
        }
    }, [exchangeDriver, coinName, isOnlyCurrentCoinName])

    useEffect(() => {
        loadHistory().finally(() => {
            setIsLoading(false)
        })
    }, [exchangeDriver, coinName, isOnlyCurrentCoinName])

    return (
        <List
            header={
                <FlexRow
                    style={{
                        alignItems: "center",
                        justifyContent: "space-between"
                    }}
                >
                    <span>Withdraw history</span>
                    <FlexRow
                        style={{
                            alignItems: "center"
                        }}
                    >
                        {coinName !== null && (
                            <Switch
                                // size="small"
                                checkedChildren={`${coinName}`}
                                unCheckedChildren="ALL"
                                checked={isOnlyCurrentCoinName}
                                onChange={checked => {
                                    setIsOnlyCurrentCoinName(checked)
                                }}
                            />
                        )}
                        <Button
                            icon={<ReloadOutlined />}
                            onClick={async () => {
                                setIsLoading(true)
                                try {
                                    await loadHistory()
                                } finally {
                                    setIsLoading(false)
                                }
                            }}
                        />
                    </FlexRow>
                </FlexRow>
            }
            loading={isLoading}
            style={{
                backgroundColor: "white"
            }}
            size="small"
            bordered
            pagination={{
                pageSize: 5,
                hideOnSinglePage: true,
                size: "small"
            }}
            footer={null}
            dataSource={withdrawHistory ?? []}
            renderItem={historyElement => {
                let statusStr = historyElement.status.toUpperCase()
                let statusTag = <Tag color="default">{statusStr}</Tag>
                switch (historyElement.statusSeverity) {
                    case StatusSeverity.Warning:
                        statusTag = <Tag color="warning">{statusStr}</Tag>
                        break
                    case StatusSeverity.Error:
                        statusTag = <Tag color="error">{statusStr}</Tag>
                        break
                    case StatusSeverity.Success:
                        statusTag = <Tag color="success">{statusStr}</Tag>
                        break
                    default:
                        break
                }
                let networkTag = (
                    <Tooltip overlay={commonNetworkNameMap[historyElement.networkName]}>
                        <Tag color="default">{historyElement.networkName.toUpperCase()}</Tag>
                    </Tooltip>
                )
                let fontSize = "0.8rem"
                return (
                    <List.Item
                        key={`
                        ${historyElement.coinName}_${historyElement.txID}
                    `}
                    >
                        <FlexCol
                            style={{
                                gap: 0,
                                width: "100%"
                            }}
                        >
                            <FlexRow
                                style={{
                                    gap: 1,
                                    width: "100%",
                                    justifyContent: "space-between",
                                    alignItems: "center"
                                }}
                            >
                                <FlexRow style={{ gap: 1, alignItems: "center" }}>
                                    {statusTag}
                                    {networkTag}
                                    {bigDecimal.getPrettyValue(
                                        formattedNumberToFixedWithoutTrailingZeros(historyElement.amount)
                                    )}{" "}
                                    {historyElement.coinName.toUpperCase()}
                                </FlexRow>
                                <span style={{ fontSize }}>{new Date(historyElement.timestamp).toLocaleString()}</span>
                            </FlexRow>
                            <span style={{ fontSize }}>
                                <b>Fee:</b>{" "}
                                {historyElement.fee ?
                                    bigDecimal.getPrettyValue(
                                        formattedNumberToFixedWithoutTrailingZeros(historyElement.fee)
                                    )
                                :   "N/A"}{" "}
                                {historyElement.coinName.toUpperCase()}
                            </span>
                            <span style={{ fontSize }}>
                                <b>Addr:</b>
                                <Typography.Text code copyable style={{ fontSize }}>
                                    {historyElement.address}
                                </Typography.Text>
                            </span>
                            <FlexRow style={{ gap: 1, alignItems: "center", justifyContent: "space-between" }}>
                                {historyElement.txID ?
                                    <span style={{ fontSize }}>
                                        <b>TxID:</b>
                                        <Typography.Text code copyable style={{ fontSize }}>
                                            {historyElement.txID}
                                        </Typography.Text>
                                    </span>
                                :   <span style={{ fontSize }}>
                                        <b>Internal ID:</b>
                                        <Typography.Text code copyable style={{ fontSize }}>
                                            {historyElement.id}
                                        </Typography.Text>
                                    </span>
                                }
                                <Button
                                    icon={<CloseOutlined />}
                                    size="small"
                                    danger
                                    disabled={
                                        exchangeDriver === null ||
                                        !!historyElement.txID ||
                                        historyElement.statusSeverity !== StatusSeverity.Neutral
                                    }
                                    onClick={async () => {
                                        if (exchangeDriver === null) {
                                            return
                                        }
                                        try {
                                            await exchangeDriver.withdrawCancel(historyElement.id)
                                            message.success(
                                                `Withdrawal cancelled on ${exchangeDriver.name()}. ID: ${
                                                    historyElement.id
                                                }`
                                            )
                                            await loadHistory()
                                        } catch (e: any) {
                                            message.error(
                                                `An error occurred whilst cancelling withdrawal #${
                                                    historyElement.id
                                                }: ${e.toString()}`
                                            )
                                        }
                                    }}
                                />
                            </FlexRow>
                        </FlexCol>
                    </List.Item>
                )
            }}
        />
    )
}

const WhitelistWithdrawalAlertWidget: FC<{
    exchangeDriver: IExchangeDriver | null
}> = ({ exchangeDriver }) => {
    if (exchangeDriver === null || !exchangeDriver.config().withdrawalWhitelist) {
        return null
    }
    return (
        <Tooltip
            overlay={
                <>{exchangeDriver.name()} requires the address to be manually added to the whitelist or address book</>
            }
        >
            <WarningFilled style={{ color: ARBITRAGE_COLOR_WARN }} />
        </Tooltip>
    )
}

const WithdrawalIsImmediateAlertWidget: FC<{
    exchangeDriver: IExchangeDriver | null
}> = ({ exchangeDriver }) => {
    if (exchangeDriver === null || !exchangeDriver.config().withdrawalIsImmediate) {
        return null
    }
    return (
        <Tooltip
            overlay={
                <>
                    {" "}
                    It is impossible to cancel a withdrawal via API on {exchangeDriver.name()}, double-check the
                    parameters!
                </>
            }
        >
            <ExclamationCircleFilled style={{ color: ARBITRAGE_COLOR_WITHDRAWAL }} />
        </Tooltip>
    )
}

export const WithdrawController: FC<{
    exchangeDriver: IExchangeDriver | null
    commonNetworkNameMap: CommonNetworkNameMap
    wellKnownCoinFeeRatesMap: WellKnownCoinFeeRatesMap | null
}> = ({ exchangeDriver, commonNetworkNameMap, wellKnownCoinFeeRatesMap }) => {
    const [coins, setCoins] = useState<CoinInfo[] | null>(null)
    const [symbols, setSymbols] = useState<SymbolInfo[] | null>(null)
    const [balances, setBalances] = useState<CoinSpotBalance[] | null>(null)

    const [coinNetworks, setCoinNetworks] = useState<CoinNetworkInfo[]>([])

    const [selectedCoinName, setSelectedCoinName] = useState<string | null>(null)
    const [selectedNetworkName, setSelectedNetworkName] = useState<string | null>(null)

    const [currentSymbol, setSelectedSymbol] = useState<SymbolInfo | null>(null)
    const [currentPrice, setCurrentPrice] = useState<Price | null>(null)
    const [currentNetwork, setCurrentNetwork] = useState<CoinNetworkInfo | null>(null)
    const [currentBalance, setCurrentBalance] = useState<CoinSpotBalance | null>(null)

    const [withdrawAmount, setWithdrawAmount] = useState<Amount | null>(null)
    const [withdrawAddress, setWithdrawAddress] = useState<string | null>(null)
    const [withdrawMemo, setWithdrawMemo] = useState<string | null>(null)

    const [currentWithdrawalID, setCurrentWithdrawalID] = useState<string | number | null>(null)

    const {
        executorLeftmostExchangeName,
        executorRightmostExchangeName,
        executorCommonCoin,
        executorCommonNetwork,
        executorCommonAddress,
        executorCommonMemo,
        executorCommonMinDepositAmount,
        executorLinkEnabled,

        setExecutorCommonCoin,
        setExecutorCommonNetwork
    } = useExecutorContext()

    const { callSecureAPI } = useAuthContext()

    const [wellKnownCoinNetworkContractAddresses, setWellKnownCoinNetworkContractAddresses] = useState<
        WellKnownCoinNetworkExchangeContractAddress[] | null
    >(null)

    const loadCoins = useCallback(async () => {
        if (exchangeDriver === null) {
            return
        }
        try {
            let { coins } = await exchangeDriver.getCoins()
            let _coins: CoinInfo[] = []
            for (let coin of coins) {
                if (isLeveraged(coin)) {
                    continue
                }
                _coins.push(coin)
            }
            setCoins(_coins)
        } catch (e: any) {
            console.log(`WithdrawController: ${exchangeDriver.name()}: getCoins error`, e)
        }
    }, [exchangeDriver])

    const loadBalances = useCallback(async () => {
        if (exchangeDriver === null) {
            return
        }
        try {
            let { balances } = await exchangeDriver.getSpotBalances()
            setBalances(balances)
        } catch (e: any) {
            console.log(`WithdrawController: ${exchangeDriver.name()}: getSpotBalances error`, e)
        }
    }, [exchangeDriver])

    const loadSymbols = useCallback(async () => {
        if (exchangeDriver === null) {
            return
        }
        try {
            let { symbols } = await exchangeDriver.getSpotSymbols()
            setSymbols(symbols)
        } catch (e: any) {
            console.log(`WithdrawController: ${exchangeDriver.name()}: getSymbols error`, e)
        }
    }, [exchangeDriver])

    const loadCoinBalance = useCallback(async () => {
        if (selectedCoinName === null || exchangeDriver === null) {
            setCurrentBalance(null)
            return
        }
        exchangeDriver
            .getSpotCoinBalance(selectedCoinName)
            .then(({ balance }) => {
                setCurrentBalance(balance)
            })
            .catch((e: any) => {
                console.log(`WithdrawController: ${exchangeDriver.name()}: getSpotCoinBalance error`, e)
            })
    }, [exchangeDriver, selectedCoinName])

    const loadSymbolPrice = useCallback(async () => {
        if (exchangeDriver === null || currentSymbol === null) {
            setCurrentPrice(null)
            return
        }
        exchangeDriver
            .getSymbolOrderbook(currentSymbol.ownName)
            .then(({ orderbook }) => {
                let hb = orderbook.bids[0]
                let la = orderbook.asks[0]
                if (hb === undefined || la === undefined) {
                    return
                }
                let price = bigDecimal.add(hb.price, la.price)
                price = bigDecimal.divide(price, "2", 24)
                price = formattedNumberToFixedWithoutTrailingZeros(price)
                setCurrentPrice(price)
            })
            .catch((e: any) => {
                console.warn(`WithdrawController: ${exchangeDriver.name()}: getOrderBook error`, e)
            })
    }, [exchangeDriver, currentSymbol])

    const loadCoinNetworks = useCallback(async () => {
        if (exchangeDriver === null || selectedCoinName === null) {
            setCoinNetworks([])
            return
        }
        exchangeDriver
            .getCoinNetworks(selectedCoinName)
            .then(({ networks }) => {
                setCoinNetworks(networks)
                let sortedNetworkOptions = _getSortedNetworkOptions(networks, selectedCoinName)
                let firstNetwork = sortedNetworkOptions[0]
                if (firstNetwork !== undefined) {
                    if (executorLinkEnabled && executorCommonNetwork === null) {
                        setSelectedNetworkName(firstNetwork.networkName)
                        let commonName = getDirectNetworkCommonName(firstNetwork.networkName, commonNetworkNameMap)
                        if (commonName === null) {
                            return
                        }
                        setExecutorCommonNetwork(commonName)
                        // if (executorLinkEnabled) {
                        // }
                    }
                }
            })
            .catch((e: any) => {
                console.warn(`WithdrawController: ${exchangeDriver.name()}: getCoinNetworks error`, e)
            })
    }, [exchangeDriver, selectedCoinName])

    useEffect(() => {
        if (executorCommonCoin === null || executorCommonNetwork === null) {
            return
        }
        let path = `/well-known/coin/network/contract_addresses?coin_name=${executorCommonCoin}&network_name=${executorCommonNetwork}`
        callSecureAPI<WellKnownCoinNetworkExchangeContractAddress[]>(path)
            .then(({ responseObject }) => {
                setWellKnownCoinNetworkContractAddresses(responseObject)
            })
            .catch((e: any) => {
                console.warn(`WithdrawController: callSecureAPI (${path}) error`, e)
            })
    }, [executorCommonCoin, executorCommonNetwork])

    const withdrawApply = useCallback(async () => {
        if (
            exchangeDriver === null ||
            selectedCoinName === null ||
            withdrawAmount === null ||
            withdrawAddress === null ||
            currentNetwork === null
        ) {
            return
        }
        try {
            let { withdrawId } = await exchangeDriver.withdrawApply({
                coinName: selectedCoinName,
                networkName: currentNetwork.ownName,
                address: withdrawAddress,
                memo: withdrawMemo,
                amount: withdrawAmount
            })
            if (withdrawId !== null && withdrawId !== undefined) {
                setCurrentWithdrawalID(withdrawId)
            }
            message.success(`Withdrawal submitted on ${exchangeDriver.name()}. ID: ${withdrawId}`)
        } catch (e: any) {
            message.error(`An error occurred whilst withdrawing: ${e.toString()}`)
        }
    }, [exchangeDriver, selectedCoinName, currentNetwork, withdrawAmount, withdrawAddress, withdrawMemo])

    const withdrawCancel = useCallback(async () => {
        if (exchangeDriver === null || currentWithdrawalID === null) {
            return
        }
        try {
            let { raw } = await exchangeDriver.withdrawCancel(currentWithdrawalID)
            console.log("withdrawCancel resp", raw)
            message.success(`Withdrawal cancelled on ${exchangeDriver.name()}. ID: ${currentWithdrawalID}`)
        } catch (e: any) {
            message.error(`An error occurred whilst cancelling withdrawal #${currentWithdrawalID}: ${e.toString()}`)
        }
    }, [exchangeDriver, currentWithdrawalID])

    const withdrawTest = useCallback(async () => {
        if (
            exchangeDriver === null ||
            selectedCoinName === null ||
            withdrawAmount === null ||
            withdrawAddress === null ||
            currentNetwork === null
        ) {
            return
        }
        if (exchangeDriver.config().withdrawalIsImmediate) {
            message.warning(
                `Withdrawal test is not available on ${exchangeDriver.name()} as withdrawals are immediate.`
            )
            return
        }
        try {
            let { raw: rawApply, withdrawId } = await exchangeDriver.withdrawApply({
                coinName: selectedCoinName,
                networkName: currentNetwork.ownName,
                address: withdrawAddress,
                memo: withdrawMemo,
                amount: withdrawAmount
            })
            console.log(`WithdrawController: withdrawTest: withdrawApply resp: `, rawApply)
            let { raw: rawCancel } = await exchangeDriver.withdrawCancel(withdrawId)
            console.log(`WithdrawController: withdrawTest: withdrawCancel resp: `, rawCancel)
            message.success(`Withdrawal test succeeded on ${exchangeDriver.name()}: ${withdrawId}`)
        } catch (e: any) {
            message.error(`An error occurred whilst testing withdrawal: ${e.toString()}`)
        }
    }, [exchangeDriver, selectedCoinName, currentNetwork, withdrawAmount, withdrawAddress, withdrawMemo])

    // Load coins
    useEffect(() => {
        loadCoins()
    }, [exchangeDriver])

    // Load balances
    useEffect(() => {
        loadBalances()
    }, [exchangeDriver])

    // Load symbols
    useEffect(() => {
        loadSymbols()
    }, [exchangeDriver])

    // Set the current coin symbol
    useEffect(() => {
        if (symbols === null || selectedCoinName === null) {
            setSelectedSymbol(null)
            return
        }
        for (let symbol of symbols) {
            if (isLeveraged(symbol) || !symbolIsUSDTQuoted(symbol)) {
                continue
            }
            if (symbol.baseAsset.toUpperCase() === selectedCoinName.toUpperCase()) {
                setSelectedSymbol(symbol)
                return
            }
        }
        setSelectedSymbol(null)
    }, [symbols, selectedCoinName])

    // Load the current symbol price
    useEffect(() => {
        loadSymbolPrice()
    }, [exchangeDriver, currentSymbol])

    // Load the current balance
    useEffect(() => {
        loadCoinBalance()
    }, [exchangeDriver, selectedCoinName])

    // Load coin networks
    useEffect(() => {
        loadCoinNetworks()
    }, [exchangeDriver, selectedCoinName])

    // Set the current coin network
    useEffect(() => {
        if (coinNetworks === null || selectedNetworkName === null) {
            setCurrentNetwork(null)
            return
        }
        for (let network of coinNetworks) {
            if (network.networkName === selectedNetworkName) {
                setCurrentNetwork(network)
                return
            }
        }
        // setCurrentNetwork(null)
    }, [coinNetworks, selectedNetworkName])

    useEffect(() => {
        if (exchangeDriver === null) {
            setCurrentWithdrawalID(null)
        }
    }, [exchangeDriver])

    // Linking
    useEffect(() => {
        if (!executorLinkEnabled) {
            return
        }
        if (executorCommonCoin !== null && coins !== null) {
            let _selectedCoinName: string | null = null
            for (let coin of coins) {
                if (coin.coinName === executorCommonCoin) {
                    _selectedCoinName = coin.coinName
                    break
                }
            }
            setSelectedCoinName(_selectedCoinName)
        }
        if (coinNetworks === null) {
            return
        }
        if (executorCommonNetwork !== null) {
            let ownName: string | null = getReverseNetworkCommonName(
                executorCommonNetwork,
                coinNetworks.map(network => network.networkName),
                commonNetworkNameMap
            )
            setSelectedNetworkName(ownName)
        }
        setWithdrawAddress(executorCommonAddress)
        setWithdrawMemo(executorCommonMemo)
    }, [
        executorCommonCoin,
        executorCommonNetwork,
        executorCommonAddress,
        executorCommonMemo,
        executorLinkEnabled,
        coins,
        coinNetworks
    ])

    const memoCoinsOptions = useMemo((): BaseOptionType[] => {
        if (coins === null || balances === null) {
            return []
        }
        // console.debug(`WithdrawController: getMemoCoinsOptions: coinNetworks: `, coins)
        let preOptions: {
            coinName: string
            coinBalance: Amount
        }[] = []
        for (let coin of coins) {
            let balance: CoinSpotBalance | undefined = undefined
            if (balances !== null) {
                balance = balances.find(balance => {
                    return balance.coinName === coin.coinName
                })
            }
            preOptions.push({
                coinName: coin.coinName,
                coinBalance: balance?.freeQty ?? "0"
            })
        }
        preOptions.sort((a, b) => {
            let i = bigDecimal.compareTo(a.coinBalance, b.coinBalance)
            if (i !== 0) {
                return -i // descending
            } else {
                return a.coinName.localeCompare(b.coinName)
            }
        })
        let options: BaseOptionType[] = []
        for (let preOption of preOptions) {
            let children: ReactElement[] = [<>{preOption.coinName}</>]
            if (gt(preOption.coinBalance, 0)) {
                children.push(<span>{preOption.coinBalance}</span>)
            }
            options.push({
                label: <FlexRow style={{ justifyContent: "space-between" }}>{children}</FlexRow>,
                value: preOption.coinName
            })
        }
        return options
    }, [coins, balances])

    const _getSortedNetworkOptions = useCallback((_coinNetworks: CoinNetworkInfo[], _selectedCoinName: string) => {
        let preOptions: {
            networkName: string
            withdrawOK: boolean
            withdrawFee: Amount
            withdrawFeeUnits: string
        }[] = []
        for (let network of _coinNetworks) {
            let withdrawFee = network.withdrawFeeCoins
            let withdrawFeeUnits = _selectedCoinName ?? ""
            if (withdrawFee === null) {
                withdrawFee = network.withdrawFeeUSDT
                withdrawFeeUnits = "USDT"
            }
            if (withdrawFee === null) {
                withdrawFee = "0"
                withdrawFeeUnits = "N/A"
            }
            preOptions.push({
                networkName: network.networkName,
                withdrawOK: network.withdrawOK,
                withdrawFee: withdrawFee,
                withdrawFeeUnits: withdrawFeeUnits
            })
        }
        preOptions.sort((a, b) => {
            if (a.withdrawOK && !b.withdrawOK) {
                return -1
            } else if (!a.withdrawOK && b.withdrawOK) {
                return 1
            } else {
                let i = bigDecimal.compareTo(a.withdrawFee, b.withdrawFee)
                if (i !== 0) {
                    return i // ascending
                } else {
                    return a.networkName.localeCompare(b.networkName)
                }
            }
        })
        return preOptions
    }, [])

    const memoNetworksOptions = useMemo((): BaseOptionType[] => {
        if (selectedCoinName === null || coinNetworks === null) {
            return []
        }
        // console.debug(`WithdrawController: getMemoNetworksOptions: coinNetworks: `, coinNetworks)
        let preOptions = _getSortedNetworkOptions(coinNetworks, selectedCoinName)
        let options: BaseOptionType[] = []
        for (let preOption of preOptions) {
            let overlayChildren: ReactElement[] = []
            let rootChildren: ReactElement[] = []
            if (preOption.withdrawFeeUnits !== "USDT" && currentPrice !== null && currentPrice !== "0") {
                let feeUSDT = bigDecimal.multiply(preOption.withdrawFee, currentPrice)
                feeUSDT = formattedNumberToFixedWithoutTrailingZeros(bigDecimal.round(feeUSDT, 2))
                rootChildren.push(<span>~ {bigDecimal.getPrettyValue(feeUSDT)} USDT</span>)
                overlayChildren.push(
                    <span>
                        {bigDecimal.getPrettyValue(preOption.withdrawFee)} {preOption.withdrawFeeUnits}
                    </span>
                )
            } else {
                rootChildren.push(
                    <span>
                        {bigDecimal.getPrettyValue(preOption.withdrawFee)} {preOption.withdrawFeeUnits}
                    </span>
                )
            }
            let children: ReactElement[] = [
                <>{preOption.networkName}</>,
                <span>
                    {overlayChildren.length > 0 ?
                        <Tooltip overlay={overlayChildren}>{rootChildren}</Tooltip>
                    :   <>{rootChildren}</>}
                </span>
            ]
            options.push({
                label: <FlexRow style={{ justifyContent: "space-between" }}>{children}</FlexRow>,
                value: preOption.networkName,
                disabled: !preOption.withdrawOK
            })
        }
        return options
    }, [selectedCoinName, coinNetworks, currentPrice])

    const memoFeeElement = useMemo((): ReactElement => {
        if (currentNetwork === null || selectedCoinName === null) {
            return <>N/A</>
        }
        let children: ReactElement[] = []

        if (currentNetwork.withdrawFeeCoins !== null && currentNetwork.withdrawFeeUSDT === null) {
            children.push(
                <>
                    {bigDecimal.getPrettyValue(currentNetwork.withdrawFeeCoins)} {selectedCoinName}
                </>
            )
            if (currentPrice !== null && currentPrice !== "0") {
                let feeUSDT = bigDecimal.multiply(currentNetwork.withdrawFeeCoins, currentPrice)
                feeUSDT = formattedNumberToFixedWithoutTrailingZeros(bigDecimal.round(feeUSDT, 2))
                children.push(<> (~ {bigDecimal.getPrettyValue(feeUSDT)} USDT)</>)
            }
        } else if (currentNetwork.withdrawFeeCoins === null && currentNetwork.withdrawFeeUSDT !== null) {
            children.push(<>{bigDecimal.getPrettyValue(currentNetwork.withdrawFeeUSDT)} USDT</>)
        } else {
            children.push(
                <>
                    {bigDecimal.getPrettyValue(currentNetwork.withdrawFeeCoins)} {selectedCoinName} (
                    {bigDecimal.getPrettyValue(currentNetwork.withdrawFeeUSDT)} USDT)
                </>
            )
        }
        if (currentNetwork.withdrawFeeRate !== null && gt(currentNetwork.withdrawFeeRate, 0)) {
            children.push(
                <>
                    {" "}
                    s + {bigDecimal.multiply(currentNetwork.withdrawFeeRate, 100)}%{" "}
                    <WarningFilled style={{ color: ARBITRAGE_COLOR_WARN }} />
                </>
            )
        }
        return <>{children}</>
    }, [selectedCoinName, currentNetwork, currentPrice])

    const memoMinWithdrawalElement = useMemo((): ReactElement => {
        if (currentNetwork === null || selectedCoinName === null) {
            return <>N/A</>
        }
        let children: ReactElement[] = []

        children.push(
            <>
                {formattedNumberToFixedWithoutTrailingZeros(bigDecimal.getPrettyValue(currentNetwork.withdrawMin))}{" "}
                {selectedCoinName}
            </>
        )
        if (currentPrice !== null && currentPrice !== "0") {
            let minUSDT = bigDecimal.multiply(currentNetwork.withdrawMin, currentPrice)
            minUSDT = formattedNumberToFixedWithoutTrailingZeros(bigDecimal.round(minUSDT, 2))
            children.push(<> (~ {bigDecimal.getPrettyValue(minUSDT)} USDT)</>)
        }
        return <>{children}</>
    }, [selectedCoinName, currentNetwork, currentPrice, withdrawAmount])

    const memoMinDepositElement = useMemo((): ReactElement => {
        if (currentNetwork === null || selectedCoinName === null || executorCommonMinDepositAmount === null) {
            return <>N/A</>
        }
        let children: ReactElement[] = []

        children.push(
            <>
                {formattedNumberToFixedWithoutTrailingZeros(bigDecimal.getPrettyValue(executorCommonMinDepositAmount))}{" "}
                {selectedCoinName}
            </>
        )

        if (currentPrice !== null && currentPrice !== "0") {
            let minUSDT = bigDecimal.multiply(currentNetwork.depositMin, currentPrice)
            minUSDT = formattedNumberToFixedWithoutTrailingZeros(bigDecimal.round(minUSDT, 2))
            children.push(<> (~ {bigDecimal.getPrettyValue(minUSDT)} USDT)</>)
        }
        return <>{children}</>
    }, [selectedCoinName, currentNetwork, currentPrice, withdrawAmount])

    const memoMinWithdrawalWarning = useMemo((): ReactElement | null => {
        let children: ReactElement[] = []
        if (
            withdrawAmount !== null &&
            currentNetwork !== null &&
            currentNetwork.withdrawMin !== null &&
            lt(withdrawAmount, currentNetwork.withdrawMin)
        ) {
            children.push(
                <Tooltip overlay="The entered amount is below the minimum WITHDRAWAL amount">
                    <WarningFilled style={{ color: ARBITRAGE_COLOR_WARN }} />
                </Tooltip>
            )
        }
        return <>{children}</>
    }, [selectedCoinName, currentNetwork, withdrawAmount])

    const memoMinDepositAmountWarning = useMemo((): ReactElement | null => {
        let children: ReactElement[] = []
        if (
            withdrawAmount !== null &&
            executorCommonMinDepositAmount !== null &&
            lt(withdrawAmount, executorCommonMinDepositAmount)
        ) {
            children.push(
                <Tooltip overlay="The entered amount is below the minimum DEPOSIT amount">
                    <WarningFilled style={{ color: ARBITRAGE_COLOR_WARN }} />
                </Tooltip>
            )
        }
        return <>{children}</>
    }, [selectedCoinName, currentNetwork, withdrawAmount])

    const memoStepElement = useMemo((): ReactElement => {
        if (currentNetwork === null || selectedCoinName === null) {
            return <>N/A</>
        }
        if (currentNetwork.withdrawPrecision === null) {
            return <>N/A</>
        }
        return (
            <>
                {formattedNumberToFixedWithoutTrailingZeros(
                    Math.pow(10, -currentNetwork.withdrawPrecision).toFixed(currentNetwork.withdrawPrecision)
                )}
            </>
        )
    }, [selectedCoinName, currentNetwork])

    const memoBalanceElement = useMemo((): ReactElement => {
        if (currentBalance === null) {
            return <>N/A</>
        }
        let children: ReactElement[] = [
            <>
                {bigDecimal.getPrettyValue(currentBalance.freeQty)} {selectedCoinName}
            </>
        ]
        if (currentPrice !== null && currentPrice !== "0") {
            let balUSDT = bigDecimal.multiply(currentBalance.freeQty, currentPrice)
            balUSDT = formattedNumberToFixedWithoutTrailingZeros(bigDecimal.round(balUSDT, 2))
            children.push(<> (~ {bigDecimal.getPrettyValue(balUSDT)} USDT)</>)
        }
        return <>{children}</>
    }, [currentBalance, currentPrice])

    const memoContractAddress = useMemo((): ReactElement => {
        if (exchangeDriver === null || wellKnownCoinNetworkContractAddresses === null) {
            return <>N/A</>
        }
        let children: ReactElement[] = []
        let contractAddress: string | null = null
        for (let contractAddressElement of wellKnownCoinNetworkContractAddresses) {
            if (contractAddressElement.ExchangeName === exchangeDriver.name()) {
                contractAddress = contractAddressElement.ContractAddress
                break
            }
        }
        if (contractAddress === null || contractAddress === "") {
            return <>N/A</>
        } else {
            children.push(
                <Typography.Text code copyable>
                    {contractAddress}
                </Typography.Text>
            )
        }
        if (
            executorCommonCoin !== null &&
            executorLeftmostExchangeName !== null &&
            executorRightmostExchangeName !== null
        ) {
            let leftmostContractAddress: string | null = null
            let rightmostContractAddress: string | null = null
            for (let contractAddressElement of wellKnownCoinNetworkContractAddresses) {
                if (contractAddressElement.ExchangeName === executorLeftmostExchangeName) {
                    leftmostContractAddress = contractAddressElement.ContractAddress
                }
                if (contractAddressElement.ExchangeName === executorRightmostExchangeName) {
                    rightmostContractAddress = contractAddressElement.ContractAddress
                }
            }
            if (leftmostContractAddress !== null && rightmostContractAddress !== null) {
                let caMatch = contractAddressesMatch(
                    executorCommonCoin,
                    leftmostContractAddress,
                    rightmostContractAddress
                )
                if (!caMatch) {
                    children.push(
                        <Tooltip
                            overlay={
                                <>
                                    Contract addresses DO NOT MATCH between exchanges.
                                    <br />
                                    <br />
                                    You will 100% loose assets if you proceed with withdrawal!
                                </>
                            }
                        >
                            <WarningFilled style={{ color: ARBITRAGE_COLOR_RED, marginLeft: 5 }} />
                        </Tooltip>
                    )
                } else {
                    children.push(
                        <Tooltip overlay="Contract addresses MATCH between exchanges">
                            <CheckCircleFilled style={{ color: ARBITRAGE_COLOR_GREEN, marginLeft: 5 }} />
                        </Tooltip>
                    )
                }
            }
        }

        return <>{children}</>
    }, [
        exchangeDriver,
        executorCommonCoin,
        executorCommonNetwork,
        executorLeftmostExchangeName,
        executorRightmostExchangeName,
        wellKnownCoinNetworkContractAddresses
    ])

    if (exchangeDriver === null || balances === null || coins === null) {
        return (
            <FlexCol
                style={{
                    minHeight: 200,
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <Spin size="large" />
            </FlexCol>
        )
    }

    return (
        <div
            style={{
                backgroundColor: colorHexToRGBA(ARBITRAGE_COLOR_WITHDRAWAL, 0.1),
                padding: 10,
                display: "flex",
                flexDirection: "column",
                gap: 3
            }}
        >
            {/* Coin/Network selector */}
            <Row gutter={[10, 10]}>
                <Col xs={12} md={12}>
                    <FlexCol
                        style={{
                            gap: 0
                        }}
                    >
                        <span>
                            <b>Coin</b>
                        </span>
                        <Select
                            style={{
                                width: "100%"
                            }}
                            loading={coins === null}
                            options={memoCoinsOptions}
                            value={selectedCoinName}
                            onChange={value => {
                                if (value === undefined) {
                                    return
                                }
                                console.log(`WithdrawController: coin changed: ${value}`)
                                setSelectedCoinName(value)
                                // setSelectedNetworkName(null)
                                // setCurrentNetwork(null)
                                if (executorLinkEnabled) {
                                    setExecutorCommonCoin(value)
                                    // setExecutorCommonNetwork(null)
                                }
                            }}
                            placeholder="Select a coin"
                            showSearch
                            allowClear
                        />
                    </FlexCol>
                </Col>
                <Col xs={12} md={12}>
                    <FlexCol
                        style={{
                            gap: 0
                        }}
                    >
                        <span>
                            <b>Network</b>
                        </span>
                        <Select
                            style={{
                                width: "100%"
                            }}
                            loading={coinNetworks === null}
                            options={memoNetworksOptions}
                            value={selectedNetworkName}
                            onChange={value => {
                                if (value === undefined) {
                                    return
                                }
                                // console.log(`WithdrawController: network changed: ${value}`)
                                setSelectedNetworkName(value)
                                if (executorLinkEnabled) {
                                    let commonName = getDirectNetworkCommonName(value, commonNetworkNameMap)
                                    setExecutorCommonNetwork(commonName) // will set to null if not found thus disabling the link
                                }
                            }}
                            placeholder="Select a network"
                            showSearch
                            allowClear
                        />
                    </FlexCol>
                </Col>
            </Row>
            {/* Balance and refresh */}
            <Row justify="space-between" align="middle">
                <Col>
                    <FlexCol style={{ gap: 0 }}>
                        <span>
                            <b>Balance:</b> {memoBalanceElement}
                        </span>
                        <span>
                            <b>Price:</b>{" "}
                            {currentPrice !== null ?
                                <>{bigDecimal.getPrettyValue(currentPrice)} USDT</>
                            :   <> N/A</>}
                        </span>
                    </FlexCol>
                </Col>
                <Col>
                    <FlexRow style={{ gap: 3 }}>
                        <Tooltip overlay="Consolidate balances on SPOT account">
                            <Button
                                icon={<FullscreenExitOutlined />}
                                onClick={async () => {
                                    if (exchangeDriver === null) {
                                        return
                                    }
                                    try {
                                        await exchangeDriver.consolidateSpotBalances()
                                        loadBalances()
                                        await loadCoinBalance()
                                        message.success("Balances consolidated!")
                                    } catch (e: any) {
                                        message.info(`Could not consolidate balances: ${e.toString()}`)
                                    }
                                }}
                            />
                        </Tooltip>
                        <Tooltip overlay="Reload coins and balances">
                            <Button
                                icon={<ReloadOutlined />}
                                onClick={async () => {
                                    setCoins(null)
                                    setBalances(null)
                                    setSymbols(null)
                                    loadCoins()
                                    loadBalances()
                                    loadSymbols()
                                    loadCoinNetworks()
                                    loadCoinBalance()
                                }}
                            />
                        </Tooltip>
                    </FlexRow>
                </Col>
            </Row>
            {/* Network infos */}
            <div>
                <b>Network: </b>
                {currentNetwork !== null ?
                    <>
                        {currentNetwork?.withdrawOK ? "OK 🟢" : "NOK 🔴"}
                        <ul
                            style={{
                                marginTop: 0,
                                marginBottom: 0
                            }}
                        >
                            <li>
                                Name: <Tooltip overlay={currentNetwork.ownName}>{currentNetwork.networkName}</Tooltip> (
                                {getDirectNetworkCommonName(currentNetwork.networkName, commonNetworkNameMap)})
                            </li>
                            <li>Minimum: {memoMinWithdrawalElement}</li>
                            <li>Fee: {memoFeeElement}</li>
                            {currentNetwork.withdarawTip && (
                                <li>
                                    <i>{currentNetwork.withdarawTip}</i>
                                </li>
                            )}
                            <li>Contract: {memoContractAddress}</li>
                        </ul>
                    </>
                :   <> N/A</>}
            </div>
            <Row
                gutter={[10, 5]}
                justify={"end"}
                style={{
                    height: "100%"
                }}
            >
                <Col xs={24} lg={12}>
                    <FlexCol
                        style={{
                            gap: 0
                        }}
                    >
                        <span>
                            <b>Address</b> <WhitelistWithdrawalAlertWidget exchangeDriver={exchangeDriver} />{" "}
                            <WithdrawalIsImmediateAlertWidget exchangeDriver={exchangeDriver} />
                        </span>
                        <Input
                            style={{
                                width: "100%"
                            }}
                            value={withdrawAddress === null ? "" : withdrawAddress}
                            onChange={value => {
                                if (value.target.value === "") {
                                    setWithdrawAddress(null)
                                    return
                                }
                                setWithdrawAddress(value.target.value)
                            }}
                            disabled={currentNetwork === null}
                            allowClear
                        />
                    </FlexCol>
                </Col>
                <Col xs={24} lg={12}>
                    <FlexCol
                        style={{
                            gap: 0
                        }}
                    >
                        <span>
                            <b>Memo</b>{" "}
                            <Tooltip
                                placement="topLeft"
                                overlay="Memo, tag, payment identifier or an extension, if needed"
                            >
                                <InfoCircleTwoTone />
                            </Tooltip>
                        </span>
                        <Input
                            style={{
                                width: "100%"
                            }}
                            value={withdrawMemo === null ? "" : withdrawMemo}
                            onChange={value => {
                                if (value.target.value === "") {
                                    setWithdrawMemo(null)
                                    return
                                }
                                setWithdrawMemo(value.target.value)
                            }}
                            disabled={currentNetwork === null}
                            allowClear
                        />
                    </FlexCol>
                </Col>
            </Row>
            <Row gutter={[10, 5]}>
                <Col xs={24}>
                    <FlexCol
                        style={{
                            gap: 0
                        }}
                    >
                        <b>Amount</b>
                        <FlexRow>
                            <InputNumber
                                value={withdrawAmount}
                                onChange={value => {
                                    setWithdrawAmount(value)
                                }}
                                onBlur={() => {
                                    let _amount = withdrawAmount
                                    if (_amount === null) {
                                        return
                                    }
                                    if (currentNetwork !== null && currentNetwork.withdrawPrecision !== null) {
                                        _amount = bigDecimal.round(_amount, currentNetwork.withdrawPrecision)
                                    }
                                    setWithdrawAmount(_amount)
                                }}
                                style={{ width: "100%" }}
                                addonAfter={
                                    <Button
                                        type="link"
                                        disabled={currentBalance === null}
                                        onClick={() => {
                                            if (currentBalance === null) {
                                                return
                                            }
                                            let _amount = currentBalance.freeQty
                                            if (_amount === null) {
                                                return
                                            }
                                            if (currentNetwork !== null && currentNetwork.withdrawPrecision !== null) {
                                                _amount = bigDecimal.round(_amount, currentNetwork.withdrawPrecision)
                                            }
                                            setWithdrawAmount(_amount)
                                        }}
                                    >
                                        All
                                    </Button>
                                }
                            />
                            <Popconfirm
                                title={
                                    <>
                                        Are you sure to withdraw <b>{withdrawAmount}</b> {selectedCoinName} to{" "}
                                        <b>{withdrawAddress}</b> on <b>{currentNetwork?.networkName}</b>?
                                    </>
                                }
                                cancelText="Test"
                                okText="Apply"
                                onCancel={withdrawTest}
                                onConfirm={withdrawApply}
                                disabled={withdrawAmount === null || !gt(withdrawAmount, 0)}
                                placement="bottom"
                            >
                                <Button
                                    type="primary"
                                    disabled={
                                        withdrawAmount === null ||
                                        withdrawAmount === "0" ||
                                        withdrawAddress === null ||
                                        currentNetwork == null ||
                                        !currentNetwork.withdrawOK ||
                                        (currentNetwork.withdrawMin !== null &&
                                            gt(currentNetwork.withdrawMin, withdrawAmount))
                                    }
                                >
                                    Withdraw
                                </Button>
                            </Popconfirm>
                            {currentWithdrawalID !== null && (
                                <Button
                                    danger
                                    type="primary"
                                    disabled={exchangeDriver.config().withdrawalIsImmediate}
                                    onClick={withdrawCancel}
                                >
                                    Cancel
                                </Button>
                            )}
                        </FlexRow>
                        <i
                            style={{
                                fontSize: 10
                            }}
                        >
                            Available balance: {memoBalanceElement}
                        </i>
                        <i
                            style={{
                                fontSize: 10
                            }}
                        >
                            Min withdrawal: {memoMinWithdrawalElement} {memoMinWithdrawalWarning}
                        </i>
                        <i
                            style={{
                                fontSize: 10
                            }}
                        >
                            Min deposit: {memoMinDepositElement} {memoMinDepositAmountWarning}
                        </i>
                        <i
                            style={{
                                fontSize: 10
                            }}
                        >
                            Step: {memoStepElement}
                        </i>
                    </FlexCol>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    {wellKnownCoinFeeRatesMap !== null &&
                        selectedCoinName !== null &&
                        wellKnownCoinFeeRatesMap[selectedCoinName] !== undefined &&
                        wellKnownCoinFeeRatesMap[selectedCoinName].WithdrawFeeRate !== 0 && (
                            <Alert
                                type="warning"
                                message={
                                    <>
                                        {selectedCoinName} is known to implement a "hidden"{" "}
                                        <Tooltip overlay="E.g. stacking, marketing, developer or other fees hardcoded into token's smart contract">
                                            <QuestionCircleTwoTone />
                                        </Tooltip>{" "}
                                        fee rate of{" "}
                                        <b>
                                            {bigDecimal.multiply(
                                                wellKnownCoinFeeRatesMap[selectedCoinName].WithdrawFeeRate,
                                                100
                                            )}
                                            %
                                        </b>
                                        . Be careful!
                                    </>
                                }
                            />
                        )}
                </Col>
            </Row>
            <FlexRow style={{ gap: 3 }}>
                <span>
                    <b>Withdrawal ID:</b>{" "}
                    {currentWithdrawalID ?
                        <Typography.Text copyable>{currentWithdrawalID}</Typography.Text>
                    :   "N/A"}
                </span>
                {currentWithdrawalID !== null && (
                    <>
                        <Tooltip overlay="Cancel">
                            <Button size="small" danger icon={<CloseOutlined />} onClick={withdrawCancel} />
                        </Tooltip>
                        <Tooltip overlay="Clear">
                            <Button
                                size="small"
                                type="text"
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    setCurrentWithdrawalID(null)
                                }}
                            />
                        </Tooltip>
                    </>
                )}
            </FlexRow>
            <WithdrawHistoryList
                exchangeDriver={exchangeDriver}
                coinName={selectedCoinName}
                commonNetworkNameMap={commonNetworkNameMap}
            />
        </div>
    )
}
