import { Checkbox, InputNumber, Button, Slider, List, Tag, Tooltip, message } from "antd"
import {
    SymbolInfo,
    OrderBook,
    OrderSide,
    Amount,
    Price,
    IExchangeDriver,
    OrderInfo,
    OrderType,
    StatusSeverity
} from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/types"
import {
    eq,
    gt,
    truncateFloatUsingDecimalStepHE,
    prettyValue,
    truncateFloatUsingDecimalStepDW
} from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/utils"
import bigDecimal from "js-big-decimal"
import { FC, useState, useEffect, ReactNode, ReactElement, useCallback } from "react"
import { FlexCol, FlexRow } from "../../common"
import {
    colorHexToRGBA,
    ARBITRAGE_COLOR_BUY,
    ARBITRAGE_COLOR_SELL,
    ARBITRAGE_COLOR_LONG,
    ARBITRAGE_COLOR_SHORT
} from "../../constants"
import { formattedNumberToFixedWithoutTrailingZeros, formatNumberLog1e3 } from "../../utils"
import { CloseOutlined, ReloadOutlined } from "@ant-design/icons"
import { USDT_DECIMAL_STEP } from "./utils"

export const OrderbookWidget: FC<{
    symbol: SymbolInfo
    orderbook: OrderBook
    orderSide: OrderSide
    quoteQty: Amount | null
    baseQty: Amount | null
    setQuoteQty: (quoteQty: Amount) => void
    setBaseQty: (baseQty: Amount) => void
    setLimitPrice: (price: Price) => void
    colorAsks: string
    colorBids: string
}> = ({
    symbol,
    orderbook,
    orderSide,
    quoteQty,
    baseQty,
    setQuoteQty,
    setBaseQty,
    setLimitPrice,
    colorAsks,
    colorBids
}) => {
    const [idxOfHighlightedOrder, setIdxOfHighlightedOrder] = useState<number | null>(null)

    const [showBaseQty, setShowBaseQty] = useState<boolean>(false)
    const [showCumulatedBaseQty, setShowCumulatedBaseQty] = useState<boolean>(true)
    const [showQuoteQty, setShowQuoteQty] = useState<boolean>(false)
    const [showCumulatedQuoteQty, setShowCumulatedQuoteQty] = useState<boolean>(true)

    useEffect(() => {
        if (orderbook === null) {
            setIdxOfHighlightedOrder(null)
            return
        }
        if (orderSide === OrderSide.Buy) {
            if (quoteQty === null || eq(quoteQty, 0)) {
                setIdxOfHighlightedOrder(null)
                return
            }
            let cumulatedQuoteQty = "0"
            let i = 0
            for (let ask of orderbook.asks) {
                cumulatedQuoteQty = bigDecimal.add(cumulatedQuoteQty, bigDecimal.multiply(ask.price, ask.amount))
                if (gt(cumulatedQuoteQty, quoteQty)) {
                    setIdxOfHighlightedOrder(i)
                    return
                }
                i++
            }
            setIdxOfHighlightedOrder(orderbook.asks.length - 1)
        } else {
            if (baseQty === null || eq(baseQty, 0)) {
                setIdxOfHighlightedOrder(null)
                return
            }
            let cumulatedQty = "0"
            let i = 0
            for (let bid of orderbook.bids) {
                cumulatedQty = bigDecimal.add(cumulatedQty, bid.amount)
                if (gt(cumulatedQty, baseQty)) {
                    setIdxOfHighlightedOrder(i)
                    return
                }
                i++
            }
            setIdxOfHighlightedOrder(orderbook.bids.length - 1)
        }
    }, [orderbook, quoteQty, baseQty, orderSide])

    return (
        <FlexCol
            style={{
                fontSize: 11,
                gap: 5,
                width: "100%",
                padding: 5,
                backgroundColor: "white",
                borderRadius: 10
            }}
        >
            <FlexRow
                style={{
                    gap: 0,
                    justifyContent: "space-between",
                    marginLeft: "calc(2em + 2px)"
                }}
            >
                <div>
                    <Checkbox checked={showBaseQty} onChange={e => setShowBaseQty(e.target.checked)} /> <span>Vol</span>
                </div>
                <div>
                    <Checkbox
                        checked={showCumulatedBaseQty}
                        onChange={e => setShowCumulatedBaseQty(e.target.checked)}
                    />{" "}
                    <span>⅀Vol</span>
                </div>
                <div>
                    <Checkbox checked={showQuoteQty} onChange={e => setShowQuoteQty(e.target.checked)} />{" "}
                    <span>Amt</span>
                </div>
                <div>
                    <Checkbox
                        checked={showCumulatedQuoteQty}
                        onChange={e => setShowCumulatedQuoteQty(e.target.checked)}
                    />{" "}
                    <span>⅀Amt</span>
                </div>
            </FlexRow>
            <FlexCol
                style={{
                    gap: 0
                }}
            >
                {(function () {
                    let rows: ReactNode[] = []
                    let cumulatedQty = "0"
                    let cumulatedQuoteQty = "0"
                    for (let i = 0; i < 10; i++) {
                        let ask = orderbook.asks[i]
                        if (ask === undefined) {
                            break
                        }
                        let askQuoteQty = bigDecimal.multiply(ask.price, ask.amount)
                        cumulatedQty = bigDecimal.add(cumulatedQty, ask.amount)
                        cumulatedQuoteQty = bigDecimal.add(cumulatedQuoteQty, askQuoteQty)
                        let localCumulatedQty = "" + cumulatedQty
                        let localCumulatedQuoteQty = bigDecimal.subtract(cumulatedQuoteQty, USDT_DECIMAL_STEP)
                        let t = 0.1
                        if (
                            orderSide === OrderSide.Buy &&
                            quoteQty !== null &&
                            idxOfHighlightedOrder !== null &&
                            i <= idxOfHighlightedOrder
                        ) {
                            t = 0.3
                        }
                        let color = colorHexToRGBA(colorAsks ?? ARBITRAGE_COLOR_BUY, t)
                        rows.push(
                            <div
                                key={`${ask.price}_${ask.amount}`}
                                onClick={() => {
                                    if (orderSide === OrderSide.Buy) {
                                        let _price = ask.price
                                        if (symbol.priceTick != null) {
                                            _price = truncateFloatUsingDecimalStepHE(_price, symbol.priceTick)
                                        }
                                        let _quoteQty = truncateFloatUsingDecimalStepHE(
                                            localCumulatedQuoteQty,
                                            USDT_DECIMAL_STEP
                                        )
                                        let _baseQty = localCumulatedQty
                                        if (symbol.lotStep != null) {
                                            _baseQty = truncateFloatUsingDecimalStepHE(_baseQty, symbol.lotStep)
                                        }
                                        setLimitPrice(_price)
                                        setQuoteQty(_quoteQty)
                                        setBaseQty(_baseQty)
                                    }
                                }}
                            >
                                <FlexRow
                                    style={{
                                        gap: 2,
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <span
                                        style={{
                                            minWidth: "2em",
                                            textAlign: "right"
                                        }}
                                    >
                                        {i + 1}.
                                    </span>
                                    <FlexRow
                                        style={{
                                            backgroundColor: color,
                                            width: "100%",
                                            justifyContent: "space-between",
                                            paddingTop: 1,
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            cursor: orderSide === OrderSide.Buy ? "pointer" : "default"
                                        }}
                                        key={ask.price}
                                    >
                                        <div>{formattedNumberToFixedWithoutTrailingZeros(ask.price)}</div>
                                        <div>
                                            {showBaseQty && <>{formatNumberLog1e3(ask.amount, 3)} | </>}
                                            {showCumulatedBaseQty && <>{formatNumberLog1e3(cumulatedQty, 3)} | </>}
                                            {showQuoteQty && <>{formatNumberLog1e3(askQuoteQty, 2)}$ | </>}
                                            {showCumulatedQuoteQty && <>{formatNumberLog1e3(cumulatedQuoteQty, 2)}$</>}
                                        </div>
                                    </FlexRow>
                                </FlexRow>
                            </div>
                        )
                    }
                    return rows.reverse()
                })()}
            </FlexCol>
            <FlexCol
                style={{
                    gap: 0
                }}
            >
                {(function () {
                    let rows: ReactNode[] = []
                    let cumulatedQty = "0"
                    let cumulatedQuoteQty = "0"
                    for (let i = 0; i < 10; i++) {
                        let bid = orderbook.bids[i]
                        if (bid === undefined) {
                            break
                        }
                        let bidQuoteQty = bigDecimal.multiply(bid.price, bid.amount)
                        cumulatedQty = bigDecimal.add(cumulatedQty, bid.amount)
                        cumulatedQuoteQty = bigDecimal.add(cumulatedQuoteQty, bidQuoteQty)
                        let localCumulatedQty = bigDecimal.subtract(cumulatedQty, symbol.lotStep)
                        let localCumulatedQuoteQty = "" + cumulatedQuoteQty
                        let t = 0.1
                        if (
                            orderSide === OrderSide.Sell &&
                            baseQty !== null &&
                            idxOfHighlightedOrder !== null &&
                            i <= idxOfHighlightedOrder
                        ) {
                            t = 0.3
                        }
                        let color = colorHexToRGBA(colorBids ?? ARBITRAGE_COLOR_SELL, t)
                        rows.push(
                            <div
                                key={`${bid.price}_${bid.amount}`}
                                onClick={() => {
                                    if (orderSide === OrderSide.Sell) {
                                        let _price = bid.price
                                        if (symbol.priceTick != null) {
                                            _price = formattedNumberToFixedWithoutTrailingZeros(
                                                truncateFloatUsingDecimalStepHE(_price, symbol.priceTick)
                                            )
                                        }
                                        let _quoteQty = formattedNumberToFixedWithoutTrailingZeros(
                                            truncateFloatUsingDecimalStepHE(localCumulatedQuoteQty, USDT_DECIMAL_STEP)
                                        )
                                        let _baseQty = localCumulatedQty
                                        if (symbol.lotStep != null) {
                                            _baseQty = formattedNumberToFixedWithoutTrailingZeros(
                                                truncateFloatUsingDecimalStepHE(_baseQty, symbol.lotStep)
                                            )
                                        }
                                        setLimitPrice(_price)
                                        setQuoteQty(_quoteQty)
                                        setBaseQty(_baseQty)
                                    }
                                }}
                            >
                                <FlexRow
                                    style={{
                                        gap: 2,
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <span
                                        style={{
                                            minWidth: "2em",
                                            textAlign: "right"
                                        }}
                                    >
                                        {i + 1}.
                                    </span>
                                    <FlexRow
                                        style={{
                                            backgroundColor: color,
                                            width: "100%",
                                            justifyContent: "space-between",
                                            paddingTop: 1,
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            cursor: orderSide === OrderSide.Sell ? "pointer" : "default"
                                        }}
                                        key={bid.price}
                                    >
                                        <div>{formattedNumberToFixedWithoutTrailingZeros(bid.price)}</div>
                                        <div>
                                            {showBaseQty && <>{formatNumberLog1e3(bid.amount, 3)} | </>}
                                            {showCumulatedBaseQty && <>{formatNumberLog1e3(cumulatedQty, 3)} | </>}
                                            {showQuoteQty && <>{formatNumberLog1e3(bidQuoteQty, 2)}$ | </>}
                                            {showCumulatedQuoteQty && <>{formatNumberLog1e3(cumulatedQuoteQty, 2)}$</>}
                                        </div>
                                    </FlexRow>
                                </FlexRow>
                            </div>
                        )
                    }
                    return rows
                })()}
            </FlexCol>
        </FlexCol>
    )
}

export const OrderQtyInput: FC<{
    propValue: Amount | null
    suffixLabel?: string
    suffixValue: Amount | null
    stepValue: string | null
    prefix: string
    onChange: (value: string | null) => void
    subscript?: ReactElement
    disabled?: boolean
}> = ({ propValue, suffixLabel, suffixValue, stepValue, prefix, subscript, onChange, disabled }) => {
    const [currentValue, setCurrentValue] = useState<string | null>(null)
    const [currentSliderValue, setCurrentSliderValue] = useState<number>(0)

    useEffect(() => {
        setCurrentValue(propValue)
    }, [propValue])

    return (
        <FlexCol
            style={{
                width: "100%",
                gap: 0,
                alignItems: "center"
            }}
        >
            <FlexCol style={{ gap: 0, alignItems: "end", width: "100%" }}>
                <InputNumber
                    style={{
                        width: "100%"
                    }}
                    disabled={disabled}
                    stringMode
                    // type="number"
                    inputMode="decimal"
                    addonBefore={prefix}
                    min={"0"}
                    // max={suffixValue ?? undefined}
                    step={stepValue ?? USDT_DECIMAL_STEP}
                    value={currentValue}
                    onChange={value => {
                        setCurrentValue(value)
                    }}
                    onBlur={() => {
                        onChange(currentValue)
                        if (suffixValue !== null && currentValue !== null) {
                            let _sliderValue = bigDecimal.multiply(bigDecimal.divide(currentValue, suffixValue), 100)
                            setCurrentSliderValue(parseFloat(_sliderValue))
                        }
                    }}
                    formatter={value => {
                        if (value === null || value === undefined) {
                            return ""
                        }
                        return prettyValue(value)
                    }}
                    addonAfter={
                        suffixValue !== null && (
                            <Button
                                type="link"
                                size="small"
                                onClick={() => {
                                    setCurrentValue(truncateFloatUsingDecimalStepDW(suffixValue, stepValue))
                                    onChange(suffixValue)
                                    setCurrentSliderValue(100)
                                }}
                            >
                                {suffixLabel ?? "Max"}
                            </Button>
                        )
                    }
                />
                {subscript && subscript}
            </FlexCol>
            {suffixValue !== null && (
                <Slider
                    style={{ width: "90%" }}
                    marks={{
                        0: "0%",
                        25: "25%",
                        50: "50%",
                        75: "75%",
                        100: "100%"
                    }}
                    min={0}
                    max={100}
                    value={currentSliderValue}
                    onChange={value => {
                        setCurrentSliderValue(value)
                        if (suffixValue === null) {
                            return
                        }
                        let _value = bigDecimal.multiply(suffixValue, bigDecimal.divide(value, 100))
                        _value = truncateFloatUsingDecimalStepDW(_value, stepValue)
                        setCurrentValue(_value)
                        onChange(_value)
                    }}
                />
            )}
        </FlexCol>
    )
}

export enum OrderHistoryMode {
    Spot,
    Margin
}

export const _OrdersHistoryList: FC<{
    exchangeDriver: IExchangeDriver | null
    symbol: SymbolInfo | null
    mode: OrderHistoryMode
    loadBalances: () => Promise<void>
}> = ({ exchangeDriver, symbol, mode, loadBalances }) => {
    const [symbolOrders, setSymbolOrders] = useState<OrderInfo[] | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const loadOrders = useCallback(async () => {
        if (exchangeDriver === null || symbol === null) {
            return
        }
        // console.log(`_OrdersHistoryList: ${exchangeDriver.name()}: loading orders history for ${symbol.ownName}`)
        try {
            let orders: OrderInfo[] = []
            let raw: any = null
            if (mode === OrderHistoryMode.Spot) {
                let resp = await exchangeDriver.getSpotSymbolOrdersHistory(symbol.ownName)
                orders = resp.orders
                raw = resp.raw
            } else {
                let resp = await exchangeDriver.getMarginSymbolOrdersHistory(symbol.ownName)
                orders = resp.orders
                raw = resp.raw
            }
            // console.log(`_OrdersHistoryList: ${exchangeDriver.name()}: ${orders.length} orders`, orders, raw)
            orders.sort((a, b) => {
                return b.timestamp - a.timestamp
            })
            setSymbolOrders(orders)
        } catch (e: any) {
            console.warn(`_OrdersHistoryList: ${exchangeDriver.name()}: error loading orders history`, e)
        } finally {
            setIsLoading(false)
        }
    }, [exchangeDriver, symbol, mode])

    useEffect(() => {
        loadOrders()
    }, [exchangeDriver, symbol])

    const callbackOrderDetails = useCallback(
        (order: OrderInfo): ReactElement => {
            if (order === null || symbol === null) {
                return <></>
            }

            let baseAsset = order.symbolName
                .replaceAll("USDT", "")
                .replaceAll("SPBL", "")
                .replaceAll("-", "")
                .replaceAll("_", "")
            let quoteAsset = "USDT"

            let children: ReactElement[] = []
            if (order.type === OrderType.Limit) {
                // Qty: 4.2 / 8.4 ADA (50%) | 6.3 USDT
                let qtyChildren: ReactElement[] = []
                if (order.executedBaseQty !== null) {
                    qtyChildren.push(<>{formattedNumberToFixedWithoutTrailingZeros(order.executedBaseQty)} / </>)
                } else {
                    qtyChildren.push(<>N/A</>)
                }
                if (order.baseQty !== null) {
                    qtyChildren.push(
                        <>
                            {formattedNumberToFixedWithoutTrailingZeros(order.baseQty)} {baseAsset}
                        </>
                    )
                } else {
                    qtyChildren.push(<>N/A {baseAsset}</>)
                }
                if (order.executedBaseQty !== null) {
                    let baseQtyPercent = bigDecimal.round(
                        bigDecimal.multiply(bigDecimal.divide(order.executedBaseQty, order.baseQty), 100),
                        0
                    )
                    qtyChildren.push(<> ({baseQtyPercent}%)</>)
                } else {
                    qtyChildren.push(<> (0%)</>)
                }
                if (order.executedQuoteQty !== null) {
                    qtyChildren.push(
                        <>
                            {" "}
                            | {formattedNumberToFixedWithoutTrailingZeros(
                                bigDecimal.round(order.executedQuoteQty, 2)
                            )}{" "}
                            USDT
                        </>
                    )
                }

                // Price: 0.62 / 0.60339 USDT
                let priceChildren: ReactElement[] = []
                if (order.executedPrice !== null) {
                    priceChildren.push(<>{formattedNumberToFixedWithoutTrailingZeros(order.executedPrice)} / </>)
                } else if (
                    order.executedQuoteQty !== null &&
                    order.executedBaseQty !== null &&
                    gt(order.executedBaseQty, "0") // avoid division by zero
                ) {
                    let priceEstimate = bigDecimal.divide(order.executedQuoteQty, order.executedBaseQty)
                    if (symbol.priceTick !== null) {
                        priceEstimate = truncateFloatUsingDecimalStepHE(priceEstimate, symbol.priceTick)
                    }
                    priceChildren.push(<>{formattedNumberToFixedWithoutTrailingZeros(priceEstimate)} / </>)
                }
                if (order.price !== null) {
                    priceChildren.push(<>{formattedNumberToFixedWithoutTrailingZeros(order.price)}</>)
                } else {
                    priceChildren.push(<>N/A</>)
                }

                children = [
                    <span>
                        <b>Quantity: </b>
                        {qtyChildren}
                    </span>,
                    <span>
                        <b>Price: </b>
                        {priceChildren}
                    </span>
                ]
            } else if (order.type === OrderType.Market) {
                let qtyChildren: ReactElement[] = []
                if (order.side === OrderSide.Sell) {
                    if (order.executedBaseQty !== null) {
                        qtyChildren.push(<>{formattedNumberToFixedWithoutTrailingZeros(order.executedBaseQty)} / </>)
                    }
                    if (order.baseQty !== null) {
                        qtyChildren.push(
                            <>
                                {formattedNumberToFixedWithoutTrailingZeros(order.baseQty)} {baseAsset}
                            </>
                        )
                    } else {
                        qtyChildren.push(<>N/A {baseAsset}</>)
                    }
                    if (order.executedBaseQty !== null) {
                        let baseQtyPercent = "0"
                        if (order.baseQty !== null && gt(order.baseQty, "0")) {
                            baseQtyPercent = bigDecimal.round(
                                bigDecimal.multiply(bigDecimal.divide(order.executedBaseQty, order.baseQty), 100),
                                0
                            )
                        }
                        qtyChildren.push(<> ({baseQtyPercent}%)</>)
                    }
                    if (order.executedQuoteQty !== null) {
                        qtyChildren.push(
                            <> | {formattedNumberToFixedWithoutTrailingZeros(order.executedQuoteQty)} USDT</>
                        )
                    }
                } else if (order.side === OrderSide.Buy) {
                    if (order.executedQuoteQty !== null) {
                        qtyChildren.push(<>{formattedNumberToFixedWithoutTrailingZeros(order.executedQuoteQty)} / </>)
                    }
                    if (order.quoteQty !== null) {
                        qtyChildren.push(
                            <>
                                {formattedNumberToFixedWithoutTrailingZeros(order.quoteQty)} {quoteAsset}
                            </>
                        )
                    } else {
                        qtyChildren.push(<>N/A {quoteAsset}</>)
                    }
                    if (order.quoteQty !== null && order.executedQuoteQty !== null) {
                        let quoteQtyPercent = "0"
                        if (order.quoteQty !== null && gt(order.quoteQty, "0")) {
                            quoteQtyPercent = bigDecimal.round(
                                bigDecimal.multiply(bigDecimal.divide(order.executedQuoteQty, order.quoteQty), 100),
                                0
                            )
                        }
                        qtyChildren.push(<> ({quoteQtyPercent}%)</>)
                    }
                }

                // Eff. Price: 0.62 (effective average)
                let priceChildren: ReactElement[] = []
                if (order.executedPrice !== null) {
                    priceChildren.push(<>{formattedNumberToFixedWithoutTrailingZeros(order.executedPrice)}</>)
                } else if (
                    order.executedQuoteQty !== null &&
                    order.executedBaseQty !== null &&
                    gt(order.executedBaseQty, "0") // avoid division by zero
                ) {
                    let priceEstimate = bigDecimal.divide(order.executedQuoteQty, order.executedBaseQty)
                    if (symbol.priceTick !== null) {
                        priceEstimate = truncateFloatUsingDecimalStepHE(priceEstimate, symbol.priceTick)
                    }
                    priceChildren.push(<>{formattedNumberToFixedWithoutTrailingZeros(priceEstimate)}</>)
                }
                children = [
                    <>
                        <span>
                            <b>Quantity: </b>
                            {qtyChildren || <>N/A</>}
                        </span>
                        <span>
                            <b>Eff. price: </b>
                            {priceChildren ?? <>N/A</>}
                        </span>
                    </>
                ]
            }
            return <>{children}</>
        },
        [exchangeDriver, symbol]
    )

    return (
        <List
            header={
                <FlexRow
                    style={{
                        justifyContent: "space-between"
                    }}
                >
                    <span>Order History</span>
                    <Button
                        icon={<ReloadOutlined />}
                        onClick={async () => {
                            setIsLoading(true)
                            try {
                                await loadOrders()
                                await loadBalances()
                            } finally {
                                setIsLoading(false)
                            }
                        }}
                    />
                </FlexRow>
            }
            loading={isLoading}
            style={{
                backgroundColor: "white"
            }}
            size="small"
            bordered
            pagination={{
                pageSize: 10,
                hideOnSinglePage: true,
                size: "small"
            }}
            // footer={null}
            dataSource={symbolOrders ?? []}
            renderItem={order => {
                let statusString = order.status.toUpperCase()
                let statusColor = "default"
                switch (order.statusSeverity) {
                    case StatusSeverity.Warning:
                        statusColor = "warning"
                        break
                    case StatusSeverity.Error:
                        statusColor = "error"
                        break
                    case StatusSeverity.Success:
                        statusColor = "success"
                        break
                    default:
                        break
                }
                let statusTag = <Tag color={statusColor}>{statusString}</Tag>
                return (
                    <List.Item key={`${exchangeDriver?.name()}_${order.orderId}_${order.timestamp}`}>
                        <FlexRow
                            style={{
                                gap: 0,
                                width: "100%",
                                justifyContent: "space-between",
                                alignItems: "start"
                            }}
                        >
                            <FlexCol style={{ gap: 0 }}>
                                <span>
                                    {statusTag}
                                    <Tag
                                        style={{
                                            backgroundColor:
                                                order.side === OrderSide.Buy ?
                                                    colorHexToRGBA(
                                                        mode === OrderHistoryMode.Spot ?
                                                            ARBITRAGE_COLOR_BUY
                                                        :   ARBITRAGE_COLOR_LONG,
                                                        0.1
                                                    )
                                                :   colorHexToRGBA(
                                                        mode === OrderHistoryMode.Spot ?
                                                            ARBITRAGE_COLOR_SELL
                                                        :   ARBITRAGE_COLOR_SHORT,
                                                        0.1
                                                    )
                                        }}
                                    >
                                        {order.type} {order.side}
                                    </Tag>
                                    {order.type === OrderType.Limit && order.timeInForce !== null && (
                                        <>
                                            <Tag color="default">{order.timeInForce}</Tag>
                                        </>
                                    )}
                                </span>
                                <FlexCol style={{ gap: 0, fontSize: "0.8rem" }}>{callbackOrderDetails(order)}</FlexCol>
                            </FlexCol>
                            <FlexCol style={{ alignItems: "end", justifyContent: "space-between" }}>
                                <span style={{ fontSize: "0.8rem", textAlign: "end" }}>
                                    {new Date(order.timestamp).toLocaleString()}
                                </span>
                                <Tooltip overlay="Cancel">
                                    <Button
                                        danger
                                        disabled={!order.isOpen}
                                        icon={<CloseOutlined />}
                                        onClick={async () => {
                                            if (exchangeDriver === null) {
                                                return
                                            }
                                            if (
                                                order.orderId === "" ||
                                                order.orderId === 0 ||
                                                order.orderId === null ||
                                                order.orderId === undefined
                                            ) {
                                                message.warning(`Could not cancel order: invalid order ID`)
                                                return
                                            }
                                            let func = exchangeDriver.cancelSpotOrder
                                            if (mode === OrderHistoryMode.Margin) {
                                                func = exchangeDriver.cancelMarginOrder
                                            }
                                            try {
                                                await func({
                                                    symbolName: order.symbolName,
                                                    orderId: order.orderId
                                                })
                                                await loadOrders()
                                                message.success(`Order #${order.orderId} cancelled!`)
                                            } catch (e: any) {
                                                message.error(
                                                    `Error occurred whilst cancelling order #${
                                                        order.orderId
                                                    }: ${e.toString()}`
                                                )
                                            } finally {
                                                await loadBalances()
                                            }
                                        }}
                                    />
                                </Tooltip>
                            </FlexCol>
                        </FlexRow>
                    </List.Item>
                )
            }}
        />
    )
}
