import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
    Coin,
    Exchange,
    ExchangePairCoinDiscrepancy,
    ExchangePairCoinDiscrepancyTimeseries,
    InstantOpportunity,
    Network,
    OpportunityTimeseries
} from "../types"
import { getExchangePairCoinNetworkTimeseriesParams, getExchangePairCoinTimeseriesParams } from "../api"
import { Button, Col, Divider, Empty, Row, Spin, Tooltip, message } from "antd"

import uPlot from "uplot"
import "uplot/dist/uPlot.min.css"
import { FlexCol, FlexRow } from "../common"
import {
    formatDurationExact,
    getTotalFee,
    numberToFixedWithoutTrailingZeros,
    projectDepositOnProfitNonLinear,
    projectFeeOnCostNonLinear
} from "../utils"
import { useAuthContext } from "../reducers/authReducer"
import { decodeSimpleDiscrepancies, decodeInstantOpportunities, decodeCompositeDiscrepancies } from "../binary"
import {
    ARBITRAGE_COLOR_BUY,
    ARBITRAGE_COLOR_FEE,
    ARBITRAGE_COLOR_GREEN,
    ARBITRAGE_COLOR_NEUTRAL,
    ARBITRAGE_COLOR_PERSONALIZED_MARGIN,
    ARBITRAGE_COLOR_PERSONALIZED_PROFIT,
    ARBITRAGE_COLOR_RED,
    ARBITRAGE_COLOR_RELATIVE_DISCREPANCY,
    ARBITRAGE_COLOR_SELL,
    ARBITRAGE_COLOR_TOTAL_MARGIN,
    ARBITRAGE_COLOR_TOTAL_PROFIT,
    OPPORTUNITIES_AUTO_REFRESH_INTERVAL_MS,
    colorHexToRGBA,
    colorTupleToRGBA,
    ARBITRAGE_COLOR_VIRTUAL_ANTI_PROFIT
} from "../constants"
import dayjs from "dayjs"
import { PriceDiscrepancyWidget, formatPrices } from "./priceDiscrepancyWidget"
import {
    CheckCircleFilled,
    CheckOutlined,
    CloseCircleFilled,
    CloseOutlined,
    FastForwardFilled,
    FastForwardOutlined
} from "@ant-design/icons"

const UPLOT_DEFAULT_WIDTH = 400
const UPLOT_DEFAULT_HEIGHT = 200
const UPLOT_DEFAULT_MARGIN_BOTTOM = 0

const LegendSquare: FC<{
    color: string
    borderStyle?: "solid" | "dashed" | "dotted"
}> = ({ color, borderStyle }) => {
    return (
        <span
            style={{
                width: "1.1rem",
                height: "1.1rem",
                borderRadius: 3,
                borderWidth: "2px",
                borderStyle: borderStyle || "solid",
                borderColor: colorHexToRGBA(color, 0.8),
                backgroundColor: colorHexToRGBA(color, 0.1)
            }}
        />
    )
}

const PriceTimeseriesChart: FC<{
    exchangeOne: Exchange
    exchangeTwo: Exchange
    coinID: Coin["ID"]
    mooSync: uPlot.SyncPubSub
    timeseries: ExchangePairCoinDiscrepancyTimeseries | null
    setCurrentTs: React.Dispatch<React.SetStateAction<string | null>>
    showLegend: boolean
}> = ({ exchangeOne, exchangeTwo, coinID, mooSync, timeseries, setCurrentTs, showLegend }) => {
    const [uplotOptions, setUplotOptions] = useState<uPlot.Options | null>(null)
    const [uplotData, setUplotData] = useState<uPlot.AlignedData | null>(null)

    const targetRef = useRef<HTMLDivElement>(null)
    const chartRef = useRef<uPlot | null>(null)
    const tsIndexRef = useRef<string[]>([])

    const [isLoading, setIsLoading] = useState<boolean>(true)
    useEffect(() => {
        if (timeseries === null || timeseries.length === 0) {
            return
        }
        // Data
        // https://github.com/leeoniya/uPlot/tree/master/docs#installation
        const X: number[] = [] // time
        const Y1: number[] = [] // LA (from)
        const Y2: number[] = [] // HB (to)
        const Y3: number[] = [] // HB (from)
        const Y4: number[] = [] // LA (to)
        const _tsIndex: string[] = []
        timeseries.forEach(frame => {
            _tsIndex.push(frame.OrderbookTimestamp)
            let d = new Date(frame.OrderbookTimestamp)
            X.push(d.getTime() / 1000)
            Y1.push(frame.Discrepancy.ExchangeOneLAPrice)
            Y2.push(frame.Discrepancy.ExchangeTwoHBPrice)
            Y3.push(frame.Discrepancy.ExchangeOneHBPrice)
            Y4.push(frame.Discrepancy.ExchangeTwoLAPrice)
        })
        tsIndexRef.current = _tsIndex
        setUplotData([X, Y1, Y2, Y3, Y4])

        const opts: uPlot.Options = {
            id: `uplot-chart-${exchangeOne.ID}-${exchangeTwo.ID}-${coinID}`,
            width: UPLOT_DEFAULT_WIDTH,
            height: UPLOT_DEFAULT_HEIGHT,
            legend: {
                show: showLegend,
                live: true
            },
            cursor: {
                lock: true,
                sync: {
                    key: mooSync.key
                }
            },
            scales: {
                y: {
                    range(u, min, max) {
                        let diff = max - min
                        max += diff / 2
                        min -= diff / 2
                        return [min, max]
                    }
                }
            },
            axes: [
                {
                    grid: {
                        show: true
                    }
                },
                {
                    values: (u, vals, space) => {
                        return vals.map(v => v.toString())
                    }
                }
            ],
            series: [
                {
                    show: true,
                    value: (_, rawValue) => (rawValue ? dayjs(rawValue * 1e3).format("HH:mm:ss") : "N/A")
                },
                {
                    show: true,
                    spanGaps: false,
                    label: `LA on ${exchangeOne.Name}`,
                    value: (_, rawValue) => (rawValue ? rawValue : "N/A"),
                    stroke: ARBITRAGE_COLOR_BUY,
                    dash: [],
                    width: 2
                },
                {
                    show: true,
                    spanGaps: false,
                    label: `HB on ${exchangeTwo.Name}`,
                    value: (_, rawValue) => (rawValue ? rawValue : "N/A"),
                    stroke: ARBITRAGE_COLOR_SELL,
                    dash: [],
                    width: 2
                },
                {
                    show: true,
                    spanGaps: false,
                    label: `HB on ${exchangeOne.Name}`,
                    value: (_, rawValue) => (rawValue ? rawValue : "N/A"),
                    stroke: ARBITRAGE_COLOR_BUY,
                    dash: [5, 5],
                    width: 2
                },
                {
                    show: true,
                    spanGaps: false,
                    label: `LA on ${exchangeTwo.Name}`,
                    value: (_, rawValue) => (rawValue ? rawValue : "N/A"),
                    stroke: ARBITRAGE_COLOR_SELL,
                    dash: [5, 5],
                    width: 2
                }
            ],
            hooks: {
                draw: [
                    (u: uPlot) => {
                        const ctx = u.ctx
                        ctx.fillStyle = colorHexToRGBA(ARBITRAGE_COLOR_SELL, 0.1)
                        ctx.strokeStyle = "transparent"
                        ctx.lineWidth = 0
                        let seriesTime = u.data[0]
                        let seriesOne = u.data[1]
                        let seriesTwo = u.data[2]

                        if (seriesOne.length !== seriesTwo.length) {
                            return
                        }
                        if (seriesOne.length !== seriesTime.length) {
                            return
                        }
                        if (seriesOne.length === 0) {
                            return
                        }
                        let nbLegs: number = 0
                        let beginningIdx: number = 0
                        while (beginningIdx < seriesOne.length - 1) {
                            // console.log('beginningIdx', beginningIdx)
                            // console.log('nbLegs', nbLegs)
                            if (nbLegs > 1000) {
                                return
                            }
                            ctx.beginPath()
                            for (let i = beginningIdx; i < seriesOne.length; i++) {
                                // console.log('i(y)', i)
                                let vOne = seriesOne[i]
                                if (vOne === null || vOne === undefined) {
                                    continue
                                }

                                let vTwo = seriesTwo[i]
                                if (vTwo === null || vTwo === undefined) {
                                    continue
                                }

                                if (vOne > vTwo) {
                                    beginningIdx++
                                    continue
                                }

                                let y = u.valToPos(vOne, "y", true)
                                let x = u.valToPos(seriesTime[i], "x", true)
                                if (i === beginningIdx) {
                                    ctx.moveTo(x, y)
                                    continue
                                }
                                nbLegs++
                                ctx.lineTo(x, y)
                                if (i + 1 < seriesOne.length) {
                                    let nextVOne = seriesOne[i + 1]
                                    let nextVTwo = seriesTwo[i + 1]
                                    if (
                                        nextVOne === null ||
                                        nextVOne === undefined ||
                                        nextVTwo === null ||
                                        nextVTwo === undefined
                                    ) {
                                        nbLegs++
                                        ctx.lineTo(x, y)
                                        continue
                                    }
                                    if (nextVOne < nextVTwo) {
                                        nbLegs++
                                        ctx.lineTo(x, y)
                                        continue
                                    }

                                    for (let j = i; j >= beginningIdx; j--) {
                                        // console.log('@break j(x)', j, '/', beginningIdx)
                                        let v = seriesTwo[j]
                                        if (v === null || v === undefined) {
                                            continue
                                        }
                                        let y = u.valToPos(v, "y", true)
                                        let x = u.valToPos(seriesTime[j], "x", true)
                                        nbLegs++
                                        ctx.lineTo(x, y)
                                    }
                                    // ctx.stroke()
                                    ctx.closePath()
                                    ctx.fill()
                                    beginningIdx = i + 1
                                    break
                                } else {
                                    for (let j = i; j >= beginningIdx; j--) {
                                        // console.log('@end j(x)', j, '/', beginningIdx)
                                        let v = seriesTwo[j]
                                        if (v === null || v === undefined) {
                                            continue
                                        }
                                        let y = u.valToPos(v, "y", true)
                                        let x = u.valToPos(seriesTime[j], "x", true)
                                        nbLegs++
                                        ctx.lineTo(x, y)
                                    }
                                    // ctx.stroke()
                                    ctx.closePath()
                                    ctx.fill()
                                    beginningIdx = i + 1
                                    break
                                }
                            }
                        }

                        // Reset canvas context
                        ctx.setLineDash([])
                        ctx.lineWidth = 2
                    }
                ],
                setCursor: [
                    (u: uPlot) => {
                        if (!u.cursor.idxs) {
                            return
                        }
                        let tsIdx = u.cursor.idxs[0]
                        if (tsIdx === null) {
                            return
                        }
                        let tsStr = tsIndexRef.current[tsIdx]
                        if (tsStr === undefined) {
                            return
                        }
                        setCurrentTs(tsStr)
                    }
                ]
            }
        }
        setUplotOptions(opts)
    }, [timeseries, showLegend])

    useEffect(() => {
        if (uplotOptions === null || uplotData === null || targetRef.current === null) {
            return
        }
        if (chartRef.current === null) {
            let plot = new uPlot(uplotOptions, uplotData, targetRef.current)
            plot.setSize({
                width: targetRef.current.clientWidth,
                height: UPLOT_DEFAULT_HEIGHT
            })
            mooSync.sub(plot)
            chartRef.current = plot
            setIsLoading(false)
        } else {
            chartRef.current.setData(uplotData)
        }
    }, [uplotOptions, uplotData])

    useEffect(() => {
        const resizePlot = () => {
            if (targetRef.current !== null && chartRef.current !== null) {
                chartRef.current.setSize({
                    width: targetRef.current.clientWidth,
                    height: UPLOT_DEFAULT_HEIGHT
                })
            }
        }
        void window.addEventListener("resize", resizePlot)
        return () => {
            void window.removeEventListener("resize", resizePlot)
        }
    }, [targetRef.current])

    if (isLoading) {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                ref={targetRef}
            >
                <Spin />
            </div>
        )
    }

    if (timeseries === null || uplotOptions === null || uplotData === null) {
        return null
    }

    return (
        <div
            style={{
                width: "100%",
                height: UPLOT_DEFAULT_HEIGHT,
                marginBottom: UPLOT_DEFAULT_MARGIN_BOTTOM + (showLegend ? 25 : 0)
            }}
            ref={targetRef}
        ></div>
    )
}

const DiscrepancyTimeseriesChart: FC<{
    exchangeOne: Exchange
    exchangeTwo: Exchange
    coinID: Coin["ID"]
    mooSync: uPlot.SyncPubSub
    timeseries: ExchangePairCoinDiscrepancyTimeseries | null
}> = ({ exchangeOne, exchangeTwo, coinID, mooSync, timeseries }) => {
    const [uplotOptions, setUplotOptions] = useState<uPlot.Options | null>(null)
    const [uplotData, setUplotData] = useState<uPlot.AlignedData | null>(null)

    const targetRef = useRef<HTMLDivElement>(null)
    const chartRef = useRef<uPlot | null>(null)

    const [isLoading, setIsLoading] = useState<boolean>(true)

    const drawZeroLine = (u: uPlot) => {
        const ctx = u.ctx
        const x0 = u.bbox.left
        const x1 = u.bbox.left + u.bbox.width

        const y0 = u.valToPos(0, "y", true)
        const y1 = u.valToPos(0, "y", true)

        ctx.strokeStyle = "rgba(0, 0, 0, 0.5)"
        ctx.lineWidth = 2
        ctx.setLineDash([20, 5, 5, 5])
        ctx.beginPath()
        ctx.moveTo(x0, y0)
        ctx.lineTo(x1, y1)
        ctx.stroke()

        // Reset canvas context
        ctx.setLineDash([])
        ctx.lineWidth = 2
    }

    const drawMetadata = (u: uPlot) => {
        let ctx = u.ctx
        ctx.setLineDash([])
        ctx.lineWidth = 1
        let seriesTime = u.data[0]

        let defaultFont = "" + ctx.font

        let barWidth = 15
        let barGap = 3

        let _min = 0
        let rdSeries = u.data[1]
        let rdBisSeries = u.data[2]
        if (rdSeries === null) {
            return
        }
        for (let k = 0; k < rdSeries.length; k++) {
            let _rd = rdSeries[k]
            let _rdb = rdBisSeries[k]
            if (_rd === undefined || _rdb === undefined) {
                return
            }
            if (_rd === null && _rdb === null) {
                continue
            }
            if (_rdb !== null) {
                _min = Math.min(_min, _rdb)
                continue
            } else if (_rd !== null) {
                _min = Math.min(_min, _rd)
                continue
            }
        }

        for (let n = 3; n <= 6; n++) {
            let label = ""
            switch (n) {
                case 3:
                    label = `Trading on ${exchangeOne.Name}`
                    break
                case 4:
                    label = `Trading on ${exchangeTwo.Name}`
                    break
                case 5:
                    label = `Withdraw from ${exchangeOne.Name}`
                    break
                case 6:
                    label = `Deposit to ${exchangeTwo.Name}`
                    break
            }

            let y0 = u.valToPos(_min, "y", true) + barGap + (barGap + barWidth) * (n - 3)

            ctx.fillStyle = "black"
            ctx.font = "0.8rem Montserrat, sans-serif"
            let textSize = ctx.measureText(label)
            ctx.fillText(label, u.bbox.left + textSize.width + 5, y0 + barWidth / 2)
            let seriesOne = u.data[n]
            let nbLegs: number = 0
            let beginningIdx: number = 0
            let currValue = -1
            while (beginningIdx < seriesOne.length - 1) {
                if (nbLegs > 1000) {
                    return
                }
                ctx.beginPath()
                for (let i = beginningIdx; i < seriesOne.length; i++) {
                    // console.log('i(y)', i)
                    let vOne = seriesOne[i]
                    if (vOne === null || vOne === undefined) {
                        continue
                    }

                    if (vOne !== currValue) {
                        if (vOne === 1) {
                            ctx.fillStyle = colorHexToRGBA(ARBITRAGE_COLOR_GREEN, 0.1)
                            ctx.strokeStyle = ARBITRAGE_COLOR_GREEN
                            currValue = vOne
                        } else {
                            ctx.fillStyle = colorHexToRGBA(ARBITRAGE_COLOR_RED, 0.1)
                            ctx.strokeStyle = ARBITRAGE_COLOR_RED
                            currValue = 0
                        }
                    }

                    let y = y0
                    let x = u.valToPos(seriesTime[i], "x", true)
                    if (i === beginningIdx) {
                        ctx.moveTo(x, y)
                        continue
                    }
                    nbLegs++
                    ctx.lineTo(x, y)
                    if (i + 1 < seriesOne.length) {
                        let nextVOne = seriesOne[i + 1]
                        if (nextVOne === null) {
                            // nbLegs++
                            // ctx.lineTo(x, y)
                            continue
                        }
                        if (nextVOne === vOne) {
                            // nbLegs++
                            // ctx.lineTo(x, y)
                            continue
                        }

                        for (let j = i; j >= beginningIdx; j--) {
                            // console.log('@break j(x)', j, '/', beginningIdx)
                            let y = y0 + barWidth
                            let x = u.valToPos(seriesTime[j], "x", true)
                            nbLegs++
                            ctx.lineTo(x, y)
                        }
                        ctx.closePath()
                        ctx.stroke()
                        ctx.fill()
                        beginningIdx = i + 1
                        break
                    } else {
                        for (let j = i; j >= beginningIdx; j--) {
                            // console.log('@end j(x)', j, '/', beginningIdx)
                            let y = y0 + barWidth
                            let x = u.valToPos(seriesTime[j], "x", true)
                            nbLegs++
                            ctx.lineTo(x, y)
                        }
                        ctx.closePath()
                        ctx.stroke()
                        ctx.fill()
                        beginningIdx = i + 1
                        break
                    }
                }
            }
        }

        // Reset canvas context
        ctx.setLineDash([])
        ctx.lineWidth = 2
        ctx.font = defaultFont
    }

    useEffect(() => {
        if (timeseries === null || timeseries.length === 0) {
            return
        }
        // Data
        // https://github.com/leeoniya/uPlot/tree/master/docs#installation
        const X: number[] = []
        const Y1: (number | null)[] = []
        const Y1b: (number | null)[] = []

        const Y2: (number | null)[] = []
        const Y3: (number | null)[] = []
        const Y4: (number | null)[] = []
        const Y5: (number | null)[] = []

        let prevRelRD: number | null = null
        let prevTsMs: number | null = null
        for (let frame of timeseries) {
            let d = new Date(frame.OrderbookTimestamp)
            let tsMs = d.getTime()

            let absRD = frame.Discrepancy.ExchangeTwoHBPrice - frame.Discrepancy.ExchangeOneLAPrice
            let relRD = (100 * absRD) / frame.Discrepancy.ExchangeOneLAPrice

            if (prevRelRD !== null && prevTsMs !== null) {
                if ((prevRelRD <= 0 && relRD > 0) || (prevRelRD >= 0 && relRD < 0)) {
                    let intermediateTsMs = prevTsMs + (tsMs - prevTsMs) / 2
                    X.push(intermediateTsMs / 1000)
                    Y1.push(0)
                    Y1b.push(0)
                    Y2.push(null)
                    Y3.push(null)
                    Y4.push(null)
                    Y5.push(null)
                }
            }

            X.push(tsMs / 1000)
            if (relRD > 0) {
                Y1.push(relRD)
                Y1b.push(null)
            } else {
                Y1.push(null)
                Y1b.push(relRD)
            }

            prevTsMs = tsMs
            prevRelRD = relRD

            if (frame.Metadata !== null) {
                Y2.push(frame.Metadata.ExchangeOneSymbolOK ? 1 : 0)
                Y3.push(frame.Metadata.ExchangeTwoSymbolOK ? 1 : 0)
                Y4.push(frame.Metadata.ExchangeOneWithdrawOK ? 1 : 0)
                Y5.push(frame.Metadata.ExchangeTwoDepositOK ? 1 : 0)
            } else {
                Y2.push(null)
                Y3.push(null)
                Y4.push(null)
                Y5.push(null)
            }
        }
        setUplotData([X, Y1, Y1b, Y2, Y3, Y4, Y5])

        const opts: uPlot.Options = {
            id: `uplot-chart-${exchangeOne.ID}-${exchangeTwo.ID}-${coinID}`,
            width: UPLOT_DEFAULT_WIDTH,
            height: UPLOT_DEFAULT_HEIGHT,
            padding: [20, 30, (15 + 3) * 4, 0],
            legend: {
                show: false
            },
            cursor: {
                lock: true,
                sync: {
                    key: mooSync.key
                }
            },
            axes: [
                {
                    show: false
                }
            ],
            scales: {
                y: {
                    range: (_, min, max) => {
                        if (min > 0) {
                            min = 0
                        }
                        min -= Math.abs(0.1 * min)
                        max += Math.abs(0.1 * max)
                        return [min, max]
                    }
                }
            },
            series: [
                {
                    show: true
                },
                {
                    show: true,
                    spanGaps: false,
                    label: `Relative Discrepancy ${exchangeOne.Name} -> ${exchangeTwo.Name}`,
                    value: (_, rawValue) => (rawValue ? `${rawValue.toFixed(2)}%` : "N/A"),
                    stroke: ARBITRAGE_COLOR_RELATIVE_DISCREPANCY,
                    dash: [],
                    width: 2,
                    fill: colorHexToRGBA(ARBITRAGE_COLOR_RELATIVE_DISCREPANCY, 0.1),
                    fillTo: 0
                },
                {
                    show: true,
                    spanGaps: false,
                    label: `Relative Discrepancy ${exchangeOne.Name} -> ${exchangeTwo.Name}`,
                    value: (_, rawValue) => (rawValue ? `${rawValue.toFixed(2)}%` : "N/A"),
                    stroke: ARBITRAGE_COLOR_RED,
                    dash: [],
                    width: 2,
                    fill: colorHexToRGBA(ARBITRAGE_COLOR_RED, 0.1),
                    fillTo: 0
                }
            ],
            hooks: {
                draw: [drawZeroLine, drawMetadata]
            }
        }
        setUplotOptions(opts)
    }, [timeseries])

    useEffect(() => {
        if (uplotOptions === null || uplotData === null || targetRef.current === null) {
            return
        }
        if (chartRef.current === null) {
            let plot = new uPlot(uplotOptions, uplotData, targetRef.current)
            plot.setSize({
                width: targetRef.current.clientWidth,
                height: UPLOT_DEFAULT_HEIGHT
            })
            mooSync.sub(plot)
            chartRef.current = plot
            setIsLoading(false)
        } else {
            chartRef.current.setData(uplotData)
        }
    }, [uplotOptions, uplotData])

    useEffect(() => {
        const resizePlot = () => {
            if (targetRef.current !== null && chartRef.current !== null) {
                chartRef.current.setSize({
                    width: targetRef.current.clientWidth,
                    height: UPLOT_DEFAULT_HEIGHT
                })
            }
        }

        void window.addEventListener("resize", resizePlot)
        return () => {
            void window.removeEventListener("resize", resizePlot)
        }
    }, [targetRef.current])

    if (isLoading) {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                ref={targetRef}
            >
                <Spin />
            </div>
        )
    }

    if (timeseries === null || uplotOptions === null || uplotData === null) {
        return null
    }

    return (
        <div
            style={{
                width: "100%",
                height: UPLOT_DEFAULT_HEIGHT,
                marginBottom: UPLOT_DEFAULT_MARGIN_BOTTOM
            }}
            ref={targetRef}
        />
    )
}

const DoubleOrderbookAnalysisWidget: FC<{
    exchangeOne: Exchange
    exchangeTwo: Exchange
    minPrice: number | null
    maxPrice: number | null
    discrepancyPoint: ExchangePairCoinDiscrepancy | null
}> = ({ exchangeOne, exchangeTwo, minPrice, maxPrice, discrepancyPoint }) => {
    const [uplotOptions, setUplotOptions] = useState<uPlot.Options | null>(null)
    const [uplotData, setUplotData] = useState<uPlot.AlignedData | null>(null)

    const targetRef = useRef<HTMLDivElement>(null)
    const chartRef = useRef<uPlot | null>(null)

    const drawInterExchangeDiscrepancy = (u: uPlot) => {
        if (discrepancyPoint === null) {
            return
        }
        let ctx = u.ctx
        ctx.setLineDash([])
        ctx.lineWidth = 2
        let sens =
            discrepancyPoint.Discrepancy.ExchangeOneLAPrice - discrepancyPoint.Discrepancy.ExchangeTwoHBPrice < 0 ?
                1
            :   -1
        if (sens > 0) {
            ctx.strokeStyle = ARBITRAGE_COLOR_RELATIVE_DISCREPANCY
        } else {
            ctx.strokeStyle = ARBITRAGE_COLOR_RED
        }

        let xLeft = u.valToPos(3.2, "x", true)
        let xRight = u.valToPos(3.8, "x", true)
        let xMid = (xLeft + xRight) / 2

        let yBot = u.valToPos(discrepancyPoint.Discrepancy.ExchangeOneLAPrice, "y", true)
        let yTop = u.valToPos(discrepancyPoint.Discrepancy.ExchangeTwoHBPrice, "y", true)

        ctx.beginPath()

        ctx.moveTo(xLeft, yBot)
        ctx.lineTo(xRight, yBot)

        ctx.moveTo(xLeft, yTop)
        ctx.lineTo(xRight, yTop)

        ctx.moveTo(xMid, yBot)
        ctx.lineTo(xMid, yTop)

        let headlenY = 10
        if (Math.abs(yTop - yBot) < 10) {
            headlenY = Math.abs(yTop - yBot) / 2
        }
        let headlenX = headlenY / 3
        let tox = xMid
        let toy = yTop
        ctx.lineTo(tox - headlenX, toy + sens * headlenY)
        ctx.moveTo(tox, toy)
        ctx.lineTo(tox + headlenX, toy + sens * headlenY)

        ctx.stroke()

        // Reset canvas context
        ctx.setLineDash([])
        ctx.lineWidth = 2
    }

    useEffect(() => {
        if (discrepancyPoint === null) {
            return
        }
        let X = [0, 1, 2, 3, 4, 5, 6, 7]
        // Asks One
        let Y1 = [
            null,
            discrepancyPoint.Discrepancy.ExchangeOneLAPrice,
            discrepancyPoint.Discrepancy.ExchangeOneLAPrice,
            discrepancyPoint.Discrepancy.ExchangeOneLAPrice,
            null,
            null,
            null,
            null
        ]
        // Bids Two
        let Y2 = [
            null,
            null,
            null,
            null,
            discrepancyPoint.Discrepancy.ExchangeTwoHBPrice,
            discrepancyPoint.Discrepancy.ExchangeTwoHBPrice,
            discrepancyPoint.Discrepancy.ExchangeTwoHBPrice,
            null
        ]
        // Bids One
        let Y3 = [
            null,
            discrepancyPoint.Discrepancy.ExchangeOneHBPrice,
            discrepancyPoint.Discrepancy.ExchangeOneHBPrice,
            discrepancyPoint.Discrepancy.ExchangeOneHBPrice,
            null,
            null,
            null,
            null
        ]
        // Asks Two
        let Y4 = [
            null,
            null,
            null,
            null,
            discrepancyPoint.Discrepancy.ExchangeTwoLAPrice,
            discrepancyPoint.Discrepancy.ExchangeTwoLAPrice,
            discrepancyPoint.Discrepancy.ExchangeTwoLAPrice,
            null
        ]
        setUplotData([X, Y1, Y2, Y3, Y4])
    }, [discrepancyPoint])

    useEffect(() => {
        if (minPrice === null || maxPrice === null) {
            return
        }
        let priceDiff = maxPrice - minPrice
        let effMinPrice = minPrice - priceDiff / 2
        let effMaxPrice = maxPrice + priceDiff / 2
        const opts: uPlot.Options = {
            width: UPLOT_DEFAULT_WIDTH,
            height: UPLOT_DEFAULT_HEIGHT,
            scales: {
                y: {
                    range: () => {
                        return [effMinPrice, effMaxPrice]
                    }
                },
                x: {
                    range: () => {
                        return [0, 7]
                    }
                }
            },
            legend: {
                show: false
            },
            axes: [
                {
                    // show: false,
                    grid: {
                        show: false
                    },
                    values: []
                },
                {
                    // show: false,
                    grid: {
                        show: true
                    },
                    values: (u, vals, space) => {
                        return vals.map(v => v.toString())
                    }
                }
            ],
            padding: [null, 0, 0, 0],
            series: [
                {},
                {
                    // Asks One
                    show: true,
                    points: {
                        show: false
                    },
                    spanGaps: false,
                    label: `LA @ ${exchangeOne.Name}`,
                    stroke: ARBITRAGE_COLOR_BUY,
                    dash: [],
                    width: 2,
                    fill: colorHexToRGBA(ARBITRAGE_COLOR_BUY, 0.1),
                    fillTo: effMaxPrice
                },
                {
                    // Bids Two
                    show: true,
                    points: {
                        show: false
                    },
                    spanGaps: false,
                    label: `HB @ ${exchangeTwo.Name}`,
                    stroke: ARBITRAGE_COLOR_SELL,
                    dash: [],
                    width: 2,
                    fill: colorHexToRGBA(ARBITRAGE_COLOR_SELL, 0.1),
                    fillTo: effMinPrice
                },
                {
                    // Bids One
                    show: true,
                    points: {
                        show: false
                    },
                    spanGaps: false,
                    label: `HB @ ${exchangeOne.Name}`,
                    stroke: ARBITRAGE_COLOR_NEUTRAL,
                    dash: [5, 5],
                    width: 2,
                    fill: colorHexToRGBA(ARBITRAGE_COLOR_NEUTRAL, 0.1),
                    fillTo: effMinPrice
                },
                {
                    // Asks Two
                    show: true,
                    points: {
                        show: false
                    },
                    spanGaps: false,
                    label: `HB @ ${exchangeTwo.Name}`,
                    stroke: ARBITRAGE_COLOR_NEUTRAL,
                    dash: [5, 5],
                    width: 2,
                    fill: colorHexToRGBA(ARBITRAGE_COLOR_NEUTRAL, 0.1),
                    fillTo: effMaxPrice
                }
            ],
            hooks: {
                draw: [drawInterExchangeDiscrepancy]
            }
        }
        setUplotOptions(opts)
    }, [minPrice, maxPrice])

    useEffect(() => {
        if (chartRef.current !== null && chartRef.current.hooks.draw !== undefined) {
            chartRef.current.hooks.draw[0] = drawInterExchangeDiscrepancy
        }
    }, [discrepancyPoint])

    useEffect(() => {
        if (uplotOptions === null || uplotData === null || targetRef.current === null) {
            return
        }
        if (chartRef.current === null) {
            let plot = new uPlot(uplotOptions, uplotData, targetRef.current)
            plot.setSize({
                width: targetRef.current.clientWidth,
                height: UPLOT_DEFAULT_HEIGHT
            })
            chartRef.current = plot
        } else {
            chartRef.current.setData(uplotData)
        }
    }, [uplotData, targetRef.current])

    useEffect(() => {
        if (uplotOptions === null || targetRef.current === null) {
            return
        }
        if (chartRef.current !== null) {
            chartRef.current.destroy()
            chartRef.current = null
        }
        let plot = new uPlot(uplotOptions, [], targetRef.current)
        plot.setSize({
            width: targetRef.current.clientWidth,
            height: UPLOT_DEFAULT_HEIGHT
        })
        chartRef.current = plot
    }, [uplotOptions, targetRef.current])

    useEffect(() => {
        const resizePlot = () => {
            if (targetRef.current !== null && chartRef.current !== null) {
                chartRef.current.setSize({
                    width: targetRef.current.clientWidth,
                    height: UPLOT_DEFAULT_HEIGHT
                })
            }
        }
        void window.addEventListener("resize", resizePlot)
        return () => {
            void window.removeEventListener("resize", resizePlot)
        }
    }, [targetRef.current])

    if (uplotOptions === null || uplotData === null) {
        return null
    }

    return (
        <FlexRow
            style={{
                width: "100%"
            }}
        >
            <FlexCol
                style={{
                    width: "100%"
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: UPLOT_DEFAULT_HEIGHT
                    }}
                    ref={targetRef}
                ></div>
            </FlexCol>
        </FlexRow>
    )
}

const OpportunityProfitTimeseriesChart: FC<{
    exchangeOne: Exchange
    exchangeTwo: Exchange
    coinID: Coin["ID"]
    depositAmount: number | null
    timeseries: OpportunityTimeseries | null
    mooSync: uPlot.SyncPubSub
}> = ({ exchangeOne, exchangeTwo, coinID, depositAmount, timeseries, mooSync }) => {
    const [uplotOptions, setUplotOptions] = useState<uPlot.Options | null>(null)
    const [uplotData, setUplotData] = useState<uPlot.AlignedData | null>(null)

    const targetRef = useRef<HTMLDivElement>(null)
    const chartRef = useRef<uPlot | null>(null)

    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (timeseries === null || timeseries.length === 0) {
            return
        }
        const X: number[] = []
        const Y1: (number | null)[] = []
        const Y2: (number | null)[] = []
        const Y3: (number | null)[] = []
        let previousTs: number = 0
        timeseries.forEach(frame => {
            let d = new Date(frame.OrderbookTimestamp)
            let ts = d.getTime() / 1000
            if (previousTs === 0) {
                previousTs = ts
            }
            if (ts - previousTs > 30) {
                X.push((previousTs + ts) / 2)
                Y1.push(null)
                if (depositAmount !== null) {
                    Y2.push(null)
                }
            } else {
                X.push(ts)
                Y1.push(frame.CumulativeProfit)
                if (depositAmount !== null) {
                    let { projectedProfit: accumulatedProfit } = projectDepositOnProfitNonLinear(
                        frame.ProfitPoints,
                        frame.CostPoints,
                        depositAmount
                    )
                    Y2.push(accumulatedProfit)
                }
                let totalFee = getTotalFee(frame, depositAmount)
                Y3.push(totalFee)
            }
            previousTs = ts
        })
        setUplotData([X, Y1, Y2, Y3])
        setUplotOptions({
            id: `uplot-chart-${exchangeOne.ID}-${exchangeTwo.ID}-${coinID}`,
            width: UPLOT_DEFAULT_WIDTH,
            height: UPLOT_DEFAULT_HEIGHT,
            legend: {
                show: false,
                live: true
            },
            cursor: {
                lock: true,
                sync: {
                    key: mooSync.key
                }
            },
            scales: {
                x: {
                    range: (_, min, max) => {
                        return [min, max]
                        // let _max = Date.now() / 1000
                        // // min is 30 minutes ago
                        // let _min = _max - 30 * 60
                        // return [_min, _max]
                    }
                },
                y: {
                    range: (_, min, max) => {
                        if (min > 0) {
                            min = 0
                        }
                        min -= Math.abs(0.1 * min)
                        max += Math.abs(0.1 * max)
                        return [min, max]
                    }
                }
            },
            series: [
                {},
                {
                    show: true,
                    spanGaps: false,
                    label: `TotalProfit`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) : "N/A"),
                    stroke: ARBITRAGE_COLOR_TOTAL_PROFIT,
                    width: 2,
                    dash: [],
                    fillTo: depositAmount !== null ? undefined : 0,
                    fill: depositAmount !== null ? undefined : colorHexToRGBA(ARBITRAGE_COLOR_TOTAL_PROFIT, 0.1)
                },
                {
                    show: true,
                    spanGaps: false,
                    label: `PersonalizedProfit`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) : "N/A"),
                    stroke: ARBITRAGE_COLOR_PERSONALIZED_PROFIT,
                    width: 2,
                    dash: [],
                    fillTo: 0,
                    fill: colorHexToRGBA(ARBITRAGE_COLOR_PERSONALIZED_PROFIT, 0.2)
                },
                {
                    show: true,
                    spanGaps: true,
                    label: `TotalFee`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) : "N/A"),
                    stroke: ARBITRAGE_COLOR_FEE,
                    width: 1,
                    dash: [20, 5, 5, 5],
                    fillTo: 0,
                    fill: colorHexToRGBA(ARBITRAGE_COLOR_FEE, 0.05)
                }
            ],
            hooks: {
                draw: [
                    // (u: uPlot) => { // Draw last profitable point (vertical)
                    //     const ctx = u.ctx
                    //     if (timeseries[timeseries.length-1].CumulativeProfit > feeUSDT) {
                    //         return
                    //     }
                    //     let lastProfitableTs = timeseries[timeseries.length-1].OrderbookTimestamp
                    //     for (let i = timeseries.length-1; i >= 0; i--) {
                    //         if (timeseries[i].CumulativeProfit > feeUSDT) {
                    //             lastProfitableTs = timeseries[i].OrderbookTimestamp
                    //             break
                    //         }
                    //     }
                    //     const x = u.valToPos(new Date(lastProfitableTs).getTime()/1000, "x", true)
                    //     const y0 = u.bbox.top
                    //     const y1 = u.bbox.top + u.bbox.height
                    //     ctx.strokeStyle = "rgba(255, 0, 0, 0.5)"
                    //     ctx.setLineDash([20, 5, 5, 5])
                    //     ctx.lineWidth = 2
                    //     ctx.beginPath()
                    //     ctx.moveTo(x, y0)
                    //     ctx.lineTo(x, y1)
                    //     ctx.stroke()
                    //     // Reset canvas context
                    //     ctx.setLineDash([])
                    //     ctx.lineWidth = 2
                    // }
                ]
            }
        })
    }, [timeseries, depositAmount])

    useEffect(() => {
        if (uplotOptions === null || uplotData === null || targetRef.current === null) {
            return
        }
        if (chartRef.current === null) {
            let plot = new uPlot(uplotOptions, uplotData, targetRef.current)
            plot.setSize({
                width: targetRef.current.clientWidth,
                height: UPLOT_DEFAULT_HEIGHT
            })
            mooSync.sub(plot)
            chartRef.current = plot
            setIsLoading(false)
        } else {
            chartRef.current.setData(uplotData)
        }
    }, [uplotOptions, uplotData])

    useEffect(() => {
        const resizePlot = () => {
            if (targetRef.current !== null && chartRef.current !== null) {
                // console.log("resize: ", targetRef.current.clientWidth, UPLOT_DEFAULT_HEIGHT)
                chartRef.current.setSize({
                    width: targetRef.current.clientWidth,
                    height: UPLOT_DEFAULT_HEIGHT
                })
            }
        }
        void window.addEventListener("resize", resizePlot)
        return () => {
            void window.removeEventListener("resize", resizePlot)
        }
    }, [targetRef.current])

    if (isLoading) {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                ref={targetRef}
            >
                <Spin />
            </div>
        )
    }

    if (timeseries === null || uplotOptions === null || uplotData === null) {
        return null
    }

    return (
        <div
            style={{
                width: "100%",
                height: UPLOT_DEFAULT_HEIGHT,
                marginBottom: UPLOT_DEFAULT_MARGIN_BOTTOM
            }}
            ref={targetRef}
        />
    )
}

const OpportunityProfitVirtualAntiProfitTimeseriesChart: FC<{
    exchangeOne: Exchange
    exchangeTwo: Exchange
    coinID: Coin["ID"]
    depositAmount: number | null
    timeseries: OpportunityTimeseries | null
    mooSync: uPlot.SyncPubSub
}> = ({ exchangeOne, exchangeTwo, coinID, depositAmount, timeseries, mooSync }) => {
    const [uplotOptions, setUplotOptions] = useState<uPlot.Options | null>(null)
    const [uplotData, setUplotData] = useState<uPlot.AlignedData | null>(null)

    const targetRef = useRef<HTMLDivElement>(null)
    const chartRef = useRef<uPlot | null>(null)

    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (timeseries === null || timeseries.length === 0) {
            return
        }
        // console.log(`OpportunityProfitVirtualAntiProfitTimeseriesChart`, timeseries)
        const X: number[] = []
        const Y1: (number | null)[] = []
        const Y2: (number | null)[] = []
        const Y3: (number | null)[] = []

        let previousTs: number = 0
        for (let frame of timeseries) {
            let d = new Date(frame.OrderbookTimestamp)
            let ts = d.getTime() / 1000
            if (previousTs === 0) {
                previousTs = ts
            }
            if (ts - previousTs > 30) {
                X.push((previousTs + ts) / 2)
                Y1.push(null)
                Y2.push(null)
                Y3.push(null)
            } else {
                X.push(ts)
                Y1.push(frame.CumulativeProfit)
                Y2.push(frame.VirtualAntiProfit)
                // let pVapRatio = frame.ProfitToVirtualAntiProfitRatio
                let pVapCos =
                    frame.CumulativeProfit /
                    Math.sqrt(Math.pow(frame.CumulativeProfit, 2) + Math.pow(frame.VirtualAntiProfit, 2))
                Y3.push(pVapCos * 100)
            }
            previousTs = ts
        }
        setUplotData([X, Y1, Y2, Y3])
        setUplotOptions({
            id: `uplot-chart-${exchangeOne.ID}-${exchangeTwo.ID}-${coinID}`,
            width: UPLOT_DEFAULT_WIDTH,
            height: UPLOT_DEFAULT_HEIGHT,
            legend: {
                show: true,
                live: true
            },
            cursor: {
                lock: true,
                sync: {
                    key: mooSync.key
                }
            },
            scales: {
                x: {
                    range: (_, min, max) => {
                        return [min, max]
                    }
                },
                y: {
                    range: (_, min, max) => {
                        if (min > 0) {
                            min = 0
                        }
                        min -= Math.abs(0.1 * min)
                        max += Math.abs(0.1 * max)
                        return [min, max]
                    }
                },
                y2: {
                    range: (_, min, max) => {
                        return [0, 100]
                    }
                }
            },
            axes: [
                {},
                { scale: "y" },
                {
                    scale: "y2",
                    side: 1,
                    values: (u, vals, space) => {
                        return vals.map(v => v.toFixed(0) + "%")
                    }
                }
            ],
            series: [
                {
                    value: (_, rawValue) => (rawValue ? dayjs(rawValue * 1e3).format("HH:mm:ss") : "N/A")
                },
                {
                    scale: "y",
                    show: true,
                    spanGaps: false,
                    label: `P`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) : "N/A"),
                    stroke: ARBITRAGE_COLOR_TOTAL_PROFIT,
                    width: 2,
                    dash: [],
                    fillTo: depositAmount !== null ? undefined : 0,
                    fill: depositAmount !== null ? undefined : colorHexToRGBA(ARBITRAGE_COLOR_TOTAL_PROFIT, 0.1)
                },
                {
                    scale: "y",
                    show: true,
                    spanGaps: false,
                    label: `VAP`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) : "N/A"),
                    stroke: ARBITRAGE_COLOR_VIRTUAL_ANTI_PROFIT, //"#34ebe5",
                    width: 2,
                    dash: [],
                    fillTo: 0,
                    fill: colorHexToRGBA(ARBITRAGE_COLOR_VIRTUAL_ANTI_PROFIT, 0.1)
                },
                {
                    scale: "y2",
                    show: true,
                    spanGaps: false,
                    label: `cos(P, VAP)`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(0) + "%" : "N/A"),
                    stroke: "#777777",
                    width: 1,
                    dash: [],
                    fillTo: 0
                    // fill: colorHexToRGBA("#777777", 0.1)
                }
            ],
            hooks: {
                draw: [
                    (u: uPlot) => {
                        let t0 = u.data[0][0]
                        let t1 = u.data[0][u.data[0].length - 1]
                        let y = 100 * Math.cos(Math.PI / 4)
                        // console.log(`draw: ${t0} -> ${t1} @ ${y}`)
                        const ctx = u.ctx
                        ctx.strokeStyle = "rgba(255, 0, 0, 1)"
                        ctx.lineWidth = 2
                        ctx.setLineDash([5, 5])
                        ctx.beginPath()
                        ctx.moveTo(u.valToPos(t0, "x", true), u.valToPos(y, "y2", true))
                        ctx.lineTo(u.valToPos(t1, "x", true), u.valToPos(y, "y2", true))
                        ctx.stroke()
                    }
                ]
            }
        })
    }, [timeseries, depositAmount])

    useEffect(() => {
        if (uplotOptions === null || uplotData === null || targetRef.current === null) {
            return
        }
        if (chartRef.current === null) {
            let plot = new uPlot(uplotOptions, uplotData, targetRef.current)
            plot.setSize({
                width: targetRef.current.clientWidth,
                height: UPLOT_DEFAULT_HEIGHT
            })
            mooSync.sub(plot)
            chartRef.current = plot
            setIsLoading(false)
        } else {
            chartRef.current.setData(uplotData)
        }
    }, [uplotOptions, uplotData])

    useEffect(() => {
        const resizePlot = () => {
            if (targetRef.current !== null && chartRef.current !== null) {
                // console.log("resize: ", targetRef.current.clientWidth, UPLOT_DEFAULT_HEIGHT)
                chartRef.current.setSize({
                    width: targetRef.current.clientWidth,
                    height: UPLOT_DEFAULT_HEIGHT
                })
            }
        }
        void window.addEventListener("resize", resizePlot)
        return () => {
            void window.removeEventListener("resize", resizePlot)
        }
    }, [targetRef.current])

    if (isLoading) {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                ref={targetRef}
            >
                <Spin />
            </div>
        )
    }

    if (timeseries === null || uplotOptions === null || uplotData === null) {
        return null
    }

    return (
        <div
            style={{
                width: "100%",
                height: UPLOT_DEFAULT_HEIGHT,
                marginBottom: UPLOT_DEFAULT_MARGIN_BOTTOM + 30
            }}
            ref={targetRef}
        />
    )
}

const OpportunityProfitVirtualAntiProfitRetrospectiveTimeseriesChart: FC<{
    exchangeOne: Exchange
    exchangeTwo: Exchange
    coinID: Coin["ID"]
    depositAmount: number | null
    timeseries: OpportunityTimeseries | null
    mooSync: uPlot.SyncPubSub
}> = ({ exchangeOne, exchangeTwo, coinID, depositAmount, timeseries, mooSync }) => {
    const [uplotOptions, setUplotOptions] = useState<uPlot.Options | null>(null)
    const [uplotData, setUplotData] = useState<uPlot.AlignedData | null>(null)

    const targetRef = useRef<HTMLDivElement>(null)
    const chartRef = useRef<uPlot | null>(null)

    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (timeseries === null || timeseries.length === 0) {
            return
        }
        console.log(`OpportunityProfitVirtualAntiProfitRetrospectiveTimeseriesChart`, timeseries)
        const X: number[] = []
        const Y1: (number | null)[] = []
        const Y2: (number | null)[] = []
        const Y3: (number | null)[] = []

        let previousTs: number = 0
        for (let frame of timeseries) {
            let d = new Date(frame.OrderbookTimestamp)
            let ts = d.getTime() / 1000
            if (previousTs === 0) {
                previousTs = ts
            }
            if (ts - previousTs > 30) {
                X.push((previousTs + ts) / 2)
                Y1.push(null)
                Y2.push(null)
                Y3.push(null)
            } else {
                X.push(ts)
                Y1.push(frame.CumulativeProfit)
                Y2.push(frame.RetrospectiveAverageVirtualAntiProfit)
                let pVapCos =
                    frame.CumulativeProfit /
                    Math.sqrt(
                        Math.pow(frame.CumulativeProfit, 2) + Math.pow(frame.RetrospectiveAverageVirtualAntiProfit, 2)
                    )
                Y3.push(pVapCos * 100)
            }
            previousTs = ts
        }
        setUplotData([X, Y1, Y2, Y3])
        setUplotOptions({
            id: `uplot-chart-${exchangeOne.ID}-${exchangeTwo.ID}-${coinID}`,
            width: UPLOT_DEFAULT_WIDTH,
            height: UPLOT_DEFAULT_HEIGHT,
            legend: {
                show: true,
                live: true
            },
            cursor: {
                lock: true,
                sync: {
                    key: mooSync.key
                }
            },
            scales: {
                x: {
                    range: (_, min, max) => {
                        return [min, max]
                    }
                },
                y: {
                    range: (_, min, max) => {
                        if (min > 0) {
                            min = 0
                        }
                        min -= Math.abs(0.1 * min)
                        max += Math.abs(0.1 * max)
                        return [min, max]
                    }
                },
                y2: {
                    range: (_, min, max) => {
                        return [0, 100]
                    }
                }
            },
            axes: [
                {},
                { scale: "y" },
                {
                    scale: "y2",
                    side: 1,
                    values: (u, vals, space) => {
                        return vals.map(v => v.toFixed(0) + "%")
                    }
                }
            ],
            series: [
                {
                    value: (_, rawValue) => (rawValue ? dayjs(rawValue * 1e3).format("HH:mm:ss") : "N/A")
                },
                {
                    scale: "y",
                    show: true,
                    spanGaps: false,
                    label: `P`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) : "N/A"),
                    stroke: ARBITRAGE_COLOR_TOTAL_PROFIT,
                    width: 2,
                    dash: [],
                    fillTo: depositAmount !== null ? undefined : 0,
                    fill: depositAmount !== null ? undefined : colorHexToRGBA(ARBITRAGE_COLOR_TOTAL_PROFIT, 0.1)
                },
                {
                    scale: "y",
                    show: true,
                    spanGaps: false,
                    label: `reVAP`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) : "N/A"),
                    stroke: ARBITRAGE_COLOR_VIRTUAL_ANTI_PROFIT, //"#34ebe5",
                    width: 2,
                    dash: [],
                    fillTo: 0,
                    fill: colorHexToRGBA(ARBITRAGE_COLOR_VIRTUAL_ANTI_PROFIT, 0.1)
                },
                {
                    scale: "y2",
                    show: true,
                    spanGaps: false,
                    label: `cos(P, reVAP)`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(0) + "%" : "N/A"),
                    stroke: "#777777",
                    width: 1,
                    dash: [],
                    fillTo: 0
                    // fill: colorHexToRGBA("#777777", 0.1)
                }
            ],
            hooks: {
                draw: [
                    (u: uPlot) => {
                        let t0 = u.data[0][0]
                        let t1 = u.data[0][u.data[0].length - 1]
                        let y = 100 * Math.cos(Math.PI / 4)
                        // console.log(`draw: ${t0} -> ${t1} @ ${y}`)
                        const ctx = u.ctx
                        ctx.strokeStyle = "rgba(255, 0, 0, 1)"
                        ctx.lineWidth = 2
                        ctx.setLineDash([5, 5])
                        ctx.beginPath()
                        ctx.moveTo(u.valToPos(t0, "x", true), u.valToPos(y, "y2", true))
                        ctx.lineTo(u.valToPos(t1, "x", true), u.valToPos(y, "y2", true))
                        ctx.stroke()
                    }
                ]
            }
        })
    }, [timeseries, depositAmount])

    useEffect(() => {
        if (uplotOptions === null || uplotData === null || targetRef.current === null) {
            return
        }
        if (chartRef.current === null) {
            let plot = new uPlot(uplotOptions, uplotData, targetRef.current)
            plot.setSize({
                width: targetRef.current.clientWidth,
                height: UPLOT_DEFAULT_HEIGHT
            })
            mooSync.sub(plot)
            chartRef.current = plot
            setIsLoading(false)
        } else {
            chartRef.current.setData(uplotData)
        }
    }, [uplotOptions, uplotData])

    useEffect(() => {
        const resizePlot = () => {
            if (targetRef.current !== null && chartRef.current !== null) {
                // console.log("resize: ", targetRef.current.clientWidth, UPLOT_DEFAULT_HEIGHT)
                chartRef.current.setSize({
                    width: targetRef.current.clientWidth,
                    height: UPLOT_DEFAULT_HEIGHT
                })
            }
        }
        void window.addEventListener("resize", resizePlot)
        return () => {
            void window.removeEventListener("resize", resizePlot)
        }
    }, [targetRef.current])

    if (isLoading) {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                ref={targetRef}
            >
                <Spin />
            </div>
        )
    }

    if (timeseries === null || uplotOptions === null || uplotData === null) {
        return null
    }

    return (
        <div
            style={{
                width: "100%",
                height: UPLOT_DEFAULT_HEIGHT,
                marginBottom: UPLOT_DEFAULT_MARGIN_BOTTOM + 30
            }}
            ref={targetRef}
        />
    )
}

const OpportunityCostTimeseriesChart: FC<{
    exchangeOne: Exchange
    exchangeTwo: Exchange
    coinID: Coin["ID"]
    depositAmount: number | null
    timeseries: OpportunityTimeseries | null
    mooSync: uPlot.SyncPubSub
}> = ({ exchangeOne, exchangeTwo, coinID, depositAmount, timeseries, mooSync }) => {
    const [uplotOptions, setUplotOptions] = useState<uPlot.Options | null>(null)
    const [uplotData, setUplotData] = useState<uPlot.AlignedData | null>(null)

    const targetRef = useRef<HTMLDivElement>(null)
    const chartRef = useRef<uPlot | null>(null)

    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (timeseries === null || timeseries.length === 0) {
            return
        }
        const X: number[] = []
        const Y1: (number | null)[] = []
        const Y2: (number | null)[] = []
        let previousTs: number = 0
        timeseries.forEach(frame => {
            let d = new Date(frame.OrderbookTimestamp)
            let ts = d.getTime() / 1000
            if (previousTs === 0) {
                previousTs = ts
            }
            if (ts - previousTs > 30) {
                X.push((previousTs + ts) / 2)
                Y1.push(null)
                if (depositAmount !== null) {
                    Y2.push(null)
                }
            } else {
                X.push(ts)
                Y1.push(frame.CumulativeCost)
                if (depositAmount !== null) {
                    Y2.push(Math.min(frame.CumulativeCost, depositAmount))
                }
            }
            previousTs = ts
        })
        setUplotData([X, Y1, Y2])
        setUplotOptions({
            id: `uplot-chart-${exchangeOne.ID}-${exchangeTwo.ID}-${coinID}`,
            width: UPLOT_DEFAULT_WIDTH,
            height: UPLOT_DEFAULT_HEIGHT,
            legend: {
                show: false,
                live: true
            },
            cursor: {
                lock: true,
                sync: {
                    key: mooSync.key
                }
            },
            scales: {
                x: {
                    range: (_, min, max) => {
                        return [min, max]
                        // let _max = Date.now() / 1000
                        // // min is 30 minutes ago
                        // let _min = _max - 30 * 60
                        // return [_min, _max]
                    }
                },
                y: {
                    range: (_, min, max) => {
                        if (min > 0) {
                            min = 0
                        }
                        min -= Math.abs(0.1 * min)
                        max += Math.abs(0.1 * max)
                        return [min, max]
                    }
                }
            },
            series: [
                {},
                {
                    show: true,
                    spanGaps: false,
                    label: `TotalInvestmant`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) + " %" : "N/A"),
                    stroke: ARBITRAGE_COLOR_TOTAL_MARGIN,
                    width: 2,
                    fillTo: depositAmount !== null ? undefined : 0,
                    fill: depositAmount !== null ? undefined : colorHexToRGBA(ARBITRAGE_COLOR_TOTAL_MARGIN, 0.1)
                },
                {
                    show: true,
                    spanGaps: false,
                    label: `PersonalizedInvestmant`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) + " %" : "N/A"),
                    stroke: ARBITRAGE_COLOR_PERSONALIZED_MARGIN,
                    width: 2,
                    fillTo: 0,
                    fill: colorHexToRGBA(ARBITRAGE_COLOR_PERSONALIZED_MARGIN, 0.2)
                }
            ]
        })
    }, [timeseries, depositAmount])

    useEffect(() => {
        if (uplotOptions === null || uplotData === null || targetRef.current === null) {
            return
        }
        if (chartRef.current === null) {
            let plot = new uPlot(uplotOptions, uplotData, targetRef.current)
            plot.setSize({
                width: targetRef.current.clientWidth,
                height: UPLOT_DEFAULT_HEIGHT
            })
            mooSync.sub(plot)
            chartRef.current = plot
            setIsLoading(false)
        } else {
            chartRef.current.setData(uplotData)
        }
    }, [uplotOptions, uplotData])

    useEffect(() => {
        const resizePlot = () => {
            if (targetRef.current !== null && chartRef.current !== null) {
                chartRef.current.setSize({
                    width: targetRef.current.clientWidth,
                    height: UPLOT_DEFAULT_HEIGHT
                })
            }
        }
        void window.addEventListener("resize", resizePlot)
        return () => {
            void window.removeEventListener("resize", resizePlot)
        }
    }, [targetRef.current])

    if (isLoading) {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                ref={targetRef}
            >
                <Spin />
            </div>
        )
    }

    if (timeseries === null || uplotOptions === null || uplotData === null) {
        return null
    }

    return (
        <div
            style={{
                width: "100%",
                height: UPLOT_DEFAULT_HEIGHT,
                marginBottom: UPLOT_DEFAULT_MARGIN_BOTTOM
            }}
            ref={targetRef}
        />
    )
}

const OpportunityMarginTimeseriesChart: FC<{
    exchangeOne: Exchange
    exchangeTwo: Exchange
    coinID: Coin["ID"]
    depositAmount: number | null
    timeseries: OpportunityTimeseries | null
    mooSync: uPlot.SyncPubSub
}> = ({ exchangeOne, exchangeTwo, coinID, depositAmount, timeseries, mooSync }) => {
    const [uplotOptions, setUplotOptions] = useState<uPlot.Options | null>(null)
    const [uplotData, setUplotData] = useState<uPlot.AlignedData | null>(null)

    const targetRef = useRef<HTMLDivElement>(null)
    const chartRef = useRef<uPlot | null>(null)

    const [isLoading, setIsLoading] = useState<boolean>(true)

    useEffect(() => {
        if (timeseries === null || timeseries.length === 0) {
            return
        }
        const X: number[] = []
        const Y1: (number | null)[] = []
        const Y2: (number | null)[] = []
        const Y3: (number | null)[] = []
        let previousTs: number = 0
        timeseries.forEach(frame => {
            let d = new Date(frame.OrderbookTimestamp)
            let ts = d.getTime() / 1000
            if (previousTs === 0) {
                previousTs = ts
            }
            if (ts - previousTs > 30) {
                X.push((previousTs + ts) / 2)
                Y1.push(null)
                Y3.push(null)
                if (depositAmount !== null) {
                    Y2.push(null)
                }
            } else {
                X.push(ts)
                Y1.push((100 * frame.CumulativeProfit) / frame.CumulativeCost)
                if (depositAmount !== null) {
                    let { projectedProfit } = projectDepositOnProfitNonLinear(
                        frame.ProfitPoints,
                        frame.CostPoints,
                        depositAmount
                    )
                    Y2.push((100 * projectedProfit) / depositAmount)

                    let totalFee = getTotalFee(frame, depositAmount)
                    Y3.push((100 * totalFee) / depositAmount)
                } else {
                    let totalFee = getTotalFee(frame, frame.CumulativeCost)
                    Y3.push((100 * totalFee) / frame.CumulativeCost)
                }
            }
            previousTs = ts
        })
        setUplotData([X, Y1, Y2, Y3])
        setUplotOptions({
            id: `uplot-chart-${exchangeOne.ID}-${exchangeTwo.ID}-${coinID}`,
            width: UPLOT_DEFAULT_WIDTH,
            height: UPLOT_DEFAULT_HEIGHT,
            legend: {
                show: false,
                live: true
            },
            cursor: {
                lock: true,
                sync: {
                    key: mooSync.key
                }
            },
            scales: {
                x: {
                    range: (_, min, max) => {
                        return [min, max]
                        // let _max = Date.now() / 1000
                        // // min is 30 minutes ago
                        // let _min = _max - 30 * 60
                        // return [_min, _max]
                    }
                },
                y: {
                    range: (_, min, max) => {
                        if (min > 0) {
                            min = 0
                        }
                        min -= Math.abs(0.1 * min)
                        max += Math.abs(0.1 * max)
                        return [min, max]
                    }
                }
            },
            series: [
                {},
                {
                    show: true,
                    spanGaps: false,
                    label: `TotalMargin`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) + " %" : "N/A"),
                    stroke: ARBITRAGE_COLOR_TOTAL_MARGIN,
                    width: 2,
                    fillTo: depositAmount !== null ? undefined : 0,
                    fill: depositAmount !== null ? undefined : colorHexToRGBA(ARBITRAGE_COLOR_TOTAL_MARGIN, 0.1)
                },
                {
                    show: true,
                    spanGaps: false,
                    label: `PersonalizedMargin`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) + " %" : "N/A"),
                    stroke: ARBITRAGE_COLOR_PERSONALIZED_MARGIN,
                    width: 2,
                    fillTo: 0,
                    fill: colorHexToRGBA(ARBITRAGE_COLOR_PERSONALIZED_MARGIN, 0.2)
                },
                {
                    show: true,
                    spanGaps: true,
                    label: `Margin@TotalFee`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) + " %" : "N/A"),
                    stroke: ARBITRAGE_COLOR_FEE,
                    dash: [20, 5, 5, 5],
                    fillTo: 0,
                    fill: colorHexToRGBA(ARBITRAGE_COLOR_FEE, 0.05)
                }
            ],
            hooks: {
                draw: [
                    // (u: uPlot) => {
                    //     const ctx = u.ctx
                    //     const x0 = u.bbox.left
                    //     const x1 = u.bbox.left + u.bbox.width
                    //     const y0 = u.valToPos(0, "y", true)
                    //     const y1 = u.valToPos(100*feeUSDT/timeseries[timeseries.length-1].CumulativeCost, "y", true)
                    //     ctx.strokeStyle = "rgba(0, 0, 0, 0.5)"
                    //     ctx.fillStyle = "rgba(0, 0, 0, 0.05)"
                    //     ctx.setLineDash([20, 5, 5, 5])
                    //     ctx.lineWidth = 2
                    //     ctx.beginPath()
                    //     ctx.moveTo(x0, y1)
                    //     ctx.lineTo(x1, y1)
                    //     ctx.stroke()
                    //     ctx.fillRect(x0, y0, x1-x0, y1-y0)
                    // },
                    // (u: uPlot) => { // Draw last profitable point (vertical)
                    //     const ctx = u.ctx
                    //     let frame = timeseries[timeseries.length-1]
                    //     if (frame === undefined) {
                    //         return
                    //     }
                    //     if (frame.CumulativeProfit > getTotalFee(frame, depositAmount)) {
                    //         return
                    //     }
                    //     let lastProfitableTs = timeseries[timeseries.length-1].OrderbookTimestamp
                    //     for (let i = timeseries.length-1; i >= 0; i--) {
                    //         if (timeseries[i].CumulativeProfit > feeUSDT) {
                    //             lastProfitableTs = timeseries[i].OrderbookTimestamp
                    //             break
                    //         }
                    //     }
                    //     const x = u.valToPos(new Date(lastProfitableTs).getTime()/1000, "x", true)
                    //     const y0 = u.bbox.top
                    //     const y1 = u.bbox.top + u.bbox.height
                    //     ctx.strokeStyle = "rgba(255, 0, 0, 0.5)"
                    //     ctx.setLineDash([20, 5, 5, 5])
                    //     ctx.lineWidth = 2
                    //     ctx.beginPath()
                    //     ctx.moveTo(x, y0)
                    //     ctx.lineTo(x, y1)
                    //     ctx.stroke()
                    //     // Reset canvas context
                    //     ctx.setLineDash([])
                    //     ctx.lineWidth = 2
                    // }
                ]
            }
        })
    }, [timeseries, depositAmount])

    useEffect(() => {
        if (uplotOptions === null || uplotData === null || targetRef.current === null) {
            return
        }
        if (chartRef.current === null) {
            let plot = new uPlot(uplotOptions, uplotData, targetRef.current)
            plot.setSize({
                width: targetRef.current.clientWidth,
                height: UPLOT_DEFAULT_HEIGHT
            })
            mooSync.sub(plot)
            chartRef.current = plot
            setIsLoading(false)
        } else {
            chartRef.current.setData(uplotData)
        }
    }, [uplotOptions, uplotData])

    useEffect(() => {
        const resizePlot = () => {
            if (targetRef.current !== null && chartRef.current !== null) {
                chartRef.current.setSize({
                    width: targetRef.current.clientWidth,
                    height: UPLOT_DEFAULT_HEIGHT
                })
            }
        }
        void window.addEventListener("resize", resizePlot)
        return () => {
            void window.removeEventListener("resize", resizePlot)
        }
    }, [targetRef.current])

    if (isLoading) {
        return (
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
                ref={targetRef}
            >
                <Spin />
            </div>
        )
    }

    if (timeseries === null || uplotOptions === null || uplotData === null) {
        return null
    }

    return (
        <div
            style={{
                width: "100%",
                height: UPLOT_DEFAULT_HEIGHT,
                marginBottom: UPLOT_DEFAULT_MARGIN_BOTTOM
            }}
            ref={targetRef}
        />
    )
}

export const CostVsVolumeAnalysisChart: FC<{
    volumePoints: number[]
    costPointsFrom: number[]
    costPointsTo: number[]
}> = ({ volumePoints, costPointsFrom, costPointsTo }) => {
    const [uplotOptions, setUplotOptions] = useState<uPlot.Options | null>(null)
    const [uplotData, setUplotData] = useState<uPlot.AlignedData | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const targetRef = useRef<HTMLDivElement>(null)
    const chartRef = useRef<uPlot | null>(null)

    // Generate Data
    useEffect(() => {
        if (costPointsFrom.length === 0 || costPointsTo.length === 0 || volumePoints === null) {
            return
        }
        if (costPointsFrom.length !== volumePoints.length) {
            throw new Error(`CostVsVolumeAnalysisChart: costPointsFrom.length !== volumePoints.length`)
        }
        if (costPointsTo.length !== volumePoints.length) {
            throw new Error(`CostVsVolumeAnalysisChart: costPointsTo.length !== volumePoints.length`)
        }

        // Data
        // https://github.com/leeoniya/uPlot/tree/master/docs#installation

        let accumulatedVolume = 0
        let accumulatedCostFrom = 0
        let accumulatedCostTo = 0

        // let xAlignedTuples: [
        //     number,
        //     [
        //         number | null, // from y alue
        //         number | null // to y value
        //     ]
        // ][] = []
        // // From
        // for (let i = 0; i < costPointsFrom.length; i++) {
        //     accumulatedVolumeFrom += volumePointsFrom[i]
        //     accumulatedCostFrom += costPointsFrom[i]
        //     xAlignedTuples.push([accumulatedVolumeFrom, [accumulatedCostFrom, null]])
        // }
        // // To
        // for (let i = 0; i < costPointsTo.length; i++) {
        //     accumulatedVolumeTo += volumePointsTo[i]
        //     accumulatedCostTo += costPointsTo[i]
        //     let existingTupleFound = false
        //     for (let j = 0; j < xAlignedTuples.length; j++) {
        //         if (xAlignedTuples[j][0] === accumulatedVolumeTo) {
        //             xAlignedTuples[j][1][1] = accumulatedCostTo
        //             existingTupleFound = true
        //             break
        //         }
        //     }
        //     if (!existingTupleFound) {
        //         xAlignedTuples.push([accumulatedVolumeTo, [null, accumulatedCostTo]])
        //     }
        // }
        // // Sort
        // xAlignedTuples.sort((a, b) => a[0] - b[0])

        // let X = xAlignedTuples.map(tuple => tuple[0])
        // let YFrom = xAlignedTuples.map(tuple => tuple[1][0])
        // let YTo = xAlignedTuples.map(tuple => tuple[1][1])

        let X: number[] = [0]
        let YFrom: (number | null)[] = [0]
        let YTo: (number | null)[] = [0]
        for (let i = 0; i < costPointsFrom.length; i++) {
            accumulatedVolume += volumePoints[i]
            accumulatedCostFrom += costPointsFrom[i]
            accumulatedCostTo += costPointsTo[i]
            X.push(accumulatedVolume)
            YFrom.push(accumulatedCostFrom)
            YTo.push(accumulatedCostTo)
        }
        console.log(
            `CostVsVolumeAnalysisChart: X: ${X.length}, YFrom: ${YFrom.length}, YTo: ${YTo.length}`,
            X,
            YFrom,
            YTo
        )
        setUplotData([X, YFrom, YTo])
    }, [volumePoints, costPointsFrom, costPointsTo])

    // Generate Options
    useEffect(() => {
        const opts: uPlot.Options = {
            width: UPLOT_DEFAULT_WIDTH,
            height: UPLOT_DEFAULT_HEIGHT,

            legend: {
                show: false
            },
            // padding: [null, 0, 0, 0],
            axes: [
                {
                    values: (_, values) => values.map(v => v.toFixed(2))
                }
            ],
            series: [
                {
                    show: true,
                    label: `Volume`
                    // value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) : "N/A"),
                    // width: 2
                },
                {
                    show: true,
                    points: {
                        show: true,
                        size: 5
                    },
                    spanGaps: true,
                    label: `Cost@From`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) : "N/A"),
                    stroke: "rgb(21, 21, 81)",
                    // dash: [],
                    width: 2
                    // fill: "rgba(21, 21, 81, 0.08)",
                    // fillTo: 0
                },
                {
                    show: true,
                    points: {
                        show: true,
                        size: 5
                    },
                    spanGaps: true,
                    label: `Cost@To`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) : "N/A"),
                    stroke: "rgb(21, 21, 81)",
                    // dash: [],
                    width: 2
                    // fill: "rgba(21, 21, 81, 0.08)",
                    // fillTo: 0
                }
            ]
            // hooks: {
            //     draw: []
            // }
        }
        setUplotOptions(opts)
    }, [])

    useEffect(() => {
        if (uplotOptions === null || uplotData === null || targetRef.current === null) {
            return
        }
        if (chartRef.current === null) {
            let plot = new uPlot(uplotOptions, uplotData, targetRef.current)
            plot.setSize({
                width: targetRef.current.clientWidth,
                height: UPLOT_DEFAULT_HEIGHT
            })
            chartRef.current = plot
            setIsLoading(false)
        } else {
            chartRef.current.setData(uplotData)
        }
    }, [uplotData, uplotOptions, targetRef.current])

    // useEffect(() => {
    //     if (uplotOptions === null || targetRef.current === null) {
    //         return
    //     }
    //     if (chartRef.current !== null) {
    //         chartRef.current.destroy()
    //         chartRef.current = null
    //     }
    //     let plot = new uPlot(uplotOptions, [], targetRef.current)
    //     plot.setSize({
    //         width: targetRef.current.clientWidth,
    //         height: UPLOT_DEFAULT_HEIGHT
    //     })
    //     chartRef.current = plot
    //     setIsLoading(false)
    // }, [uplotOptions, targetRef.current])

    useEffect(() => {
        const resizePlot = () => {
            if (targetRef.current !== null && chartRef.current !== null) {
                chartRef.current.setSize({
                    width: targetRef.current.clientWidth,
                    height: UPLOT_DEFAULT_HEIGHT
                })
            }
        }
        void window.addEventListener("resize", resizePlot)
        return () => {
            void window.removeEventListener("resize", resizePlot)
        }
    }, [targetRef.current])

    if (uplotOptions === null || uplotData === null) {
        return null
    }

    return (
        <FlexRow
            style={{
                width: "100%"
            }}
        >
            <FlexCol
                style={{
                    width: "100%"
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: UPLOT_DEFAULT_HEIGHT
                    }}
                    ref={targetRef}
                ></div>
            </FlexCol>
        </FlexRow>
    )
}

const ProfitVsCostAnalysisChart: FC<{
    profitPoints: number[]
    costPoints: number[]
    maxProfit: number
    maxCost: number
    depositAmount: number | null
    totalFee: number | null
}> = ({ profitPoints, costPoints, maxProfit, maxCost, depositAmount, totalFee }) => {
    const [uplotOptions, setUplotOptions] = useState<uPlot.Options | null>(null)
    const [uplotData, setUplotData] = useState<uPlot.AlignedData | null>(null)

    const targetRef = useRef<HTMLDivElement>(null)
    const chartRef = useRef<uPlot | null>(null)

    const [isLoading, setIsLoading] = useState<boolean>(true)

    const drawPersonalizedProfit = (u: uPlot) => {
        // Draw deposit line (square)
        if (depositAmount === null) {
            return
        }
        let { projectedProfit: accumulatedProfit } = projectDepositOnProfitNonLinear(
            profitPoints,
            costPoints,
            depositAmount
        )
        const ctx = u.ctx
        const x0 = u.valToPos(0, "x", true)

        let xData = u.data[0]
        let x1: number = 0
        if (depositAmount > xData[xData.length - 1]) {
            x1 = u.valToPos(xData[xData.length - 1], "x", true)
        } else {
            x1 = u.valToPos(depositAmount, "x", true)
        }
        if (x1 > u.bbox.left + u.bbox.width) {
            x1 = u.bbox.left + u.bbox.width
        }
        // const x1 = u.valToPos(Math.max(depositAmount, costPoints[costPoints.length-1]), "x", true)

        const y0 = u.valToPos(0, "y", true)
        const y1 = u.valToPos(accumulatedProfit, "y", true)

        ctx.strokeStyle = "pink"
        ctx.lineWidth = 2
        ctx.fillStyle = "rgba(255, 192, 203, 0.2)"
        ctx.setLineDash([20, 5, 5, 5])
        ctx.beginPath()
        ctx.moveTo(x1, y0)
        ctx.lineTo(x1, y1)
        ctx.lineTo(x0, y1)
        ctx.stroke()
        ctx.fillRect(x0, y0, x1 - x0, y1 - y0)

        // Reset canvas context
        ctx.setLineDash([])
        ctx.lineWidth = 2
    }

    const drawWithdrawFeeLine = (u: uPlot) => {
        // Draw withdraw fee line (horizontal)
        if (totalFee === null) {
            return
        }
        let { projectedCost: costAtFee } = projectFeeOnCostNonLinear(profitPoints, costPoints, totalFee)
        const ctx = u.ctx
        const x0 = u.valToPos(0, "x", true)
        let x1 = u.valToPos(costAtFee, "x", true)
        if (x1 > u.bbox.left + u.bbox.width) {
            x1 = u.bbox.left + u.bbox.width
        }

        const y0 = u.valToPos(0, "y", true)
        const y1 = u.valToPos(totalFee, "y", true)

        ctx.strokeStyle = "black"
        ctx.lineWidth = 2
        ctx.fillStyle = "rgba(0, 0, 0, 0.05)"
        ctx.setLineDash([20, 5, 5, 5])
        ctx.beginPath()
        ctx.moveTo(x1, y0)
        ctx.lineTo(x1, y1)
        ctx.lineTo(x0, y1)
        ctx.stroke()
        ctx.fillRect(x0, y0, x1 - x0, y1 - y0)

        // Reset canvas context
        ctx.setLineDash([])
        ctx.lineWidth = 2
    }

    useEffect(() => {
        if (profitPoints.length === 0 || costPoints.length === 0) {
            return
        }
        if (profitPoints.length !== costPoints.length) {
            return
        }
        // Data
        // https://github.com/leeoniya/uPlot/tree/master/docs#installation
        const X: number[] = [0]
        const Y: number[] = [0]
        let accumulatedProfit = 0
        let accumulatedCost = 0
        for (let i = 0; i < profitPoints.length; i++) {
            accumulatedProfit += profitPoints[i]
            accumulatedCost += costPoints[i]
            X.push(accumulatedCost)
            Y.push(accumulatedProfit)
        }
        setUplotData([X, Y])
    }, [profitPoints, costPoints, depositAmount])

    useEffect(() => {
        if (chartRef.current !== null && chartRef.current.hooks.draw !== undefined) {
            chartRef.current.hooks.draw[0] = drawPersonalizedProfit
        }
    }, [profitPoints, costPoints, depositAmount])

    useEffect(() => {
        const opts: uPlot.Options = {
            width: UPLOT_DEFAULT_WIDTH,
            height: UPLOT_DEFAULT_HEIGHT,
            scales: {
                y: {
                    range: (_, min) => {
                        if (min > 0) {
                            min = 0
                        }
                        return [min, maxProfit * 1.1]
                    }
                },
                x: {
                    range: (_, min) => {
                        if (min > 0) {
                            min = 0
                        }
                        return [min, maxCost * 1.1]
                    }
                }
            },
            legend: {
                show: false
            },
            padding: [null, 0, 0, 0],
            axes: [
                {
                    values: (_, values) => values.map(v => v.toFixed(2))
                }
            ],
            series: [
                {
                    show: true,
                    label: `Cost`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) : "N/A"),
                    width: 2
                },
                {
                    show: true,
                    points: {
                        show: true,
                        size: 5
                    },
                    spanGaps: true,
                    label: `Profit`,
                    value: (_, rawValue) => (rawValue ? rawValue.toFixed(2) : "N/A"),
                    stroke: "rgb(21, 21, 81)",
                    dash: [],
                    width: 2,
                    fill: "rgba(21, 21, 81, 0.08)",
                    fillTo: 0
                }
            ],
            hooks: {
                draw: [drawPersonalizedProfit, drawWithdrawFeeLine]
            }
        }
        setUplotOptions(opts)
    }, [maxProfit, maxCost])

    useEffect(() => {
        if (chartRef.current !== null && chartRef.current.hooks.draw !== undefined) {
            chartRef.current.hooks.draw[1] = drawWithdrawFeeLine
        }
    }, [totalFee])

    useEffect(() => {
        if (uplotOptions === null || uplotData === null || targetRef.current === null) {
            return
        }
        if (chartRef.current === null) {
            let plot = new uPlot(uplotOptions, uplotData, targetRef.current)
            plot.setSize({
                width: targetRef.current.clientWidth,
                height: UPLOT_DEFAULT_HEIGHT
            })
            chartRef.current = plot
            setIsLoading(false)
        } else {
            chartRef.current.setData(uplotData)
        }
    }, [uplotData, targetRef.current])

    useEffect(() => {
        if (uplotOptions === null || targetRef.current === null) {
            return
        }
        if (chartRef.current !== null) {
            chartRef.current.destroy()
            chartRef.current = null
        }
        let plot = new uPlot(uplotOptions, [], targetRef.current)
        plot.setSize({
            width: targetRef.current.clientWidth,
            height: UPLOT_DEFAULT_HEIGHT
        })
        chartRef.current = plot
        setIsLoading(false)
    }, [uplotOptions, targetRef.current])

    useEffect(() => {
        const resizePlot = () => {
            if (targetRef.current !== null && chartRef.current !== null) {
                chartRef.current.setSize({
                    width: targetRef.current.clientWidth,
                    height: UPLOT_DEFAULT_HEIGHT
                })
            }
        }
        void window.addEventListener("resize", resizePlot)
        return () => {
            void window.removeEventListener("resize", resizePlot)
        }
    }, [targetRef.current])

    if (uplotOptions === null || uplotData === null) {
        return null
    }

    return (
        <FlexRow
            style={{
                width: "100%"
            }}
        >
            <FlexCol
                style={{
                    width: "100%"
                }}
            >
                <div
                    style={{
                        width: "100%",
                        height: UPLOT_DEFAULT_HEIGHT
                    }}
                    ref={targetRef}
                ></div>
            </FlexCol>
        </FlexRow>
    )
}

const DynamicDepthAnalysisWidget: FC<{
    opportunityPoint: InstantOpportunity | null
    maxProfit: number
    maxCost: number
    depositAmount: number | null
}> = ({ opportunityPoint, maxProfit, maxCost, depositAmount }) => {
    if (opportunityPoint === null) {
        return <Empty description="N/A" image={Empty.PRESENTED_IMAGE_SIMPLE} />
    }

    return (
        <ProfitVsCostAnalysisChart
            profitPoints={opportunityPoint.ProfitPoints}
            costPoints={opportunityPoint.CostPoints}
            maxProfit={maxProfit}
            maxCost={maxCost}
            depositAmount={depositAmount}
            totalFee={getTotalFee(opportunityPoint, depositAmount)}
        />
    )
}

const UnifiedChartLegendOpportunityPart: FC<{
    exchangeOne: Exchange
    exchangeTwo: Exchange
    opportunityPoint: InstantOpportunity | null
    depositAmount: number | null
}> = ({ exchangeOne, exchangeTwo, opportunityPoint, depositAmount }) => {
    const [grossTotalProfit, setGrossTotalProfit] = useState<number | null>(null)
    const [grossTotalMargin, setGrossTotalMargin] = useState<number | null>(null)
    const [grossTotalCost, setGrossTotalCost] = useState<number | null>(null)

    const [totalFee, setTotalFee] = useState<number | null>(null)
    const [fixFee, setFixFee] = useState<number | null>(null)
    const [rateWithdrawFee, setRateWithdrawFee] = useState<number | null>(null)
    const [rateDepositFee, setRateDepositFee] = useState<number | null>(null)

    const [grossPersonalizedProfit, setGrossPersonalizedProfit] = useState<number | null>(null)
    const [grossPersonalizedMargin, setGrossPersonalizedMargin] = useState<number | null>(null)
    const [grossPersonalizedCost, setGrossPersonalizedCost] = useState<number | null>(null)

    const [netPersonalizedProfit, setNetPersonalizedProfit] = useState<number | null>(null)
    const [netPersonalizedMargin, setNetPersonalizedMargin] = useState<number | null>(null)

    useEffect(() => {
        if (opportunityPoint === null) {
            setGrossTotalProfit(null)
            setGrossTotalCost(null)
            setGrossTotalMargin(null)
            setGrossPersonalizedProfit(null)
            setGrossPersonalizedCost(null)
            setGrossPersonalizedMargin(null)
            setNetPersonalizedProfit(null)
            setNetPersonalizedMargin(null)
            return
        }

        let _totalFee = getTotalFee(opportunityPoint, depositAmount)
        setTotalFee(_totalFee)
        setFixFee(opportunityPoint.WithdrawFeeFixUSDTFrom)
        setRateWithdrawFee(opportunityPoint.WithdrawFeeRateFrom)
        setRateDepositFee(opportunityPoint.DepositFeeRateTo)

        setGrossTotalProfit(opportunityPoint.CumulativeProfit)
        setGrossTotalCost(opportunityPoint.CumulativeCost)
        setGrossTotalMargin((100 * opportunityPoint.CumulativeProfit) / opportunityPoint.CumulativeCost)

        if (depositAmount !== null) {
            let { projectedProfit, projectedCost } = projectDepositOnProfitNonLinear(
                opportunityPoint.ProfitPoints,
                opportunityPoint.CostPoints,
                depositAmount
            )
            setGrossPersonalizedProfit(projectedProfit)
            setGrossPersonalizedCost(projectedCost)
            setGrossPersonalizedMargin((100 * projectedProfit) / projectedCost)

            let _netProjectedProfit = projectedProfit - _totalFee
            setNetPersonalizedProfit(_netProjectedProfit)
            setNetPersonalizedMargin((100 * _netProjectedProfit) / projectedCost)
        } else {
            setGrossPersonalizedProfit(null)
            setGrossPersonalizedCost(null)
            setGrossPersonalizedMargin(null)

            setNetPersonalizedProfit(null)
            setNetPersonalizedMargin(null)
        }
    }, [opportunityPoint])

    return (
        <Col>
            <Row
                justify={{
                    xs: "center",
                    md: "center"
                }}
                gutter={[10, 10]}
            >
                <Col
                    style={{
                        borderRight: "1px solid #ccc"
                    }}
                >
                    <FlexCol
                        style={{
                            gap: 5
                        }}
                    >
                        <span>
                            <b>Profit</b>
                        </span>
                        <FlexRow
                            style={{
                                alignItems: "center",
                                gap: 5
                            }}
                        >
                            <LegendSquare color={ARBITRAGE_COLOR_TOTAL_PROFIT} />
                            <span>
                                G. total:{" "}
                                {grossTotalProfit !== null ?
                                    numberToFixedWithoutTrailingZeros(grossTotalProfit, 2)
                                :   <>N/A</>}{" "}
                                $
                            </span>
                        </FlexRow>
                        <FlexRow
                            style={{
                                alignItems: "center",
                                gap: 5
                            }}
                        >
                            <LegendSquare color={ARBITRAGE_COLOR_PERSONALIZED_PROFIT} />
                            <span>
                                G. personal:{" "}
                                {grossPersonalizedProfit !== null ?
                                    numberToFixedWithoutTrailingZeros(grossPersonalizedProfit, 2)
                                :   <>N/A</>}{" "}
                                $
                            </span>
                        </FlexRow>
                        <FlexRow
                            style={{
                                alignItems: "center",
                                gap: 5,
                                paddingTop: 3,
                                borderTop: "1px solid #ccc"
                            }}
                        >
                            <LegendSquare color={ARBITRAGE_COLOR_PERSONALIZED_PROFIT} />
                            <span>
                                <b>Net personal</b>:{" "}
                                {netPersonalizedProfit !== null ?
                                    numberToFixedWithoutTrailingZeros(netPersonalizedProfit, 2)
                                :   <>N/A</>}{" "}
                                $
                            </span>
                        </FlexRow>
                    </FlexCol>
                </Col>
                <Col xs={{ order: 2 }} md={{ order: 0 }}>
                    <FlexCol
                        style={{
                            gap: 5
                        }}
                    >
                        <span>
                            <b>Fee</b>
                        </span>
                        <FlexRow
                            style={{
                                alignItems: "center",
                                gap: 5
                            }}
                        >
                            <LegendSquare color={ARBITRAGE_COLOR_FEE} borderStyle="dashed" />
                            {totalFee !== null ?
                                <span>Total: {numberToFixedWithoutTrailingZeros(totalFee, 2)}$</span>
                            :   <span>N/A</span>}
                        </FlexRow>
                        <FlexRow
                            style={{
                                alignItems: "center",
                                gap: 5
                            }}
                        >
                            <LegendSquare color={ARBITRAGE_COLOR_FEE} borderStyle="dashed" />
                            {fixFee !== null ?
                                <span>Fix: {numberToFixedWithoutTrailingZeros(fixFee, 2)}$</span>
                            :   <span>N/A</span>}
                        </FlexRow>
                        {rateWithdrawFee !== null && rateWithdrawFee > 0 && (
                            <FlexRow
                                style={{
                                    alignItems: "center",
                                    gap: 5
                                }}
                            >
                                <LegendSquare color={ARBITRAGE_COLOR_FEE} borderStyle="dashed" />
                                <span>
                                    Rate @ {exchangeOne.Name}:{" "}
                                    {numberToFixedWithoutTrailingZeros(100 * rateWithdrawFee, 2)}%
                                </span>
                            </FlexRow>
                        )}
                        {rateDepositFee !== null && rateDepositFee > 0 && (
                            <FlexRow
                                style={{
                                    alignItems: "center",
                                    gap: 5
                                }}
                            >
                                <LegendSquare color={ARBITRAGE_COLOR_FEE} />
                                <span>
                                    Rate @ {exchangeTwo.Name}:{" "}
                                    {numberToFixedWithoutTrailingZeros(100 * rateDepositFee, 2)}%
                                </span>
                            </FlexRow>
                        )}
                    </FlexCol>
                </Col>
                <Col
                    style={{
                        borderLeft: "1px solid #ccc"
                    }}
                >
                    <FlexCol
                        style={{
                            gap: 5
                        }}
                    >
                        <span>
                            <b>Investment</b>
                        </span>
                        <FlexRow
                            style={{
                                alignItems: "center",
                                gap: 5
                            }}
                        >
                            <LegendSquare color={ARBITRAGE_COLOR_TOTAL_MARGIN} />
                            <span>
                                G. total:{" "}
                                {grossTotalCost !== null ?
                                    numberToFixedWithoutTrailingZeros(grossTotalCost, 2)
                                :   <>N/A</>}{" "}
                                $
                            </span>
                        </FlexRow>
                        <FlexRow
                            style={{
                                alignItems: "center",
                                gap: 5
                            }}
                        >
                            <LegendSquare color={ARBITRAGE_COLOR_PERSONALIZED_MARGIN} />
                            <span>
                                G. personal:{" "}
                                {grossPersonalizedCost !== null ?
                                    numberToFixedWithoutTrailingZeros(grossPersonalizedCost, 2)
                                :   <>N/A</>}{" "}
                                $
                            </span>
                        </FlexRow>
                        <FlexRow
                            style={{
                                alignItems: "center",
                                gap: 5,
                                paddingTop: 3,
                                borderTop: "1px solid #ccc"
                            }}
                        >
                            <LegendSquare color={ARBITRAGE_COLOR_PERSONALIZED_MARGIN} />
                            <span>
                                <b>Net ROI</b>:{" "}
                                {netPersonalizedMargin !== null ?
                                    numberToFixedWithoutTrailingZeros(netPersonalizedMargin, 2)
                                :   <>N/A</>}{" "}
                                %
                            </span>
                        </FlexRow>
                    </FlexCol>
                </Col>
            </Row>
        </Col>
    )
}

const UnifiedChartLegendDiscrepancyPart: FC<{
    exchangeOne: Exchange
    exchangeTwo: Exchange
    discrepancyPoint: ExchangePairCoinDiscrepancy | null
    shouldFastForward: boolean
    setShouldFastForward: (shouldFastForward: boolean) => void
}> = ({ exchangeOne, exchangeTwo, discrepancyPoint, shouldFastForward, setShouldFastForward }) => {
    const [timestamp, setTimestamp] = useState<Date | null>(null)

    const [priceFromNode, setPriceFromNode] = useState<ReactNode | null>(null)
    const [priceToNode, setPriceToNode] = useState<ReactNode | null>(null)
    const [priceNDigits, setPriceNDigits] = useState<number>(2)
    const [relativeDiscrepancy, setRelativeDiscrepancy] = useState<number>(0)
    const [absoluteDiscrepancy, setAbsoluteDiscrepancy] = useState<number>(0)

    const [symbolOKFrom, setSymbolOKFrom] = useState<boolean>(false)
    const [symbolOKTo, setSymbolOKTo] = useState<boolean>(false)
    const [withdrawOKFrom, setWithdrawOKFrom] = useState<boolean>(false)
    const [depositOKTo, setDepositOKTo] = useState<boolean>(false)

    useEffect(() => {
        if (discrepancyPoint === null) {
            return
        }
        setTimestamp(new Date(discrepancyPoint.OrderbookTimestamp))

        let absDiscrepancy =
            discrepancyPoint.Discrepancy.ExchangeTwoHBPrice - discrepancyPoint.Discrepancy.ExchangeOneLAPrice
        setAbsoluteDiscrepancy(absDiscrepancy)
        // let avgPrice = (discrepancyPoint.Discrepancy.ExchangeTwoHBPrice + discrepancyPoint.Discrepancy.ExchangeOneLAPrice)/2
        setRelativeDiscrepancy((100 * absDiscrepancy) / discrepancyPoint.Discrepancy.ExchangeOneLAPrice)

        let { priceFromNode, priceToNode, nDigits } = formatPrices(
            discrepancyPoint.Discrepancy.ExchangeOneLAPrice,
            discrepancyPoint.Discrepancy.ExchangeTwoHBPrice
        )

        setPriceFromNode(priceFromNode)
        setPriceToNode(priceToNode)
        setPriceNDigits(nDigits)

        if (discrepancyPoint.Metadata !== null) {
            setSymbolOKFrom(discrepancyPoint.Metadata.ExchangeOneSymbolOK)
            setSymbolOKTo(discrepancyPoint.Metadata.ExchangeTwoSymbolOK)
            setWithdrawOKFrom(discrepancyPoint.Metadata.ExchangeOneWithdrawOK)
            setDepositOKTo(discrepancyPoint.Metadata.ExchangeTwoDepositOK)
        }
    }, [discrepancyPoint])

    const durationSinceTimestamp = useMemo(() => {
        if (timestamp === null) {
            return null
        }
        let d = Date.now() - timestamp.getTime()
        if (d < 0) {
            d = 0
        }
        return d
    }, [timestamp])

    return (
        <Col
            style={{
                paddingRight: 20,
                marginRight: 20,
                borderRight: "1px solid #ccc"
            }}
        >
            <FlexRow
                style={{
                    alignItems: "center",
                    justifyContent: "space-between"
                }}
            >
                <FlexRow>
                    <Tooltip
                        placement="left"
                        overlay={
                            <FlexCol
                                style={{
                                    paddingRight: 10,
                                    borderRight: "1px solid #ccc",
                                    gap: 2
                                }}
                            >
                                <span>
                                    <span>
                                        Trading on <b>{exchangeOne.Name}</b>:{" "}
                                    </span>
                                    <span>
                                        {symbolOKFrom ?
                                            <CheckCircleFilled
                                                style={{
                                                    color: ARBITRAGE_COLOR_GREEN
                                                }}
                                            />
                                        :   <CloseCircleFilled />}
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        Trading on <b>{exchangeTwo.Name}</b>:{" "}
                                    </span>
                                    <span>
                                        {symbolOKTo ?
                                            <CheckCircleFilled
                                                style={{
                                                    color: ARBITRAGE_COLOR_GREEN
                                                }}
                                            />
                                        :   <CloseCircleFilled />}
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        Withdraw from <b>{exchangeOne.Name}</b>:{" "}
                                    </span>
                                    <span>
                                        {withdrawOKFrom ?
                                            <CheckCircleFilled
                                                style={{
                                                    color: ARBITRAGE_COLOR_GREEN
                                                }}
                                            />
                                        :   <CloseCircleFilled />}
                                    </span>
                                </span>
                                <span>
                                    <span>
                                        Deposit on <b>{exchangeTwo.Name}</b>:{" "}
                                    </span>
                                    <span>
                                        {depositOKTo ?
                                            <CheckCircleFilled
                                                style={{
                                                    color: ARBITRAGE_COLOR_GREEN
                                                }}
                                            />
                                        :   <CloseCircleFilled />}
                                    </span>
                                </span>
                            </FlexCol>
                        }
                    >
                        {symbolOKFrom && symbolOKTo && withdrawOKFrom && depositOKTo ?
                            <CheckCircleFilled
                                style={{
                                    color: ARBITRAGE_COLOR_GREEN
                                }}
                            />
                        :   <CloseCircleFilled />}
                    </Tooltip>
                    {durationSinceTimestamp !== null && timestamp !== null && (
                        <span>
                            {timestamp.toLocaleTimeString()} (<b>{formatDurationExact(durationSinceTimestamp)} ago</b>)
                        </span>
                    )}
                </FlexRow>
                <Button
                    type="default"
                    icon={
                        shouldFastForward ?
                            <FastForwardFilled
                                style={{
                                    color: "#4096ff" // blue
                                }}
                            />
                        :   <FastForwardOutlined />
                    }
                    onClick={() => setShouldFastForward(!shouldFastForward)}
                />
            </FlexRow>
            <PriceDiscrepancyWidget
                exchangeFromName={exchangeOne.Name}
                exchangeToName={exchangeTwo.Name}
                priceFromNode={priceFromNode}
                priceToNode={priceToNode}
                nDigits={priceNDigits}
                relativeDiscrepancy={relativeDiscrepancy}
                absoluteDiscrepancy={absoluteDiscrepancy}
            />
        </Col>
    )
}

export const UnifiedExchangePairCoinTimeseriesWidget: FC<{
    exchangeOne: Exchange
    exchangeTwo: Exchange
    coinID: Coin["ID"]
    networkID: Network["ID"] | null
    depositAmount: number | null
    isSimplifiedRender: boolean

    isPersistentStorage?: boolean
    persistentPeriodicOpportunityUUID?: string
    persistentTimeMargins?: number
}> = ({
    exchangeOne,
    exchangeTwo,
    coinID,
    networkID,
    depositAmount,
    isSimplifiedRender,
    isPersistentStorage,
    persistentPeriodicOpportunityUUID,
    persistentTimeMargins
}) => {
    const opportunityLastTimestampRef = useRef<dayjs.Dayjs | null>(null)
    const opportunityTimeseriesRef = useRef<OpportunityTimeseries | null>(null)

    const discrepancyTimeseriesRef = useRef<ExchangePairCoinDiscrepancyTimeseries | null>(null)
    const discrepancyLastTimestampRef = useRef<dayjs.Dayjs | null>(null)

    const [discrepancyPoint, setDiscrepancyPoint] = useState<ExchangePairCoinDiscrepancy | null>(null)
    const [opportunityPoint, setOpportunityPoint] = useState<InstantOpportunity | null>(null)

    const [maxProfit, setMaxProfit] = useState<number>(0)
    const [maxCost, setMaxCost] = useState<number>(0)

    const [minPrice, setMinPrice] = useState<number | null>(null)
    const [maxPrice, setMaxPrice] = useState<number | null>(null)

    const [currentTs, setCurrentTs] = useState<string | null>(null)
    const mooSyncRef = useRef<uPlot.SyncPubSub>(uPlot.sync("moo"))
    const refreshIntervalRef = useRef<NodeJS.Timer | null>(null)
    const [shouldFastForward, setShouldFastForward] = useState<boolean>(true)
    const [messageApi] = message.useMessage()
    const { callSecureAPI } = useAuthContext()

    const [isLoading, setIsLoading] = useState<boolean>(true)

    // Mouse-over point picker
    useEffect(() => {
        if (currentTs === null) {
            return
        }
        if (discrepancyTimeseriesRef.current === null) {
            return
        }
        let _discrepancyPoint = discrepancyTimeseriesRef.current.find(d => d.OrderbookTimestamp === currentTs)
        if (_discrepancyPoint === undefined) {
            return
        }
        setDiscrepancyPoint(_discrepancyPoint)

        if (opportunityTimeseriesRef.current === null) {
            return
        }
        for (let opp of opportunityTimeseriesRef.current) {
            if (opp.OrderbookTimestamp === _discrepancyPoint.OrderbookTimestamp) {
                setOpportunityPoint(opp)
                return
            }
        }
        setOpportunityPoint(null)
    }, [currentTs])

    const fetchOpportunityTimeseriesMutCB = useCallback(async () => {
        try {
            let { responseObject: buf } = await callSecureAPI<ArrayBuffer | null>(
                `/exchange/pair/coin/opportunity/timeseries` +
                    getExchangePairCoinTimeseriesParams(
                        exchangeOne.ID,
                        exchangeTwo.ID,
                        coinID,
                        opportunityLastTimestampRef.current,
                        null
                    ) +
                    `&is_persistent=${isPersistentStorage}&persistent_uuid=${persistentPeriodicOpportunityUUID}&persistent_margins=${persistentTimeMargins}`
            )
            if (buf === null) {
                return
            }
            let newOpportunityTimeseries = decodeInstantOpportunities(buf)

            let effectiveOpportunityTimeseries: OpportunityTimeseries = []

            // console.log("existing opportunity timeseries", opportunityTimeseriesRef.current)
            let nOmittedPoints = 0
            if (opportunityTimeseriesRef.current !== null) {
                for (let opp of opportunityTimeseriesRef.current) {
                    // let oppTs = dayjs(opp.OrderbookTimestamp)
                    // if (oppTs.isBefore(dayjs().subtract(30, "minute"))) {
                    //     nOmittedPoints++
                    //     continue
                    // }
                    effectiveOpportunityTimeseries.push(opp)
                }
            }
            // console.log('opportunity timeseries omitted points', nOmittedPoints)

            for (let opp of newOpportunityTimeseries) {
                let oppTs = dayjs(opp.OrderbookTimestamp)
                if (oppTs.isBefore(opportunityLastTimestampRef.current)) {
                    continue
                }
                effectiveOpportunityTimeseries.push(opp)
            }
            // console.log("effective opportunity timeseries length", effectiveOpportunityTimeseries.length)
            if (newOpportunityTimeseries.length > 0) {
                opportunityLastTimestampRef.current = dayjs(
                    newOpportunityTimeseries[newOpportunityTimeseries.length - 1].OrderbookTimestamp
                )
            }
            let _maxProfit = 0
            let _maxCost = 0
            for (let opp of effectiveOpportunityTimeseries) {
                _maxProfit = Math.max(_maxProfit, opp.CumulativeProfit)
                _maxCost = Math.max(_maxCost, opp.CumulativeCost)
            }
            // console.log(
            //     "Opportunity timeseries: \nnew:",
            //     newOpportunityTimeseries,
            //     "\neffective:",
            //     effectiveOpportunityTimeseries
            // )
            opportunityTimeseriesRef.current = effectiveOpportunityTimeseries
            setMaxProfit(_maxProfit)
            setMaxCost(_maxCost)
            setIsLoading(false)
        } catch (e: any) {
            console.error(e)
        }
    }, [
        exchangeOne.ID,
        exchangeTwo.ID,
        coinID,
        isPersistentStorage,
        persistentPeriodicOpportunityUUID,
        persistentTimeMargins
    ])

    const fetchDiscrepancyTimeseriesMutCB = useCallback(async () => {
        try {
            let { responseObject: buf } = await callSecureAPI<ArrayBuffer | null>(
                `/exchange/pair/coin/network/composite/timeseries` +
                    getExchangePairCoinNetworkTimeseriesParams(
                        exchangeOne.ID,
                        exchangeTwo.ID,
                        coinID,
                        networkID,
                        discrepancyLastTimestampRef.current,
                        null
                    ) +
                    `&is_persistent=${isPersistentStorage}&persistent_uuid=${persistentPeriodicOpportunityUUID}&persistent_margins=${persistentTimeMargins}`
            )
            if (buf === null) {
                return null
            }
            let newDiscrepancyTimeseries = decodeCompositeDiscrepancies(buf)
            if (newDiscrepancyTimeseries === null) {
                return
            }

            let effectiveDiscrepancyTimeseries: ExchangePairCoinDiscrepancyTimeseries = []
            let nOmittedPoints = 0
            if (discrepancyTimeseriesRef.current !== null) {
                for (let discr of discrepancyTimeseriesRef.current) {
                    let descrTs = dayjs(discr.OrderbookTimestamp)
                    // WTF
                    // if (descrTs.isBefore(dayjs().subtract(30, "minute"))) {
                    //     nOmittedPoints++
                    //     continue
                    // }
                    if (networkID !== null && discr.Metadata === null) {
                        discrepancyLastTimestampRef.current = descrTs
                        nOmittedPoints++
                        continue
                    }
                    effectiveDiscrepancyTimeseries.push(discr)
                }
            }
            // console.log('discrepancy timeseries omitted points', nOmittedPoints)
            for (let discr of newDiscrepancyTimeseries) {
                let descrTs = dayjs(discr.OrderbookTimestamp)
                if (descrTs.isBefore(discrepancyLastTimestampRef.current)) {
                    continue
                }
                effectiveDiscrepancyTimeseries.push(discr)
            }
            // console.log("effective discrepancy timeseries length", effectiveDiscrepancyTimeseries.length)
            if (newDiscrepancyTimeseries !== null && newDiscrepancyTimeseries.length > 0) {
                discrepancyLastTimestampRef.current = dayjs(
                    newDiscrepancyTimeseries[newDiscrepancyTimeseries.length - 1].OrderbookTimestamp
                )
            }
            // console.log(
            //     "Composite timeseries: \nnew:",
            //     newDiscrepancyTimeseries,
            //     "\neffective:",
            //     effectiveDiscrepancyTimeseries
            // )
            discrepancyTimeseriesRef.current = effectiveDiscrepancyTimeseries
            if (shouldFastForward && discrepancyTimeseriesRef.current !== null) {
                let lastIdx = discrepancyTimeseriesRef.current.length - 1
                let lastDiscrepancyPoint = discrepancyTimeseriesRef.current[lastIdx]
                // console.log("Fast-forwarding to the latest point on discrepancy point fetch", lastDiscrepancyPoint)
                setCurrentTs(lastDiscrepancyPoint.OrderbookTimestamp)
            }
            let _minPrice = Number.MAX_VALUE
            let _maxPrice = Number.MIN_VALUE
            for (let discr of effectiveDiscrepancyTimeseries) {
                _minPrice = Math.min(
                    _minPrice,
                    discr.Discrepancy.ExchangeOneLAPrice,
                    discr.Discrepancy.ExchangeTwoHBPrice,
                    discr.Discrepancy.ExchangeOneHBPrice,
                    discr.Discrepancy.ExchangeTwoLAPrice
                )
                _maxPrice = Math.max(
                    _maxPrice,
                    discr.Discrepancy.ExchangeOneLAPrice,
                    discr.Discrepancy.ExchangeTwoHBPrice,
                    discr.Discrepancy.ExchangeOneHBPrice,
                    discr.Discrepancy.ExchangeTwoLAPrice
                )
            }
            setMinPrice(_minPrice)
            setMaxPrice(_maxPrice)
            setIsLoading(false)
        } catch (e: any) {
            console.error(e)
            messageApi.error(e.message)
        }
    }, [
        exchangeOne.ID,
        exchangeTwo.ID,
        coinID,
        networkID,
        isPersistentStorage,
        persistentPeriodicOpportunityUUID,
        persistentTimeMargins
    ])

    useEffect(() => {
        discrepancyTimeseriesRef.current = null
        opportunityTimeseriesRef.current = null

        discrepancyLastTimestampRef.current = null
        opportunityLastTimestampRef.current = null

        setIsLoading(true)
    }, [persistentTimeMargins])

    useEffect(() => {
        Promise.all([fetchOpportunityTimeseriesMutCB(), fetchDiscrepancyTimeseriesMutCB()])
        if (isPersistentStorage === true) {
            return
        }
        // console.log("setting interval", exchangeOne.ID, exchangeTwo.ID, coinID, networkID)
        refreshIntervalRef.current = setInterval(async () => {
            await Promise.all([fetchOpportunityTimeseriesMutCB(), fetchDiscrepancyTimeseriesMutCB()])
        }, OPPORTUNITIES_AUTO_REFRESH_INTERVAL_MS)
        return () => {
            if (refreshIntervalRef.current !== null) {
                console.log("clearing interval", exchangeOne.ID, exchangeTwo.ID, coinID, networkID)
                clearInterval(refreshIntervalRef.current)
                refreshIntervalRef.current = null
            }
        }
    }, [
        exchangeOne.ID,
        exchangeTwo.ID,
        coinID,
        networkID,
        isPersistentStorage,
        persistentPeriodicOpportunityUUID,
        persistentTimeMargins
    ])

    useEffect(() => {
        return () => {
            discrepancyLastTimestampRef.current = null
            if (refreshIntervalRef.current !== null) {
                console.log("clearing interval on destroy")
                clearInterval(refreshIntervalRef.current)
                refreshIntervalRef.current = null
            }
        }
    }, [])

    useEffect(() => {
        if (shouldFastForward && discrepancyTimeseriesRef.current !== null) {
            let lastIdx = discrepancyTimeseriesRef.current.length - 1
            let lastDiscrepancyPoint = discrepancyTimeseriesRef.current[lastIdx]
            // console.log("Fast-forwarding to the latest point on shouldFastForward sideEffect", lastDiscrepancyPoint)
            setCurrentTs(lastDiscrepancyPoint.OrderbookTimestamp)
        }
    }, [shouldFastForward])

    if (isLoading) {
        return (
            <FlexRow
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%"
                }}
            >
                <Spin />
            </FlexRow>
        )
    }

    if (!isSimplifiedRender) {
        return (
            <>
                <Row
                    justify={{
                        md: "space-between",
                        xs: "center"
                    }}
                    style={{
                        paddingBottom: 20,
                        marginBottom: 20,
                        borderBottom: "1px solid #ccc"
                    }}
                >
                    <UnifiedChartLegendDiscrepancyPart
                        exchangeOne={exchangeOne}
                        exchangeTwo={exchangeTwo}
                        discrepancyPoint={discrepancyPoint}
                        shouldFastForward={shouldFastForward}
                        setShouldFastForward={setShouldFastForward}
                    />
                    {opportunityTimeseriesRef.current !== null && opportunityTimeseriesRef.current.length > 0 && (
                        <UnifiedChartLegendOpportunityPart
                            exchangeOne={exchangeOne}
                            exchangeTwo={exchangeTwo}
                            opportunityPoint={opportunityPoint}
                            depositAmount={depositAmount}
                        />
                    )}
                </Row>
                <Row
                    style={{
                        width: "100%"
                    }}
                >
                    <Col xs={24} lg={10}>
                        <FlexCol
                            style={{
                                gap: 0,
                                alignItems: "center"
                            }}
                        >
                            <span>
                                <i>Prices [$]</i>
                            </span>
                            <PriceTimeseriesChart
                                exchangeOne={exchangeOne}
                                exchangeTwo={exchangeTwo}
                                coinID={coinID}
                                mooSync={mooSyncRef.current}
                                timeseries={discrepancyTimeseriesRef.current}
                                setCurrentTs={setCurrentTs}
                                showLegend={true}
                            />
                        </FlexCol>
                    </Col>
                    <Col xs={0} lg={4}>
                        <FlexCol
                            style={{
                                gap: 0,
                                alignItems: "center"
                            }}
                        >
                            <span>
                                <i>Orderbooks</i>
                            </span>
                            <DoubleOrderbookAnalysisWidget
                                exchangeOne={exchangeOne}
                                exchangeTwo={exchangeTwo}
                                minPrice={minPrice}
                                maxPrice={maxPrice}
                                discrepancyPoint={discrepancyPoint}
                            />
                        </FlexCol>
                    </Col>
                    <Col xs={24} lg={10}>
                        <FlexCol
                            style={{
                                gap: 0,
                                alignItems: "center"
                            }}
                        >
                            <span>
                                <i>Relative discrepancy [%]</i>
                            </span>
                            <DiscrepancyTimeseriesChart
                                exchangeOne={exchangeOne}
                                exchangeTwo={exchangeTwo}
                                coinID={coinID}
                                mooSync={mooSyncRef.current}
                                timeseries={discrepancyTimeseriesRef.current}
                            />
                        </FlexCol>
                    </Col>

                    {opportunityTimeseriesRef.current !== null && opportunityTimeseriesRef.current.length > 0 && (
                        <>
                            <Col xs={24} lg={10}>
                                <FlexCol
                                    style={{
                                        gap: 0,
                                        alignItems: "center"
                                    }}
                                >
                                    <span>
                                        <i>Gross profit [$]</i>
                                    </span>
                                    <OpportunityProfitTimeseriesChart
                                        exchangeOne={exchangeOne}
                                        exchangeTwo={exchangeTwo}
                                        coinID={coinID}
                                        depositAmount={depositAmount}
                                        timeseries={opportunityTimeseriesRef.current}
                                        mooSync={mooSyncRef.current}
                                    />
                                </FlexCol>
                            </Col>
                            <Col xs={0} lg={4}>
                                <FlexCol
                                    style={{
                                        gap: 0,
                                        alignItems: "center"
                                    }}
                                >
                                    <span>
                                        <i>ROI</i>
                                    </span>
                                    <DynamicDepthAnalysisWidget
                                        opportunityPoint={opportunityPoint}
                                        maxProfit={maxProfit}
                                        maxCost={maxCost}
                                        depositAmount={depositAmount}
                                    />
                                </FlexCol>
                            </Col>
                            <Col xs={24} lg={10}>
                                <FlexCol
                                    style={{
                                        gap: 0,
                                        alignItems: "center"
                                    }}
                                >
                                    <span>
                                        <i>Investment [$]</i>
                                    </span>
                                    <OpportunityCostTimeseriesChart
                                        exchangeOne={exchangeOne}
                                        exchangeTwo={exchangeTwo}
                                        coinID={coinID}
                                        depositAmount={depositAmount}
                                        timeseries={opportunityTimeseriesRef.current}
                                        mooSync={mooSyncRef.current}
                                    />
                                </FlexCol>
                            </Col>
                        </>
                    )}
                </Row>
            </>
        )
    } else {
        return (
            <Row
                style={{
                    width: "100%"
                }}
            >
                <Col xs={24} lg={10}>
                    <FlexCol
                        style={{
                            gap: 0,
                            alignItems: "center"
                        }}
                    >
                        <span>
                            <i>Prices [$]</i>
                        </span>
                        <PriceTimeseriesChart
                            exchangeOne={exchangeOne}
                            exchangeTwo={exchangeTwo}
                            coinID={coinID}
                            mooSync={mooSyncRef.current}
                            timeseries={discrepancyTimeseriesRef.current}
                            setCurrentTs={setCurrentTs}
                            showLegend={false}
                        />
                    </FlexCol>
                </Col>
                {opportunityTimeseriesRef.current !== null && opportunityTimeseriesRef.current.length > 0 && (
                    <>
                        <Col xs={0} lg={4}>
                            <FlexCol
                                style={{
                                    gap: 0,
                                    alignItems: "center"
                                }}
                            >
                                <span>
                                    <i>ROI</i>
                                </span>
                                <DynamicDepthAnalysisWidget
                                    opportunityPoint={opportunityPoint}
                                    maxProfit={maxProfit}
                                    maxCost={maxCost}
                                    depositAmount={depositAmount}
                                />
                            </FlexCol>
                        </Col>
                        <Col xs={24} lg={10}>
                            <FlexCol
                                style={{
                                    gap: 0,
                                    alignItems: "center"
                                }}
                            >
                                <span>
                                    <i>Gross profit [$]</i>
                                </span>
                                <OpportunityProfitTimeseriesChart
                                    exchangeOne={exchangeOne}
                                    exchangeTwo={exchangeTwo}
                                    coinID={coinID}
                                    depositAmount={depositAmount}
                                    timeseries={opportunityTimeseriesRef.current}
                                    mooSync={mooSyncRef.current}
                                />
                            </FlexCol>
                        </Col>
                    </>
                )}
                <Col xs={24} lg={10}>
                    <FlexCol
                        style={{
                            gap: 0,
                            alignItems: "center"
                        }}
                    >
                        <span>
                            <i>cos(P, reVAP) [%]</i>
                        </span>
                        <OpportunityProfitVirtualAntiProfitRetrospectiveTimeseriesChart
                            exchangeOne={exchangeOne}
                            exchangeTwo={exchangeTwo}
                            coinID={coinID}
                            depositAmount={depositAmount}
                            timeseries={opportunityTimeseriesRef.current}
                            mooSync={mooSyncRef.current}
                        />
                    </FlexCol>
                </Col>
                <Col xs={24} lg={10} push={4}>
                    <FlexCol
                        style={{
                            gap: 0,
                            alignItems: "center"
                        }}
                    >
                        <span>
                            <i>cos(P, VAP) [%]</i>
                        </span>
                        <OpportunityProfitVirtualAntiProfitTimeseriesChart
                            exchangeOne={exchangeOne}
                            exchangeTwo={exchangeTwo}
                            coinID={coinID}
                            depositAmount={depositAmount}
                            timeseries={opportunityTimeseriesRef.current}
                            mooSync={mooSyncRef.current}
                        />
                    </FlexCol>
                </Col>
            </Row>
        )
    }
}
