import { FC, useEffect, useState, useCallback, useMemo } from "react"
import {
    Coin,
    CommonNetworkNameMap,
    Exchange,
    ExecutorControllerSide,
    WellKnownCoinFeeRatesMap,
    WellKnownNetworkBlockTimesMap
} from "../../types"
import { Button, Col, Row, Select, Typography, message as antdMessage, Collapse, Dropdown, Tooltip } from "antd"
import { FlexCol, FlexRow, Paper } from "../../common"
import {
    EyeOutlined,
    LinkOutlined,
    MenuOutlined,
    PlusCircleOutlined,
    ReloadOutlined,
    SwapOutlined
} from "@ant-design/icons"
import { loadExchangeAPIKeySet, fetchStoredExchangeNames } from "../../store"
import {
    CoinNetworkInfo,
    CoinInfo,
    SymbolInfo,
    OrderBook,
    IExchangeDriver,
    Amount,
    Price
} from "arbitrage-ts-exchange-apis/lib/exchangeAPIs/types"
import { useAuthContext } from "../../reducers/authReducer"
import { getExchangeHref, useQuery } from "../../utils"
import { useExecutorContext } from "../../reducers/executorReducer"
import BigDecimal from "js-big-decimal"

import { SupportedExchanges } from "arbitrage-ts-exchange-apis"
import { Redirect } from "react-router-dom"
import { getExchangeDriverByName } from "./utils"
import { WhitelistManagerModal } from "./whitelistManager"
import { APIControllerTab, ExchangeAPIControlWidget, ExchangeAPIKeysWidget } from "./exchangeApiController"
import { ARBITRAGE_COLOR_BUY, ARBITRAGE_COLOR_SELL } from "../../constants"
import { OpportunityLiveCalculator } from "./opportunityLiveCalculator"

export const ExecutorPage: FC = () => {
    const [exchanges, setExchanges] = useState<Exchange[] | null>(null)
    const [exchangesMap, setExchangesMap] = useState<Record<number, Exchange> | null>(null)
    const [coinsMap, setCoinsMap] = useState<Record<Coin["ID"], Coin> | null>(null)
    const [selectedExchangeName, setSelectedExchangeName] = useState<string | null>(null)

    const [commonNetworkNameMap, setCommonNetworkNameMap] = useState<CommonNetworkNameMap | null>(null)
    const [wellKnownCoinFeeRatesMap, setWellKnownCoinFeeRatesMap] = useState<WellKnownCoinFeeRatesMap | null>(null)
    const [wellKnownNetworkBlockTimesMap, setWellKnownNetworkBlockTimesMap] =
        useState<WellKnownNetworkBlockTimesMap | null>(null)

    const [storedExchangeNames, setStoredExchangeNames] = useState<Exchange["Name"][]>([])
    const [currentLeftmostExchangeDriver, setCurrentLeftExchangeDriver] = useState<IExchangeDriver | null>(null)
    const [currentRightmostExchangeDriver, setCurrentRightExchangeDriver] = useState<IExchangeDriver | null>(null)

    const [exchangeFromID, setExchangeFromID] = useState<Exchange["ID"] | null>(null)
    const [exchangeToID, setExchangeToID] = useState<Exchange["ID"] | null>(null)
    const [coinID, setCoinID] = useState<number | null>(null)

    const [exchangeFromOrderbook, setExchangeFromOrderbook] = useState<OrderBook | null>(null)
    const [exchangeToOrderbook, setExchangeToOrderbook] = useState<OrderBook | null>(null)

    const [balancesExternalTrigger, setBalancesExternalTrigger] = useState<number>(0)

    const [rawContent, setRawContent] = useState<any>({})
    const [abstractContent, setAbstractContent] = useState<any>({})

    const [whitelistManagerModalIsOpen, setWhitelistManagerModalIsOpen] = useState<boolean>(false)

    const query = useQuery()

    const { userInfo, callSecureAPI } = useAuthContext()
    const { executorLinkEnabled, setExecutorLinkEnabled, setExecutorCommonCoin } = useExecutorContext()
    const {
        executorLeftmostExchangeName,
        executorRightmostExchangeName,
        executorLeftmostActiveTab,
        executorRightmostActiveTab,
        setExecutorLeftmostExchangeName,
        setExecutorRightmostExchangeName,
        setExecutorLefmostActiveTab,
        setExecutorRightmostActiveTab
    } = useExecutorContext()

    const [currentLeftmostActiveTab, setCurrentLeftmostActiveTab] = useState<APIControllerTab | null>(null)
    const [currentRightmostActiveTab, setCurrentRightmostActiveTab] = useState<APIControllerTab | null>(null)

    const [isVisible, setIsVisible] = useState<boolean>(false)

    // storedExchangeNames
    useEffect(() => {
        fetchStoredExchangeNames().then(storedExchangeNames => {
            // console.log("storedExchangeNames", storedExchangeNames)
            setStoredExchangeNames(storedExchangeNames)
        })
    }, [])

    // exchanges and exchangesMap
    useEffect(() => {
        callSecureAPI<Exchange[] | null>("/exchanges").then(({ responseObject: exchanges }) => {
            setExchanges(exchanges)
            if (exchanges !== null) {
                const exchangesMap: Record<Exchange["ID"], Exchange> = {}
                exchanges.forEach(exchange => {
                    exchangesMap[exchange.ID] = exchange
                })
                setExchangesMap(exchangesMap)
            }
        })
    }, [])

    // coins and coinsMap
    useEffect(() => {
        callSecureAPI<Coin[] | null>("/coins").then(({ responseObject: coins }) => {
            if (coins !== null) {
                const coinsMap: Record<Coin["ID"], Coin> = {}
                coins.forEach(coin => {
                    coinsMap[coin.ID] = coin
                })
                setCoinsMap(coinsMap)
            }
        })
    }, [])

    // Common network name map
    useEffect(() => {
        callSecureAPI<CommonNetworkNameMap | null>("/common/network/name/map").then(
            ({ responseObject: commonNetworkNameMap }) => {
                setCommonNetworkNameMap(commonNetworkNameMap)
            }
        )
    }, [])

    // Well-known coin fee rates
    useEffect(() => {
        callSecureAPI<WellKnownCoinFeeRatesMap | null>("/well-known/coin/fee/rates").then(
            ({ responseObject: wellKnownFeeRates }) => {
                setWellKnownCoinFeeRatesMap(wellKnownFeeRates)
            }
        )
    }, [])

    // Well-known network block times map
    useEffect(() => {
        callSecureAPI<WellKnownNetworkBlockTimesMap | null>("/well-known/network/block/time/map").then(
            ({ responseObject: wellKnownNetworkBlockTimes }) => {
                setWellKnownNetworkBlockTimesMap(wellKnownNetworkBlockTimes)
            }
        )
    }, [])

    // Query params: exchangeFromID, exchangeToID, coinID
    useEffect(() => {
        const exchangeFromID = query.get("exchange_from_id")
        const exchangeToID = query.get("exchange_to_id")
        const coinID = query.get("coin_id")
        if (exchangeFromID === null || exchangeToID === null || coinID === null) {
            return
        }
        setExchangeFromID(parseInt(exchangeFromID))
        setExchangeToID(parseInt(exchangeToID))
        setCoinID(parseInt(coinID))
    }, [query])

    // Set currentLeftExchangeDriver based on queryParam exchangeFromID
    useEffect(() => {
        if (exchangesMap === null) {
            return
        }
        if (exchangeFromID === null) {
            if (executorLeftmostExchangeName !== null) {
                for (let exchangeID in exchangesMap) {
                    let exchange = exchangesMap[exchangeID]
                    if (exchange.Name === executorLeftmostExchangeName) {
                        setExchangeFromID(exchange.ID)
                        return
                    }
                }
            }
            return
        }
        let _exchange = exchangesMap[exchangeFromID]
        if (_exchange === undefined) {
            return
        }
        loadExchangeAPIKeySet(_exchange.Name).then(apiKeySet => {
            if (apiKeySet === null) {
                console.warn("ExecutorPage: could not load apiKeySet for exchange", _exchange.Name)
                return
            }
            let _exchangeDriver = getExchangeDriverByName(_exchange.Name, apiKeySet)
            setCurrentLeftExchangeDriver(_exchangeDriver)
        })
        setExecutorLeftmostExchangeName(_exchange.Name)
    }, [exchangeFromID, exchangesMap])

    // Set executorCommonCoin based on queryParam coinID
    useEffect(() => {
        if (coinsMap === null || coinID === null) {
            return
        }
        let _coin = coinsMap[coinID]
        if (_coin === undefined) {
            return
        }
        setExecutorCommonCoin(_coin.Name)
    }, [coinID, coinsMap])

    // Set currentRightExchangeDriver based on queryParam exchangeToID
    useEffect(() => {
        if (exchangesMap === null) {
            return
        }
        if (exchangeToID === null) {
            if (executorRightmostExchangeName !== null) {
                for (let exchangeID in exchangesMap) {
                    let exchange = exchangesMap[exchangeID]
                    if (exchange.Name === executorRightmostExchangeName) {
                        setExchangeToID(exchange.ID)
                        return
                    }
                }
            }
            return
        }
        let _exchange = exchangesMap[exchangeToID]
        if (_exchange === undefined) {
            return
        }
        loadExchangeAPIKeySet(_exchange.Name).then(apiKeySet => {
            if (apiKeySet === null) {
                console.warn("ExecutorPage: could not load apiKeySet for exchange", _exchange.Name)
                return
            }
            let _exchangeDriver = getExchangeDriverByName(_exchange.Name, apiKeySet)
            setCurrentRightExchangeDriver(_exchangeDriver)
        })
        setExecutorRightmostExchangeName(_exchange.Name)
    }, [exchangeToID, exchangesMap])

    // Leftmost tabs
    useEffect(() => {
        if (executorLeftmostActiveTab === null) {
            return
        }
        setCurrentLeftmostActiveTab(executorLeftmostActiveTab as APIControllerTab)
    }, [executorLeftmostActiveTab])

    // Rightmost tabs
    useEffect(() => {
        if (executorRightmostActiveTab === null) {
            return
        }
        setCurrentRightmostActiveTab(executorRightmostActiveTab as APIControllerTab)
    }, [executorRightmostActiveTab])

    const removeExchange = useCallback(
        (exchangeName: string) => {
            let _storedExchangeNames = storedExchangeNames.filter(name => {
                return name !== exchangeName
            })
            setStoredExchangeNames(_storedExchangeNames)
        },
        [storedExchangeNames]
    )

    const memoExFromName = useMemo(() => {
        if (exchangeFromID === null || exchangesMap === null) {
            return null
        }
        return exchangesMap[exchangeFromID].Name
    }, [exchangeFromID, exchangesMap])

    const memoExToName = useMemo(() => {
        if (exchangeToID === null || exchangesMap === null) {
            return null
        }
        return exchangesMap[exchangeToID].Name
    }, [exchangeToID, exchangesMap])

    const memoCoinName = useMemo(() => {
        if (coinID === null || coinsMap === null) {
            return null
        }
        return coinsMap[coinID].Name
    }, [coinID, coinsMap])

    const isOportunityDriven = useMemo(() => {
        return exchangeFromID !== null && exchangeToID !== null && coinID !== null
    }, [exchangeFromID, exchangeToID, coinID])

    if (
        exchanges === null ||
        exchangesMap === null ||
        commonNetworkNameMap === null ||
        coinsMap === null ||
        userInfo == null
    ) {
        return null
    }

    if (![238856039, 338397149, 742642429].includes(userInfo.id)) {
        antdMessage.error("Unauthorized for /executor")
        return <Redirect to="/" />
    }

    return (
        <FlexCol
            style={{
                minHeight: "80vh"
            }}
        >
            <Paper
            // style={{
            //     height: "60px"
            // }}
            >
                <WhitelistManagerModal
                    isOpen={whitelistManagerModalIsOpen}
                    setIsOpen={setWhitelistManagerModalIsOpen}
                />
                <FlexRow style={{ justifyContent: "space-between", width: "100%" }}>
                    <span style={{ fontSize: "1.5rem" }}>ArbitrageFlow Executor</span>
                    <FlexRow
                        style={{
                            gap: 5
                        }}
                    >
                        <Tooltip overlay="Parameters linking (COIN, NETWORK, ADDR, MEMO)">
                            <Button
                                icon={<LinkOutlined style={{ fontSize: 20 }} />}
                                type={executorLinkEnabled ? "primary" : "default"}
                                onClick={() => {
                                    setExecutorLinkEnabled(!executorLinkEnabled)
                                }}
                            />
                        </Tooltip>
                        <Tooltip overlay="API keys visibility">
                            <Button
                                icon={<EyeOutlined style={{ fontSize: 20 }} />}
                                type={isVisible ? "primary" : "default"}
                                onClick={() => {
                                    setIsVisible(!isVisible)
                                }}
                            />
                        </Tooltip>
                        <Tooltip overlay="Reload balances">
                            <Button
                                icon={<ReloadOutlined />}
                                onClick={() => setBalancesExternalTrigger(balancesExternalTrigger + 1)}
                            />
                        </Tooltip>
                        <Tooltip overlay="Swap">
                            <Button
                                icon={<SwapOutlined />}
                                onClick={() => {
                                    setCurrentLeftExchangeDriver(currentRightmostExchangeDriver)
                                    setExecutorLeftmostExchangeName(executorRightmostExchangeName)
                                    setCurrentRightExchangeDriver(currentLeftmostExchangeDriver)
                                    setExecutorRightmostExchangeName(executorLeftmostExchangeName)
                                }}
                            />
                        </Tooltip>
                        <Dropdown
                            menu={{
                                items: [
                                    {
                                        key: "1",
                                        label: "Manage Whitelists",
                                        style: { fontSize: "1.2rem" },
                                        onClick: () => {
                                            setWhitelistManagerModalIsOpen(true)
                                        }
                                    },
                                    {
                                        key: "2",
                                        label: "Consolidate USDT",
                                        style: { fontSize: "1.2rem" },
                                        onClick: () => {}
                                    }
                                ]
                            }}
                        >
                            <MenuOutlined
                                style={{
                                    cursor: "pointer",
                                    fontSize: "1.5rem"
                                }}
                            />
                        </Dropdown>
                    </FlexRow>
                </FlexRow>
            </Paper>
            <Collapse
                defaultActiveKey={isOportunityDriven ? ["2"] : ["1"]}
                items={[
                    {
                        key: "1",
                        label: "Exchanges",
                        children: (
                            <FlexCol>
                                <FlexRow
                                    style={{
                                        alignItems: "center"
                                    }}
                                >
                                    <Select
                                        style={{
                                            width: "100%"
                                        }}
                                        options={exchanges.map(exchange => ({
                                            label: exchange.Name,
                                            value: exchange.Name,
                                            disabled:
                                                storedExchangeNames.includes(exchange.Name) ||
                                                !SupportedExchanges.includes(exchange.Name)
                                        }))}
                                        value={selectedExchangeName}
                                        placeholder="Select an exchange to add API keys"
                                        onChange={value => {
                                            if (value === undefined) {
                                                return
                                            }
                                            console.log("selectedExchangeName", value)
                                            setSelectedExchangeName(value)
                                        }}
                                        showSearch
                                        filterOption={(input, option) => {
                                            if (option === undefined) {
                                                return false
                                            }
                                            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}
                                    />
                                    <Button
                                        icon={<PlusCircleOutlined />}
                                        onClick={() => {
                                            if (selectedExchangeName === null) {
                                                return
                                            }
                                            let _storedExchangeNames = Array.from(
                                                new Set([...storedExchangeNames, selectedExchangeName])
                                            )
                                            // console.log("storedExchangeNames", _storedExchangeNames)
                                            _storedExchangeNames.sort()
                                            setStoredExchangeNames(_storedExchangeNames)
                                        }}
                                    />
                                </FlexRow>
                                <Row gutter={[10, 10]}>
                                    {storedExchangeNames.map(exchangeName => {
                                        return (
                                            <Col key={exchangeName}>
                                                <ExchangeAPIKeysWidget
                                                    exchangeName={exchangeName}
                                                    setCurrentLeftExchangeDriver={v => {
                                                        setExecutorLeftmostExchangeName(v ? v.name() : null)
                                                        setCurrentLeftExchangeDriver(v)
                                                    }}
                                                    setCurrentRightExchangeDriver={v => {
                                                        setExecutorRightmostExchangeName(v ? v.name() : null)
                                                        setCurrentRightExchangeDriver(v)
                                                    }}
                                                    deleteExchange={removeExchange}
                                                    setRawContent={setRawContent}
                                                    setAbstractContent={setAbstractContent}
                                                    balancesExternalTrigger={balancesExternalTrigger}
                                                    isVisible={isVisible}
                                                />
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </FlexCol>
                        )
                    },
                    {
                        key: "2",
                        label: "Opportunity",
                        children: (
                            <>
                                {exchangeFromID !== null && exchangeToID !== null && coinID !== null && (
                                    <FlexCol>
                                        <Paper>
                                            <OpportunityLiveCalculator
                                                exchangeFromDriver={currentLeftmostExchangeDriver}
                                                exchangeToDriver={currentRightmostExchangeDriver}
                                                commonCoinName={memoCoinName}
                                                commonNetworkNameMap={commonNetworkNameMap}
                                            />
                                        </Paper>
                                    </FlexCol>
                                )}
                            </>
                        )
                    }
                ]}
            ></Collapse>
            {/* <ExecutorLinkerWidget /> */}

            <Row gutter={[10, 5]} align="stretch" justify="center">
                <Col xs={24} lg={currentRightmostExchangeDriver === null ? 24 : 12}>
                    <ExchangeAPIControlWidget
                        exchangeDriver={currentLeftmostExchangeDriver}
                        clearExchangeName={() => {
                            setExecutorLeftmostExchangeName(null)
                            setCurrentLeftExchangeDriver(null)
                        }}
                        commonNetworkNameMap={commonNetworkNameMap}
                        wellKnownCoinFeeRatesMap={wellKnownCoinFeeRatesMap}
                        wellKnownNetworkBlockTimesMap={wellKnownNetworkBlockTimesMap}
                        activeTabKey={currentLeftmostActiveTab ?? APIControllerTab.Withdraw}
                        setActiveTabKey={v => {
                            setCurrentLeftmostActiveTab(v)
                            setExecutorLefmostActiveTab(v)
                        }}
                        executorControllerSide={ExecutorControllerSide.Leftmost}
                    />
                </Col>
                <Col xs={24} lg={currentLeftmostExchangeDriver === null ? 24 : 12}>
                    <ExchangeAPIControlWidget
                        exchangeDriver={currentRightmostExchangeDriver}
                        clearExchangeName={() => {
                            setExecutorRightmostExchangeName(null)
                            setCurrentRightExchangeDriver(null)
                        }}
                        commonNetworkNameMap={commonNetworkNameMap}
                        wellKnownCoinFeeRatesMap={wellKnownCoinFeeRatesMap}
                        wellKnownNetworkBlockTimesMap={wellKnownNetworkBlockTimesMap}
                        activeTabKey={currentRightmostActiveTab ?? APIControllerTab.Deposit}
                        setActiveTabKey={v => {
                            setCurrentRightmostActiveTab(v)
                            setExecutorRightmostActiveTab(v)
                        }}
                        executorControllerSide={ExecutorControllerSide.Rightmost}
                    />
                </Col>
            </Row>
        </FlexCol>
    )
}
