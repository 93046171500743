import React, { FC } from "react"
import { FlexCol } from "../common"
import { getTelegramBotName } from "../constants"

export const LoginWidget: FC = () => {
    return (
        <FlexCol
            style={{
                alignItems: "center"
            }}
        >
            <div
                ref={el => {
                    if (!el) {
                        return
                    }
                    if (el.firstChild !== null) {
                        return
                    }
                    const script = document.createElement("script")
                    script.src = "https://telegram.org/js/telegram-widget.js?22"
                    script.async = true
                    script.setAttribute("data-telegram-login", getTelegramBotName())
                    script.setAttribute("data-size", "large")
                    script.setAttribute("data-auth-url", "/app/auth/telegram")
                    script.setAttribute("data-request-access", "write")
                    el.appendChild(script)
                }}
            />
        </FlexCol>
    )
}
