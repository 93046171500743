import { FC, useEffect } from "react"
import { useAuthContext } from "../reducers/authReducer"
import { Link, Redirect } from "react-router-dom"
import { Divider } from "antd"
import { FlexCol } from "../common"
import { callAPI } from "../api"

export const AuthRedirectPage: FC = () => {
    const { userInfo, authToken, setAuthToken, callSecureAPI } = useAuthContext()

    useEffect(() => {
        if (window.location.search === "") {
            return
        }
        const authToken = btoa(window.location.search.substring(1))
        window.history.pushState({}, "", window.location.pathname)
        callAPI("/me", authToken, setAuthToken).then(user => {
            if (user === null) {
                setAuthToken(null)
                return
            }
            setAuthToken(authToken)
        })
    }, [window.location.search])

    if (!authToken || !userInfo) {
        return <div>Not Authenticated</div>
    }

    return <Redirect to="/" />
}
