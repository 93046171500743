import React, { FC, useState } from "react"
import "./App.css"
import { Col, ConfigProvider, Layout, Menu, Row, theme } from "antd"
import { Content, Footer, Header } from "antd/es/layout/layout"
import { Link, Redirect, Route, Switch } from "react-router-dom"
import Sider from "antd/es/layout/Sider"
import { ControlOutlined, DashboardOutlined, HistoryOutlined, HomeOutlined, PieChartOutlined } from "@ant-design/icons"
import { ExecutorPage } from "./app/pages/executor/executorPage"
import { PeriodicOpportunitiesPage } from "./app/pages/periodicOpportunitiesPage"
import "react-json-view-lite/dist/index.css"
import { AuthRedirectPage } from "./app/pages/authRedirectPage"
import { UserAvatarWidget } from "./app/widgets/userAvatarWidget"
import { HomePage } from "./app/pages/homePage"
import { useMediaQuery } from "./app/utils"
import { FlexCol } from "./app/common"
import { OpportunityPage } from "./app/pages/periodicOpportunityPage"
import { DYORPage } from "./app/pages/dyorPage"
import { PersistentPeriodicOpportunitiesPage } from "./app/pages/persistentPeriodicOpportunitiesPage"

const SIDER_WIDTH = 250
const SIDER_COLLAPSED_WIDTH = 80

const Logo: FC = () => {
    return (
        <img
            src="/app/arbitrage-logo-dark-256.png"
            alt="logo"
            style={{
                padding: 10,
                height: "64px",
                width: "auto"
            }}
        />
    )
}
function App() {
    const [isCollapsed, setIsCollapsed] = useState(true)
    const isMobile = useMediaQuery()

    return (
        <ConfigProvider
            theme={{
                algorithm: theme.defaultAlgorithm,
                token: {
                    colorPrimary: "rgb(48, 60, 78)",
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: isMobile ? 14 : 15,
                    colorTextBase: "#000"
                },
                components: {
                    Collapse: {
                        headerBg: "#fff",
                        colorBgContainer: "rgb(245, 245, 245)"
                    },
                    InputNumber: {
                        addonBg: "#eee"
                    }
                }
            }}
        >
            <Layout hasSider>
                <Sider
                    style={
                        isMobile ?
                            {
                                overflow: "auto",
                                position: "fixed",
                                width: "100%",
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 1000
                            }
                        :   {
                                overflow: "auto",
                                height: "100vh",
                                position: "fixed",
                                left: 0,
                                top: 0,
                                bottom: 0
                            }
                    }
                    collapsible={isMobile ? false : true}
                    collapsed={isCollapsed}
                    onCollapse={collapsed => {
                        setIsCollapsed(collapsed)
                    }}
                    collapsedWidth={isMobile ? "100%" : undefined}
                    width={isMobile ? "100%" : SIDER_WIDTH}
                >
                    {!isMobile && (
                        <FlexCol
                            style={{
                                alignItems: "center"
                            }}
                        >
                            <Logo />
                            {!isCollapsed && <span style={{ color: "#eee" }}>Arbitrage Flow</span>}
                        </FlexCol>
                    )}
                    <div
                        style={{
                            height: "80%",
                            display: "flex",
                            flexDirection: isMobile ? "row" : "column",
                            justifyContent: "center"
                        }}
                    >
                        <Menu
                            theme="dark"
                            mode={isMobile ? "horizontal" : "vertical"}
                            style={{
                                width: "100%"
                            }}
                            defaultActiveFirst
                            items={[
                                {
                                    key: "menu-key-home",
                                    icon: <HomeOutlined />,
                                    label: <Link to="/home">Home</Link>
                                },
                                {
                                    key: "menu-key-dashboard",
                                    icon: <DashboardOutlined />,
                                    label: <Link to="/dashboard">Dashboard</Link>
                                },
                                {
                                    key: "menu-key-history",
                                    icon: <HistoryOutlined />,
                                    label: <Link to="/history">History</Link>
                                },
                                {
                                    key: "menu-key-dyor",
                                    icon: <PieChartOutlined />,
                                    label: <Link to="/dyor">DYOR</Link>
                                },
                                {
                                    key: "menu-key-executor",
                                    icon: <ControlOutlined />,
                                    label: <Link to="/executor">Executor</Link>
                                }
                            ]}
                        />
                    </div>
                </Sider>
                <Layout
                    className="site-layout"
                    style={{
                        marginLeft:
                            isMobile ? 0
                            : isCollapsed ? SIDER_COLLAPSED_WIDTH
                            : SIDER_WIDTH
                    }}
                >
                    <Header
                        className="header"
                        style={{
                            position: "sticky",
                            top: 0,
                            zIndex: 1000
                        }}
                    >
                        <Row
                            style={{ height: "100%", color: "#eee" }}
                            justify={isMobile ? "space-between" : "end"}
                            gutter={[10, 5]}
                        >
                            {isMobile && <Logo />}
                            <Col>
                                <UserAvatarWidget />
                            </Col>
                        </Row>
                    </Header>

                    <Content
                        style={{
                            margin: isMobile ? "0px 8px 0px" : "0px 16px 0px",
                            padding: isMobile ? 8 : 24,
                            overflow: "initial",
                            minHeight: isMobile ? "calc(100vh - 180px)" : "calc(100vh - 130px)"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                width: "100%"
                            }}
                        >
                            <div
                                style={{
                                    maxWidth: 1200,
                                    width: "100%"
                                }}
                            >
                                <Switch>
                                    <Route path="/home">
                                        <HomePage />
                                    </Route>
                                    <Route path="/dashboard">
                                        <PeriodicOpportunitiesPage />
                                    </Route>
                                    <Route path="/history">
                                        <PersistentPeriodicOpportunitiesPage />
                                    </Route>
                                    <Route path="/executor">
                                        <ExecutorPage />
                                    </Route>
                                    <Route path="/opportunity">
                                        <OpportunityPage />
                                    </Route>
                                    <Route path="/dyor">
                                        <DYORPage />
                                    </Route>
                                    <Route path="/auth/telegram">
                                        <AuthRedirectPage />
                                    </Route>

                                    {/* Default route */}
                                    <Route path="/">
                                        <Redirect to="/dashboard" />
                                    </Route>
                                </Switch>
                            </div>
                        </div>
                    </Content>

                    <Footer
                        style={{
                            textAlign: "center",
                            height: "70px",
                            marginBottom: isMobile ? 50 : 0
                        }}
                    >
                        <FlexCol
                            style={{
                                gap: 0
                            }}
                        >
                            <span>
                                ©{" "}
                                <a href="https://arbitrage.ngineera.com" target="_blank">
                                    ArbitrageFlow
                                </a>{" "}
                                {new Date().getFullYear()} — ver.{" "}
                                <b>{process.env.REACT_APP_VER != null && `${process.env.REACT_APP_VER}`}</b>{" "}
                            </span>
                            <span
                                style={{
                                    fontSize: 12,
                                    marginTop: 5
                                }}
                            >
                                <a href="https://arbitrage.ngineera.com/terms" target="_blank">
                                    Terms of Service
                                </a>
                            </span>
                        </FlexCol>
                    </Footer>
                </Layout>
            </Layout>
        </ConfigProvider>
    )
}

export default App
