"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublicWsStateMachineBase = void 0;
const async_mutex_1 = require("async-mutex");
const ws_1 = require("ws");
const utils_1 = require("./utils");
const isNode = () => {
    return typeof process === "object" && typeof window === "undefined";
};
const WEBSOCKET_OPEN = 1;
const WEBSOCKET_CLOSED = 3;
class PublicWsStateMachineBase {
    constructor() {
        this._name = "PublicWsStateMachineBase";
        this._wsUrl = "";
        this._ws = null;
        this._orderbookLock = new async_mutex_1.Mutex();
        this._currentBids = [];
        this._currentAsks = [];
        this._tOpen = 0;
        this._nMsg = 0;
        this._meanMsgPeriod = null;
        this._subscribedSymbolsStack = [];
        this._tLastMsg = 0;
        this.nbMsgsUpd = 0;
        this.tMsgSumUpd = 0;
        this.tPrevMsgUpd = 0;
        this.tMsgMeansUpd = [];
        this._defaultSubscribeUnsubscribeTimeout = 3e3;
    }
    _defaultMsgHandler(msg) {
        console.log(`${this._name}: WS: unhandled message: ${msg.data}`);
    }
    _resetCounters() {
        this._nMsg = 0;
        this.nbMsgsUpd = 0;
        this.tMsgSumUpd = 0;
        this.tPrevMsgUpd = 0;
        this.tMsgMeansUpd = [];
        this._meanMsgPeriod = null;
    }
    _processCounters() {
        this.nbMsgsUpd++;
        if (this.tPrevMsgUpd === 0) {
            this.tPrevMsgUpd = Date.now();
            return;
        }
        this.tMsgSumUpd += Date.now() - this.tPrevMsgUpd;
        this.tPrevMsgUpd = Date.now();
        if (this.nbMsgsUpd === 10) {
            let tMsgMean = this.tMsgSumUpd / this.nbMsgsUpd;
            this.tMsgMeansUpd.push(tMsgMean);
            this.tMsgSumUpd = 0;
            this.nbMsgsUpd = 0;
            this._meanMsgPeriod = (0, utils_1.getMeanWithStd)(this.tMsgMeansUpd);
            if (this.tMsgMeansUpd.length >= 10) {
                this.tMsgMeansUpd.shift();
            }
        }
    }
    async open() {
        let t0 = Date.now();
        if (isNode()) {
            this._ws = new ws_1.WebSocket(this._wsUrl);
        }
        else {
            this._ws = new WebSocket(this._wsUrl);
        }
        this._ws.onopen = () => {
            console.log(`${this._name}: WS: opened in ${Date.now() - t0}ms`);
        };
        this._ws.onclose = () => {
            let dt = Date.now() - this._tOpen;
            console.info(`${this._name}: WS: closed connection after ${dt / 1000}s`);
            this._ws = null;
        };
        this._ws.onerror = (err) => {
            console.log(`${this._name}: WS: error: ${err}`);
        };
        this._ws.onmessage = this._defaultMsgHandler;
        while (true) {
            if (this._ws.readyState === WEBSOCKET_OPEN) {
                break;
            }
            else if (this._ws.readyState === WEBSOCKET_CLOSED) {
                throw new Error(`${this._name}: WS: open: ws closed on open`);
            }
            await (0, utils_1.sleep)(10);
        }
        this._tOpen = Date.now();
    }
    close() {
        if (this._ws) {
            this._ws.close();
        }
        this._ws = null;
    }
    async copyCurrentOrderbook() {
        try {
            await this._orderbookLock.acquire();
            return {
                asks: this._currentAsks,
                bids: this._currentBids,
            };
        }
        finally {
            this._orderbookLock.release();
        }
    }
    async pingForever() {
        console.warn(`${this._name}: pingForever: not implemented: returning immediately..`);
    }
    healthString() {
        if (this._meanMsgPeriod === null) {
            return `${this._name}: WS: no message period info`;
        }
        let { count, mean, std } = this._meanMsgPeriod;
        let meanStr = mean.toFixed(1);
        let stdStr = std.toFixed(1);
        let lowerThirdStr = (mean - std).toFixed(1);
        let upperThirdStr = (mean + std).toFixed(1);
        let uptimeStr = (0, utils_1.formatDurationMs)(Date.now() - this._tOpen);
        let lastMsgDurationStr = (0, utils_1.formatDurationMs)(Date.now() - this._tLastMsg);
        let _latestSubscribedSymbol = this._subscribedSymbolsStack[this._subscribedSymbolsStack.length - 1];
        return `${this._name}: WS: uptime: ${uptimeStr}; currentSymbol: ${_latestSubscribedSymbol}; last msg received: ${lastMsgDurationStr}; total msgs: ${this._nMsg}; T: ${meanStr}±${stdStr} [${lowerThirdStr} — ${upperThirdStr}]`;
    }
}
exports.PublicWsStateMachineBase = PublicWsStateMachineBase;
