import { Tooltip } from "antd"
import { FC, ReactNode } from "react"
import { FlexRow, FlexCol } from "../common"
import { formattedNumberToFixedWithoutTrailingZeros, getNumberParts } from "../utils"
import { ARBITRAGE_COLOR_RED, ARBITRAGE_COLOR_RELATIVE_DISCREPANCY, ARBITRAGE_COLOR_SELL } from "../constants"

export const formatPrices = (
    priceFrom: number,
    priceTo: number
): {
    priceFromNode: ReactNode
    priceToNode: ReactNode
    nDigits: number
} => {
    let priceFromStr = priceFrom.toString()
    let priceToStr = priceTo.toString()

    let priceFromChildren: ReactNode[] = []
    let priceToChildren: ReactNode[] = []

    let maxLen = Math.max(priceFromStr.length, priceToStr.length)
    let nDigits = 0
    let pointFromIdx = 0
    let pointToIdx = 0
    let firstDifferentCharacterPassed = false

    let exFromExpIdx: number | undefined = undefined
    let exToExpIdx: number | undefined = undefined

    for (let i = 0; i < maxLen; i++) {
        let chFrom = priceFromStr[i]
        let chTo = priceToStr[i]

        if (pointFromIdx || pointToIdx) {
            nDigits++
        }

        if (chFrom === ".") {
            pointFromIdx = i
        }
        if (chTo === ".") {
            pointToIdx = i
        }

        if (chFrom === "e") {
            exFromExpIdx = i
        }
        if (chTo === "e") {
            exToExpIdx = i
        }

        if (chFrom !== chTo) {
            firstDifferentCharacterPassed = true
        }

        if (firstDifferentCharacterPassed) {
            let exFromSpan = (
                <span style={{ fontWeight: "bold", color: ARBITRAGE_COLOR_RED }}>
                    {chFrom === undefined ? "0" : chFrom}
                </span>
            )
            if (chFrom === undefined && exFromExpIdx !== undefined && i >= exFromExpIdx) {
                if (pointFromIdx === 0 && pointToIdx > 0) {
                    priceFromChildren.splice(
                        pointToIdx,
                        0,
                        <span style={{ fontWeight: "bold", color: ARBITRAGE_COLOR_RED }}>.</span>
                    )
                    pointFromIdx = pointToIdx
                    exFromExpIdx += 1
                } else {
                    priceFromChildren.splice(exFromExpIdx, 0, exFromSpan)
                }
            } else {
                priceFromChildren.push(exFromSpan)
            }
            let exToSpan = (
                <span style={{ fontWeight: "bold", color: ARBITRAGE_COLOR_SELL }}>
                    {chTo === undefined ? "0" : chTo}
                </span>
            )
            if (chTo === undefined && exToExpIdx !== undefined && i >= exToExpIdx) {
                priceToChildren.splice(exToExpIdx, 0, exToSpan)
            } else {
                priceToChildren.push(exToSpan)
            }
        } else {
            priceFromChildren.push(chFrom)
            priceToChildren.push(chTo)
        }
    }

    if (exFromExpIdx !== undefined && exToExpIdx !== undefined) {
        nDigits = Math.max(Math.abs(getNumberParts(priceFrom).exponent), Math.abs(getNumberParts(priceTo).exponent))
        nDigits = Math.min(nDigits, 16)
    }

    return {
        nDigits,
        priceFromNode: <>{priceFromChildren}</>,
        priceToNode: <>{priceToChildren}</>
    }
}

export const PriceDiscrepancyWidget: FC<{
    exchangeFromName: string
    exchangeToName: string
    priceFromNode: ReactNode
    priceToNode: ReactNode
    relativeDiscrepancy: number
    absoluteDiscrepancy: number
    nDigits: number
}> = ({
    exchangeFromName,
    exchangeToName,
    priceFromNode,
    priceToNode,
    relativeDiscrepancy,
    absoluteDiscrepancy,
    nDigits
}) => {
    return (
        <FlexRow
            style={{
                justifyContent: "center",
                alignItems: "center",
                gap: 2
            }}
        >
            <FlexCol
                style={{
                    gap: 0
                }}
            >
                <FlexRow
                    style={{
                        justifyContent: "space-between",
                        alignItems: "end"
                    }}
                >
                    <span>
                        <Tooltip overlay="Highest Bid" placement="top">
                            HB
                        </Tooltip>{" "}
                        on <b>{exchangeToName}</b>:
                    </span>
                    <span style={{}}>{priceToNode}</span>
                </FlexRow>
                <FlexRow
                    style={{
                        justifyContent: "space-between",
                        alignItems: "end"
                    }}
                >
                    <span>
                        <Tooltip overlay="Lowest Ask" placement="bottom">
                            LA
                        </Tooltip>{" "}
                        on <b>{exchangeFromName}</b>:
                    </span>
                    <span style={{}}>{priceFromNode}</span>
                </FlexRow>
            </FlexCol>
            <div
                style={{
                    borderLeft: "1px solid black",
                    margin: 10,
                    padding: 5,
                    fontSize: 14
                }}
            >
                <span>
                    {nDigits > 8 ?
                        parseFloat(
                            formattedNumberToFixedWithoutTrailingZeros(absoluteDiscrepancy.toFixed(nDigits))
                        ).toString()
                    :   formattedNumberToFixedWithoutTrailingZeros(absoluteDiscrepancy.toFixed(nDigits))}{" "}
                    (
                    <b style={{ color: ARBITRAGE_COLOR_RELATIVE_DISCREPANCY }}>
                        {relativeDiscrepancy.toFixed(2)}&nbsp;%
                    </b>
                    )
                </span>
            </div>
        </FlexRow>
    )
}
