"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewPoloniexExchangeDriver = void 0;
const crypto_js_1 = require("crypto-js");
const types_1 = require("./types");
const utils_1 = require("./utils");
// https://api-docs.poloniex.com/spot/api/private/wallet#wallets-activity-records
// GET https://api.poloniex.com/wallets/activity
var PoloniexDepositStatus;
(function (PoloniexDepositStatus) {
    PoloniexDepositStatus["Pending"] = "PENDING";
    PoloniexDepositStatus["Completed"] = "COMPLETED";
})(PoloniexDepositStatus || (PoloniexDepositStatus = {}));
var PoloniexWithdrawStatus;
(function (PoloniexWithdrawStatus) {
    PoloniexWithdrawStatus["Processing"] = "PROCESSING";
    PoloniexWithdrawStatus["AwaitingApproval"] = "AWAITING APPROVAL";
    PoloniexWithdrawStatus["Completed"] = "COMPLETED";
    PoloniexWithdrawStatus["CompleteError"] = "COMPLETE ERROR";
})(PoloniexWithdrawStatus || (PoloniexWithdrawStatus = {}));
// https://api-docs.poloniex.com/spot/api/private/order#create-order
// POST https://api.poloniex.com/orders
// {
//   "symbol": "BTC_USDT",
//   "type": "LIMIT",
//   "quantity": "100",
//   "side": "BUY",
//   "price": "40000.50000",
//   "timeInForce": "IOC",
//   "clientOrderId": "1234Abc"
// }
var PoloniexAccountType;
(function (PoloniexAccountType) {
    PoloniexAccountType["Spot"] = "SPOT";
    PoloniexAccountType["Margin"] = "MARGIN";
})(PoloniexAccountType || (PoloniexAccountType = {}));
var PoloniexOrderType;
(function (PoloniexOrderType) {
    PoloniexOrderType["Limit"] = "LIMIT";
    PoloniexOrderType["Market"] = "MARKET";
    PoloniexOrderType["LimitMaker"] = "LIMIT_MAKER";
})(PoloniexOrderType || (PoloniexOrderType = {}));
var PoloniexOrderSide;
(function (PoloniexOrderSide) {
    PoloniexOrderSide["Buy"] = "BUY";
    PoloniexOrderSide["Sell"] = "SELL";
})(PoloniexOrderSide || (PoloniexOrderSide = {}));
var PoloniexTimeInForce;
(function (PoloniexTimeInForce) {
    PoloniexTimeInForce["GTC"] = "GTC";
    PoloniexTimeInForce["IOC"] = "IOC";
    PoloniexTimeInForce["FOK"] = "FOK";
})(PoloniexTimeInForce || (PoloniexTimeInForce = {}));
var PoloniexOrderState;
(function (PoloniexOrderState) {
    PoloniexOrderState["New"] = "NEW";
    PoloniexOrderState["PartiallyFilled"] = "PARTIALLY_FILLED";
    PoloniexOrderState["Filled"] = "FILLED";
    PoloniexOrderState["PendingCancel"] = "PENDING_CANCEL";
    PoloniexOrderState["PartiallyCancelled"] = "PARTIALLY_CANCELLED";
    PoloniexOrderState["Canceled"] = "CANCELED";
    PoloniexOrderState["Failed"] = "FAILED";
})(PoloniexOrderState || (PoloniexOrderState = {}));
var PoloniexOrderCancelReason;
(function (PoloniexOrderCancelReason) {
    PoloniexOrderCancelReason[PoloniexOrderCancelReason["NotApplicable"] = 0] = "NotApplicable";
    PoloniexOrderCancelReason[PoloniexOrderCancelReason["AsRequestedByUser"] = 1] = "AsRequestedByUser";
    PoloniexOrderCancelReason[PoloniexOrderCancelReason["DueToBreachOfControlsInMatchingEngine"] = 1000] = "DueToBreachOfControlsInMatchingEngine";
    PoloniexOrderCancelReason[PoloniexOrderCancelReason["DueToSelfTrade"] = 1004] = "DueToSelfTrade";
    PoloniexOrderCancelReason[PoloniexOrderCancelReason["ExceedSlippageTolerance"] = 1012] = "ExceedSlippageTolerance";
    PoloniexOrderCancelReason[PoloniexOrderCancelReason["DueToMarginLiquidation"] = 2000] = "DueToMarginLiquidation";
    PoloniexOrderCancelReason[PoloniexOrderCancelReason["DueToMarginThresholdBreach"] = 2001] = "DueToMarginThresholdBreach";
    PoloniexOrderCancelReason[PoloniexOrderCancelReason["DueToSymbolMarkedAsOffline"] = 2002] = "DueToSymbolMarkedAsOffline";
})(PoloniexOrderCancelReason || (PoloniexOrderCancelReason = {}));
var PoloniexOrderSource;
(function (PoloniexOrderSource) {
    PoloniexOrderSource["API"] = "API";
    PoloniexOrderSource["App"] = "APP";
    PoloniexOrderSource["Web"] = "WEB";
})(PoloniexOrderSource || (PoloniexOrderSource = {}));
const plainAPICallPoloniex = async (method, path, queryParams) => {
    let url = `https://api.poloniex.com${path}?${queryParams.toString()}`;
    let relayedURL = `${types_1.RELAY_ENDPOINT}?url=${encodeURIComponent(url)}`;
    let resp = await fetch(relayedURL, {
        method: method,
        mode: "cors",
        keepalive: true,
    });
    if (resp.ok) {
        return await resp.json();
    }
    else {
        console.warn("POLONIEX API call NOK: ", resp.status, resp.statusText);
        if (resp.status >= 400) {
            const err = (await resp.json());
            throw new Error(err.message);
        }
    }
};
const signedAPICallPoloniex = async (apiKeys, method, path, queryParams, body) => {
    let signTimestampMs = Date.now();
    let msg = method + "\n" + path + "\n";
    let paramValue = "";
    if (method === "GET" || method === "DELETE") {
        queryParams.set("signTimestamp", signTimestampMs.toString());
        queryParams.sort();
        paramValue = queryParams.toString();
    }
    else {
        if (body !== undefined) {
            paramValue +=
                "requestBody=" +
                    body +
                    "&signTimestamp=" +
                    signTimestampMs.toString();
        }
    }
    msg += paramValue;
    // if (body !== undefined) {
    //     queryParams.set("requestBody", body)
    //     queryParams.sort()
    //     // msg +=
    //     //     "requestBody=" +
    //     //     body +
    //     //     "&signTimestamp=" +
    //     //     signTimestampMs.toString()
    // }
    // msg += queryParams.toString()
    // // if (queryParams.toString() !== "") {
    // // }
    // queryParams.delete("signTimestamp")
    // queryParams.delete("requestBody")
    let signature = (0, crypto_js_1.HmacSHA256)(msg, apiKeys.secretKey);
    let signatureBase64 = signature.toString(crypto_js_1.enc.Base64);
    // console.log("POLINIEX: query to sign:", msg)
    // console.log(
    //     "POLONIEX: path:",
    //     path,
    //     "queryParams:",
    //     queryParams.toString(),
    //     "body:",
    //     body
    // )
    // console.log("POLONIEX: signature:", signature)
    let headers = {
        key: apiKeys.apiKey,
        signatureMethod: "hmacSHA256",
        signatureVersion: "1",
        signTimestamp: signTimestampMs.toString(),
        signature: signatureBase64,
    };
    if (body !== undefined) {
        headers["Content-Type"] = "application/json";
    }
    let url = `https://api.poloniex.com${path}?${queryParams.toString()}`;
    let relayURL = `${types_1.RELAY_ENDPOINT}?url=${encodeURIComponent(url)}`;
    // console.log("POLONIEX: relayURL:", relayURL)
    let resp = await fetch(relayURL, {
        method: method,
        headers: headers,
        body: body,
        keepalive: true,
    });
    if (resp.ok) {
        let respText = await resp.text();
        if (respText === "") {
            return {};
        }
        try {
            return JSON.parse(respText);
        }
        catch (e) {
            throw new Error(`POLONIEX: failed to parse responseText: ${e.message}: ${respText}`);
        }
    }
    else {
        console.log("POLONIEX signed API call NOK: ", resp.status, resp.statusText);
        if (resp.status >= 400) {
            const err = (await resp.json());
            throw err;
        }
    }
};
const poloniexGetSpotBalances = async (apiKeys) => {
    let path = "/accounts/balances";
    let queryParams = new URLSearchParams();
    return await signedAPICallPoloniex(apiKeys, "GET", path, queryParams);
};
const poloniexGetCoins = async () => {
    let path = `/v2/currencies`;
    let queryParams = new URLSearchParams();
    return await plainAPICallPoloniex(`GET`, path, queryParams);
};
const poloniexGetCoin = async (coinName) => {
    let path = `/v2/currencies/${coinName}`;
    let queryParams = new URLSearchParams();
    return await plainAPICallPoloniex(`GET`, path, queryParams);
};
const poloniexGetSymbols = async (symbolName) => {
    let path = "/markets";
    if (symbolName !== null) {
        path += `/${symbolName}`;
    }
    let queryParams = new URLSearchParams();
    return await plainAPICallPoloniex(`GET`, path, queryParams);
};
const poloniexGetWithdrawHistory = async (apiKeys) => {
    let path = `/wallets/activity`;
    let queryParams = new URLSearchParams();
    queryParams.set("start", "0");
    queryParams.set("end", Date.now().toString());
    queryParams.set("activityType", "withdrawals");
    return await signedAPICallPoloniex(apiKeys, `GET`, path, queryParams);
};
const poloniexGetDepositHistory = async (apiKeys) => {
    let path = `/wallets/activity`;
    let queryParams = new URLSearchParams();
    queryParams.set("start", "0");
    queryParams.set("end", Date.now().toString());
    queryParams.set("activityType", "deposits");
    return await signedAPICallPoloniex(apiKeys, `GET`, path, queryParams);
};
const poloniexWithdrawApply = async (apiKeys, coinName, networkName, address, memo, amount) => {
    let path = `/v2/wallets/withdraw`;
    let queryParams = new URLSearchParams();
    let bodyObj = {
        coin: coinName,
        network: networkName,
        amount: amount,
        address: address,
    };
    if (memo !== null) {
        bodyObj["paymentId"] = memo;
    }
    let body = JSON.stringify(bodyObj);
    return await signedAPICallPoloniex(apiKeys, `POST`, path, queryParams, body);
};
const poloniexGetSymbolOrderbook = async (symbolName) => {
    let path = `/markets/${symbolName}/orderBook`;
    let queryParams = new URLSearchParams();
    queryParams.set("limit", "10");
    return await plainAPICallPoloniex(`GET`, path, queryParams);
};
const poloniexGetOrdersHistory = async (apiKeys, symbolName) => {
    let path = `/orders/history`;
    let queryParams = new URLSearchParams();
    queryParams.set("symbol", symbolName);
    return await signedAPICallPoloniex(apiKeys, `GET`, path, queryParams);
};
const poloniexGetOpenOrders = async (apiKeys, symbolName) => {
    let path = `/orders`;
    let queryParams = new URLSearchParams();
    queryParams.set("symbol", symbolName);
    return await signedAPICallPoloniex(apiKeys, `GET`, path, queryParams);
};
const poloniexPlaceOrder = async (apiKeys, symbolName, orderSide, orderType, timeInForce, price, baseQty, quoteQty, allowBorrow) => {
    let path = `/orders`;
    let queryParams = new URLSearchParams();
    let body = JSON.stringify({
        symbol: symbolName,
        type: orderType,
        side: orderSide,
        timeInForce: timeInForce,
        quantity: baseQty,
        amount: quoteQty,
        price: price,
        allowBorrow: allowBorrow,
    });
    // console.log("POLONIEX: placeOrder:", path, body)
    return await signedAPICallPoloniex(apiKeys, `POST`, path, queryParams, body);
};
const poloniexCancelOrder = async (apiKeys, orderId) => {
    let path = `/orders/${orderId}`;
    let queryParams = new URLSearchParams();
    return await signedAPICallPoloniex(apiKeys, `DELETE`, path, queryParams);
};
const poloniexGetCurrencyAddress = async (apiKeys, coinName, networkName) => {
    let path = "/wallets/address";
    let queryParams = new URLSearchParams();
    // queryParams.set("currency", coinName)
    let body = JSON.stringify({
        currency: networkName,
    });
    console.log("POLONIEX: getCurrencyAddress:", path, body);
    return await signedAPICallPoloniex(apiKeys, `POST`, path, queryParams, body);
};
const poloniexTransferAccounts = async (apiKeys, coinName, amount, fromAccount, toAccount) => {
    let path = "/accounts/transfer";
    let queryParams = new URLSearchParams();
    let body = JSON.stringify({
        currency: coinName,
        amount: amount,
        fromAccount: fromAccount,
        toAccount: toAccount,
    });
    return await signedAPICallPoloniex(apiKeys, `POST`, path, queryParams, body);
};
const poloniexGetMarginBalances = async (apiKeys, coinName) => {
    let path = "/margin/borrowStatus";
    let queryParams = new URLSearchParams();
    if (coinName !== null) {
        queryParams.set("currency", coinName);
    }
    return await signedAPICallPoloniex(apiKeys, `GET`, path, queryParams);
};
const poloniexGetMarginMaxBuySellAmounts = async (apiKeys, symbolName) => {
    let path = "/margin/maxSize";
    let queryParams = new URLSearchParams();
    queryParams.set("symbol", symbolName);
    return await signedAPICallPoloniex(apiKeys, `GET`, path, queryParams);
};
const poloniexGetMarginAccountInfo = async (apiKeys) => {
    let path = "/margin/accountMargin";
    let queryParams = new URLSearchParams();
    return await signedAPICallPoloniex(apiKeys, `GET`, path, queryParams);
};
const _poloniexOrderToOrderInfo = (order) => {
    let type = types_1.OrderType.Limit;
    if (order.type === PoloniexOrderType.Market) {
        type = types_1.OrderType.Market;
    }
    let side = types_1.OrderSide.Buy;
    if (order.side === PoloniexOrderSide.Sell) {
        side = types_1.OrderSide.Sell;
    }
    let isOpen = false;
    let statusSeverity = types_1.StatusSeverity.Neutral;
    switch (order.state) {
        case PoloniexOrderState.New:
            statusSeverity = types_1.StatusSeverity.Neutral;
            isOpen = true;
            break;
        case PoloniexOrderState.PartiallyFilled:
            statusSeverity = types_1.StatusSeverity.Warning;
            isOpen = true;
            break;
        case PoloniexOrderState.Filled:
            statusSeverity = types_1.StatusSeverity.Success;
            break;
        case PoloniexOrderState.PartiallyCancelled:
        case PoloniexOrderState.Canceled:
        case PoloniexOrderState.PendingCancel:
            statusSeverity = types_1.StatusSeverity.Error;
            break;
        case PoloniexOrderState.Failed:
            statusSeverity = types_1.StatusSeverity.Error;
            break;
    }
    let timeInForce = types_1.OrderTimeInForce.GoodTilCancelled;
    if (order.timeInForce === PoloniexTimeInForce.IOC) {
        timeInForce = types_1.OrderTimeInForce.ImmediateOrCancel;
    }
    else if (order.timeInForce === PoloniexTimeInForce.FOK) {
        timeInForce = types_1.OrderTimeInForce.FillOrKill;
    }
    return {
        symbolName: order.symbol,
        orderId: order.id,
        timestamp: order.createTime,
        side: side,
        type: type,
        timeInForce: timeInForce,
        price: order.price,
        executedPrice: order.avgPrice,
        baseQty: order.quantity,
        executedBaseQty: order.filledQuantity,
        quoteQty: order.amount,
        executedQuoteQty: order.filledAmount,
        isOpen: isOpen,
        status: order.state,
        statusSeverity: statusSeverity,
    };
};
const _poloniexParseSymbols = (resp) => {
    let symbols = [];
    for (let symbol of resp) {
        let priceMin = symbol.symbolTradeLimit.lowestAsk;
        if (priceMin === "0") {
            priceMin = null;
        }
        let priceMax = symbol.symbolTradeLimit.highestBid;
        if (priceMax === "0") {
            priceMax = null;
        }
        symbols.push({
            symbolName: symbol.symbol.replace("_", ""),
            ownName: symbol.symbol,
            baseAsset: symbol.baseCurrencyName,
            quoteAsset: symbol.quoteCurrencyName,
            priceMin: priceMin,
            priceMax: priceMax,
            priceTick: Math.pow(10, -symbol.symbolTradeLimit.priceScale).toFixed(symbol.symbolTradeLimit.priceScale),
            lotMin: symbol.symbolTradeLimit.minQuantity,
            lotMax: null,
            lotStep: Math.pow(10, -symbol.symbolTradeLimit.quantityScale).toFixed(symbol.symbolTradeLimit.quantityScale),
            limitNotionalMin: symbol.symbolTradeLimit.minAmount,
            limitNotionalMax: null,
            marketNotionalMin: symbol.symbolTradeLimit.minAmount,
            marketNotionalMax: null,
            isAvailable: symbol.state === "NORMAL",
            status: symbol.state,
            takerFeeRate: "0.0155", // 0.155%
        });
    }
    return symbols;
};
const NewPoloniexExchangeDriver = (apiKeys) => {
    let name = "POLONIEX";
    let cachedCoins = (0, utils_1.newCachedEntity)(utils_1.DEFAULT_COINS_CACHE_TIME_SEC);
    let cachedSymbols = (0, utils_1.newCachedEntity)(utils_1.DEFAULT_COINS_CACHE_TIME_SEC);
    return {
        name() {
            return name;
        },
        config() {
            return {
                withdrawalIsImmediate: true,
                withdrawalWhitelist: false,
                marginTradeTypes: [types_1.MarginTradeType.Cross],
                marginAccountMode: types_1.MarginAccountMode.Unified,
                timeInForceIsSupported: true,
            };
        },
        async init() {
            throw new Error("init not implemented.");
        },
        async checkAPI() {
            await poloniexGetSpotBalances(apiKeys);
            return {
                raw: {},
                check: {
                    ok: true,
                },
            };
        },
        async getCoins() {
            let resp = cachedCoins.getEntity();
            if (resp === null) {
                resp = await poloniexGetCoins();
                cachedCoins.setEntity(resp);
            }
            let coins = [];
            for (let curr of resp) {
                coins.push({
                    coinName: curr.coin,
                    ownName: curr.name,
                });
            }
            return {
                raw: resp,
                coins: coins,
            };
        },
        async getCoinNetworks(coinName) {
            let resp = await poloniexGetCoin(coinName);
            let networks = [];
            for (let network of resp.networkList) {
                networks.push({
                    networkName: network.blockchain,
                    ownName: network.blockchain,
                    depositOK: network.depositEnable,
                    withdrawOK: network.withdrawalEnable,
                    withdrawFeeCoins: network.withdrawFee,
                    withdrawFeeUSDT: null,
                    withdrawFeeRate: null,
                    withdrawMin: network.withdrawMin,
                    withdrawMax: null,
                    contractAddress: null,
                    depositFeeRate: null,
                    depositMin: null,
                    depositNbConfirmations: network.minConfirm,
                    depositTip: "",
                    withdarawTip: "",
                    withdrawPrecision: network.decimals,
                });
            }
            return {
                raw: resp,
                networks: networks,
            };
        },
        async consolidateSpotBalances() {
            throw new Error("no need for consolidation on POLONIEX");
        },
        async getSpotSymbols() {
            let poloniexSymbols = cachedSymbols.getEntity();
            if (poloniexSymbols === null) {
                poloniexSymbols = await poloniexGetSymbols(null);
                cachedSymbols.setEntity(poloniexSymbols);
            }
            let symbols = _poloniexParseSymbols(poloniexSymbols);
            return {
                raw: poloniexSymbols,
                symbols: symbols,
            };
        },
        async getSpotBalances() {
            let accounts = await poloniexGetSpotBalances(apiKeys);
            let balances = [];
            for (let account of accounts) {
                for (let balance of account.balances) {
                    balances.push({
                        coinName: balance.currency,
                        freeQty: balance.available,
                        lockedQty: balance.hold,
                    });
                }
            }
            return {
                raw: accounts,
                balances: balances,
            };
        },
        async getSpotCoinBalance(coinName) {
            let resp = await poloniexGetSpotBalances(apiKeys);
            let balance = undefined;
            for (let account of resp) {
                for (let b of account.balances) {
                    if (b.currency === coinName) {
                        balance = {
                            coinName: b.currency,
                            freeQty: b.available,
                            lockedQty: b.hold,
                        };
                        break;
                    }
                }
            }
            return {
                raw: resp,
                balance: balance ?? {
                    coinName: coinName,
                    freeQty: "0",
                    lockedQty: "0",
                },
            };
        },
        async getCoinNetworkDepositAddress({ coinName, networkName, }) {
            let resp = await poloniexGetCurrencyAddress(apiKeys, coinName, networkName);
            return {
                raw: resp,
                address: {
                    address: resp.address,
                    memo: null,
                    contractAddress: null,
                },
            };
        },
        async withdrawApply({ coinName, networkName, address, memo, amount, }) {
            let resp = await poloniexWithdrawApply(apiKeys, coinName, networkName, address, memo, amount);
            return {
                raw: resp,
                withdrawId: resp.withdrawalRequestsId.toString(),
            };
        },
        async withdrawCancel(withdrawId) {
            throw new Error("withdrawCancel is not available on Poloniex.");
        },
        async getWithdrawHistory({ coinName, networkName, }) {
            let resp = await poloniexGetWithdrawHistory(apiKeys);
            // console.log("POLONIEX: withdraw history:", resp)
            let withdrawEntries = [];
            for (let entry of resp.withdrawals) {
                if (coinName !== null && !entry.currency.startsWith(coinName)) {
                    console.log("POLONIEX: skip entry:", entry.currency, coinName);
                    continue;
                }
                let statusSeverity = types_1.StatusSeverity.Neutral;
                switch (entry.status) {
                    case PoloniexWithdrawStatus.Processing:
                        statusSeverity = types_1.StatusSeverity.Neutral;
                        break;
                    case PoloniexWithdrawStatus.AwaitingApproval:
                        statusSeverity = types_1.StatusSeverity.Warning;
                        break;
                    case PoloniexWithdrawStatus.Completed:
                        statusSeverity = types_1.StatusSeverity.Success;
                        break;
                    case PoloniexWithdrawStatus.CompleteError:
                        statusSeverity = types_1.StatusSeverity.Error;
                        break;
                }
                withdrawEntries.push({
                    id: entry.withdrawalRequestsId,
                    coinName: entry.currency,
                    networkName: "",
                    address: entry.address,
                    memo: null,
                    txID: entry.txid,
                    fee: entry.fee,
                    amount: entry.amount,
                    status: entry.status,
                    statusSeverity: statusSeverity,
                    timestamp: entry.timestamp * 1000, // ms
                });
            }
            return {
                raw: resp,
                history: withdrawEntries,
            };
        },
        async getDepositHistory({ coinName, networkName, }) {
            let resp = await poloniexGetDepositHistory(apiKeys);
            let depositEntries = [];
            for (let entry of resp.deposits) {
                if (coinName !== null && !entry.currency.startsWith(coinName)) {
                    continue;
                }
                let statusSeverity = types_1.StatusSeverity.Neutral;
                switch (entry.status) {
                    case PoloniexDepositStatus.Pending:
                        statusSeverity = types_1.StatusSeverity.Warning;
                        break;
                    case PoloniexDepositStatus.Completed:
                        statusSeverity = types_1.StatusSeverity.Success;
                        break;
                }
                depositEntries.push({
                    coinName: entry.currency,
                    networkName: "",
                    address: entry.address,
                    memo: null,
                    txID: entry.txid,
                    amount: entry.amount,
                    status: entry.status,
                    statusSeverity: statusSeverity,
                    timestamp: entry.timestamp * 1000,
                    needConfirms: null,
                    confirms: entry.confirmations,
                });
            }
            return {
                raw: resp,
                history: depositEntries,
            };
        },
        // Spot trade
        async getSymbolOrderbook(symbolName) {
            let resp = await poloniexGetSymbolOrderbook(symbolName);
            let orderbook = {
                asks: [],
                bids: [],
            };
            for (let i = 0; i < resp.asks.length; i += 2) {
                let price = resp.asks[i];
                let amount = resp.asks[i + 1];
                if (price === undefined || amount === undefined) {
                    continue;
                }
                orderbook.asks.push({
                    price,
                    amount,
                });
            }
            for (let i = 0; i < resp.bids.length; i += 2) {
                let price = resp.bids[i];
                let amount = resp.bids[i + 1];
                if (price === undefined || amount === undefined) {
                    continue;
                }
                orderbook.bids.push({
                    price,
                    amount,
                });
            }
            return {
                raw: resp,
                orderbook,
            };
        },
        async getSpotSymbolOrdersHistory(symbolName) {
            let respOpenOrders = await poloniexGetOpenOrders(apiKeys, symbolName);
            let respOrdersHstory = await poloniexGetOrdersHistory(apiKeys, symbolName);
            let orders = [];
            for (let order of respOpenOrders) {
                if (order.accountType !== PoloniexAccountType.Spot) {
                    continue;
                }
                let _orderInfo = _poloniexOrderToOrderInfo(order);
                _orderInfo.isOpen = true;
                orders.push(_orderInfo);
            }
            for (let order of respOrdersHstory) {
                if (order.accountType !== PoloniexAccountType.Spot) {
                    continue;
                }
                let _orderInfo = _poloniexOrderToOrderInfo(order);
                orders.push(_orderInfo);
            }
            return {
                raw: respOrdersHstory,
                orders: orders,
            };
        },
        async placeSpotOrder({ symbolName, orderSide, orderType, timeInForce, price, baseQty, quoteQty, }) {
            let poloniexOrderSide = PoloniexOrderSide.Buy;
            if (orderSide === types_1.OrderSide.Sell) {
                poloniexOrderSide = PoloniexOrderSide.Sell;
            }
            let poloniexOrderType = PoloniexOrderType.Limit;
            if (orderType === types_1.OrderType.Market) {
                poloniexOrderType = PoloniexOrderType.Market;
            }
            let poloniexTimeInForce = PoloniexTimeInForce.GTC;
            if (timeInForce === types_1.OrderTimeInForce.ImmediateOrCancel) {
                poloniexTimeInForce = PoloniexTimeInForce.IOC;
            }
            else if (timeInForce === types_1.OrderTimeInForce.FillOrKill) {
                poloniexTimeInForce = PoloniexTimeInForce.FOK;
            }
            let resp = await poloniexPlaceOrder(apiKeys, symbolName, poloniexOrderSide, poloniexOrderType, poloniexTimeInForce, price, baseQty, quoteQty, false);
            return {
                raw: resp,
                orderId: resp.id,
            };
        },
        async cancelSpotOrder({ symbolName, orderId, }) {
            let resp = await poloniexCancelOrder(apiKeys, orderId);
            return {
                raw: resp,
                order: null,
                orderId: resp.orderId,
            };
        },
        async getSpotOrder() {
            throw new Error("getSpotOrder not implemented.");
        },
        // Margin trade
        async getMarginSymbols() {
            let poloniexSymbols = await poloniexGetSymbols(null);
            let poloniexMarginSymbols = poloniexSymbols.filter((s) => s.crossMargin.supportCrossMargin);
            let symbols = _poloniexParseSymbols(poloniexMarginSymbols);
            return {
                raw: poloniexMarginSymbols,
                symbols: symbols,
            };
        },
        async getMarginBalances() {
            let marginBorrowResp = await poloniexGetMarginBalances(apiKeys, null);
            let balancesResp = await poloniexGetSpotBalances(apiKeys);
            let balances = [];
            for (let account of balancesResp) {
                for (let balance of account.balances) {
                    let marginBorrowStatus = marginBorrowResp.find((b) => b.currency === balance.currency);
                    let bal = {
                        coinName: balance.currency,
                        freeQty: balance.available,
                        lockedQty: balance.hold,
                        borrowedQty: "0",
                        netQty: "0",
                    };
                    if (marginBorrowStatus !== undefined) {
                        bal.borrowedQty = marginBorrowStatus.borrowed;
                        bal.netQty = marginBorrowStatus.maxAvailable;
                    }
                    balances.push(bal);
                }
            }
            return {
                raw: marginBorrowResp,
                balances: balances,
            };
        },
        async getMarginCoinBalance({ coinName, }) {
            let balancesResp = await poloniexGetSpotBalances(apiKeys);
            let marginBalance = {
                coinName: coinName,
                freeQty: "0",
                lockedQty: "0",
                borrowedQty: "0",
                netQty: "0",
            };
            console.log(`POLONIEX: getMarginCoinBalance (${coinName}):`, balancesResp);
            for (let account of balancesResp) {
                for (let balance of account.balances) {
                    if (balance.currency === coinName) {
                        marginBalance.freeQty = balance.available;
                        marginBalance.lockedQty = balance.hold;
                        break;
                    }
                }
                break;
            }
            let marginBorrowStatusResp = await poloniexGetMarginBalances(apiKeys, coinName);
            let poloniexBalance = marginBorrowStatusResp[0];
            if (poloniexBalance !== undefined) {
                marginBalance.borrowedQty = poloniexBalance.borrowed;
                marginBalance.netQty = poloniexBalance.maxAvailable;
            }
            return {
                raw: marginBorrowStatusResp,
                balance: marginBalance,
            };
        },
        async getMarginSymbolOrdersHistory(symbolName) {
            let respOpenOrders = await poloniexGetOpenOrders(apiKeys, symbolName);
            let respOrdersHstory = await poloniexGetOrdersHistory(apiKeys, symbolName);
            let orders = [];
            for (let order of respOpenOrders) {
                if (order.accountType !== PoloniexAccountType.Margin) {
                    continue;
                }
                let _orderInfo = _poloniexOrderToOrderInfo(order);
                _orderInfo.isOpen = true;
                orders.push(_orderInfo);
            }
            for (let order of respOrdersHstory) {
                if (order.accountType !== PoloniexAccountType.Margin) {
                    continue;
                }
                let _orderInfo = _poloniexOrderToOrderInfo(order);
                orders.push(_orderInfo);
            }
            return {
                raw: respOrdersHstory,
                orders: orders,
            };
        },
        async marginLoanBorrow() {
            return {
                raw: "unified spot/margin account does not support manual borrow",
                loanId: null,
            };
        },
        async marginLoanRepay() {
            return {
                raw: "unified spot/margin account does not support manual repay",
                loanId: null,
            };
        },
        async placeMarginOrder({ symbolName, orderSide, orderType, timeInForce, price, baseQty, quoteQty, }) {
            let poloniexOrderSide = PoloniexOrderSide.Buy;
            if (orderSide === types_1.OrderSide.Sell) {
                poloniexOrderSide = PoloniexOrderSide.Sell;
            }
            let poloniexOrderType = PoloniexOrderType.Limit;
            if (orderType === types_1.OrderType.Market) {
                poloniexOrderType = PoloniexOrderType.Market;
            }
            let poloniexTimeInForce = PoloniexTimeInForce.GTC;
            if (timeInForce === types_1.OrderTimeInForce.ImmediateOrCancel) {
                poloniexTimeInForce = PoloniexTimeInForce.IOC;
            }
            else if (timeInForce === types_1.OrderTimeInForce.FillOrKill) {
                poloniexTimeInForce = PoloniexTimeInForce.FOK;
            }
            let resp = await poloniexPlaceOrder(apiKeys, symbolName, poloniexOrderSide, poloniexOrderType, poloniexTimeInForce, price, baseQty, quoteQty, true);
            return {
                raw: resp,
                orderId: null,
            };
        },
        async cancelMarginOrder({ symbolName, orderId, }) {
            let resp = await poloniexCancelOrder(apiKeys, orderId);
            return {
                raw: resp,
                order: null,
                orderId: resp.orderId,
            };
        },
        async getMarginOrder() {
            throw new Error("getMarginOrder not implemented.");
        },
        async transferCoinSpotToMargin() {
            return {
                raw: "unified spot/margin account does not support transfers",
                ok: true,
            };
        },
        async transferCoinMarginToSpot() {
            return {
                raw: "unified spot/margin account does not support transfers",
                ok: true,
            };
        },
        async getMarginMaxBorrowAmount({ coinName, }) {
            let resp = await poloniexGetMarginMaxBuySellAmounts(apiKeys, coinName + "_USDT");
            return {
                raw: resp,
                amount: resp.maxAvailableSell,
            };
        },
        async getMarginTransferableBalance({ coinName, }) {
            // throw new Error("getMarginTransferableBalance is not available in UNIFIED margin account mode.")
            let resp = await poloniexGetMarginAccountInfo(apiKeys);
            return {
                raw: resp,
                amount: resp.freeMargin,
            };
        },
        async getMarginLoanRecord() {
            throw new Error("getMarginLoanRecord not implemented.");
        },
        async getMarginLoanCeilingCoinMap() {
            throw new Error("getMarginLoanCeilingCoinMap not implemented.");
        },
        wsStateMachine() {
            throw new Error("wsStateMachine not implemented.");
        },
    };
};
exports.NewPoloniexExchangeDriver = NewPoloniexExchangeDriver;
